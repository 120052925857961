import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import SearchPage from 'containers/SearchPage';
import { RootState } from 'reducers/index';
import { BreadcrumbRouteMap } from 'components/Breadcrumbs/Breadcrumbs';
import { itemSelector } from 'modules/files';
import DocumentContextualize from './DocumentContextualize';
import DocumentContextualizeFilePreview from './DocumentContextualizeFilePreview';

const PDF_FILTER = {
  files: {
    filter: {
      mimeType: 'application/pdf',
    },
  },
};

function DocumentContextualizeRoutes() {
  const match = useRouteMatch<{ tenant: string; fileId?: string }>();

  return (
    <>
      <Switch>
        <Route
          exact
          path={`${match.path}/`}
          render={() => (
            <SearchPage
              type="files"
              defaultFilters={PDF_FILTER}
              noDataText="No documents found"
            />
          )}
        />
        <Route
          exact
          path={`${match.path}/:filesDataKitId`}
          render={() => <SearchPage type="assets" />}
        />
        <Route
          exact
          path={`${match.path}/:filesDataKitId/:assetsDataKitId`}
          component={DocumentContextualize}
        />
        <Route
          exact
          path={`${match.path}/:filesDataKitId/:assetsDataKitId/document/:fileId`}
          component={DocumentContextualizeFilePreview}
        />
      </Switch>
    </>
  );
}

function breadcrumbs(mountedAt: string) {
  return (state: RootState): BreadcrumbRouteMap => {
    const fileMap = itemSelector(state);
    return {
      [`${mountedAt}`]: ['Document Contextualization', 'File Selection'],
      [`${mountedAt}/:filesDataKitId`]: ['Reference Asset Selection'],
      [`${mountedAt}/:filesDataKitId/:assetsDataKitId`]: ['Model Results'],
      [`${mountedAt}/:filesDataKitId/:assetsDataKitId/document/:fileId`]: ({
        fileId,
      }) => {
        const file = fileMap(Number(fileId));
        if (file) {
          return [file.name];
        }
        return ['Loading'];
      },
    };
  };
}

DocumentContextualizeRoutes.breadcrumbs = breadcrumbs;

export default DocumentContextualizeRoutes;
