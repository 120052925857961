import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import { Icons } from '@cognite/cogs.js';

import Layout from 'antd/lib/layout';
import Link from 'components/Link';
import { Section } from 'components/Navigation';
import Menu from 'components/Navigation/Menu';
import theme from 'styles/theme';

import CogniteLogo from './CogniteLogo';
import defaultSections from './defaultSections';
import DefaultTopBarContent from './DefaultTopBarContent';

const { Header: AntdHeader } = Layout;

const hideProjectNameMapper: { [key: string]: boolean } = {
  'ncs-demo': true,
};

interface NavigationProps {
  height?: number;
  logo?: string | ReactElement;
  appName?: string;
  projectName?: string;
  sections?: Section[];
  topBarContent?: ReactElement;
  metrics?: any;
  user?: string;
}

const Navigation = (props: NavigationProps) => {
  const {
    height = 64,
    logo = <CogniteLogo />,
    appName = '',
    projectName = null,
    sections = defaultSections,
    topBarContent: topBarContentProp = null,
    metrics = { track: () => {} },
    user,
  } = props;

  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [openTime, setOpenTime] = useState<number>(0);

  let topBarContent = topBarContentProp;
  if (!topBarContent) {
    topBarContent = <DefaultTopBarContent user={user} metrics={metrics} />;
  }

  const closeMenu = (destination: string) => {
    const closeTime = Date.now();
    const timeElapsed = closeTime - openTime;
    metrics.track('Menu.Close', { destination, timeElapsed });
    setOpenTime(0);
    setMenuVisible(false);
    window.removeEventListener('keydown', closeOnEsc, false);
  };

  function closeOnEsc(this: Window, e: KeyboardEvent) {
    if (e.keyCode === 27) {
      closeMenu('esc');
    }
  }

  const openMenu = () => {
    setOpenTime(Date.now());
    setMenuVisible(true);
    window.addEventListener('keydown', closeOnEsc, false);
  };

  const toggleMenu = () => {
    if (menuVisible) {
      closeMenu('menu-button');
    } else {
      openMenu();
    }
  };

  const onTopBarClick = () => {
    if (menuVisible) {
      closeMenu('top-bar');
    }
  };

  const showProjectName =
    appName && projectName && !hideProjectNameMapper[projectName];

  return (
    <>
      <NavigationBar height={height} onClick={onTopBarClick}>
        <MenuButton>
          <Button onClick={toggleMenu}>
            <Icons.Hamburger />
          </Button>
        </MenuButton>
        <Link
          to={`/${projectName}`}
          onClick={() => metrics.track('TopBar.Logo.Click')}
        >
          <AppNameContainer>
            <StyledLogo>{logo}</StyledLogo>
            {appName && <AppName>{appName}</AppName>}
          </AppNameContainer>
        </Link>
        {showProjectName && (
          <>
            <Separator>|</Separator>
            <ProjectName>{projectName}</ProjectName>
          </>
        )}
        <Content>{topBarContent}</Content>
      </NavigationBar>
      <Menu
        visible={menuVisible}
        onClose={closeMenu}
        sections={sections}
        height={height}
      />
    </>
  );
};

interface NavigationBarProps {
  height: number | string;
}

const NavigationBar = styled(AntdHeader)<NavigationBarProps>`
  z-index: 5;
  position: fixed;
  width: 100%;
  height: ${props => props.height}px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  @media (min-width: 992px) {
    padding: 0 50px;
  }
`;

const MenuButton = styled.div`
  flex: 0 0 48px;
  margin-right: 16px;
`;

const Content = styled.div`
  flex: 1 0 auto;
  margin-left: 16px;
`;

const Button = styled.div`
  font-size: 20px;
  color: ${theme.textColor};
  border: 0;
  height: 48px;
  width: 48px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    background-color: ${theme.navigationButton};
  }
`;

const AppNameContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.textColor};
  font-size: 20px;
`;

const StyledLogo = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 38px;
  }
`;

const AppName = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 16px;
  white-space: nowrap;
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

const Separator = styled.div`
  margin-left: 16px;
  font-size: 20px;
  color: ${theme.textColor};
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

const ProjectName = styled.div`
  margin-left: 16px;
  font-size: 20px;
  color: ${theme.textColor};
`;

export default Navigation;
