import React from 'react';
import { match as matchRoute } from 'path-to-regexp';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import styled from 'styled-components';
import { RootState } from 'reducers';
import Link from '../Link';

const StyledBreadcrumb = styled(Breadcrumb)`
  font-weight: 800;
  padding-bottom: 20px;
`;

type RouteFunction = (keys: { [key: string]: string }) => React.ReactNode[];
export type BreadcrumbRouteMap = {
  [key: string]: RouteFunction | React.ReactNode[];
};
export type BreadcrumbRouteMapFn = (state: RootState) => BreadcrumbRouteMap;

export const Breadcrumbs = ({
  routesMap,
}: {
  routesMap: BreadcrumbRouteMapFn[];
}) => {
  const location = useLocation();
  const match = useRouteMatch<{ tenant: string }>();

  const rootState = useSelector((state: RootState) => state);
  const routes: BreadcrumbRouteMap = routesMap.reduce(
    (accl, routeGen) => ({
      ...accl,
      ...routeGen(rootState),
    }),
    {} as BreadcrumbRouteMap
  );

  const pathSnippets = location.pathname.split('/').filter(i => i);
  const extraBreadcrumbItems: JSX.Element[] = [];
  pathSnippets.forEach((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    Object.keys(routes).forEach(item => {
      const matches = matchRoute<{ [key: string]: string }>(item, {
        decode: decodeURIComponent,
      })(url);
      if (matches) {
        if (typeof routes[item] === 'function') {
          (routes[item] as RouteFunction)(matches.params).forEach(text => {
            extraBreadcrumbItems.push(
              <Breadcrumb.Item key={url}>
                <Link to={url}>{text}</Link>
              </Breadcrumb.Item>
            );
          });
        } else {
          (routes[item] as string[]).forEach(text => {
            extraBreadcrumbItems.push(
              <Breadcrumb.Item key={url}>
                <Link to={url}>{text}</Link>
              </Breadcrumb.Item>
            );
          });
        }
      }
    });
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to={`/${match.params.tenant}`}>Data Studio</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return <StyledBreadcrumb separator=">">{breadcrumbItems}</StyledBreadcrumb>;
};
