import React from 'react';
import styled from 'styled-components';
import { Colors } from '@cognite/cogs.js';

type Props = {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  style?: React.CSSProperties;
};
export const Input = (props: Props) => {
  const { value, onChange, placeholder, disabled, style } = props;

  return (
    <StyledInput
      type="text"
      value={value}
      style={style}
      disabled={disabled}
      placeholder={placeholder}
      onChange={e => onChange && onChange(e.target.value)}
    />
  );
};

export const TextArea = (props: Props) => {
  const { value, onChange, placeholder, disabled, style } = props;

  return (
    <StyledTextArea
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      style={style}
      onChange={e => onChange && onChange(e.target.value)}
    />
  );
};

const StyledInput = styled.input<{ disabled?: boolean }>`
  background: ${props =>
    props.disabled
      ? Colors['greyscale-grey1'].hex()
      : Colors['greyscale-grey2'].hex()};
  border-radius: 4px;
  border: none;
  padding: 5px 16px;
  color: ${props =>
    props.disabled
      ? Colors['greyscale-grey6'].hex()
      : Colors['greyscale-grey8'].hex()};
  font-weight: bold;
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'default')};
`;

const StyledTextArea = styled.textarea<{ disabled?: boolean }>`
  background: ${props =>
    props.disabled
      ? Colors['greyscale-grey1'].hex()
      : Colors['greyscale-grey2'].hex()};
  border-radius: 4px;
  border: none;
  padding: 5px 16px;
  color: ${props =>
    props.disabled
      ? Colors['greyscale-grey6'].hex()
      : Colors['greyscale-grey8'].hex()};
  font-weight: bold;
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  min-height: 40px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'default')};
`;
