import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { itemSelector, retrieve } from 'modules/timeseries';
import { Button, Icon } from '@cognite/cogs.js';
import { qualityDimensions } from 'utils/DataQuality';
import { dqEventsSelector, fetchDQEventsForAssetId } from 'modules/dataQuality';
import {
  TimeseriesGraph,
  InfoGrid,
  DetailsItem,
  InfoCell,
} from 'components/Common';
import { DescriptionList } from '@cognite/gearbox';
import { useHistory } from 'react-router-dom';
import { Row } from 'antd';
import { CogniteEvent } from '@cognite/sdk';
import { Wrapper } from './Common';

const formatMetadata = (metadata: { [key: string]: any }) =>
  Object.keys(metadata).reduce(
    (agg, cur) => ({
      ...agg,
      [cur]: String(metadata[cur]) || '',
    }),
    {}
  );

export const TimeseriesMetadataPreview = ({
  timeseriesId,
  extraActions,
  showQualityEvents,
}: {
  timeseriesId: number;
  extraActions?: React.ReactNode[];
  showQualityEvents?: boolean;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const timeseries = useSelector(itemSelector)(timeseriesId);

  const qualityEventsSelector = useSelector(dqEventsSelector);
  const { items: events } =
    showQualityEvents && timeseries
      ? qualityEventsSelector(timeseries)
      : { items: [] };

  useEffect(() => {
    if (!timeseries) {
      dispatch(retrieve([{ id: timeseriesId }]));
    }
  }, [dispatch, timeseries, timeseriesId]);

  const assetId = timeseries?.assetId;

  useEffect(() => {
    if (showQualityEvents && assetId) {
      dispatch(fetchDQEventsForAssetId(assetId));
    }
  }, [dispatch, showQualityEvents, assetId]);

  return (
    <Wrapper>
      <Button className="back-button" onClick={() => history.goBack()}>
        Back
      </Button>
      <h1>
        <Icon type="Timeseries" />
        {timeseries ? timeseries.name : 'Loading...'}
      </h1>
      <InfoGrid noBorders>
        <Row type="flex" gutter={12} justify="start" className="button-row">
          {extraActions}
        </Row>
        {timeseries && (
          <>
            {!timeseries.isString && (
              <InfoCell noBorders>
                <TimeseriesGraph
                  timeseries={timeseries}
                  events={events.filter(
                    (e: CogniteEvent) =>
                      e.subtype && !!qualityDimensions[e.subtype]
                  )}
                  contextChart
                  graphHeight={500}
                />
              </InfoCell>
            )}

            <DetailsItem name="Description" value={timeseries.description} />
            <DetailsItem name="Unit" value={timeseries.unit} />
            <InfoCell noBorders>
              <DescriptionList
                valueSet={formatMetadata(timeseries.metadata ?? {})}
              />
            </InfoCell>
          </>
        )}
      </InfoGrid>
    </Wrapper>
  );
};
