import React, { useState } from 'react';
import { IAnnotation, IRectShapeData } from '@cognite/react-picture-annotation';
import { Badge, Card, Collapse, Row, Col } from 'antd';
import styled from 'styled-components';
import { PendingPnIDAnnotation } from 'utils/PnIDApi';
import { useSelector } from 'react-redux';
import { Button, Colors, Icon } from '@cognite/cogs.js';
import { selectAnnotations } from 'modules/annotations';

const AnnotationOverview = styled.div`
  button {
    pointer-events: auto;
    display: flex;
    align-items: center;
    i {
      margin-left: 6px;
    }
    .ant-badge {
      margin-right: 6px;
    }
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border: none;
    background-color: #fff;
  }

  button:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    background-color: ${Colors['greyscale-grey1'].hex()};
  }

  && .ant-card {
    pointer-events: auto;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 14px;

    h1 {
      text-align: center;
      font-size: 64px;
      line-height: 72px;
      font-weight: 700;
      margin-bottom: 0;
    }
    p.subtitle {
      text-align: center;
      font-weight: 700;
    }
  }
`;

const StyledCollapse = styled(Collapse)`
  && .ant-collapse-header {
    padding-left: 0px;
    display: flex;
    align-items: center;

    .ant-badge {
      margin-left: 16px;
    }
  }
  .ant-collapse-item {
    background: #fff;
    border: none;
  }
`;

type Props = {
  fileId: number;
  annotations: IAnnotation<IRectShapeData>[];
};

export const selectAnnotationColor = <T extends PendingPnIDAnnotation>(
  annotation: T,
  isSelected = false
) => {
  if (isSelected) {
    return Colors.midblue.hex();
  }
  // Assets are purple
  if (annotation.linkedAssetExternalId || annotation.linkedAssetId) {
    if (annotation.type.includes('User')) {
      return Colors['purple-3'].hex();
    }
    return Colors['purple-2'].hex();
  }

  // Files are orange
  if (annotation.linkedFileExternalId || annotation.linkedFileId) {
    if (annotation.type.includes('Model')) {
      return Colors['midorange-3'].hex();
    }
    return Colors['midorange-2'].hex();
  }

  // Undefined are pink
  return Colors['pink-3'].hex();
};

export const getPnIdAnnotationCategories = <T extends PendingPnIDAnnotation>(
  annotations: T[]
) =>
  annotations.reduce(
    (prev, el) => {
      if (el.linkedAssetExternalId || el.linkedAssetId) {
        if (!prev.Asset.items[el.type]) {
          prev.Asset.items[el.type] = [];
        }
        prev.Asset.items[el.type].push(el);
        prev.Asset.count += 1;
      } else if (el.linkedFileExternalId || el.linkedFileId) {
        if (!prev.File.items[el.type]) {
          prev.File.items[el.type] = [];
        }
        prev.File.items[el.type].push(el);
        prev.File.count += 1;
      } else {
        if (!prev.Unclassified.items[el.type]) {
          prev.Unclassified.items[el.type] = [];
        }
        prev.Unclassified.items[el.type].push(el);
        prev.Unclassified.count += 1;
      }
      return prev;
    },
    {
      Asset: { items: {}, count: 0 },
      File: { items: {}, count: 0 },
      Unclassified: { items: {}, count: 0 },
    } as {
      [key: string]: {
        items: { [key: string]: T[] };
        count: number;
      };
    }
  );

export const AnnotatedPnIDOverview = ({ fileId, annotations }: Props) => {
  const [showDetails, setShowDetails] = useState(false);
  const pnidAnnotations = useSelector(selectAnnotations)(fileId);

  if (showDetails) {
    const pnidAnnotationsDetails = getPnIdAnnotationCategories(pnidAnnotations);
    return (
      <AnnotationOverview>
        <Card
          style={{ width: '340px' }}
          title="Detected Linked Tags"
          extra={
            <Icon type="Up" onClick={() => setShowDetails(!showDetails)} />
          }
        >
          <h1>{annotations.length}</h1>
          <p className="subtitle">Detected Linked Tags</p>
          <StyledCollapse
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <Icon type={isActive ? 'CaretUp' : 'CaretDown'} />
            )}
          >
            {Object.keys(pnidAnnotationsDetails).map(type => {
              const { count, items } = pnidAnnotationsDetails[type];
              return (
                <Collapse.Panel
                  key={type}
                  header={
                    <div style={{ width: '100%' }}>
                      <p>
                        <strong>
                          {count} {type.toUpperCase()} LINKED TAGS
                        </strong>
                      </p>
                      {Object.keys(items).map(subType => (
                        <Row type="flex" key={subType} align="middle">
                          <Col span={16}>{subType}</Col>
                          <Col
                            span={8}
                            style={{
                              float: 'right',
                              display: 'inline-flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Badge
                              style={{
                                backgroundColor: selectAnnotationColor(
                                  items[subType][0]
                                ),
                              }}
                              count={items[subType].length}
                              showZero
                            />
                            /{pnidAnnotations.length}
                          </Col>
                        </Row>
                      ))}
                    </div>
                  }
                />
              );
            })}
          </StyledCollapse>
        </Card>
      </AnnotationOverview>
    );
  }
  return (
    <AnnotationOverview>
      <Button
        shape="round"
        type="secondary"
        onClick={() => setShowDetails(!showDetails)}
        icon="CaretDown"
        iconPlacement="right"
      >
        <Badge
          style={{ backgroundColor: Colors.midblue.hex() }}
          count={pnidAnnotations.length}
          showZero
        />{' '}
        DETECTED TAGS
      </Button>
    </AnnotationOverview>
  );
};
