import './styles/index.css';
import '@cognite/cogs.js/dist/cogs.css';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ClientSDKProvider } from '@cognite/gearbox';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'styles/global-styles';
import * as serviceWorker from './serviceWorker';
import store from './store';
import Routes from './routes';
import theme from './styles/theme';

import './utils/sentry';

render(
  <ClientSDKProvider client={store.getState().app.sdk}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Routes />
      </Provider>
    </ThemeProvider>
    <GlobalStyle theme={theme} />
  </ClientSDKProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
