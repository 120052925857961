import React, { useEffect } from 'react';
import {
  Card,
  InstanceMetadata,
  CoolH1,
  Header,
  BaseDropdown,
  PropertyCountLabel,
  Loader,
} from 'components/Common';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectInstance,
  removeType,
  selectInstanceStatus,
} from 'modules/instances';
import { Tabs, Skeleton, Modal, message, Empty } from 'antd';
import { itemSelector as typeSelector } from 'modules/types';
import { Colors, Button } from '@cognite/cogs.js';
import { retrieve, itemSelector } from 'modules/assets';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { PropertiesPane, DataQualityPane } from '../Property';

const Wrapper = styled.div`
  .row,
  .ant-row {
    margin-bottom: 32px;
  }

  .ant-tabs-tab-active {
    color: ${Colors.midblue.hex()};
    border-color: ${Colors.midblue.hex()};
  }

  .ant-tabs-tab {
    font-weight: 800;
    font-family: 'proxima-nova';
  }
`;

export const EditInstance = ({
  instanceId,
  typeId,
}: {
  instanceId: number;
  typeId: number;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { tenant } = useParams<{ tenant: string }>();
  const { search } = useLocation();
  const type = useSelector(typeSelector)(typeId)!;
  const instance = useSelector(selectInstance)(instanceId);
  const instanceStatus = useSelector(selectInstanceStatus)(instanceId);
  const rootAsset = useSelector(itemSelector)(
    instance ? instance.rootId : undefined
  );

  const { tab: urlTab }: { tab?: string } = queryString.parse(search);

  const tab =
    urlTab === 'properties' || urlTab === 'dataquality' ? urlTab : 'properties';

  useEffect(() => {
    if (instance) {
      dispatch(retrieve([{ id: instance.rootId }]));
    }
  }, [dispatch, instance]);

  const onRemoveInstance = () => {
    Modal.confirm({
      title: 'Are you sure?',
      content: `You will lose all set properties by completing this option. The asset will still remain in CDF, but it will no longer share the ${type.name} type`,
      onOk: async () => {
        if (instance) {
          try {
            await dispatch(removeType(instance.id, type));
            message.success('Successfully deleted instance');
            history.push(`/${tenant}/templates/${type.id}/instances`);
          } catch {
            message.error('Unable to delete instance');
          }
        }
      },
    });
  };

  if (instanceStatus.inProgress) {
    return <Loader />;
  }
  if (!instance) {
    return <Empty description="Unable to load instance" />;
  }
  return (
    <Wrapper>
      <div className="row">
        {instance ? (
          <InstanceMetadata
            instance={instance}
            type={type}
            rootAsset={rootAsset}
            onDelete={onRemoveInstance}
          />
        ) : (
          <Skeleton />
        )}
      </div>
      <div className="row">
        <Card>
          <div className="content">
            <Header
              title={
                <CoolH1>
                  Properties{' '}
                  <PropertyCountLabel type={type} instance={instance} />
                </CoolH1>
              }
              extra={[
                <Button
                  variant="outline"
                  icon="Plus"
                  key="add"
                  onClick={() => {
                    history.push({
                      search: queryString.stringify({
                        ...queryString.parse(search),
                        tab: 'timeseries',
                      }),
                    });
                  }}
                >
                  Add Data
                </Button>,
                <Button
                  icon="Timeseries"
                  variant="outline"
                  key="add-qm"
                  disabled
                >
                  Add quality monitoring
                </Button>,
                <BaseDropdown
                  disabled
                  options={[
                    { value: 'required', name: 'Required' },
                    { value: 'optional', name: 'Optional' },
                  ]}
                  key="select"
                  icon="TriangleDown"
                />,
              ]}
            />
            <Tabs
              animated={false}
              activeKey={tab}
              onChange={key => {
                history.push({
                  search: queryString.stringify({
                    ...queryString.parse(search),
                    tab: key,
                  }),
                });
              }}
            >
              <Tabs.TabPane tab="Properties" key="properties">
                <PropertiesPane
                  typeId={typeId}
                  instanceId={instanceId}
                  renderExtraButtons={() => []}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Data Quality" key="dataquality">
                <DataQualityPane
                  typeId={typeId}
                  instanceId={instanceId}
                  renderExtraButtons={() => []}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </Card>
      </div>
    </Wrapper>
  );
};
