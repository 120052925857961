import React from 'react';
import { useDispatch } from 'react-redux';
import { TenantSelector } from '@cognite/gearbox';
import styled from 'styled-components';
import { setTenant, setCdfEnv } from 'modules/app';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
`;

const TenantSelectorContainer = styled.div`
  max-width: 600px;
  min-width: 400px;
  align-self: center;
`;

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Wrapper>
      <TenantSelectorContainer>
        <TenantSelector
          title="Data Studio"
          onTenantSelected={(tenant, advancedOptions) => {
            const cdfEnv = advancedOptions
              ? (advancedOptions['CDF Environment'] as string)
              : undefined;
            dispatch(setCdfEnv(cdfEnv));
            dispatch(setTenant(tenant));
            history.push(`/${tenant}${window.location.search}`);
          }}
          header="Cognite Data Fusion project name"
          validateTenant={(tenant, advancedOptions) => {
            const cdfEnv = advancedOptions
              ? advancedOptions['CDF Environment']
              : null;
            const clusterParam = cdfEnv ? `&cluster=${cdfEnv}` : '';
            return fetch(
              `https://apps-api.cognite.ai/tenant?tenant=${tenant}&app=opin${clusterParam}`
            ).then(response => response.json());
          }}
          loginText="Authenticate"
          placeholder="Project name"
          unknownMessage="The name you entered is not a valid project in Cognite Data Fusion"
          advancedOptions={{ 'CDF Environment': '' }}
        />
      </TenantSelectorContainer>
    </Wrapper>
  );
}
