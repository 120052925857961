import React, { useState, useRef } from 'react';
import { Table, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Icon } from '@cognite/cogs.js';

import {
  Prediction,
  getPredictionsSelector,
} from 'modules/contextualization/predictions';
import {
  retrieve as retrieveAssets,
  itemSelector as assetSelector,
} from 'modules/assets';

import TimeseriesModal from 'components/TimeseriesModal';
import ItemWithDescription from 'components/ItemWithDescription';
import { trackUsage } from 'utils/Metrics';
// import { animationTag } from './animation';
import { RootState } from 'reducers';
import AssetSearchWithRec from './AssetSearchWithRec';

interface ManualMatchingProps {
  resourceDataKitId: string;
  assetDKId: string;
  itemFilter: (p: Prediction) => boolean;
}
export default function ManualMatching(props: ManualMatchingProps) {
  const dispatch = useDispatch();

  const { resourceDataKitId, assetDKId, itemFilter } = props;
  const [resourceSearchQuery, setResourceQuery] = useState<string>('');
  const searchInput = useRef<Input>(null);

  const handleSearch = (selectedKeys: string[], confirm: () => void) => {
    confirm();
    setResourceQuery(selectedKeys[0]);
  };

  interface FilterDropdownProps {
    setSelectedKeys: (selectedKeys: string[]) => void;
    selectedKeys: string[];
    confirm: () => void;
    clearFilters: () => void;
  }
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: (p: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={p.selectedKeys[0]}
          onChange={e =>
            p.setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(p.selectedKeys, p.confirm)}
          style={{ width: 268, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(p.selectedKeys, p.confirm)}
          icon="Search"
          size="small"
          style={{ width: 130, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            p.clearFilters();
            setResourceQuery('');
          }}
          size="small"
          style={{ width: 130 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <Icon type="Search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (searchValue: string, prediction: any) =>
      prediction[dataIndex]
        .toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => {
          if (searchInput && searchInput.current) {
            searchInput.current.select();
          }
        });
      }
    },
  });

  const { type } = useSelector(
    (state: RootState) => state.selection.items[resourceDataKitId]
  );
  const getAsset = useSelector(assetSelector);
  const predictions = useSelector(getPredictionsSelector)(
    resourceDataKitId,
    assetDKId
  ).filter(itemFilter);
  const hasExistingAssetId =
    predictions.filter(p => !!p.resource?.assetId).length > 0;

  const resourceAssetIds = predictions
    .map(p => p.resource?.assetId)
    .filter(id => !!id)
    .map(id => ({ id })) as { id: number }[];

  dispatch(retrieveAssets(resourceAssetIds));

  const [selectedTimeseriesId, setSelectedTimeseriesId] = useState<
    number | undefined
  >();
  const columns = [
    {
      title: resourceSearchQuery ? (
        <>
          <span>Resource</span>
          <span style={{ marginLeft: 5 }}>(filter:</span>
          <span style={{ marginLeft: 5, backgroundColor: '#ffc069' }}>
            {resourceSearchQuery})
          </span>
        </>
      ) : (
        'Resource'
      ),
      dataIndex: 'input',
      key: 'input',
      sorter: (a: Prediction, b: Prediction) =>
        a.matchFrom.name.localeCompare(b.matchFrom.name),
      render: (text: string, prediction: Prediction) => (
        <ItemWithDescription
          onClick={() => {
            // @ts-ignore
            if (type === 'timeseries') {
              trackUsage(
                'Contextualization.ResourceMatching.TimeSeries.Manual.TimeSeriesClick',
                {
                  timeSeriesName: prediction.matchFrom.id,
                  dataSetId: resourceDataKitId,
                }
              );
              setSelectedTimeseriesId(prediction.matchFrom.id);
            }
          }}
          name={prediction.matchFrom.name || text}
          highlight={resourceSearchQuery}
          description={prediction.matchFrom?.description || ''}
        />
      ),
      ...getColumnSearchProps('input'),
    },
    {
      title: 'New asset mapping',
      dataIndex: 'input',
      key: 'assetRecs',
      width: 500,
      render: (id: number, prediction: Prediction) => {
        return (
          <AssetSearchWithRec
            key={id}
            prediction={prediction}
            resourceDataKitId={resourceDataKitId}
          />
        );
      },
      sorter: (a: Prediction, b: Prediction) => b.score - a.score,
    },
  ];

  if (hasExistingAssetId) {
    columns.push({
      title: 'Current asset',
      dataIndex: 'resource.assetId',
      key: 'resource',
      width: 200,
      sorter: (a: Prediction, b: Prediction) => {
        const assetIdA = a.matches[0]?.matchTo.id;
        const assetNameA = assetIdA ? getAsset(assetIdA)?.name || '' : '';
        const assetIdB = b.matches[0]?.matchTo.id;
        const assetNameB = assetIdB ? getAsset(assetIdB)?.name || '' : '';
        return assetNameA.localeCompare(assetNameB);
      },
      render: (_: number, prediction: Prediction) => {
        const assetId = prediction.matches[0]?.matchTo.id;
        const assetName = assetId ? getAsset(assetId)?.name : '';
        return <span>{assetName}</span>;
      },
    });
  }

  // TODO: add a small animation when removing rows
  return (
    <>
      {selectedTimeseriesId && (
        <TimeseriesModal
          onClose={() => setSelectedTimeseriesId(undefined)}
          timeseriesId={selectedTimeseriesId}
        />
      )}
      <Table
        pagination={{ defaultPageSize: 20 }}
        dataSource={predictions}
        columns={columns}
        rowKey="input"
      />
    </>
  );
}
