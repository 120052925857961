import {
  CogniteClient,
  ExternalEvent,
  CogniteEvent,
  FilesMetadata,
  Asset,
  IdEither,
} from '@cognite/sdk';
import { PnidResponseEntity } from 'modules/pnidParsing';
import { stripWhitespace } from 'helpers/Helpers';

import { canEditFiles, canReadFiles } from './PermissionUtils';

export const PNID_PARSING_JOB_ID_METADATA_FIELD =
  '__COGNITE_PNID_PARSING_JOB_ID';
export const PNID_METADATA_IDENTIFIER = '__COGNITE_PNID';
export const COGNITE_DETECTION_EVENT_TYPE = 'cognite_detections';
export const COGNITE_PNID_DETECTION_EVENT_SOURCE =
  'cognite_pnid_parsing_source';

export type PnIDAnnotationType =
  | 'Model Generated'
  | 'User Defined'
  | 'User Confirmed'
  | 'User Deleted'
  | 'Model Recommended';

export type BoundingBoxObject = {
  xMax: number;
  xMin: number;
  yMax: number;
  yMin: number;
};

export interface PnIDAnnotation {
  id: number;
  fileId?: number;
  fileExternalId?: string;
  label: string;
  type: PnIDAnnotationType;
  linkedAssetId?: number;
  linkedFileId?: number;
  linkedAssetExternalId?: string;
  linkedFileExternalId?: string;
  isDeleted?: boolean;
  version: 3;
  source: string;
  boundingBox: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
}

export interface PendingPnIDAnnotation extends Omit<PnIDAnnotation, 'id'> {}

export class PnIDApi {
  sdk: CogniteClient;

  constructor(sdk: CogniteClient) {
    this.sdk = sdk;
  }

  public createAnnotations = async (
    pendingAnnotations: PendingPnIDAnnotation[],
    addLinkage = false
  ) => {
    const annotations = await this.annotationsToEvents(pendingAnnotations);

    if (addLinkage) {
      // update file
      const updates = this.extractAssetIdsFromAnnotations(annotations);

      if (updates.length > 0) {
        await this.sdk.files.update(
          updates.map(update => ({
            ...(update.id
              ? { id: update.id! }
              : { externalId: update.externalId! }),
            update: {
              assetIds: {
                add: [...update.assetIds],
              },
            },
          }))
        );
      }
    }

    return annotations;
  };

  public listAnnotations = async (
    file: FilesMetadata,
    fetchAll = false
  ): Promise<PnIDAnnotation[]> => {
    try {
      const events = (
        await this.sdk.events
          .list({
            filter: {
              type: COGNITE_DETECTION_EVENT_TYPE,
              metadata: {
                fileId: `${file.id}`,
                version: '3',
                ...(fetchAll ? {} : { isDeleted: 'false' }),
              },
            },
          })
          .autoPagingToArray({ limit: -1 })
      ).concat(
        file.externalId
          ? await this.sdk.events
              .list({
                filter: {
                  type: COGNITE_DETECTION_EVENT_TYPE,
                  metadata: {
                    fileExternalId: `${file.externalId}`,
                    version: '3',
                    ...(fetchAll ? {} : { isDeleted: 'false' }),
                  },
                },
              })
              .autoPagingToArray({ limit: -1 })
          : []
      );

      return this.eventToAnnotations(events);
    } catch (e) {
      return [];
    }
  };

  public clearAnnotations = async (file: FilesMetadata, clearAll = false) => {
    const annotations = await this.listAnnotations(file);
    const toBeDeleted = annotations.filter(el =>
      clearAll ? true : el.type !== 'User Defined'
    );
    await this.deleteAnnotations(toBeDeleted);
    return toBeDeleted;
  };

  public deleteAnnotations = async (annotations: PnIDAnnotation[]) => {
    if (annotations.length === 0) {
      return;
    }

    const events = annotations.map(el => ({
      ...this.convertAnnotationToEvents(el),
      id: el.id,
    }));

    await this.sdk.events.update(
      events.map(el => ({
        id: el.id,
        update: {
          metadata: {
            set: {
              ...el.metadata,
              isDeleted: 'true',
            },
          },
        },
      }))
    );
  };

  public createPendingAnnotationsFromJob = async (
    file: FilesMetadata,
    entities: PnidResponseEntity[],
    refAssets: Asset[],
    refFiles: FilesMetadata[],
    jobId: string,
    existingEntities: PnIDAnnotation[]
  ): Promise<PendingPnIDAnnotation[]> => {
    const assetsMap = refAssets.reduce((prev, asset) => {
      const key = stripWhitespace(asset.name);
      if (!prev[key]) {
        prev[key] = { id: [], externalId: [] };
      }
      prev[key].id.push(asset.id);
      if (asset.externalId) {
        prev[key].externalId.push(asset.externalId);
      }
      return prev;
    }, {} as { [key: string]: { id: number[]; externalId: string[] } });

    const filesMap = refFiles.reduce((prev, item) => {
      const key = stripWhitespace(removeExtension(item.name));
      if (!prev[key]) {
        prev[key] = { id: [], externalId: [] };
      }
      prev[key].id.push(item.id);
      if (item.externalId) {
        prev[key].externalId.push(item.externalId);
      }
      return prev;
    }, {} as { [key: string]: { id: number[]; externalId: string[] } });

    const boundingBoxes = existingEntities
      .filter(
        // including the deleted model generated ones or the existing ones
        el => !el.isDeleted
      )
      .map(el => ({
        xMin: el.boundingBox.x,
        xMax: el.boundingBox.x + el.boundingBox.width,
        yMin: el.boundingBox.y,
        yMax: el.boundingBox.y + el.boundingBox.height,
      }));

    const deletedEntities = existingEntities.filter(
      // including the deleted model generated ones or the existing ones
      el => el.isDeleted
    );

    const filteredEntities = entities.filter(el => {
      return !boundingBoxes.some(
        box =>
          // smaller
          isSimilarBoundingBox(box, el.boundingBox, 1, true) ||
          // bigger or smaller by 20%
          isSimilarBoundingBox(box, el.boundingBox, 0.2, false)
      );
    });

    const annotations = filteredEntities.map((el: any) => {
      let assetId: number | undefined;
      let assetExternalId: string | undefined;
      let fileId: number | undefined;
      let fileExternalId: string | undefined;
      const strippedEntityText = stripWhitespace(el.text);

      if (
        assetsMap[strippedEntityText] &&
        assetsMap[strippedEntityText].id.length === 1
      ) {
        if (assetsMap[strippedEntityText].externalId.length === 1) {
          [assetExternalId] = assetsMap[strippedEntityText].externalId;
        } else {
          [assetId] = assetsMap[strippedEntityText].id;
        }
      }
      if (
        filesMap[strippedEntityText] &&
        filesMap[strippedEntityText].id.length === 1
      ) {
        if (filesMap[strippedEntityText].externalId.length === 1) {
          [fileExternalId] = filesMap[strippedEntityText].externalId;
        } else {
          [fileId] = filesMap[strippedEntityText].id;
        }
      }

      if (
        deletedEntities.some(deletedEntity => {
          if (
            deletedEntity.linkedFileExternalId === fileExternalId ||
            deletedEntity.linkedFileId === fileId ||
            deletedEntity.linkedAssetId === assetId ||
            deletedEntity.linkedAssetExternalId === assetExternalId
          ) {
            return isSimilarBoundingBox(
              {
                xMin: deletedEntity.boundingBox.x,
                xMax:
                  deletedEntity.boundingBox.x + deletedEntity.boundingBox.width,
                yMin: deletedEntity.boundingBox.y,
                yMax:
                  deletedEntity.boundingBox.y +
                  deletedEntity.boundingBox.height,
              },
              el.boundingBox,
              0.3
            );
          }
          return false;
        })
      ) {
        // some deleted one has same id and shape
        return undefined;
      }

      return {
        type: 'Model Generated',
        boundingBox: {
          x: el.boundingBox.xMin,
          y: el.boundingBox.yMin,
          width: el.boundingBox.xMax - el.boundingBox.xMin,
          height: el.boundingBox.yMax - el.boundingBox.yMin,
        },
        ...(!file.externalId ? { fileId: file.id } : {}),
        ...(file.externalId ? { fileExternalId: file.externalId } : {}),
        linkedAssetId: assetId,
        linkedAssetExternalId: assetExternalId,
        linkedFileId: fileId,
        linkedFileExternalId: fileExternalId,
        label: el.text,
        source: `${jobId}`,
        version: 3,
      } as PendingPnIDAnnotation;
    });

    return annotations.filter(el => !!el) as PendingPnIDAnnotation[];
  };

  public updateFileWithJobId = async (
    file: FilesMetadata,
    jobId: number
  ): Promise<FilesMetadata> => {
    if (!canEditFiles(true) && !canReadFiles(true)) {
      throw new Error('Missing Permissions!');
    }

    const [updateFile] = await this.sdk.files.update([
      {
        id: file.id,
        update: {
          metadata: {
            set: {
              ...file.metadata,
              [PNID_PARSING_JOB_ID_METADATA_FIELD]: `${jobId}`,
              [PNID_METADATA_IDENTIFIER]: 'true',
            },
          },
        },
      },
    ]);
    return updateFile;
  };

  public updateFileWithAnnotations = async (
    fileId: number,
    annotations: (PendingPnIDAnnotation | PnIDAnnotation)[]
  ): Promise<FilesMetadata> => {
    if (!canEditFiles(true) && !canReadFiles(true)) {
      throw new Error('Missing Permissions!');
    }

    const assetIds = new Set<number>();
    const externalIds = new Set<string>();
    annotations.forEach(el => {
      if (el.linkedAssetId) {
        assetIds.add(el.linkedAssetId);
      }
      if (el.linkedAssetExternalId) {
        externalIds.add(el.linkedAssetExternalId);
      }
    });

    if (externalIds.size > 0) {
      try {
        const assets = await this.sdk.assets.retrieve(
          [...externalIds].map(externalId => ({ externalId }))
        );
        assets.forEach(el => assetIds.add(el.id));
      } catch {
        // noop
      }
    }
    const [updateFile] = await this.sdk.files.update([
      {
        id: fileId,
        update: {
          assetIds: {
            add: [...assetIds],
          },
        },
      },
    ]);
    return updateFile;
  };

  public getPngUrl = async (file: FilesMetadata) => {
    if (file.metadata && file.metadata![PNID_PARSING_JOB_ID_METADATA_FIELD]) {
      const convertJobId = file.metadata![PNID_PARSING_JOB_ID_METADATA_FIELD];
      const response = await this.sdk.get(
        `/api/playground/projects/${this.sdk.project}/context/pnid/convert/${convertJobId}`
      );
      if (response.status !== 200 || !response.data.pngUrl) {
        throw new Error('Unable to load a preview PNG for this file.');
      }
      return response.data.pngUrl;
    }
    throw new Error('File is missing output from P&ID PNG conversion job');
  };

  private annotationsToEvents = async (
    annotations: PendingPnIDAnnotation[]
  ): Promise<PnIDAnnotation[]> => {
    const pendingEvents: ExternalEvent[] = annotations.map(
      this.convertAnnotationToEvents
    );

    if (pendingEvents.length > 0) {
      // TODO fix for 1000 +
      const events = await this.sdk.events.create(pendingEvents);

      return annotations.map((el, i) => ({
        ...el,
        id: events[i].id,
      }));
    }

    return [];
  };

  private convertAnnotationToEvents = (
    el: PendingPnIDAnnotation
  ): ExternalEvent => {
    return {
      type: COGNITE_DETECTION_EVENT_TYPE,
      subtype: el.type,
      description: el.label,
      metadata: {
        box: JSON.stringify(el.boundingBox),
        version: `${el.version}`,
        ...(el.linkedFileId && { linkedFileId: `${el.linkedFileId}` }),
        ...(el.linkedFileExternalId && {
          linkedFileExternalId: `${el.linkedFileExternalId}`,
        }),
        ...(el.linkedAssetId && { linkedAssetId: `${el.linkedAssetId}` }),
        ...(el.linkedAssetExternalId && {
          linkedAssetExternalId: `${el.linkedAssetExternalId}`,
        }),
        ...(el.fileId && { fileId: `${el.fileId}` }),
        ...(el.fileExternalId && { fileExternalId: `${el.fileExternalId}` }),
        ...(el.source && { source: `${el.source}` }),
        isDeleted: el.isDeleted ? 'true' : 'false',
      },
      source: COGNITE_PNID_DETECTION_EVENT_SOURCE,
    };
  };

  public extractAssetIdsFromAnnotations = (annotations: PnIDAnnotation[]) => {
    return annotations.reduce((prev, annotation) => {
      if (annotation.linkedAssetId) {
        const currentEdit = prev.find(
          change => change.id === annotation.fileId
        );
        if (currentEdit) {
          if (annotation.linkedAssetId) {
            currentEdit.assetIds.add(annotation.linkedAssetId);
          }
          if (annotation.linkedAssetExternalId) {
            currentEdit.externalAssetIds.add(annotation.linkedAssetExternalId);
          }
        } else {
          prev.push({
            id: annotation.fileId,
            externalId: annotation.fileExternalId,
            assetIds: new Set(
              annotation.linkedAssetId ? [annotation.linkedAssetId] : []
            ),
            externalAssetIds: new Set(
              annotation.linkedAssetExternalId
                ? [annotation.linkedAssetExternalId]
                : []
            ),
          });
        }
      }
      return prev;
    }, [] as { id?: number; externalId?: string; assetIds: Set<number>; externalAssetIds: Set<string> }[]);
  };

  private eventToAnnotations = (events: CogniteEvent[]): PnIDAnnotation[] => {
    return events.map(event => {
      const {
        linkedAssetId,
        linkedAssetExternalId,
        linkedFileId,
        linkedFileExternalId,
        box,
        version,
        source,
        fileId,
        fileExternalId,
        isDeleted,
      } = event.metadata!;
      return {
        id: event.id,
        type: event.subtype,
        label: event.description,
        linkedAssetId: linkedAssetId ? Number(linkedAssetId) : undefined,
        linkedAssetExternalId,
        linkedFileId: linkedFileId ? Number(linkedFileId) : undefined,
        linkedFileExternalId,
        boundingBox: JSON.parse(box!),
        version: Number(version),
        source,
        isDeleted: isDeleted === 'true',
        fileId: fileId ? Number(fileId) : undefined,
        fileExternalId,
      };
    }) as PnIDAnnotation[];
  };

  public filesLinkedToAssetId = async (
    asset: Asset
  ): Promise<FilesMetadata[]> => {
    const annotations = await this.sdk.events
      .list({
        filter: {
          type: COGNITE_DETECTION_EVENT_TYPE,
          source: COGNITE_PNID_DETECTION_EVENT_SOURCE,
          metadata: {
            version: '3',
            isDeleted: 'false',
            linkedAssetId: `${asset.id}`,
          },
        },
      })
      .autoPagingToArray({ limit: undefined });
    const externalAnnotations = asset.externalId
      ? await this.sdk.events
          .list({
            filter: {
              type: COGNITE_DETECTION_EVENT_TYPE,
              source: COGNITE_PNID_DETECTION_EVENT_SOURCE,
              metadata: {
                version: '3',
                isDeleted: 'false',
                linkedAssetExternalId: `${asset.externalId}`,
              },
            },
          })
          .autoPagingToArray({ limit: undefined })
      : [];

    const fileIds = new Set<number | string>();
    annotations.concat(externalAnnotations).forEach(item => {
      if (
        item.metadata &&
        item.metadata.fileId &&
        Number.isInteger(Number(item.metadata.fileId))
      ) {
        fileIds.add(Number(item.metadata.fileId));
      } else if (item.metadata && item.metadata.fileExternalId) {
        fileIds.add(item.metadata.fileExternalId);
      }
    });

    if (fileIds.size === 0) {
      return [];
    }
    try {
      return this.sdk.files.retrieve(
        [...fileIds].map(id =>
          typeof id === 'number' ? { id } : { externalId: id }
        )
      );
    } catch (ex) {
      ex.errors.forEach((error: { missing?: IdEither[] }) => {
        if (error.missing) {
          error.missing.forEach((el: any) =>
            fileIds.delete(el.externalId || el.id)
          );
        }
      });
      return fileIds.size > 0
        ? this.sdk.files.retrieve(
            [...fileIds].map(id =>
              typeof id === 'number' ? { id } : { externalId: id }
            )
          )
        : [];
    }
  };
}

export const removeExtension = (name: string) => {
  const indexOfExtension = name.lastIndexOf('.');
  if (indexOfExtension !== -1) {
    return name.substring(0, indexOfExtension);
  }
  return name;
};

export const isSimilarBoundingBox = (
  origBox: BoundingBoxObject,
  compBox: BoundingBoxObject,
  percentDiff = 0.1,
  smallerOnly = false
) => {
  const { xMax, xMin, yMax, yMin } = origBox;
  // check right
  if (
    compBox.xMax <= (smallerOnly ? xMax : xMax * (1 + percentDiff)) &&
    compBox.xMax >= xMax * (1 - percentDiff)
  ) {
    // check bottom
    if (
      compBox.yMax <= (smallerOnly ? yMax : yMax * (1 + percentDiff)) &&
      compBox.yMax >= yMax * (1 - percentDiff)
    ) {
      // check left
      if (
        compBox.xMin >= (smallerOnly ? xMin : xMin * (1 - percentDiff)) &&
        compBox.xMin <= xMin * (1 + percentDiff)
      ) {
        // check top
        if (
          compBox.yMin >= (smallerOnly ? yMin : yMin * (1 - percentDiff)) &&
          compBox.yMin <= yMin * (1 + percentDiff)
        ) {
          return true;
        }
      }
    }
  }
  return false;
};
