import React from 'react';
import styled from 'styled-components';
import { Icons } from '@cognite/cogs.js';

import Dropdown from 'antd/lib/dropdown';
import Menu from 'antd/lib/menu';
import notification from 'antd/lib/notification';
import Tooltip from 'antd/lib/tooltip';

import theme from 'styles/theme';

declare global {
  interface Window {
    Intercom: any;
  }
}

const handleFeedbackClick = (metrics: any) => {
  metrics.track('Feedback.Click');
  try {
    window.Intercom('show');
  } catch (e) {
    notification.error({
      message:
        'Feedback/support chat not available. This feature might be blocked by your browser.',
    });
  }
};

interface DefaultTopBarContentProps {
  user?: string;
  logout?: () => void;
  metrics?: any;
}

const DefaultTopBarContent = (props: DefaultTopBarContentProps) => {
  const { user, logout, metrics = { track: () => {} } } = props;

  const onUserMenuClick = ({ key }: { key: string }) => {
    if (key === 'logout') {
      metrics.track('Logout.Click');
      if (logout) logout();
    }
  };

  const userMenu = (
    <Menu onClick={onUserMenuClick}>
      {user && (
        <div style={{ padding: '8px 16px' }}>
          <span>{user}</span>
        </div>
      )}
      {logout && (
        <>
          <Menu.Divider />
          <Menu.Item key="logout">
            <Icons.LogOut />
            Logout
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  return (
    <Content>
      {window.Intercom && (
        <Tooltip title="Feedback" placement="bottom">
          <RightContentButton onClick={() => handleFeedbackClick(metrics)}>
            <Icons.Feedback />
          </RightContentButton>
        </Tooltip>
      )}
      <a
        href="https://docs.cognite.com/cdf/"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => metrics.track('Help.Click')}
      >
        <Tooltip title="Help" placement="bottom">
          <RightContentButton>
            <Icons.Help />
          </RightContentButton>
        </Tooltip>
      </a>
      <Dropdown overlay={userMenu}>
        <RightContentButton>
          <Icons.User />
        </RightContentButton>
      </Dropdown>
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Button = styled.div`
  font-size: 20px;
  color: ${theme.textColor};
  border: 0;
  height: 48px;
  width: 48px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    background-color: ${theme.navigationButton};
  }
`;

const RightContentButton = styled(Button)`
  margin-left: 16px;
`;

export default DefaultTopBarContent;
