import { chunk } from 'lodash';
import { Action, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';
import { callUntilCompleted } from 'helpers/Helpers';
import { RootState } from 'reducers';
import { removeExtension } from 'utils/PnIDApi';
import { FilesApi } from 'utils/FilesApi';
import { trackTimedUsage } from 'utils/Metrics';
import { FilesMetadata, Asset } from '@cognite/sdk';

import { Result } from 'modules/sdk-builder/types';
import { canEditEvents, canEditFiles } from 'utils/PermissionUtils';
import {
  create as createAnnotations,
  list as listAnnotations,
  selectAnnotations,
} from './annotations';
import { ModelStatus } from './contextualization/models';

import { loadResourceSelection, dataKitItemsSelector } from './selection';

const PIPELINE_STARTED = 'doc_context/PIPELINE_STARTED';
const PIPELINE_DONE = 'doc_context/PIPELINE_DONE';

const DETECT_JOB_CREATE_STARTED = 'doc_context/DETECT_CREATE_STARTED';
const DETECT_JOB_CREATED = 'doc_context/DETECT_JOB_CREATED';
const DETECT_JOB_STATUS_UPDATED = 'doc_context/DETECT_JOB_STATUS_UPDATED';
const DETECT_JOB_DONE = 'doc_context/DETECT_JOB_DONE';
const DETECT_JOB_ERROR = 'doc_context/DETECT_JOB_ERROR';

const UPLOAD_JOB_CREATE_STARTED = 'doc_context/UPLOAD_JOB_CREATE_STARTED';
const UPLOAD_JOB_CREATED = 'doc_context/UPLOAD_JOB_CREATED';
const UPLOAD_JOB_STATUS_UPDATED = 'doc_context/UPLOAD_JOB_STATUS_UPDATED';
const UPLOAD_JOB_DONE = 'doc_context/UPLOAD_JOB_DONE';
const UPLOAD_JOB_ERROR = 'doc_context/UPLOAD_JOB_ERROR';

const ANNOTATION_JOB_STARTED = 'doc_context/ANNOTATION_JOB_CREATE_STARTED';
const ANNOTATION_JOB_DONE = 'doc_context/ANNOTATION_JOB_DONE';
const ANNOTATION_JOB_ERROR = 'doc_context/ANNOTATION_JOB_ERROR';

interface PipelineStartedAction extends Action<typeof PIPELINE_STARTED> {
  fileDataKitId: string;
  assetDataKitId: string;
}
interface PipelineDoneAction extends Action<typeof PIPELINE_DONE> {
  fileDataKitId: string;
  assetDataKitId: string;
}

type PipelineAction = PipelineStartedAction | PipelineDoneAction;

interface CreateDetectJobStartedAction
  extends Action<typeof DETECT_JOB_CREATE_STARTED> {
  fileId: number;
  dataKitId: string;
}
interface DetectJobCreatedAction extends Action<typeof DETECT_JOB_CREATED> {
  fileId: number;
  jobId: number;
}
interface DetectJobStatusUpdatedAction
  extends Action<typeof DETECT_JOB_STATUS_UPDATED> {
  fileId: number;
  jobId: number;
  status: ModelStatus;
}
interface DetectJobDoneAction extends Action<typeof DETECT_JOB_DONE> {
  fileId: number;
  entities: PnidResponseEntity[];
}
interface DetectJobErrorAction extends Action<typeof DETECT_JOB_ERROR> {
  fileId: number;
}

type DetectJobActions =
  | CreateDetectJobStartedAction
  | DetectJobCreatedAction
  | DetectJobStatusUpdatedAction
  | DetectJobDoneAction
  | DetectJobErrorAction;

interface CreateUploadJobStartedAction
  extends Action<typeof UPLOAD_JOB_CREATE_STARTED> {
  fileId: number;
}
interface UploadJobCreatedAction extends Action<typeof UPLOAD_JOB_CREATED> {
  fileId: number;
  jobId: number;
}
interface UploadJobStatusUpdatedAction
  extends Action<typeof UPLOAD_JOB_STATUS_UPDATED> {
  fileId: number;
  jobId: number;
  status: ModelStatus;
}
interface UploadJobDoneAction extends Action<typeof UPLOAD_JOB_DONE> {
  fileId: number;
  svgUrl: string;
}
interface UploadJobErrorAction extends Action<typeof UPLOAD_JOB_ERROR> {
  fileId: number;
}

type UploadJobActions =
  | CreateUploadJobStartedAction
  | UploadJobCreatedAction
  | UploadJobStatusUpdatedAction
  | UploadJobDoneAction
  | UploadJobErrorAction;

interface AnnotationJobStartedAction
  extends Action<typeof ANNOTATION_JOB_STARTED> {
  fileId: number;
}
interface AnnotationJobDoneAction extends Action<typeof ANNOTATION_JOB_DONE> {
  fileId: number;
}
interface AnnotationJobErrorAction extends Action<typeof ANNOTATION_JOB_ERROR> {
  fileId: number;
}

type AnnotationJobAction =
  | AnnotationJobStartedAction
  | AnnotationJobDoneAction
  | AnnotationJobErrorAction;

const apiRootPath = (project: string) =>
  `/api/playground/projects/${project}/context/documents`;
const createParsingJobPath = (project: string) =>
  `${apiRootPath(project)}/detect`;
const getParsingStatusPath = (project: string, jobId: number) =>
  `${apiRootPath(project)}/detect/${jobId}`;

type DocumentDetectJobSchema = {
  fileId: number;
  entities: string[];
};

const documentContextJob = (
  file: FilesMetadata,
  entities: string[],
  assetsDataKit: string,
  fileDataKit: string
) => {
  return async (
    dispatch: ThunkDispatch<any, any, DetectJobActions>,
    getState: () => RootState
  ): Promise<number | undefined> => {
    const {
      app: { sdk },
    } = getState();

    const { jobStarted, dataKitId: oldJobDataSetId } =
      getState().documentContext.parsingJobs[file.id] || {};

    if (jobStarted && assetsDataKit === oldJobDataSetId) {
      return getState().documentContext.parsingJobs[file.id].jobId;
    }

    dispatch({
      type: DETECT_JOB_CREATE_STARTED,
      fileId: file.id,
      dataKitId: assetsDataKit,
    });

    const timer = trackTimedUsage(
      'Contextualization.documentContext.ParsingJob',
      {
        fileId: file.id,
      }
    );

    const response = await sdk.post(createParsingJobPath(sdk.project), {
      data: {
        fileId: file.id,
        entities,
      } as DocumentDetectJobSchema,
    });
    try {
      const {
        status: httpStatus,
        data: { jobId, status: queueStatus },
      } = response;

      dispatch({ type: DETECT_JOB_CREATED, jobId, fileId: file.id });
      dispatch({
        type: DETECT_JOB_STATUS_UPDATED,
        jobId,
        status: queueStatus,
        fileId: file.id,
      });

      if (httpStatus === 200) {
        return await new Promise((resolve, reject) => {
          callUntilCompleted(
            () => sdk.get(getParsingStatusPath(sdk.project, jobId)),
            data => data.status === 'Completed' || data.status === 'Failed',
            async data => {
              if (data.status === 'Failed') {
                dispatch({
                  type: DETECT_JOB_ERROR,
                  fileId: file.id,
                });
                reject();
              } else {
                // completed
                await dispatch({
                  type: DETECT_JOB_DONE,
                  jobId,
                  fileId: file.id,
                  entities: data.items,
                });
                dispatch(
                  startCreatingAnnotationsFromJobs(
                    file,
                    assetsDataKit,
                    fileDataKit
                  )
                );
                resolve(jobId);

                timer.stop({ success: true, jobId });
              }
            },
            data => {
              dispatch({
                type: DETECT_JOB_STATUS_UPDATED,
                jobId,
                status: data.status,
                fileId: file.id,
              });
            },
            undefined,
            3000
          );
        });
      }
      dispatch({ type: DETECT_JOB_ERROR, fileId: file.id });
      timer.stop({ success: false, jobId });
      return undefined;
    } catch {
      dispatch({ type: DETECT_JOB_ERROR, fileId: file.id });
      timer.stop({ success: false });
      return undefined;
    }
  };
};

const startCreatingAnnotationsFromJobs = (
  file: FilesMetadata,
  assetsDataKitId: string,
  filesDataKitId: string
) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState: () => RootState
  ): Promise<number | undefined> => {
    if (getState().documentContext.annotationJobs[file.id]) {
      return;
    }
    const { sdk } = getState().app;
    const {
      jobDone: parsingJobDone,
      annotations,
      jobId: parsingJobId,
    } = getState().documentContext.parsingJobs[file.id];

    const timer = trackTimedUsage(
      'Contextualization.documentContext.CreateAnnotations',
      {
        fileId: file.id,
      }
    );

    if (parsingJobId && parsingJobDone && annotations) {
      dispatch({ type: ANNOTATION_JOB_STARTED, fileId: file.id });
      try {
        const filesApi = new FilesApi(sdk);

        const state = getState();
        const assetsData = dataKitItemsSelector(state)(
          assetsDataKitId,
          true
        ) as Result<Asset>;
        const filesData = dataKitItemsSelector(state)(
          filesDataKitId,
          true
        ) as Result<FilesMetadata>;

        const existingAnnotations = selectAnnotations(state)(file.id, true);

        const pendingAnnotations = await filesApi.createPendingAnnotationsFromJob(
          file,
          annotations,
          assetsData.items,
          filesData.items,
          `${parsingJobId!}`,
          existingAnnotations
        );

        await dispatch(createAnnotations(file, pendingAnnotations));
        dispatch({
          type: ANNOTATION_JOB_DONE,
          fileId: file.id,
        });

        timer.stop({ success: true });
      } catch {
        dispatch({ type: ANNOTATION_JOB_ERROR, file: file.id });
        timer.stop({ success: false });
      }
    }
  };
};

export const runPnidParser = (
  fileDataKitId: string,
  assetDataKitId: string
) => {
  return async (
    dispatch: ThunkDispatch<any, any, PipelineAction>,
    getState: () => RootState
  ) => {
    if (
      !canEditEvents(true) ||
      !canEditFiles(true) ||
      getState().documentContext.pipelines[`${fileDataKitId}-${assetDataKitId}`]
    ) {
      return;
    }

    const timer = trackTimedUsage(
      'Contextualization.documentContext.StartAllJobs',
      {
        fileDataKitId,
        assetDataKitId,
      }
    );

    dispatch({ type: PIPELINE_STARTED, fileDataKitId, assetDataKitId });

    const state = getState();
    const assetsData = dataKitItemsSelector(state)(
      assetDataKitId,
      true
    ) as Result<Asset>;
    const filesData = dataKitItemsSelector(state)(
      fileDataKitId,
      true
    ) as Result<FilesMetadata>;

    if (!assetsData.fetching && !assetsData.done) {
      await dispatch(loadResourceSelection(assetDataKitId));
    }
    if (!filesData.fetching && !filesData.done) {
      await dispatch(loadResourceSelection(fileDataKitId));
    }

    const parentIds = new Set<number | string>();
    assetsData.items.forEach(el => {
      if (el.parentId) {
        parentIds.add(el.parentId);
      }
      if (el.parentExternalId) {
        parentIds.add(el.parentExternalId);
      }
    });

    const assetNames: string[] = assetsData.items.map(i => i.name);
    const files: FilesMetadata[] = filesData.items;

    chunk(files, 30).reduce(async (previousPromise: Promise<any>, nextSet) => {
      await previousPromise;
      return Promise.all(
        nextSet.map(async file => {
          // fetch deleted too
          await dispatch(listAnnotations(file, true, true));
          await dispatch(
            documentContextJob(
              file,
              assetNames.concat(
                files
                  .filter(el => el.id !== file.id)
                  .map(el => el.name)
                  .map(removeExtension)
              ),
              assetDataKitId,
              fileDataKitId
            )
          );
        })
      );
    }, Promise.resolve());

    dispatch({ type: PIPELINE_DONE, fileDataKitId, assetDataKitId });

    timer.stop();
  };
};
export interface PnidResponseEntity {
  text: string;
  boundingBox: { xMin: number; xMax: number; yMin: number; yMax: number };
}

interface PipelineStatus {
  completed: boolean;
  assetsDataKitId: string;
  filesDataKitId: string;
}

export interface ConversionJobState {
  jobStarted: boolean;
  jobId?: number;
  jobStatus: ModelStatus;
  jobDone: boolean;
  jobError: boolean;
  pngUrl?: string;
}

export interface UploadJobState {
  jobStarted: boolean;
  jobId?: number;
  jobStatus: ModelStatus;
  jobDone: boolean;
  jobError: boolean;
  svgUrl?: string;
}

export interface ParsingJobState {
  jobStarted: boolean;
  jobId?: number;
  jobStatus: ModelStatus;
  jobDone: boolean;
  jobError: boolean;
  dataKitId: string;
  annotations?: PnidResponseEntity[];
}
export interface AnnotationJobState {
  jobDone: boolean;
  jobError: boolean;
}

type Actions =
  | DetectJobActions
  | PipelineAction
  | AnnotationJobAction
  | UploadJobActions;

export interface DocumentContextStore {
  uploadJobs: {
    [fileId: number]: UploadJobState;
  };
  parsingJobs: {
    [fileId: number]: ParsingJobState;
  };
  pipelines: { [key: string]: PipelineStatus };
  annotationJobs: {
    [key: number]: AnnotationJobState;
  };
}

const initialPnIDState: DocumentContextStore = {
  uploadJobs: {},
  parsingJobs: {},
  pipelines: {},
  annotationJobs: {},
};

export default function reducer(
  state: DocumentContextStore = initialPnIDState,
  action: Actions
): DocumentContextStore {
  switch (action.type) {
    case PIPELINE_STARTED: {
      const key = `${action.fileDataKitId}-${action.assetDataKitId}`;
      return {
        ...state,
        pipelines: {
          ...state.pipelines,
          [key]: {
            completed: false,
            filesDataKitId: action.fileDataKitId,
            assetsDataKitId: action.assetDataKitId,
          },
        },
      };
    }
    case PIPELINE_DONE: {
      const key = `${action.fileDataKitId}-${action.assetDataKitId}`;
      return {
        ...state,
        pipelines: {
          ...state.pipelines,
          [key]: {
            ...state.pipelines[key],
            completed: true,
            filesDataKitId: action.fileDataKitId,
            assetsDataKitId: action.assetDataKitId,
          },
        },
      };
    }

    case DETECT_JOB_CREATED: {
      return {
        ...state,
        parsingJobs: {
          ...state.parsingJobs,
          [action.fileId]: {
            ...state.parsingJobs[action.fileId],
            jobId: action.jobId,
          },
        },
      };
    }
    case DETECT_JOB_CREATE_STARTED: {
      return {
        ...state,
        parsingJobs: {
          ...state.parsingJobs,
          [action.fileId]: {
            jobStarted: true,
            jobStatus: 'Queued',
            jobDone: false,
            jobError: false,
            dataKitId: action.dataKitId,
          },
        },
      };
    }
    case DETECT_JOB_STATUS_UPDATED: {
      return {
        ...state,
        parsingJobs: {
          ...state.parsingJobs,
          [action.fileId]: {
            ...state.parsingJobs[action.fileId],
            jobStatus: action.status,
          },
        },
      };
    }
    case DETECT_JOB_DONE: {
      return {
        ...state,
        parsingJobs: {
          ...state.parsingJobs,
          [action.fileId]: {
            ...state.parsingJobs[action.fileId],
            jobDone: true,
            annotations: action.entities,
          },
        },
      };
    }
    case DETECT_JOB_ERROR: {
      return {
        ...state,
        parsingJobs: {
          ...state.parsingJobs,
          [action.fileId]: {
            ...state.parsingJobs[action.fileId],
            jobDone: true,
            jobError: true,
          },
        },
      };
    }
    case UPLOAD_JOB_CREATED: {
      return {
        ...state,
        uploadJobs: {
          ...state.uploadJobs,
          [action.fileId]: {
            ...state.uploadJobs[action.fileId],
            jobId: action.jobId,
          },
        },
      };
    }
    case UPLOAD_JOB_CREATE_STARTED: {
      return {
        ...state,
        uploadJobs: {
          ...state.uploadJobs,
          [action.fileId]: {
            jobStarted: true,
            jobStatus: 'Queued',
            jobDone: false,
            jobError: false,
          },
        },
      };
    }
    case UPLOAD_JOB_STATUS_UPDATED: {
      return {
        ...state,
        uploadJobs: {
          ...state.uploadJobs,
          [action.fileId]: {
            ...state.uploadJobs[action.fileId],
            jobStatus: action.status,
          },
        },
      };
    }
    case UPLOAD_JOB_DONE: {
      return {
        ...state,
        uploadJobs: {
          ...state.uploadJobs,
          [action.fileId]: {
            ...state.uploadJobs[action.fileId],
            jobDone: true,
            svgUrl: action.svgUrl,
          },
        },
      };
    }
    case UPLOAD_JOB_ERROR: {
      return {
        ...state,
        uploadJobs: {
          ...state.uploadJobs,
          [action.fileId]: {
            ...state.uploadJobs[action.fileId],
            jobDone: true,
            jobError: true,
          },
        },
      };
    }

    case ANNOTATION_JOB_STARTED: {
      return {
        ...state,
        annotationJobs: {
          ...state.annotationJobs,
          [action.fileId]: {
            jobDone: false,
            jobError: false,
          },
        },
      };
    }
    case ANNOTATION_JOB_DONE: {
      return {
        ...state,
        annotationJobs: {
          ...state.annotationJobs,
          [action.fileId]: {
            ...state.annotationJobs[action.fileId],
            jobDone: true,
          },
        },
      };
    }
    case ANNOTATION_JOB_ERROR: {
      return {
        ...state,
        annotationJobs: {
          ...state.annotationJobs,
          [action.fileId]: {
            ...state.annotationJobs[action.fileId],
            jobDone: true,
            jobError: true,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
}

// TODO rename this
export const makeNumPnidParsingJobSelector = () =>
  createSelector(
    (state: RootState) => state.documentContext.parsingJobs,
    (_: any, fileIds: number[]) => fileIds,
    (parsingJobs, fileIds) => {
      const jobIds = new Set(Object.keys(parsingJobs));
      return fileIds.filter(fileId => jobIds.has(`${fileId}`)).length;
    }
  );

export const selectParsingJobForFileId = createSelector(
  (state: RootState) => state.documentContext.parsingJobs,
  jobMap => (fileId: number) => {
    return (jobMap[fileId] || {}).jobId;
  }
);

export const stuffForUnitTests = {
  initialPnIDState,
  UPLOAD_JOB_CREATE_STARTED,
  UPLOAD_JOB_CREATED,
  UPLOAD_JOB_STATUS_UPDATED,
  UPLOAD_JOB_DONE,
  UPLOAD_JOB_ERROR,
};
