import React from 'react';
import { Modal } from 'antd';
import { AssetMeta } from '@cognite/gearbox';

export default function AssetModal(props: {
  assetId: number;
  onClose?: () => void;
}) {
  return (
    <Modal
      footer={null}
      width="80%"
      visible
      onOk={props.onClose}
      onCancel={props.onClose}
    >
      <AssetMeta assetId={props.assetId} />
    </Modal>
  );
}
