import React from 'react';
import { Input, Col } from 'antd';
import { Filter } from 'modules/search';

import DataSetSelect from 'components/DataSetSelect';
import { Icon, Colors } from '@cognite/cogs.js';

type Props = {
  updateFilter: (filter: Filter) => void;
  filter: Filter;
};
export default function FileSearchBar({ filter, updateFilter }: Props) {
  const selectedDataSetIds = (filter.filter.dataSetIds || []).map(
    (e: any) => e?.id
  );

  return (
    <>
      <Col span={8}>
        <p>Data Set</p>
        <DataSetSelect
          multiple
          style={{ width: '100%' }}
          selectedDataSetIds={selectedDataSetIds}
          type="files"
          onDataSetSelected={(ids: number[]) => {
            const dataSetIds =
              ids.length === 0 ? undefined : ids.map(id => ({ id }));
            updateFilter({
              ...filter,
              filter: {
                ...filter.filter,
                dataSetIds,
              },
            });
          }}
        />
      </Col>
      <Col span={8}>
        <p>Search</p>
        <Input
          prefix={
            <Icon
              type="Search"
              style={{ height: '16px', color: Colors['greyscale-grey6'].hex() }}
            />
          }
          placeholder="Filter by name/description"
          value={filter.search ? filter.search.name : ''}
          onChange={ev =>
            updateFilter({
              ...filter,
              search:
                ev.target.value.length > 0
                  ? {
                      ...filter.search,
                      name: ev.target.value,
                    }
                  : undefined,
            })
          }
        />
      </Col>
      <Col span={8}>
        <p>Source</p>
        <Input
          id="source"
          placeholder="Filter by source"
          value={filter.filter ? filter.filter.source : undefined}
          onChange={ev =>
            updateFilter({
              ...filter,
              filter: {
                ...filter.filter,
                source:
                  ev.target.value.length > 0 ? ev.target.value : undefined,
              },
            })
          }
        />
      </Col>
    </>
  );
}
