import React, { useState, useEffect } from 'react';
import { Table, message, Collapse, Pagination, List } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
  ChangeInfo,
  getStagedChanges,
  getPredictionStateSelector,
  dismissChanges,
} from 'modules/contextualization/predictions';
import AssetModal from 'components/AssetModal';
import TimeseriesModal from 'components/TimeseriesModal';
import ItemWithDescription from 'components/ItemWithDescription';
import { getHeaderTitle } from 'containers/Contextualization/EntityMatching/RulesMatching';
import { Button } from '@cognite/cogs.js';
import { RootState } from 'reducers';
import { ResourceType } from 'modules/sdk-builder/types';

interface StagedChangesInnerProps {
  stagedChanges: ChangeInfo[];
  stagedRules: string[];
  writeError: boolean;
  resourceType: ResourceType | undefined;
  onDismissClick: (changes: ChangeInfo[]) => void;
}

function StagedChangesInner(props: StagedChangesInnerProps) {
  // Used for displaying modals
  const [selectedAssetId, setSelectedAssetId] = useState<number | undefined>();
  const [selectedTimeseriesId, setSelectedTimeseriesId] = useState<
    number | undefined
  >();

  const [currentPage, setCurrentPage] = useState(1);
  const RULES_PER_PAGE = 15;
  const {
    stagedChanges,
    stagedRules,
    writeError,
    onDismissClick,
    resourceType,
  } = props;
  const { Panel } = Collapse;

  useEffect(() => {
    if (writeError) {
      message.error(
        'An error occured while writing updating one or more resources.'
      );
    }
  }, [writeError]);

  const columns = [
    {
      title: 'Resource',
      key: 'resourceId',
      render: (change: ChangeInfo) => (
        <ItemWithDescription
          onClick={() =>
            resourceType === 'timeseries' &&
            setSelectedTimeseriesId(change.resourceId)
          }
          name={change.resourceName}
          description={change.resourceDescription}
        />
      ),
    },
    {
      title: 'Asset',
      key: 'assetId',
      render: (change: ChangeInfo) => (
        <ItemWithDescription
          onClick={() => setSelectedAssetId(change.assetId)}
          name={change.assetName}
          description={change.assetDescription}
        />
      ),
    },
    {
      title: 'Un-stage change',
      key: 'unStageChange',
      render: (change: ChangeInfo) => (
        <Button
          icon="Close"
          onClick={() => {
            onDismissClick([change]);
          }}
        />
      ),
    },
  ];

  const stagedChangesTable = (
    <>
      <Collapse>
        {stagedRules
          .slice(
            (currentPage - 1) * RULES_PER_PAGE,
            currentPage * RULES_PER_PAGE
          )
          .map(rule => {
            const matchingChanges = stagedChanges.filter(
              change => change.rule === rule
            );
            const inputPattern = rule.split('->')[0];
            const headerTitle = getHeaderTitle(rule, inputPattern);
            const h = (
              <List.Item style={{ display: 'flex', padding: 0 }}>
                <List.Item.Meta title={headerTitle} />
                <Button
                  style={{ marginLeft: 'auto' }}
                  onClick={() => onDismissClick(matchingChanges)}
                >
                  Un-stage {matchingChanges.length} changes
                </Button>
              </List.Item>
            );

            return (
              <Panel key={rule} header={h}>
                <Table
                  rowKey={change => change.resourceId.toString()}
                  pagination={{ pageSize: 100 }}
                  dataSource={matchingChanges}
                  columns={columns}
                />
              </Panel>
            );
          })}
      </Collapse>
      <Pagination
        current={currentPage}
        total={stagedRules.length}
        defaultPageSize={RULES_PER_PAGE}
        onChange={page => setCurrentPage(page)}
        style={{ float: 'right', margin: '16px 0' }}
      />
    </>
  );

  return (
    <>
      {selectedAssetId && (
        <AssetModal
          onClose={() => setSelectedAssetId(undefined)}
          assetId={selectedAssetId}
        />
      )}
      {selectedTimeseriesId && (
        <TimeseriesModal
          onClose={() => setSelectedTimeseriesId(undefined)}
          timeseriesId={selectedTimeseriesId}
        />
      )}
      {stagedChangesTable}
    </>
  );
}

interface StagedChangesProps {
  resourceDataKitId: string;
  assetDataKitId: string;
}
export default function StagedChanges(props: StagedChangesProps) {
  const { resourceDataKitId, assetDataKitId } = props;
  const stagedChanges = useSelector(getStagedChanges)(
    resourceDataKitId,
    assetDataKitId
  );
  const { writeError } = useSelector(getPredictionStateSelector)(
    resourceDataKitId
  );
  const { type: resourceType } = useSelector(
    (state: RootState) => state.selection.items[resourceDataKitId]
  );
  const stagedRules = Array.from(new Set(stagedChanges.map(c => c.rule)));
  const dispatch = useDispatch();

  const onDismissClick = (changes: ChangeInfo[]) => {
    dispatch(dismissChanges(resourceDataKitId, changes));
  };

  return (
    <StagedChangesInner
      stagedChanges={stagedChanges}
      stagedRules={stagedRules}
      writeError={writeError}
      onDismissClick={onDismissClick}
      resourceType={resourceType}
    />
  );
}

export const stuffForUnitTests = {
  StagedChangesInner,
};
