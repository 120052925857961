import React from 'react';
import { Row } from 'antd';
import styled from 'styled-components';

const StyledBox = styled(Row)`
  background: #f5f5f5;
  border-radius: 5px;
  border: none;
  padding: 14px;
  min-height: 48px;
  width: 100%;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
`;

interface TextBoxProps {
  children: React.ReactNode;
}
export const TextBox = ({ children }: TextBoxProps) => (
  <StyledBox className="field-text-box" type="flex" align="middle">
    {children}
  </StyledBox>
);
