import React from 'react';

const CogniteLogo = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 128 76"
  >
    <g fill="#000" fillRule="evenodd">
      <path
        className="st0"
        d="M101.1,31.3v-6.5c0-3.1-2.5-5.6-5.6-5.6s-5.6,2.5-5.6,5.6v8.3c-3.4,0.6-7,1.4-10.9,2.3V6.2
    		c0-3.1-2.5-5.6-5.6-5.6s-5.6,2.5-5.6,5.6v31.9c-0.3,0.1-0.6,0.2-1,0.2c-3.5,0.9-6.7,1.7-9.9,2.4V15.8c0-3.1-2.5-5.6-5.6-5.6
    		s-5.6,2.5-5.6,5.6v27.3c-3.9,0.8-7.5,1.5-10.9,2V32.4c0-3.1-2.5-5.6-5.6-5.6s-5.6,2.5-5.6,5.6v14.2C11.2,48,4,47.4,3.1,45.3
    		c-1.6-3.8,9.9-9.1,13-10.8c0.1,0,0.1-0.1,0-0.2c0-0.1-0.2-0.1-0.2-0.1c-3.3,1.4-17.2,7.2-15.6,12.1c1.8,5.5,23.4,6.9,68.1-4
    		c37.6-9.2,54.8-10.9,56.4-7.4c1.4,3-8,8.7-16.9,12.7c-0.2,0.1-0.4,0.4-0.3,0.7c0.1,0.3,0.4,0.4,0.7,0.3c3.3-1.3,20.3-8.5,19.4-14.6
    		C127.5,30,118.5,28.9,101.1,31.3z"
      />
      <path
        className="st0"
        d="M23.8,57.7v4.2c0,3.1,2.5,5.6,5.6,5.6S35,65,35,61.9v-5.4C30.9,57,27.2,57.4,23.8,57.7z"
      />
      <path
        className="st0"
        d="M45.9,54.7v15.1c0,3.1,2.5,5.6,5.6,5.6s5.6-2.5,5.6-5.6V52.5C53.1,53.3,49.4,54.1,45.9,54.7z"
      />
      <path
        className="st0"
        d="M68.7,49.8c-0.3,0.1-0.5,0.1-0.7,0.2v10.6c0,3.1,2.5,5.6,5.6,5.6s5.6-2.5,5.6-5.6V47.3
    			C75.8,48,72.3,48.9,68.7,49.8z"
      />
      <path
        className="st0"
        d="M90,44.9v6.4c0,3.1,2.5,5.6,5.6,5.6s5.6-2.5,5.6-5.6v-8.5C97.8,43.3,94.1,44,90,44.9z"
      />
    </g>
  </svg>
);

export default CogniteLogo;
