import React, { useEffect } from 'react';
import { Table, Empty } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { selectInstance } from 'modules/instances';
import {
  itemSelector as typeSelector,
  CogniteTypePopulatedProperty,
} from 'modules/types';
import { retrieve, itemSelector } from 'modules/timeseries';
import { ColorDot, Popover } from 'components/Common';
import { InternalId } from '@cognite/sdk/dist/src/types/types';
import { Button, Colors } from '@cognite/cogs.js';
import { qualityDimensions, getStatusColor } from 'utils/DataQuality';
import { TimeseriesHoverPreview } from 'containers/HoverPreview';

export const DataQualityPane = ({
  instanceId,
  typeId,
  renderExtraButtons,
  onVisibleChange = () => {},
}: {
  instanceId: number;
  typeId: number;
  renderExtraButtons: (propertyId: string) => React.ReactNode[];
  onVisibleChange?: (
    visible: boolean,
    propertyId: string,
    assetId: number,
    timeseriesId: number
  ) => void;
}) => {
  const dispatch = useDispatch();
  const instance = useSelector(selectInstance)(instanceId);
  const type = useSelector(typeSelector)(typeId)!;

  const typeWithProperties = (instance || { types: [] }).types!.find(
    el => el.type.id === type.id && el.type.version === type.version
  );

  const properties: CogniteTypePopulatedProperty[] = typeWithProperties
    ? typeWithProperties.properties || []
    : [];

  const getTimeSeries = useSelector(itemSelector);

  useEffect(() => {
    const timeSeriesIds = properties.reduce(
      (agg: InternalId[], cur): InternalId[] =>
        (cur.type === 'timeseriesRef' &&
          cur.value &&
          !agg.some(el => el.id === cur.value.id) && [
            ...agg,
            { id: cur.value.id },
          ]) ||
        agg,
      []
    );
    if (timeSeriesIds.length > 0) {
      dispatch(retrieve(timeSeriesIds));
    }
  }, [dispatch, properties]);

  if (!instance || !instance.types || !type) {
    return <Empty>Loading...</Empty>;
  }

  if (!typeWithProperties) {
    return <Empty>No Data Found</Empty>;
  }

  return (
    <>
      <Table<CogniteTypePopulatedProperty>
        columns={[
          {
            title: 'Attribute name',
            key: 'attributeName',
            render: record => record.name || record.propertyId,
          },
          {
            title: 'Time series',
            key: 'timeSeries',
            render: (propertyId, record) => {
              const ts =
                record.type === 'timeseriesRef' && record.value
                  ? getTimeSeries(record.value.id)
                  : undefined;
              if (ts) {
                return (
                  <Popover
                    content={
                      <TimeseriesHoverPreview
                        timeseries={ts}
                        actions={renderExtraButtons(propertyId)}
                      />
                    }
                    key={ts.id}
                    trigger="click"
                    onVisibleChange={visible => {
                      onVisibleChange(visible, propertyId, instance.id, ts.id);
                    }}
                  >
                    <Button type="link">{ts.name}</Button>
                  </Popover>
                );
              }
              return 'N/A';
            },
            dataIndex: 'propertyId',
          },
          ...Object.values(qualityDimensions).map(dimension => ({
            title: dimension.label,
            key: dimension.id,
            render: (_: any, record: CogniteTypePopulatedProperty) => {
              const ts =
                record.type === 'timeseriesRef' && record.value
                  ? getTimeSeries(record.value.id)
                  : undefined;
              if (ts) {
                return (
                  <ColorDot
                    color={getStatusColor(
                      ts.metadata && ts.metadata[dimension.id],
                      dimension.thresholds
                    )}
                  />
                );
              }
              return <ColorDot color={Colors['greyscale-grey3'].hex()} />;
            },
            dataIndex: 'value',
          })),
          { title: 'Flag', key: 'flag' },
        ]}
        rowKey="id"
        dataSource={properties}
      />
    </>
  );
};
