import React from 'react';
import { useSelector } from 'react-redux';
import { itemSelector } from 'modules/timeseries';
import { AssetIcon } from 'assets';
import styled from 'styled-components';
import { TemplateCompletitionItem } from 'modules/templateCompletion';
import { Icon, Colors, AllIconTypes } from '@cognite/cogs.js';
import { Dropdown, Menu, Popover } from 'antd';
import {
  CELL_STYLE,
  STYLE_BOTTOM_LEFT_GRID,
  STYLE_TOP_LEFT_GRID,
  STYLE_TOP_RIGHT_GRID,
  PropertyCell,
} from 'components/Common';
import { MultiGrid, AutoSizer } from 'react-virtualized';

const Subtitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SuggestionsTable = ({
  item,
  renderTimeseriesHover = () => null,
  renderInstancePopover = () => null,
  confirmedSuggestions = {},
  skippedSuggestions = {},
  onSelect,
  onSkip,
  onSkipAll,
  onAcceptAll,
}: {
  item: TemplateCompletitionItem;
  renderTimeseriesHover?: (
    assetId: number,
    propertyId: string,
    tsId: number | undefined
  ) => React.ReactNode;
  renderInstancePopover?: (assetId: number) => React.ReactNode;
  confirmedSuggestions?: { [key: number]: number | undefined | null };
  skippedSuggestions?: { [key: number]: any };
  onSelect: (assetId: number, predictionId: number | undefined | null) => void;
  onSkip: (assetId: number) => void;
  onAcceptAll: () => void;
  onSkipAll: () => void;
}) => {
  const getTimeseries = useSelector(itemSelector);

  const items = item.items.filter(el => !skippedSuggestions[el.assetId]);

  const renderTimeSeriesCell = (
    style: React.CSSProperties,
    key: string,
    assetId: number,
    propertyId: string,
    tsId: number | undefined | null
  ) => {
    if (!tsId) return <div style={{ ...CELL_STYLE, ...style }} key={key} />;
    const ts = getTimeseries(tsId);
    if (!ts)
      return (
        <div style={{ ...CELL_STYLE, ...style }} key={key}>
          <em>Loading...</em>
        </div>
      );

    let isUnConfirmed = false;
    if (confirmedSuggestions[assetId] !== undefined) {
      isUnConfirmed = confirmedSuggestions[assetId] !== tsId;
    }

    return (
      <Popover
        key={key}
        trigger="click"
        content={renderTimeseriesHover(assetId, propertyId, tsId)}
      >
        <PropertyCell style={{ ...CELL_STYLE, ...style }} key={key}>
          <Icon
            type="Timeseries"
            style={{
              marginRight: '4px',
              color: isUnConfirmed
                ? Colors['greyscale-grey6'].hex()
                : Colors.midblue.hex(),
            }}
          />
          <div style={{ flex: 1 }}>
            <p
              style={{
                fontWeight: 600,
                marginBottom: '4px',
                textAlign: 'left',
                color: isUnConfirmed
                  ? Colors['greyscale-grey6'].hex()
                  : Colors.midblue.hex(),
                textDecoration: isUnConfirmed ? 'line-through' : 'none',
              }}
            >
              {ts.name || 'Loading...'}
            </p>
            <Subtitle
              style={{
                color: isUnConfirmed
                  ? Colors['greyscale-grey6'].hex()
                  : Colors.midblue.hex(),
                textDecoration: isUnConfirmed ? 'line-through' : 'none',
              }}
            >
              {ts.description}
            </Subtitle>
          </div>
        </PropertyCell>
      </Popover>
    );
  };
  const renderPropertyCell = (
    suggestion: TemplateCompletitionItem,
    {
      columnIndex,
      key,
      rowIndex,
      style,
    }: {
      columnIndex: number;
      key: string;
      rowIndex: number;
      style: React.CSSProperties;
    }
  ) => {
    // Headers
    if (rowIndex === 0) {
      if (columnIndex === 0) {
        return (
          <div
            key={key}
            style={{
              ...CELL_STYLE,
              ...style,
              borderBottom: `2px solid ${Colors.white.hex()}`,
            }}
          >
            <strong>Properties</strong>
          </div>
        );
      }
      if (columnIndex === 1) {
        return (
          <div
            key={key}
            style={{
              ...CELL_STYLE,
              ...style,
              borderBottom: `2px solid ${Colors.white.hex()}`,
            }}
          >
            <strong>Current {suggestion.column}</strong>
          </div>
        );
      }
      if (columnIndex === 2) {
        return (
          <div
            key={key}
            style={{
              ...CELL_STYLE,
              ...style,
              borderBottom: `2px solid ${Colors.white.hex()}`,
            }}
          >
            <strong>Suggested {suggestion.column}</strong>
          </div>
        );
      }
      if (columnIndex === 3) {
        return (
          <div
            key={key}
            style={{
              ...CELL_STYLE,
              ...style,
              borderBottom: `2px solid ${Colors.white.hex()}`,
            }}
          >
            <Dropdown
              overlay={
                <Menu style={{ width: '200px' }}>
                  <Menu.Item
                    key="accept"
                    onClick={() => {
                      onAcceptAll();
                    }}
                  >
                    <Icon
                      style={{
                        width: '16px',
                        marginRight: '8px',
                        color: Colors.success.hex(),
                      }}
                      type="Check"
                    />
                    Accept
                  </Menu.Item>
                  <Menu.Item
                    key="skip"
                    onClick={() => {
                      onSkipAll();
                    }}
                  >
                    <Icon
                      style={{ width: '16px', marginRight: '8px' }}
                      type="DoubleChevronRight"
                    />
                    Skip
                  </Menu.Item>
                </Menu>
              }
            >
              <Icon
                style={{
                  padding: '8px',
                  cursor: 'pointer',
                  borderRadius: 50,
                  height: 32,
                  background: Colors['greyscale-grey2'].hex(),
                }}
                type="Down"
              />
            </Dropdown>
          </div>
        );
      }
    }
    const prediction = items[rowIndex - 1];
    if (columnIndex === 0) {
      return (
        <Popover
          trigger="click"
          content={
            <div style={{ width: 400 }}>
              {renderInstancePopover(prediction.assetId)}
            </div>
          }
        >
          <div
            key={`${key}-${prediction.assetId}`}
            style={{
              ...CELL_STYLE,
              ...style,
              backgroundColor: Colors.white.hex(),
            }}
          >
            <img src={AssetIcon} alt="" style={{ marginRight: '4px' }} />
            <div style={{ flex: 1 }}>{prediction.assetName}</div>
          </div>
        </Popover>
      );
    }

    // Last Column
    if (columnIndex === 3) {
      let background = Colors['greyscale-grey2'].hex();
      let color = Colors.black.hex();
      let icon: AllIconTypes = 'LargeDown';
      const isSelected = confirmedSuggestions[prediction.assetId] !== undefined;
      if (isSelected) {
        if (
          confirmedSuggestions[prediction.assetId] !==
          (prediction.foundId || (prediction.foundIds || [])[0])
        ) {
          icon = 'Close';
          background = Colors.danger.hex();
        } else {
          icon = 'Check';
          background = Colors.success.hex();
        }
        color = Colors.white.hex();
      }
      return (
        <div
          key={`${key}-${prediction.assetId}-action`}
          style={{
            ...CELL_STYLE,
            ...style,
            background: Colors.white.hex(),
          }}
        >
          <Dropdown
            overlay={
              <Menu style={{ width: '200px' }}>
                <Menu.Item
                  key="accept"
                  onClick={() => {
                    onSelect(
                      prediction.assetId,
                      prediction.foundId || (prediction.foundIds || [])[0]
                    );
                  }}
                >
                  <Icon
                    style={{
                      width: '16px',
                      marginRight: '8px',
                      color: Colors.success.hex(),
                    }}
                    type="Check"
                  />
                  Accept
                </Menu.Item>
                <Menu.Item
                  key="reject"
                  onClick={() => {
                    onSkip(prediction.assetId);
                  }}
                >
                  <Icon
                    style={{
                      width: '16px',
                      marginRight: '8px',
                      color: Colors.danger.hex(),
                    }}
                    type="Close"
                  />
                  Reject
                </Menu.Item>
                <Menu.Item
                  key="skip"
                  onClick={() => {
                    onSkip(prediction.assetId);
                  }}
                >
                  <Icon
                    style={{ width: '16px', marginRight: '8px' }}
                    type="DoubleChevronRight"
                  />
                  Skip
                </Menu.Item>
              </Menu>
            }
          >
            <Icon
              style={{
                padding: '8px',
                cursor: 'pointer',
                borderRadius: 50,
                height: 32,
                background,
                color,
              }}
              type={icon}
            />
          </Dropdown>
        </div>
      );
    }
    return renderTimeSeriesCell(
      style,
      key,
      prediction.assetId,
      item.column,
      columnIndex === 1
        ? prediction.currentId
        : prediction.foundId || (prediction.foundIds || [])[0]
    );
  };

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <MultiGrid
          enableFixedRowScroll
          hideTopRightGridScrollbar
          hideBottomLeftGridScrollbar
          height={400}
          columnWidth={idx => (idx.index < 3 ? (width - 80) / 3 : 80)}
          fixedRowCount={1}
          rowHeight={120}
          rowCount={items.length + 1}
          width={width}
          cellRenderer={props => renderPropertyCell(item, props)}
          columnCount={4}
          styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
          styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
          styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
        />
      )}
    </AutoSizer>
  );
};
