import React, { useState, useEffect } from 'react';
import { Empty, Tabs, message, Modal } from 'antd';
import {
  BaseDropdown,
  Header,
  Card,
  VerticalDivider,
  PropertySummaryIndicator,
  XOMHeader,
  MetadataFilterDropdown,
} from 'components/Common';
import { ViewInstancesSidebar } from 'containers/ScalingUseCase/Instance';
import { useSelector, useDispatch } from 'react-redux';
import {
  retrieve as retrieveTypes,
  itemSelector as typeSelector,
  CogniteTypePopulatedProperty,
  remove,
} from 'modules/types';
import {
  listAndCountInstancesForTypeId,
  selectInstancesUnderType,
  listAllRootAssetsIfNeeded,
  getPropertyFromInstance,
  selectInstancesPropertyCount,
  removePropertyFromType,
  selectTypeCSVData,
} from 'modules/instances';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Button, Icon, Colors } from '@cognite/cogs.js';
import { retrieve, itemSelector } from 'modules/timeseries';
import { GetTimeSeriesMetadataDTO } from '@cognite/sdk';
import { InstancesTable } from 'containers/ScalingUseCase/Instance/InstancesTable';
import { AssetIcon } from 'assets';
import styled from 'styled-components';
import {
  selectSuggestionCount,
  selectSuggestionFetching,
  selectSuggestionForPropertyAndInstance,
} from 'modules/templateCompletion';
import ScalingUseCaseRoutes from 'containers/ScalingUseCase/ScalingUseCaseRoutes';
import { HeaderButtons } from 'containers/ScalingUseCase/Common';
import moment from 'moment';
import {
  PropertiesPane,
  DataQualityPane,
} from 'containers/ScalingUseCase/Property';
import queryString from 'query-string';
import { ResourceSidebar } from 'containers/ResourceSidebar';
import { Parser } from 'json2csv';
import { selectMetadata } from 'modules/playgroundAssets';
import { NewInstanceModal } from '../Instance/NewInstanceModal';
import { NewTemplateModal as EditTemplateModal } from './NewTemplateModal';

const InstancesHeader = styled.div`
  display: flex;
  align-items: center;
  h1 {
    margin-bottom: 0px;
    margin-left: 8px;
    font-weight: 800;
  }
  .properties-details {
    text-align: center;
    p {
      margin-bottom: 0px;
      font-size: 12px;
    }
    .count {
      font-size: 18px;
      font-weight: 800;
    }
  }
`;

export const ViewTemplate = () => {
  const { tenant, showSidebar, templateId } = useParams<{
    tenant: string;
    showSidebar?: string;
    templateId: string;
  }>();

  const typeId = Number.isNaN(Number(templateId)) ? -1 : Number(templateId);
  const dispatch = useDispatch();
  const history = useHistory();
  const type = useSelector(typeSelector)(typeId)!;
  const getTimeseries = useSelector(itemSelector);
  const { search } = useLocation();

  const getPropertySuggestion = useSelector(
    selectSuggestionForPropertyAndInstance
  );

  const [editTemplateFormOpen, setEditTemplateFormOpen] = useState(false);

  const {
    tab: urlTab,
    instanceId: selectedInstanceId,
    previewInstanceId,
    previewPropertyId,
  }: {
    tab?: string;
    instanceId?: number;
    previewInstanceId?: number;
    previewPropertyId?: string;
  } = queryString.parse(search, {
    parseNumbers: true,
  });
  const tab =
    urlTab === 'properties' || urlTab === 'dataquality' ? urlTab : 'properties';

  useEffect(() => {
    if (type) {
      dispatch(listAndCountInstancesForTypeId(type.id, true));
    }
  }, [dispatch, type]);

  useEffect(() => {
    if (typeId) {
      dispatch(retrieveTypes([{ id: typeId }]));
    }
  }, [dispatch, typeId]);

  const instances = useSelector(selectInstancesUnderType)(typeId, true);
  const suggestionCountFetching = useSelector(selectSuggestionFetching)(typeId);
  const suggestionCount = useSelector(selectSuggestionCount)(typeId);
  const loadCSVData = useSelector(selectTypeCSVData);
  const { total, completed } = useSelector(selectInstancesPropertyCount)(
    typeId
  );

  useEffect(() => {
    if (instances && instances.items) {
      dispatch(listAllRootAssetsIfNeeded(instances.items));
    }
  }, [dispatch, instances]);

  const timeSeriesIds = new Set<number>();

  instances.items.forEach(instance => {
    (type?.properties || []).forEach((prop: CogniteTypePopulatedProperty) => {
      const property = getPropertyFromInstance(type, instance, prop.propertyId);
      if (property && property.id) {
        timeSeriesIds.add(property.id);
      }
    });
  });

  const metadata = useSelector(selectMetadata)(
    instances.items.map(el => el.id)
  );

  const [filters, setFilters] = useState<{
    [key: string]: string;
  }>({});

  const visibleInstances = instances.items.filter(el => {
    return !Object.keys(filters).some(
      key => !el.metadata || el.metadata[key] !== filters[key]
    );
  });

  const timeSeries = Array.from(timeSeriesIds)
    .map(el => getTimeseries(el))
    .filter(el => !!el) as GetTimeSeriesMetadataDTO[];

  useEffect(() => {
    if (timeSeriesIds.size > 0 && timeSeries.length !== timeSeriesIds.size) {
      dispatch(retrieve(Array.from(timeSeriesIds).map(id => ({ id }))));
    }
  }, [dispatch, timeSeriesIds, timeSeries]);

  const [createInstanceOpen, setCreateInstanceOpen] = useState(false);

  const renderExtraButtons = (instanceId: number, propertyId: string) => {
    const suggestion = getPropertySuggestion(type.id, propertyId, instanceId);
    return [
      <Button
        type="primary"
        onClick={() =>
          history.push(
            `/${tenant}/templates/${type.id}/instances/${instanceId}/properties/${propertyId}`
          )
        }
        key="find-timeseries"
        icon="Timeseries"
      >
        Replace Time Series
      </Button>,
      <Button
        type="danger"
        onClick={() => {
          const instance = instances.items.find(el => el.id === instanceId);
          if (instance) {
            dispatch(removePropertyFromType(instance, type, propertyId));
          }
        }}
        key="clear-timeseries"
        icon="Close"
      >
        Clear Time Series
      </Button>,
      ...(suggestion
        ? [
            <Button
              type="primary"
              onClick={() =>
                history.push(
                  `/${tenant}/templates/${type.id}/suggestions/${propertyId}`
                )
              }
              key="review-timeseries"
              icon="Feedback"
              style={{ width: '100%', backgroundColor: Colors.purple.hex() }}
            >
              Review Suggestion
            </Button>,
          ]
        : []),
    ];
  };

  const onVisibleChange = (
    visible: boolean,
    prevPropId: string,
    prevInstId: number
  ) => {
    if (visible) {
      history.push({
        search: queryString.stringify({
          ...queryString.parse(search),
          showSidebar: false,
          previewPropertyId: prevPropId,
          previewInstanceId: prevInstId,
        }),
      });
    }
  };

  if (typeId === -1 || !type) {
    return <Empty>Invalid Template Selected.</Empty>;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <XOMHeader
        onBackClicked={() => history.push(`/${tenant}/templates`)}
        breadcrumbs={ScalingUseCaseRoutes.breadcrumbs}
        path={`/${tenant}/templates`}
        title={
          <>
            <h4 style={{ display: 'inline-block' }}>{type.name}</h4>
            <small style={{ marginLeft: '8px', textTransform: 'uppercase' }}>
              {type.description}
            </small>
          </>
        }
      >
        <HeaderButtons>
          <Button
            icon="Download"
            variant="outline"
            onClick={async () => {
              try {
                const data = await loadCSVData(type.id);
                const parser = new Parser();
                const csv = parser.parse(data);

                const blob = new Blob([csv], {
                  type: 'text/csv;charset=utf-8;',
                });
                const link = document.createElement('a');
                if (link.download !== undefined) {
                  const url = URL.createObjectURL(blob);
                  link.setAttribute('href', url);
                  link.setAttribute(
                    'download',
                    `${type.name || type.externalId}.csv`
                  );
                  link.style.visibility = 'hidden';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
              } catch (err) {
                message.error('Unable to download JSON');
              }
            }}
          >
            Download
          </Button>
          <Button
            icon="Plus"
            onClick={() => setCreateInstanceOpen(true)}
            variant="outline"
          >
            Add Instance
          </Button>
          <Button
            icon="Edit"
            onClick={() => setEditTemplateFormOpen(true)}
            variant="outline"
          >
            Edit Properties
          </Button>
          <Button
            icon="Delete"
            onClick={() =>
              Modal.confirm({
                title: 'Are you sure?',
                onOk: async () => {
                  await dispatch(remove([type]));
                  history.push(`/${tenant}/templates`);
                },
              })
            }
            variant="outline"
            type="danger"
          />
          <div style={{ textAlign: 'end' }}>
            <p style={{ marginBottom: '4px', marginTop: '8px' }}>Owner: N/A</p>
            <p style={{ marginBottom: '0px' }}>
              {moment(type.createdTime).format('MM/DD/YYYY HH:MM')}
            </p>
          </div>
        </HeaderButtons>
      </XOMHeader>
      <div
        style={{
          flex: 1,
          overflowY: 'auto',
          position: 'relative',
          margin: '24px',
        }}
      >
        <Card style={{ height: '100%', padding: '12px' }}>
          <Header
            style={{ marginBottom: '24px' }}
            title={
              <InstancesHeader
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <img src={AssetIcon} alt="" />
                <h1>{visibleInstances.length} Instances</h1>
                <VerticalDivider />
                <PropertySummaryIndicator
                  text="# MAPPED PROPERTIES"
                  count={completed}
                />
                <VerticalDivider />
                <PropertySummaryIndicator
                  text="TOTAL # PROPERTIES"
                  count={total}
                />
                <VerticalDivider />
                <PropertySummaryIndicator
                  text="SUGGESTIONS"
                  count={
                    suggestionCountFetching ? (
                      <Icon type="Loading" />
                    ) : (
                      suggestionCount
                    )
                  }
                  disabled={suggestionCount === 0}
                  onClick={e => {
                    e.stopPropagation();
                    history.push(`/${tenant}/templates/${typeId}/suggestions`);
                  }}
                />
              </InstancesHeader>
            }
            extra={[
              <MetadataFilterDropdown
                key="select-dropdown"
                metadata={metadata}
                setFilters={setFilters}
                filters={filters}
              />,
              <BaseDropdown
                options={[
                  { value: undefined, name: 'All' },
                  ...visibleInstances.map(el => ({
                    value: el.id,
                    name: el.name,
                  })),
                ]}
                key="select"
                icon="TriangleDown"
                placeholder="Select Instance"
                onSelect={val => {
                  if (val) {
                    history.push({
                      search: queryString.stringify({
                        ...queryString.parse(search),
                        instanceId: val,
                      }),
                    });
                  } else {
                    const params = queryString.parse(search);
                    delete params.instanceId;
                    history.push({
                      search: queryString.stringify({
                        ...params,
                      }),
                    });
                  }
                }}
              />,
            ]}
          />
          {selectedInstanceId ? (
            <div style={{ overflowY: 'scroll' }}>
              <Tabs
                animated={false}
                activeKey={tab}
                onChange={key => {
                  history.push({
                    search: queryString.stringify({
                      ...queryString.parse(search),
                      tab: key,
                    }),
                  });
                }}
              >
                <Tabs.TabPane tab="Properties" key="properties">
                  <PropertiesPane
                    typeId={typeId}
                    instanceId={selectedInstanceId}
                    renderExtraButtons={propertyId =>
                      renderExtraButtons(selectedInstanceId, propertyId)
                    }
                    onVisibleChange={onVisibleChange}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Data Quality" key="dataquality">
                  <DataQualityPane
                    typeId={typeId}
                    instanceId={selectedInstanceId}
                    renderExtraButtons={propertyId =>
                      renderExtraButtons(selectedInstanceId, propertyId)
                    }
                    onVisibleChange={onVisibleChange}
                  />
                </Tabs.TabPane>
              </Tabs>
            </div>
          ) : (
            <InstancesTable
              typeId={typeId}
              onVisibleChange={onVisibleChange}
              instances={visibleInstances}
            />
          )}
        </Card>
      </div>
      <ViewInstancesSidebar
        typeId={typeId}
        visible={!!showSidebar}
        onClose={() => history.push(`/${tenant}/templates/${typeId}`)}
        onAddClicked={() => setCreateInstanceOpen(true)}
      />
      <NewInstanceModal
        template={type}
        visible={createInstanceOpen}
        onClose={() => setCreateInstanceOpen(false)}
      />
      <EditTemplateModal
        template={type}
        visible={editTemplateFormOpen}
        onClose={() => setEditTemplateFormOpen(false)}
      />

      <ResourceSidebar
        extraButtons={({ timeseriesId }) => {
          if (timeseriesId && previewInstanceId && previewPropertyId) {
            return renderExtraButtons(previewInstanceId, previewPropertyId);
          }
          return [];
        }}
      />
    </div>
  );
};
