import producer from 'immer';
import { createSelector } from 'reselect';
import { Action, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { callUntilCompleted } from 'helpers/Helpers';
import { RootState } from 'reducers';
import { ModelType } from '.';

export type ModelStatus =
  | 'New'
  | 'Scheduled'
  | 'Queued'
  | 'Completed'
  | 'Running'
  | 'Failed';

const MODEL_CREATE_STARTED = 'contextualization/MODEL_CREATE_STARTED';
const MODEL_CREATED = 'contextualization/MODEL_CREATED';
const MODEL_STATUS_UPDATED = 'contextualization/MODEL_STATUS_UPDATED';
const MODEL_DONE = 'contextualization/MODEL_DONE';
const MODEL_ERROR = 'contextualization/MODEL_ERROR';
const MODEL_RESET = 'contextualization/MODEL_RESET';

interface CreateModelStartedAction extends Action<typeof MODEL_CREATE_STARTED> {
  dataKitId: string;
}
interface ModelCreatedAction extends Action<typeof MODEL_CREATED> {
  dataKitId: string;
  modelId: number;
  inputByName: { [key: string]: ItemWithNameAndId };
}
interface ModelStatusUpdatedAction extends Action<typeof MODEL_STATUS_UPDATED> {
  dataKitId: string;
  status: ModelStatus;
}
interface ModelDoneAction extends Action<typeof MODEL_DONE> {
  dataKitId: string;
}
interface ModelErrorAction extends Action<typeof MODEL_ERROR> {
  dataKitId: string;
}

interface ModelResetAction extends Action<typeof MODEL_RESET> {
  dataKitId: string;
}

type ModelActions =
  | CreateModelStartedAction
  | ModelCreatedAction
  | ModelStatusUpdatedAction
  | ModelDoneAction
  | ModelErrorAction
  | ModelResetAction;

export const apiRootPath = (project: string) =>
  `/api/playground/projects/${project}/context/entity_matching`;
const createModelPath = (project: string) => `${apiRootPath(project)}/fitml`;
const getModelStausPath = (project: string, modelId: number) =>
  `${apiRootPath(project)}/${modelId}`;

export function entityMatchingFit(
  dataKitId: string,
  modelType: ModelType,
  inputFrom: ItemWithNameAndId[],
  inputTo: ItemWithNameAndId[]
) {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState: () => RootState
  ): Promise<number> => {
    const {
      app: { sdk },
    } = getState();

    // Ensure all names are unique. For now, we'll just ignore duplicates (pick the last one in the array).
    const inputByName: { [key: string]: ItemWithNameAndId } = {};
    const matchFrom = inputFrom;
    const matchTo = inputTo;

    dispatch({ type: MODEL_CREATE_STARTED, dataKitId });
    return new Promise((resolve, reject) => {
      sdk
        .post(createModelPath(sdk.project), {
          data: {
            matchFrom,
            matchTo,
            modelType,
          },
        })
        .then(response => {
          const {
            status: httpStatus,
            data: { modelId, status: queueStatus },
          } = response;

          dispatch({
            type: MODEL_CREATED,
            modelId,
            dataKitId,
            inputByName,
          });
          dispatch({
            type: MODEL_STATUS_UPDATED,
            status: queueStatus,
            dataKitId,
          });

          if (httpStatus === 200) {
            callUntilCompleted(
              () => sdk.get(getModelStausPath(sdk.project, modelId)),
              data => data.status === 'Completed' || data.status === 'Failed',
              data => {
                dispatch({ type: MODEL_DONE, modelId, dataKitId });
                if (data.status === 'Failed') {
                  dispatch({
                    type: MODEL_ERROR,
                    dataKitId,
                  });
                  reject();
                } else {
                  resolve(modelId);
                }
              },
              data => {
                dispatch({
                  type: MODEL_STATUS_UPDATED,
                  modelId,
                  status: data.status,
                  dataKitId,
                });
              },
              () => {
                dispatch({
                  type: MODEL_STATUS_UPDATED,
                  modelId,
                  status: 'Failed',
                  dataKitId,
                });
                dispatch({ type: MODEL_ERROR, dataKitId });
                reject();
              }
            );
          } else {
            dispatch({ type: MODEL_ERROR, dataKitId });
            reject();
          }
        })
        .catch(() => {
          dispatch({ type: MODEL_ERROR, dataKitId });
          reject();
        });
    });
  };
}

export function ensureModel(modelId: string, dataKitId: string) {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState: () => RootState
  ) => {
    const {
      app: { sdk },
      contextualization: { models },
    } = getState();

    if (models[dataKitId] && models[dataKitId].done) {
      return;
    }

    dispatch({ type: MODEL_CREATE_STARTED, dataKitId });
    const { data } = await sdk.get(
      getModelStausPath(sdk.project, parseInt(modelId, 10))
    );
    dispatch({
      type: MODEL_CREATED,
      modelId,
      dataKitId,
    });
    dispatch({ type: MODEL_DONE, modelId, dataKitId });
    dispatch({
      type: MODEL_STATUS_UPDATED,
      modelId,
      status: data.status,
      dataKitId,
    });
    if (data.status === 'Failed') {
      dispatch({
        type: MODEL_ERROR,
        dataKitId,
      });
    }
  };
}

export function resetModel(dataKitId: string) {
  return {
    type: MODEL_RESET,
    dataKitId,
  };
}

export interface ItemWithNameAndId {
  id: number;
  name: string;
}

export interface ModelState {
  started: boolean;
  id?: number;
  status: ModelStatus;
  done: boolean;
  error: boolean;
}

export const modelDefaultState: ModelState = Object.freeze({
  started: false,
  id: undefined,
  status: 'New',
  done: false,
  error: false,
});

interface ModelStore {
  [dataKitId: string]: ModelState;
}

export default function reducer(
  state: ModelStore = {},
  action: ModelActions
): ModelStore {
  return producer(state, draft => {
    const id = action.dataKitId || -1;
    switch (action.type) {
      case MODEL_CREATE_STARTED: {
        draft[id] = { ...modelDefaultState };
        draft[id].started = true;
        draft[id].error = false;
        break;
      }

      case MODEL_CREATED: {
        draft[id].id = action.modelId;
        break;
      }

      case MODEL_STATUS_UPDATED: {
        draft[id].status = action.status;
        break;
      }

      case MODEL_DONE: {
        draft[id].done = true;
        break;
      }

      case MODEL_RESET: {
        draft[id] = modelDefaultState;
        break;
      }

      case MODEL_ERROR: {
        draft[id].error = true;
        draft[id].status = 'Failed';
      }
    }
  });
}

export const getModel = createSelector(
  (state: RootState) => state.contextualization.models,
  models => (id: string) => models[id] || modelDefaultState
);
