import React from 'react';
import { MatchingFieldsInfoModal } from './MatchingFieldsInfoModal';

export default function EntityMatcherOptions() {
  return (
    <>
      <h1>Entity matching model options</h1>
      <MatchingFieldsInfoModal />
    </>
  );
}
