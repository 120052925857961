import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, Table } from 'antd';

import { dataKitItemMapSelector } from 'modules/selection';
import { getStagedChanges } from 'modules/contextualization/predictions';

type ConfirmationModalProps = {
  resourceDataKitId: string;
  assetsDSId: string;
  visible: boolean;
  confirmed: () => void;
  onClose: () => void;
};

const columns = [
  { title: 'Resource', dataIndex: 'resourceName' },
  { title: 'Existing asset id', dataIndex: 'oldAssetId' },
  { title: 'New asset id', dataIndex: 'assetId' },
];

export default function ConfirmationModal(props: ConfirmationModalProps) {
  const { visible, confirmed, onClose, resourceDataKitId, assetsDSId } = props;
  const stagedChanges = useSelector(getStagedChanges)(
    resourceDataKitId,
    assetsDSId
  );
  const { items: resources } = useSelector(dataKitItemMapSelector)(
    resourceDataKitId,
    true
  );
  const changesOverwritingExistingIds = stagedChanges
    .map(change => {
      const resource = resources[change.resourceId];
      if (resource?.assetId && resource?.assetId !== change.assetId) {
        return {
          ...change,
          oldAssetId: resource?.assetId,
        };
      }
      return {
        ...change,
        oldAssetId: undefined,
      };
    })
    .filter(change => !!change.oldAssetId);
  return (
    <Modal
      title="Confirm overwriting asset ids"
      visible={visible}
      onOk={() => {
        confirmed();
        onClose();
      }}
      onCancel={() => onClose()}
      width={1000}
    >
      <p>
        The following resources already have an asset id that will be
        overwritten!
      </p>
      <Table
        columns={columns}
        dataSource={changesOverwritingExistingIds}
        size="small"
      />
    </Modal>
  );
}
