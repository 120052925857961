import React, { useState, useEffect, useMemo } from 'react';
import { Progress, Col, Row, Collapse } from 'antd';
import { useSelector } from 'react-redux';
import { Asset, FilesMetadata } from '@cognite/sdk';
import { RootState } from 'reducers/index';
import {
  makeNumPnidParsingJobSelector,
  makeNumPnidConvertJobSelector,
} from 'modules/pnidParsing';
import { dataKitItemsSelector } from 'modules/selection';
import { Result } from 'modules/sdk-builder/types';

interface LoadResourcesProps {
  assetDataKitId: string;
  fileDataKitId: string;
}
export default function LoadResources(props: LoadResourcesProps) {
  const { assetDataKitId, fileDataKitId } = props;

  const { progress: assetProgress, items: assets } = useSelector(
    dataKitItemsSelector
  )(assetDataKitId, true, true) as Result<Asset>;

  const { progress: fileProgress, items } = useSelector(dataKitItemsSelector)(
    fileDataKitId
  ) as Result<FilesMetadata>;

  const files = items.filter(el => !!el);

  const selectNumParsingJob = useMemo(makeNumPnidParsingJobSelector, []);
  const selectNumConvertJob = useMemo(makeNumPnidConvertJobSelector, []);

  const parsingJobCount = useSelector((state: RootState) =>
    selectNumParsingJob(
      state,
      files.map(el => el.id)
    )
  );

  const conversionJobCount = useSelector((state: RootState) =>
    selectNumConvertJob(
      state,
      files.map(el => el.id)
    )
  );

  const conversionDone =
    parsingJobCount === files.length && conversionJobCount === files.length;

  useEffect(() => {
    setCollapseExpanded(conversionDone);
  }, [conversionDone]);

  const [collapseExpanded, setCollapseExpanded] = useState<boolean>(
    conversionDone
  );

  const assetPercent: number = Math.floor(assetProgress * 100);
  const filesPercent: number = Math.floor(fileProgress * 100);
  const parsingJobPercent: number = Math.floor(
    (parsingJobCount / files.length) * 100
  );
  const convertJobPercent: number = Math.floor(
    (conversionJobCount / files.length) * 100
  );

  return (
    <Collapse
      activeKey={collapseExpanded ? undefined : '1'}
      onChange={() => setCollapseExpanded(!collapseExpanded)}
    >
      <Collapse.Panel
        header={conversionDone ? 'Resources loaded' : 'Loading resources'}
        key="1"
      >
        <Row>
          <Col span={4}>
            <p>Files</p>
          </Col>
          <Col span={20}>
            <Progress
              percent={filesPercent}
              status={fileProgress === 1 ? 'success' : 'active'}
            />
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <p>Assets</p>
          </Col>
          <Col span={20}>
            <Progress
              percent={assetPercent}
              status={assetProgress === 1 ? 'success' : 'active'}
            />
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <p>Conversion Jobs</p>
          </Col>
          <Col span={20}>
            <Progress
              percent={convertJobPercent}
              status={
                conversionJobCount === files.length ? 'success' : 'active'
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <p>Parsing Jobs</p>
          </Col>
          <Col span={20}>
            <Progress
              percent={parsingJobPercent}
              status={parsingJobPercent === files.length ? 'success' : 'active'}
            />
          </Col>
        </Row>
        <p>
          Loaded {files.length} files and{assets.length} assets
        </p>
      </Collapse.Panel>
    </Collapse>
  );
}
