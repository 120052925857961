import React from 'react';
import styled from 'styled-components';
import { Badge } from 'antd';
import { Colors, Button } from '@cognite/cogs.js';

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 16px;
  box-shadow: 0px 0px 6px #cdcdcd;
  border-radius: 12px;
  box-sizing: border-box;
  z-index: 1;
  background: #fff;
  width: 400px;
  height: auto;
  max-height: 80%;
  margin-right: 20px;
  overflow: auto;
  h1 {
    margin-bottom: 0px;
    flex: 1;
  }
`;

const SidebarHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid #aaa;
`;

const HeaderText = styled.h1`
  font-weight: 800;
  font-size: 1.1em;
  text-transform: uppercase;
`;

const StyledResourceList = styled.ul`
  list-style-type: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  width: 100%;

  li {
    padding: 16px 0;
    border-bottom: 1px solid #aaa;
    width: 100%;
  }

  li p {
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: 600;
    letter-spacing: 0.04em;
  }
`;

export default function ResourceSidebar(props: any) {
  const { annotationDetails, assetCount } = props;
  const disabled = true;

  const assetList =
    annotationDetails.Asset.items &&
    annotationDetails.Asset.items['Model Generated']
      ? [
          ...new Set(
            annotationDetails.Asset.items['Model Generated'].map(
              (annotation: any) => annotation.label
            )
          ),
        ]
          .sort()
          .map((annotation: any) => (
            <li key={annotation}>
              <h3>{annotation}</h3>
              <p>Located on pages</p>
              <Button
                disabled={disabled}
                style={{
                  padding: '10px',
                  width: '32px',
                  height: '32px',
                  // TODO this is fixed in 1.1.4 cogs.js which is not released on yarn yet
                  cursor: disabled ? 'not-allowed' : 'pointer',
                }}
                icon="Minus"
              />
            </li>
          ))
      : [];

  return (
    <Sidebar>
      <SidebarHeader>
        <HeaderText>Detected assets</HeaderText>
        <span style={{ marginLeft: '12px' }}>
          <Badge
            style={{ backgroundColor: Colors.midblue.hex() }}
            count={assetCount}
            showZero
          />{' '}
        </span>
      </SidebarHeader>
      <StyledResourceList>{assetList}</StyledResourceList>
    </Sidebar>
  );
}
