import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd';
import { SelectWrapper } from 'components/Common';
import { Button, Colors, Icon } from '@cognite/cogs.js';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  background: #fff;
  min-width: 480px;
  .tags {
    display: flex;
    margin-bottom: 12px;
    flex-wrap: wrap;

    .label {
      align-self: center;
      margin-right: 8px;
      font-weight: 800;
    }
  }
`;
const Tag = styled.div`
  display: inline-flex;
  align-items: center;
  background: ${Colors.midblue.hex()};
  padding: 8px 12px;
  color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 4px ${Colors['greyscale-grey3'].hex()};
  cursor: pointer;
  margin-right: 8px;
  transition: 0.3s all;

  .delete {
    opacity: 0;
  }

  .orig,
  .hover,
  .delete {
    margin-right: 6px;
    display: flex;
    opacity: 1;
    transition: 0.3s all;
    height: 16px;
    width: 16px;
  }
  .delete {
    opacity: 0;
    margin-right: 0px;
    margin-left: 6px;
    color: ${Colors.danger.hex()};
  }
  .hover {
    display: block;
    position: absolute;
    opacity: 0;
  }

  &&:hover {
    box-shadow: 0px 4px 4px ${Colors['greyscale-grey3'].hex()};
    .orig {
      opacity: 0;
    }
    .hover {
      opacity: 1;
    }
    .delete {
      opacity: 1;
    }
  }
`;
const MetadataItemWrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
  .key,
  .value {
    display: flex;
    margin-right: 4px;
    flex: 1;
    width: 140px;
    overflow: hidden;
    > div {
      flex: 1;
    }
    .ant-select-selection-selected-value {
      max-width: 108px;
    }
  }
  .buttons {
    width: 160px;
    display: flex;
    align-items: stretch;
    > * {
      margin-left: 4px;
    }
  }
`;

const MetadataFilterItem = ({
  key,
  metadata,
  setFilter,
  onCancel,
  initialKey,
  initialValue,
}: {
  key: string;
  metadata: {
    [key: string]: string[];
  };
  setFilter: (selectedKey: string, selectedValue: string) => void;
  onCancel: (shouldDelete: boolean) => void;
  initialKey?: string;
  initialValue?: string;
}) => {
  const [selectedKey, setSelectedKey] = useState<string | undefined>(
    initialKey
  );
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    initialValue
  );
  useEffect(() => {
    if (initialKey) {
      setSelectedKey(initialKey);
    }
  }, [initialKey]);
  useEffect(() => {
    if (initialValue) {
      setSelectedValue(initialValue);
    }
  }, [initialValue]);

  const allowEdit =
    selectedKey &&
    selectedValue &&
    (selectedKey !== initialKey || selectedValue !== initialValue);
  const hasInitialValue = initialKey || initialValue;
  return (
    <MetadataItemWrapper key={key}>
      <div className="key">
        <SelectWrapper>
          <Select
            placeholder="Key"
            disabled={!!initialKey}
            showSearch
            dropdownMatchSelectWidth={false}
            style={{ width: '100%' }}
            value={selectedKey}
            allowClear
            onChange={(value: any) => {
              if (value === undefined) {
                setSelectedKey(value);
              }
            }}
            onSelect={(value: string | undefined) => {
              setSelectedKey(value);
              setSelectedValue(undefined);
            }}
          >
            {Object.keys(metadata).map(el => (
              <Select.Option key={el}>{el}</Select.Option>
            ))}
          </Select>
        </SelectWrapper>
      </div>
      <div className="value">
        <SelectWrapper>
          <Select
            placeholder="Value"
            showSearch
            dropdownMatchSelectWidth={false}
            style={{ width: '100%' }}
            disabled={!selectedKey}
            value={selectedValue}
            onSelect={setSelectedValue}
            allowClear
            onChange={(value: any) => {
              if (value === undefined) {
                setSelectedValue(value);
              }
            }}
          >
            {selectedKey &&
              metadata[selectedKey].map(el => (
                <Select.Option key={el}>{el}</Select.Option>
              ))}
          </Select>
        </SelectWrapper>
      </div>
      <div className="buttons">
        <Button
          type="primary"
          icon={initialKey && initialValue ? 'Edit' : 'Plus'}
          disabled={!allowEdit}
          onClick={() => {
            if (allowEdit) {
              setFilter(selectedKey!, selectedValue!);
            } else {
              message.error('You must choose a key and a value');
            }
          }}
        />
        <Button icon="Close" onClick={() => onCancel(false)} />
        {hasInitialValue && (
          <Button
            onClick={() => onCancel(true)}
            type="danger"
            icon="Delete"
            disabled={!hasInitialValue}
          />
        )}
      </div>
    </MetadataItemWrapper>
  );
};

export type MetadataFilterFormProps = {
  metadata: {
    [key: string]: string[];
  };
  filters?: {
    [key: string]: string;
  };
  setFilters: (filter: { [key: string]: string }) => void;
};

export const MetadataFilterForm = ({
  metadata,
  filters = {},
  setFilters = () => {},
}: MetadataFilterFormProps) => {
  const [editingKeys, setEditingKeys] = useState<string[]>([]);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  return (
    <Wrapper>
      <div className="tags">
        <span className="label">Filters</span>
        {Object.keys(filters).map(el => (
          <Tag
            key={el}
            onClick={() =>
              setEditingKeys(Array.from(new Set(editingKeys).add(el)))
            }
          >
            <Icon type="Label" className="orig" />
            <Icon type="Edit" className="hover" />
            <strong>{el}</strong>: {filters[el]}
            <Icon
              type="Delete"
              className="delete"
              onClick={ev => {
                ev.stopPropagation();
                const newFilter = { ...filters };
                delete newFilter[el];
                setFilters(newFilter);
              }}
            />
          </Tag>
        ))}
      </div>
      {Object.keys(filters)
        .filter(el => editingKeys.includes(el))
        .map(key => (
          <MetadataFilterItem
            key={key}
            metadata={metadata}
            initialKey={key}
            initialValue={filters[key]}
            setFilter={(newKey, newValue) => {
              setFilters({ ...filters, [newKey]: newValue });
              setEditingKeys(editingKeys.filter(el => el !== key));
            }}
            onCancel={shouldDelete => {
              if (shouldDelete) {
                const newFilter = { ...filters };
                delete newFilter[key];
                setFilters(newFilter);
              }
              setEditingKeys(editingKeys.filter(el => el !== key));
            }}
          />
        ))}
      {isAdding && (
        <MetadataFilterItem
          key="add"
          metadata={metadata}
          setFilter={(newKey, newValue) => {
            setFilters({ ...filters, [newKey]: newValue });
            setIsAdding(false);
          }}
          onCancel={() => {
            setIsAdding(false);
          }}
        />
      )}
      <Button disabled={isAdding} onClick={() => setIsAdding(true)} icon="Plus">
        Add New Metadata Filter
      </Button>
    </Wrapper>
  );
};
