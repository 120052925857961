import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  itemSelector as fileItemSelector,
  retrieve as retrieveFile,
} from 'modules/files';
import { urlPreviewSelector, loadPreviewUrl } from 'modules/filePreview';
import { AnnotatedPnIDPreview } from 'components/PnIDPreview';
import { listByFileId } from 'modules/annotations';
import { trackUsage } from 'utils/Metrics';
import { PNID_PARSING_JOB_ID_METADATA_FIELD } from 'utils/PnIDApi';
import PnIDSearch from 'containers/Exploration/PnIDViewer/PnIDSearch';
import { onResourceSelected } from 'modules/app';
import { FilesMetadata, Asset } from '@cognite/sdk';
import { RenderResourceActionsFunction } from 'containers/HoverPreview';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;

  .pnid-content {
    flex: 4;
    height: 100%;
  }
`;

export default function PnIDViewer({
  fileId: fileIdFromProps,
  onFileClicked,
  onAssetClicked,
  renderResourceActions,
}: {
  fileId?: number;
  onFileClicked?: (file: FilesMetadata) => void;
  onAssetClicked?: (asset: Asset) => void;
  renderResourceActions?: RenderResourceActionsFunction;
}) {
  const { fileId } = useParams<{
    fileId: string | undefined;
  }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const fileIdNumber =
    fileIdFromProps || (fileId ? parseInt(fileId, 10) : undefined);

  const file = useSelector(fileItemSelector)(fileIdNumber);

  const filePreviewUrlResult = useSelector(
    fileIdNumber ? urlPreviewSelector(fileIdNumber) : () => undefined
  );

  const filePreviewUrl = filePreviewUrlResult
    ? filePreviewUrlResult.pngUrl
    : undefined;

  useEffect(() => {
    if (fileIdNumber) {
      (async () => {
        await dispatch(retrieveFile([{ id: fileIdNumber }]));
        await dispatch(listByFileId(fileIdNumber));
      })();
    }
  }, [dispatch, fileIdNumber]);

  useEffect(() => {
    if (file) {
      if (
        file.metadata &&
        file.metadata[PNID_PARSING_JOB_ID_METADATA_FIELD] &&
        !filePreviewUrl
      ) {
        dispatch(loadPreviewUrl(file.id));
      }
    }
  }, [dispatch, file, filePreviewUrl]);

  useEffect(() => {
    trackUsage('Exploration.PnidViewer.Load', { fileIdNumber });
  }, [fileIdNumber]);

  const onFileSelected = (newFile: FilesMetadata) => {
    if (onFileClicked) {
      onFileClicked(newFile);
    } else {
      dispatch(
        onResourceSelected({ fileId: newFile.id, showSidebar: true }, history)
      );
    }
  };

  return (
    <Wrapper>
      <div className="pnid-content">
        <AnnotatedPnIDPreview
          filePreviewUrl={filePreviewUrl}
          fileId={fileIdNumber}
          onFileClicked={onFileClicked}
          onAssetClicked={onAssetClicked}
          renderResourceActions={renderResourceActions}
        >
          <PnIDSearch
            onFileSelected={onFileSelected}
            onAssetSelected={id =>
              dispatch(
                onResourceSelected({ assetId: id, showSidebar: true }, history)
              )
            }
            renderResourceActions={renderResourceActions}
            currentFileId={fileIdNumber}
          />
        </AnnotatedPnIDPreview>
      </div>
    </Wrapper>
  );
}
