import React, { useState } from 'react';
import { List, Collapse, Table, Pagination } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import TimeseriesModal from 'components/TimeseriesModal';
import {
  getRulesSelector,
  getRulesWithApplicableMatches,
} from 'modules/contextualization/rules';
import {
  Change,
  Rule,
  Prediction,
  stageChange,
  stageChanges,
} from 'modules/contextualization/predictions';

import { RootState } from 'reducers';
import { dataKitItemMapSelector } from 'modules/selection';
import { itemSelector as assetSelector } from 'modules/assets';
import { Button, Icon } from '@cognite/cogs.js';

import AssetModal from 'components/AssetModal';
import ItemWithDescription from 'components/ItemWithDescription';
import reactStringReplace from 'react-string-replace';
import { trackUsage } from 'utils/Metrics';
import { animationTag } from './animation';

const { Panel } = Collapse;

export function getHeaderTitle(rule: string, inputPattern: string) {
  const colors = [
    '#e6194b',
    '#3cb44b',
    '#ffe119',
    '#4363d8',
    '#f58231',
    '#911eb4',
    '#46f0f0',
    '#f032e6',
    '#bcf60c',
    '#fabebe',
    '#008080',
    '#e6beff',
    '#9a6324',
    '#fffac8',
    '#800000',
    '#aaffc3',
    '#808000',
    '#ffd8b1',
    '#000075',
    '#808080',
    '#ffffff',
    '#000000',
  ];
  const title = reactStringReplace(rule, /(\[[DL]\d+\])/g, (match, i) => {
    const groupMatch = match.match(/\d+/) as RegExpMatchArray;
    const colorIndex = parseInt(groupMatch[0], 10) % colors.length;
    const color = colors[colorIndex]; // match.includes('D') ? '#8EBF5F' : '#BB5E7D';
    return (
      <span key={`${inputPattern}-${i}`} style={{ color, fontWeight: 'bold' }}>
        {match}
      </span>
    );
  });
  return title;
}

function header(rule: Rule, onApplyAllClick: () => void) {
  const title = getHeaderTitle(
    `${rule.inputPattern} -> ${rule.predictPattern}`,
    rule.inputPattern
  );
  const example = `${rule.matches[0]?.matchFrom.name} -> ${rule.matches[0]?.matches[0]?.matchTo.name}`;
  return (
    <List.Item style={{ display: 'flex', padding: 0 }}>
      <List.Item.Meta title={title} description={example} />
      <Button style={{ marginLeft: 'auto' }} onClick={() => onApplyAllClick()}>
        Apply all {rule.matches.length}
      </Button>
    </List.Item>
  );
}

interface RuleProps {
  rule: Rule;
  resourceDataKitId: string;
}
function RenderRule(props: RuleProps) {
  const { rule, resourceDataKitId } = props;
  const { inputPattern, predictPattern, avgScore } = rule;

  const dispatch = useDispatch();

  const getAsset = useSelector(assetSelector);
  const { items: resources } = useSelector(dataKitItemMapSelector)(
    resourceDataKitId,
    true
  );
  const { type } = useSelector(
    (state: RootState) => state.selection.items[resourceDataKitId]
  );

  const [selectedRows, setSelectedRows] = useState<Prediction[]>([]);

  const [selectedTimeseriesId, setSelectedTimeseriesId] = useState<
    number | undefined
  >();
  const [selectedAssetId, setSelectedAssetId] = useState<number | undefined>();

  const columns = [
    {
      title: 'Resource',
      dataIndex: 'input',
      render: (input: any, prediction: Prediction) => (
        <ItemWithDescription
          onClick={() =>
            type === 'timeseries' &&
            setSelectedTimeseriesId(prediction.matchFrom.id)
          }
          name={prediction.matchFrom.name || input} // TODO input is probably redundant
          description={
            resources[prediction.matchFrom.id || -1]?.description || ''
          }
        />
      ),
    },
    {
      title: 'Asset',
      render: (predicted: Prediction) => (
        <ItemWithDescription
          onClick={() =>
            predicted.predictedId && setSelectedAssetId(predicted.predictedId)
          }
          name={predicted.matches[0]?.matchTo.name || ''}
          description={getAsset(predicted.predictedId || -1)?.description || ''}
        />
      ),
    },
    {
      title: 'Confidence',
      render: (predicted: Prediction) => predicted.matches[0]?.score,
    },
    {
      title: (
        <Button
          disabled={selectedRows.length === 0}
          onClick={() => {
            const changes = selectedRows
              .filter(prediction => !!prediction.predictedId)
              .map(prediction => ({
                resourceId: prediction.matchFrom.id,
                assetId: prediction.predictedId,
                rule: `${rule.inputPattern} -> ${rule.predictPattern}`,
              })) as Change[];
            trackUsage('Contextualization.ResourceMatching.Rules.ApplyBulk', {
              ruleInputPattern: rule.inputPattern,
              rulePredictPattern: rule.predictPattern,
              ruleAvgScore: rule.avgScore,
              ruleSelectedMatchCount: changes.length,
              ruleTotalMatchCount: rule.matches.length,
              resourceDataKitId,
            });
            setSelectedRows([]);
            dispatch(stageChanges(changes, resourceDataKitId));
          }}
        >
          <Icon type="Check" /> ({selectedRows.length})
        </Button>
      ),
      key: 'apply',
      render: (prediction: Prediction) => (
        <Button
          icon="Check"
          onClick={() => {
            if (prediction.predictedId) {
              trackUsage(
                'Contextualization.ResourceMatching.Rules.ApplySingle',
                {
                  ruleInputPattern: inputPattern,
                  rulePredictPattern: predictPattern,
                  ruleAvgScore: avgScore,
                  resourceName: prediction.matchFrom.name || '',
                  assetName: prediction.matches[0]?.matchTo.name || '',
                  resourceDataKitId,
                }
              );
              setSelectedRows(
                selectedRows.filter(
                  row => row.matchFrom.id !== prediction.matchFrom.id
                )
              );
              dispatch(
                stageChange(
                  {
                    resourceId: prediction.matchFrom.id,
                    assetId: prediction.predictedId,
                    rule: `${inputPattern} -> ${predictPattern}`,
                  },
                  resourceDataKitId
                )
              );
            }
          }}
        />
      ),
    },
  ];

  const rowSelection = {
    onChange: (_: any, selectedRows_: Prediction[]) => {
      setSelectedRows(selectedRows_);
    },
  };

  return (
    <>
      {selectedTimeseriesId && (
        <TimeseriesModal
          onClose={() => setSelectedTimeseriesId(undefined)}
          timeseriesId={selectedTimeseriesId}
        />
      )}
      {selectedAssetId && (
        <AssetModal
          onClose={() => setSelectedAssetId(undefined)}
          assetId={selectedAssetId}
        />
      )}
      <Table
        rowKey={prediction => prediction.matchFrom.id.toString()}
        pagination={{ pageSize: 100 }}
        rowSelection={rowSelection}
        dataSource={rule.matches}
        columns={columns}
        components={{ body: { wrapper: animationTag } }}
      />
    </>
  );
}

interface RulesMatchingProps {
  resourceDataKitId: string;
  assetDKId: string;
  itemFilter: (p: Prediction) => boolean;
}

export default function RulesMatching(props: RulesMatchingProps) {
  const RULES_PER_PAGE = 15;
  const dispatch = useDispatch();

  // Rules only contains non-matched and non-staged matches in this selector

  const [currentPage, setCurrentPage] = useState(1);
  const { resourceDataKitId, assetDKId, itemFilter } = props;
  const { done: rulesDone } = useSelector(getRulesSelector)(resourceDataKitId);
  const rules = useSelector(getRulesWithApplicableMatches)(
    resourceDataKitId,
    assetDKId
  ).map(rule => ({
    ...rule,
    matches: rule.matches.filter(itemFilter),
  }));

  return (
    <>
      {!rulesDone ? (
        'Loading rules ...'
      ) : (
        <>
          <Collapse>
            {rules
              .filter(rule => rule.matches.length > 0)
              .slice(
                (currentPage - 1) * RULES_PER_PAGE,
                currentPage * RULES_PER_PAGE
              )
              .map(rule => {
                const onApplyAllClick = () => {
                  const changes = rule.matches
                    .filter(prediction => !!prediction.predictedId)
                    .map(prediction => ({
                      resourceId: prediction.matchFrom.id,
                      assetId: prediction.predictedId,
                      rule: `${rule.inputPattern} -> ${rule.predictPattern}`,
                    })) as Change[];
                  trackUsage(
                    'Contextualization.ResourceMatching.Rules.ApplyAll',
                    {
                      ruleInputPattern: rule.inputPattern,
                      rulePredictPattern: rule.predictPattern,
                      ruleAvgScore: rule.avgScore,
                      ruleMatchCount: changes.length,
                      dataKitId: props.resourceDataKitId,
                    }
                  );
                  dispatch(stageChanges(changes, props.resourceDataKitId));
                };

                const h = header(rule, onApplyAllClick);
                const key = rule.inputPattern + rule.predictPattern;
                return (
                  <Panel key={key} header={h}>
                    <RenderRule
                      rule={rule}
                      resourceDataKitId={resourceDataKitId}
                    />
                  </Panel>
                );
              })}
          </Collapse>
          <Pagination
            current={currentPage}
            total={rules.filter(rule => rule.matches.length > 0).length}
            defaultPageSize={RULES_PER_PAGE}
            onChange={page => setCurrentPage(page)}
            style={{ float: 'right' }}
          />
        </>
      )}
    </>
  );
}
