import * as mixpanelConfig from 'mixpanel-browser';
import { selectUser } from 'modules/login';
import store from '../store/index';
import { AppState } from '../modules/app';

const MIXPANEL_TOKEN = '967a5b70a337589f0be18a2696fd3a24';

const mixpanel = mixpanelConfig.init(
  MIXPANEL_TOKEN,
  { persistence: 'localStorage' },
  'datastudio'
);

export type Props = { [key: string]: string | number | boolean | Props | null };

export const trackUsage = (
  event: string,
  metadata?: { [key: string]: any }
) => {
  const { sdk } = store.getState().app as AppState;

  const { host } = window?.location;
  const { pathname } = window?.location;
  if (!host || !pathname) {
    return;
  }

  const pathWithoutTenant = pathname.substring(pathname.indexOf('/', 1));

  const user = selectUser(store.getState());
  if (host.indexOf('localhost') === -1) {
    mixpanel.track(event, {
      ...metadata,
      project: sdk.project,
      version: 1,
      appVersion: process.env.REACT_APP_VERSION,
      location: window.location.pathname,
      user,
      pathname: pathWithoutTenant,
    });
  }
};

export class Timer {
  private timerEvent: string;

  private startProps: Props = {};

  private finished = false;

  constructor(event: string, startProps: Props = {}) {
    this.timerEvent = event;
    this.startProps = startProps;

    try {
      mixpanel.time_event(event);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Unable to track ${this.timerEvent}`);
    }
  }

  stop(props: Props = {}) {
    if (this.finished) {
      return;
    }
    try {
      const combined = { ...this.startProps, ...props };
      trackUsage(this.timerEvent, combined);
      this.finished = true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Unable to track ${this.timerEvent}: `, e);
    }
  }
}

export function trackTimedUsage(event: string, props?: Props): Timer {
  return new Timer(event, props);
}
