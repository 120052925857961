import { chunk } from 'lodash';
import { Action, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';
import { callUntilCompleted } from 'helpers/Helpers';
import { RootState } from 'reducers';
import {
  PnIDApi,
  PnIDAnnotation,
  PNID_PARSING_JOB_ID_METADATA_FIELD,
  removeExtension,
} from 'utils/PnIDApi';
import { trackTimedUsage } from 'utils/Metrics';
import { UploadFileMetadataResponse, FilesMetadata, Asset } from '@cognite/sdk';

import { Result } from 'modules/sdk-builder/types';
import { canEditEvents, canEditFiles } from 'utils/PermissionUtils';
import * as PnidParsing from './pnidParsing';
import { itemSelector } from './files';
import {
  create as createAnnotations,
  list as listAnnotations,
  selectAnnotations,
} from './annotations';
import { ModelStatus } from './contextualization/models';

import { GCSUploader } from './functions/create';
import { loadResourceSelection, dataKitItemsSelector } from './selection';

const PIPELINE_STARTED = 'pnid_parsing/PIPELINE_STARTED';
const PIPELINE_DONE = 'pnid_parsing/PIPELINE_DONE';

const PARSING_JOB_CREATE_STARTED = 'pnid_parsing/PARSING_CREATE_STARTED';
const PARSING_JOB_CREATED = 'pnid_parsing/PARSING_JOB_CREATED';
const PARSING_JOB_STATUS_UPDATED = 'pnid_parsing/PARSING_JOB_STATUS_UPDATED';
const PARSING_JOB_DONE = 'pnid_parsing/PARSING_JOB_DONE';
const PARSING_JOB_ERROR = 'pnid_parsing/PARSING_JOB_ERROR';

const CONVERT_JOB_CREATE_STARTED = 'pnid_parsing/CONVERT_JOB_CREATE_STARTED';
const CONVERT_JOB_CREATED = 'pnid_parsing/CONVERT_JOB_CREATED';
const CONVERT_JOB_STATUS_UPDATED = 'pnid_parsing/CONVERT_JOB_STATUS_UPDATED';
const CONVERT_JOB_DONE = 'pnid_parsing/CONVERT_JOB_DONE';
const CONVERT_JOB_ERROR = 'pnid_parsing/CONVERT_JOB_ERROR';

const UPLOAD_JOB_CREATE_STARTED = 'pnid_parsing/UPLOAD_JOB_CREATE_STARTED';
const UPLOAD_JOB_CREATED = 'pnid_parsing/UPLOAD_JOB_CREATED';
const UPLOAD_JOB_STATUS_UPDATED = 'pnid_parsing/UPLOAD_JOB_STATUS_UPDATED';
const UPLOAD_JOB_DONE = 'pnid_parsing/UPLOAD_JOB_DONE';
const UPLOAD_JOB_ERROR = 'pnid_parsing/UPLOAD_JOB_ERROR';

const ANNOTATION_JOB_STARTED = 'pnid_parsing/ANNOTATION_JOB_CREATE_STARTED';
const ANNOTATION_JOB_DONE = 'pnid_parsing/ANNOTATION_JOB_DONE';
const ANNOTATION_JOB_ERROR = 'pnid_parsing/ANNOTATION_JOB_ERROR';

const PNID_OPTIONS = 'pnid_parsing/PNID_OPTIONS';

interface PipelineStartedAction extends Action<typeof PIPELINE_STARTED> {
  fileDataKitId: string;
  assetDataKitId: string;
}
interface PipelineDoneAction extends Action<typeof PIPELINE_DONE> {
  fileDataKitId: string;
  assetDataKitId: string;
}

type PipelineAction = PipelineStartedAction | PipelineDoneAction;

interface CreateParsingJobStartedAction
  extends Action<typeof PARSING_JOB_CREATE_STARTED> {
  fileId: number;
  dataKitId: string;
}
interface ParsingJobCreatedAction extends Action<typeof PARSING_JOB_CREATED> {
  fileId: number;
  jobId: number;
}
interface ParsingJobStatusUpdatedAction
  extends Action<typeof PARSING_JOB_STATUS_UPDATED> {
  fileId: number;
  jobId: number;
  status: ModelStatus;
}
interface ParsingJobDoneAction extends Action<typeof PARSING_JOB_DONE> {
  fileId: number;
  entities: PnidResponseEntity[];
}
interface ParsingJobErrorAction extends Action<typeof PARSING_JOB_ERROR> {
  fileId: number;
}

type ParsingJobActions =
  | CreateParsingJobStartedAction
  | ParsingJobCreatedAction
  | ParsingJobStatusUpdatedAction
  | ParsingJobDoneAction
  | ParsingJobErrorAction;

interface CreateConvertJobStartedAction
  extends Action<typeof CONVERT_JOB_CREATE_STARTED> {
  fileId: number;
}
interface ConvertJobCreatedAction extends Action<typeof CONVERT_JOB_CREATED> {
  fileId: number;
  jobId: number;
}
interface ConvertJobStatusUpdatedAction
  extends Action<typeof CONVERT_JOB_STATUS_UPDATED> {
  fileId: number;
  jobId: number;
  status: ModelStatus;
}
interface ConvertJobDoneAction extends Action<typeof CONVERT_JOB_DONE> {
  fileId: number;
  pngUrl: string;
}
interface ConvertJobErrorAction extends Action<typeof CONVERT_JOB_ERROR> {
  fileId: number;
}

type ConvertJobActions =
  | CreateConvertJobStartedAction
  | ConvertJobCreatedAction
  | ConvertJobStatusUpdatedAction
  | ConvertJobDoneAction
  | ConvertJobErrorAction;

interface CreateUploadJobStartedAction
  extends Action<typeof UPLOAD_JOB_CREATE_STARTED> {
  fileId: number;
}
interface UploadJobCreatedAction extends Action<typeof UPLOAD_JOB_CREATED> {
  fileId: number;
  jobId: number;
}
interface UploadJobStatusUpdatedAction
  extends Action<typeof UPLOAD_JOB_STATUS_UPDATED> {
  fileId: number;
  jobId: number;
  status: ModelStatus;
}
interface UploadJobDoneAction extends Action<typeof UPLOAD_JOB_DONE> {
  fileId: number;
  svgUrl: string;
}
interface UploadJobErrorAction extends Action<typeof UPLOAD_JOB_ERROR> {
  fileId: number;
}

type UploadJobActions =
  | CreateUploadJobStartedAction
  | UploadJobCreatedAction
  | UploadJobStatusUpdatedAction
  | UploadJobDoneAction
  | UploadJobErrorAction;

interface AnnotationJobStartedAction
  extends Action<typeof ANNOTATION_JOB_STARTED> {
  fileId: number;
}
interface AnnotationJobDoneAction extends Action<typeof ANNOTATION_JOB_DONE> {
  fileId: number;
}
interface AnnotationJobErrorAction extends Action<typeof ANNOTATION_JOB_ERROR> {
  fileId: number;
}

type AnnotationJobAction =
  | AnnotationJobStartedAction
  | AnnotationJobDoneAction
  | AnnotationJobErrorAction;

interface PnIDOptions extends Action<typeof PNID_OPTIONS> {
  partialMatch: boolean;
  grayscale: boolean;
}

type PnIDOptionsActions = PnIDOptions;

const apiRootPath = (project: string) =>
  `/api/playground/projects/${project}/context/pnid`;
const createParsingJobPath = (project: string) =>
  `${apiRootPath(project)}/parse`;
const createConvertJobPath = (project: string) =>
  `${apiRootPath(project)}/convert`;
const getParsingStatusPath = (project: string, jobId: number) =>
  `${apiRootPath(project)}/${jobId}`;
const createConvertStatusPath = (project: string, jobid: number) =>
  `${apiRootPath(project)}/convert/${jobid}`;

export const downloadFile = async (url: string) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('Network response was not ok.');
  }
  const blob = await response.blob();
  return blob;
};

type PnidParsingJobSchema = {
  fileId: number;
  entities: string[];
};

const pnidParsingJob = (
  file: FilesMetadata,
  entities: string[],
  partialMatch: boolean,
  grayscale: boolean,
  assetsDataKit: string,
  fileDataKit: string
) => {
  return async (
    dispatch: ThunkDispatch<any, any, ParsingJobActions>,
    getState: () => RootState
  ): Promise<number | undefined> => {
    const {
      app: { sdk },
    } = getState();

    const { jobStarted, dataKitId: oldJobDataSetId } =
      getState().pnidParsing.parsingJobs[file.id] || {};

    if (jobStarted && assetsDataKit === oldJobDataSetId) {
      return getState().pnidParsing.parsingJobs[file.id].jobId;
    }

    dispatch({
      type: PARSING_JOB_CREATE_STARTED,
      fileId: file.id,
      dataKitId: assetsDataKit,
    });

    const timer = trackTimedUsage('Contextualization.PnidParsing.ParsingJob', {
      fileId: file.id,
    });

    const response = await sdk.post(createParsingJobPath(sdk.project), {
      data: {
        fileId: file.id,
        entities,
        partialMatch,
        grayscale,
      } as PnidParsingJobSchema,
    });
    try {
      const {
        status: httpStatus,
        data: { jobId, status: queueStatus },
      } = response;

      dispatch({ type: PARSING_JOB_CREATED, jobId, fileId: file.id });
      dispatch({
        type: PARSING_JOB_STATUS_UPDATED,
        jobId,
        status: queueStatus,
        fileId: file.id,
      });

      if (httpStatus === 200) {
        return await new Promise((resolve, reject) => {
          callUntilCompleted(
            () => sdk.get(getParsingStatusPath(sdk.project, jobId)),
            data => data.status === 'Completed' || data.status === 'Failed',
            async data => {
              if (data.status === 'Failed') {
                dispatch({
                  type: PARSING_JOB_ERROR,
                  fileId: file.id,
                });
                reject();
              } else {
                // completed
                await dispatch({
                  type: PARSING_JOB_DONE,
                  jobId,
                  fileId: file.id,
                  entities: data.items,
                });
                dispatch(
                  startCreatingAnnotationsFromJobs(
                    file,
                    assetsDataKit,
                    fileDataKit
                  )
                );
                resolve(jobId);

                timer.stop({ success: true, jobId });
              }
            },
            data => {
              dispatch({
                type: PARSING_JOB_STATUS_UPDATED,
                jobId,
                status: data.status,
                fileId: file.id,
              });
            },
            undefined,
            3000
          );
        });
      }
      dispatch({ type: PARSING_JOB_ERROR, fileId: file.id });
      timer.stop({ success: false, jobId });
      return undefined;
    } catch {
      dispatch({ type: PARSING_JOB_ERROR, fileId: file.id });
      timer.stop({ success: false });
      return undefined;
    }
  };
};

type PnidConvertJobSchema = {
  fileId: number;
  items: PnidResponseEntity[];
};

const convertPnidJob = (file: FilesMetadata) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState: () => RootState
  ) => {
    const {
      app: { sdk },
    } = getState();

    const pnIDApi = new PnIDApi(sdk);

    if (file.metadata && file.metadata[PNID_PARSING_JOB_ID_METADATA_FIELD]) {
      await dispatch({
        type: CONVERT_JOB_DONE,
        jobId: file.metadata[PNID_PARSING_JOB_ID_METADATA_FIELD],
        fileId: file.id,
        pngUrl: '',
      });
      return Promise.resolve(file.metadata[PNID_PARSING_JOB_ID_METADATA_FIELD]);
    }

    const { jobStarted } = getState().pnidParsing.conversionJobs[file.id] || {};

    if (jobStarted) {
      return Promise.resolve(
        getState().pnidParsing.conversionJobs[file.id].jobId
      );
    }

    const timer = trackTimedUsage('Contextualization.PnidParsing.ConvertJob', {
      fileId: file.id,
    });

    dispatch({ type: CONVERT_JOB_CREATE_STARTED, fileId: file.id });
    return new Promise((resolve, reject) => {
      sdk
        .post(createConvertJobPath(sdk.project), {
          data: {
            fileId: file.id,
            items: [],
          } as PnidConvertJobSchema,
        })
        .then(response => {
          const {
            status: httpStatus,
            data: { jobId, status: queueStatus },
          } = response;

          dispatch({ type: CONVERT_JOB_CREATED, jobId, fileId: file.id });
          dispatch({
            type: CONVERT_JOB_STATUS_UPDATED,
            jobId,
            status: queueStatus,
            fileId: file.id,
          });

          if (httpStatus === 200) {
            callUntilCompleted(
              () => sdk.get(createConvertStatusPath(sdk.project, jobId)),
              data => data.status === 'Completed' || data.status === 'Failed',
              async data => {
                if (data.status === 'Failed') {
                  dispatch({
                    type: CONVERT_JOB_ERROR,
                    fileId: file.id,
                  });
                  reject();
                } else {
                  // completed
                  await dispatch({
                    type: CONVERT_JOB_DONE,
                    jobId,
                    fileId: file.id,
                    pngUrl: data.pngUrl,
                  });

                  const updatedFile = await pnIDApi.updateFileWithJobId(
                    file,
                    jobId
                  );

                  await dispatch({
                    type: 'files/UPDATE_ITEMS',
                    result: [updatedFile],
                  });

                  resolve(jobId);

                  timer.stop({ success: true, jobId });
                }
              },
              data => {
                dispatch({
                  type: CONVERT_JOB_STATUS_UPDATED,
                  jobId,
                  status: data.status,
                  fileId: file.id,
                });
              },
              undefined,
              3000
            );
          } else {
            dispatch({ type: CONVERT_JOB_ERROR, fileId: file.id });
            reject();
            timer.stop({ success: false, jobId });
          }
        })
        .catch(() => {
          dispatch({ type: CONVERT_JOB_ERROR, fileId: file.id });
          reject();
          timer.stop({ success: false });
        });
    });
  };
};

const startCreatingAnnotationsFromJobs = (
  file: FilesMetadata,
  assetsDataKitId: string,
  filesDataKitId: string
) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState: () => RootState
  ): Promise<number | undefined> => {
    if (getState().pnidParsing.annotationJobs[file.id]) {
      return;
    }
    const { sdk } = getState().app;
    const {
      jobDone: parsingJobDone,
      annotations,
      jobId: parsingJobId,
    } = getState().pnidParsing.parsingJobs[file.id];

    const timer = trackTimedUsage(
      'Contextualization.PnidParsing.CreateAnnotations',
      {
        fileId: file.id,
      }
    );

    if (parsingJobId && parsingJobDone && annotations) {
      dispatch({ type: ANNOTATION_JOB_STARTED, fileId: file.id });
      try {
        const pnIDApi = new PnIDApi(sdk);

        const state = getState();
        const assetsData = dataKitItemsSelector(state)(
          assetsDataKitId,
          true
        ) as Result<Asset>;
        const filesData = dataKitItemsSelector(state)(
          filesDataKitId,
          true
        ) as Result<FilesMetadata>;

        const existingAnnotations = selectAnnotations(state)(file.id, true);

        const pendingAnnotations = await pnIDApi.createPendingAnnotationsFromJob(
          file,
          annotations,
          assetsData.items,
          filesData.items,
          `${parsingJobId!}`,
          existingAnnotations
        );

        await dispatch(createAnnotations(file, pendingAnnotations));
        dispatch({
          type: ANNOTATION_JOB_DONE,
          fileId: file.id,
        });

        timer.stop({ success: true });
      } catch {
        dispatch({ type: ANNOTATION_JOB_ERROR, file: file.id });
        timer.stop({ success: false });
      }
    }
  };
};

export const runPnidParser = (
  fileDataKitId: string,
  assetDataKitId: string,
  partialMatch: boolean,
  grayscale: boolean
) => {
  return async (
    dispatch: ThunkDispatch<any, any, PipelineAction>,
    getState: () => RootState
  ) => {
    if (
      !canEditEvents(true) ||
      !canEditFiles(true) ||
      getState().pnidParsing.pipelines[`${fileDataKitId}-${assetDataKitId}`]
    ) {
      return;
    }

    const timer = trackTimedUsage(
      'Contextualization.PnidParsing.StartAllJobs',
      {
        fileDataKitId,
        assetDataKitId,
      }
    );

    dispatch({ type: PIPELINE_STARTED, fileDataKitId, assetDataKitId });

    const state = getState();
    const assetsData = dataKitItemsSelector(state)(
      assetDataKitId,
      true
    ) as Result<Asset>;
    const filesData = dataKitItemsSelector(state)(
      fileDataKitId,
      true
    ) as Result<FilesMetadata>;

    if (!assetsData.fetching && !assetsData.done) {
      await dispatch(loadResourceSelection(assetDataKitId));
    }
    if (!filesData.fetching && !filesData.done) {
      await dispatch(loadResourceSelection(fileDataKitId));
    }

    const parentIds = new Set<number | string>();
    assetsData.items.forEach(el => {
      if (el.parentId) {
        parentIds.add(el.parentId);
      }
      if (el.parentExternalId) {
        parentIds.add(el.parentExternalId);
      }
    });

    const assetNames: string[] = assetsData.items
      .filter(asset => {
        // filter out ones without children!
        return (
          !parentIds.has(asset.id) &&
          (asset.externalId ? !parentIds.has(asset.id) : true)
        );
      })
      .map(i => i.name);
    const files: FilesMetadata[] = filesData.items;

    chunk(files, 30).reduce(async (previousPromise: Promise<any>, nextSet) => {
      await previousPromise;
      return Promise.all(
        nextSet.map(async file => {
          // fetch deleted too
          await dispatch(listAnnotations(file, true, true));
          await dispatch(
            pnidParsingJob(
              file,
              assetNames.concat(
                files
                  .filter(el => el.id !== file.id)
                  .map(el => el.name)
                  .map(removeExtension)
              ),
              partialMatch,
              grayscale,
              assetDataKitId,
              fileDataKitId
            )
          );
          await dispatch(convertPnidJob(file));
        })
      );
    }, Promise.resolve());

    dispatch({ type: PIPELINE_DONE, fileDataKitId, assetDataKitId });

    timer.stop();
  };
};

export const convertToSvg = (fileId: number, annotations: PnIDAnnotation[]) => {
  return async (
    dispatch: ThunkDispatch<any, any, UploadJobActions>,
    getState: () => RootState
  ) => {
    const {
      app: { sdk },
    } = getState();

    const file = itemSelector(getState())(fileId);

    if (!file) {
      return Promise.resolve(undefined);
    }

    const { jobStarted } = getState().pnidParsing.uploadJobs[fileId] || {};

    const pnidApi = new PnIDApi(sdk);

    if (jobStarted) {
      return Promise.resolve(getState().pnidParsing.uploadJobs[fileId].jobId);
    }

    const timer = trackTimedUsage('Contextualization.PnidParsing.UploadJob', {
      fileId,
    });

    dispatch({ type: UPLOAD_JOB_CREATE_STARTED, fileId });
    return new Promise((resolve, reject) => {
      sdk
        .post(createConvertJobPath(sdk.project), {
          data: {
            fileId,
            items: annotations.map(el => ({
              text: el.label,
              boundingBox: {
                xMin: el.boundingBox.x,
                xMax: el.boundingBox.x + el.boundingBox.width,
                yMin: el.boundingBox.y,
                yMax: el.boundingBox.y + el.boundingBox.height,
              },
            })),
          },
        })
        .then(response => {
          const {
            status: httpStatus,
            data: { jobId, status: queueStatus },
          } = response;

          dispatch({ type: UPLOAD_JOB_CREATED, jobId, fileId });
          dispatch({
            type: UPLOAD_JOB_STATUS_UPDATED,
            jobId,
            status: queueStatus,
            fileId,
          });

          if (httpStatus === 200) {
            callUntilCompleted(
              () => sdk.get(createConvertStatusPath(sdk.project, jobId)),
              data => data.status === 'Completed' || data.status === 'Failed',
              async data => {
                if (data.status === 'Failed') {
                  dispatch({
                    type: UPLOAD_JOB_ERROR,
                    fileId,
                  });
                  reject();
                } else {
                  try {
                    const svg = await PnidParsing.downloadFile(data.svgUrl);
                    const [item] = pnidApi.extractAssetIdsFromAnnotations(
                      annotations
                    );
                    const assetIds = item ? item.assetIds : [];
                    const newName =
                      file.name.lastIndexOf('.') !== 0
                        ? file.name.substr(0, file.name.lastIndexOf('.'))
                        : file.name;
                    const newFile = await sdk.files.upload(
                      {
                        externalId: `processed-${fileId}`,
                        name: `Processed-${newName}.svg`,
                        mimeType: 'image/svg+xml',
                        assetIds: [
                          ...new Set(
                            (file.assetIds || []).concat([...assetIds])
                          ),
                        ],
                        metadata: {
                          original_file_id: `${file.id}`,
                        },
                      },
                      undefined,
                      true
                    );
                    const uploader = await GCSUploader(
                      svg,
                      (newFile as UploadFileMetadataResponse).uploadUrl!
                    );
                    await uploader.start();
                    await dispatch({
                      type: UPLOAD_JOB_DONE,
                      jobId,
                      fileId,
                      svgUrl: data.svgUrl,
                    });
                    resolve(jobId);

                    timer.stop({ success: true, jobId });
                  } catch {
                    dispatch({ type: UPLOAD_JOB_ERROR, fileId });
                    reject();
                    timer.stop({ success: false, jobId });
                  }
                }
              },
              data => {
                dispatch({
                  type: UPLOAD_JOB_STATUS_UPDATED,
                  jobId,
                  status: data.status,
                  fileId,
                });
              },
              undefined,
              3000
            );
          } else {
            dispatch({ type: UPLOAD_JOB_ERROR, fileId });
            reject();
            timer.stop({ success: false, jobId });
          }
        })
        .catch(() => {
          dispatch({ type: UPLOAD_JOB_ERROR, fileId });
          reject();
          timer.stop({ success: false });
        });
    });
  };
};

export const setOptions = (partialMatch: boolean, grayscale: boolean) => ({
  type: PNID_OPTIONS,
  partialMatch,
  grayscale,
});

export interface PnidResponseEntity {
  text: string;
  boundingBox: { xMin: number; xMax: number; yMin: number; yMax: number };
}

interface PipelineStatus {
  completed: boolean;
  assetsDataKitId: string;
  filesDataKitId: string;
}

export interface ConversionJobState {
  jobStarted: boolean;
  jobId?: number;
  jobStatus: ModelStatus;
  jobDone: boolean;
  jobError: boolean;
  pngUrl?: string;
}

export interface UploadJobState {
  jobStarted: boolean;
  jobId?: number;
  jobStatus: ModelStatus;
  jobDone: boolean;
  jobError: boolean;
  svgUrl?: string;
}

export interface ParsingJobState {
  jobStarted: boolean;
  jobId?: number;
  jobStatus: ModelStatus;
  jobDone: boolean;
  jobError: boolean;
  dataKitId: string;
  annotations?: PnidResponseEntity[];
}
export interface AnnotationJobState {
  jobDone: boolean;
  jobError: boolean;
}

type Actions =
  | ParsingJobActions
  | ConvertJobActions
  | PipelineAction
  | AnnotationJobAction
  | UploadJobActions
  | PnIDOptionsActions;

export interface PnidParsingStore {
  conversionJobs: {
    [fileId: number]: ConversionJobState;
  };
  uploadJobs: {
    [fileId: number]: UploadJobState;
  };
  parsingJobs: {
    [fileId: number]: ParsingJobState;
  };
  pipelines: { [key: string]: PipelineStatus };
  annotationJobs: {
    [key: number]: AnnotationJobState;
  };
  options: {
    partialMatch: boolean;
    grayscale: boolean;
  };
}

const initialPnIDState: PnidParsingStore = {
  conversionJobs: {},
  uploadJobs: {},
  parsingJobs: {},
  pipelines: {},
  annotationJobs: {},
  options: {
    partialMatch: false,
    grayscale: false,
  },
};

export default function reducer(
  state: PnidParsingStore = initialPnIDState,
  action: Actions
): PnidParsingStore {
  switch (action.type) {
    case PIPELINE_STARTED: {
      const key = `${action.fileDataKitId}-${action.assetDataKitId}`;
      return {
        ...state,
        pipelines: {
          ...state.pipelines,
          [key]: {
            completed: false,
            filesDataKitId: action.fileDataKitId,
            assetsDataKitId: action.assetDataKitId,
          },
        },
      };
    }
    case PIPELINE_DONE: {
      const key = `${action.fileDataKitId}-${action.assetDataKitId}`;
      return {
        ...state,
        pipelines: {
          ...state.pipelines,
          [key]: {
            ...state.pipelines[key],
            completed: true,
            filesDataKitId: action.fileDataKitId,
            assetsDataKitId: action.assetDataKitId,
          },
        },
      };
    }

    case PARSING_JOB_CREATED: {
      return {
        ...state,
        parsingJobs: {
          ...state.parsingJobs,
          [action.fileId]: {
            ...state.parsingJobs[action.fileId],
            jobId: action.jobId,
          },
        },
      };
    }
    case PARSING_JOB_CREATE_STARTED: {
      return {
        ...state,
        parsingJobs: {
          ...state.parsingJobs,
          [action.fileId]: {
            jobStarted: true,
            jobStatus: 'Queued',
            jobDone: false,
            jobError: false,
            dataKitId: action.dataKitId,
          },
        },
      };
    }
    case PARSING_JOB_STATUS_UPDATED: {
      return {
        ...state,
        parsingJobs: {
          ...state.parsingJobs,
          [action.fileId]: {
            ...state.parsingJobs[action.fileId],
            jobStatus: action.status,
          },
        },
      };
    }
    case PARSING_JOB_DONE: {
      return {
        ...state,
        parsingJobs: {
          ...state.parsingJobs,
          [action.fileId]: {
            ...state.parsingJobs[action.fileId],
            jobDone: true,
            annotations: action.entities,
          },
        },
      };
    }
    case PARSING_JOB_ERROR: {
      return {
        ...state,
        parsingJobs: {
          ...state.parsingJobs,
          [action.fileId]: {
            ...state.parsingJobs[action.fileId],
            jobDone: true,
            jobError: true,
          },
        },
      };
    }

    case CONVERT_JOB_CREATED: {
      return {
        ...state,
        conversionJobs: {
          ...state.conversionJobs,
          [action.fileId]: {
            ...state.conversionJobs[action.fileId],
            jobId: action.jobId,
          },
        },
      };
    }
    case CONVERT_JOB_CREATE_STARTED: {
      return {
        ...state,
        conversionJobs: {
          ...state.conversionJobs,
          [action.fileId]: {
            jobStarted: true,
            jobStatus: 'Queued',
            jobDone: false,
            jobError: false,
          },
        },
      };
    }
    case CONVERT_JOB_STATUS_UPDATED: {
      return {
        ...state,
        conversionJobs: {
          ...state.conversionJobs,
          [action.fileId]: {
            ...state.conversionJobs[action.fileId],
            jobStatus: action.status,
          },
        },
      };
    }
    case CONVERT_JOB_DONE: {
      return {
        ...state,
        conversionJobs: {
          ...state.conversionJobs,
          [action.fileId]: {
            ...state.conversionJobs[action.fileId],
            jobDone: true,
            pngUrl: action.pngUrl,
          },
        },
      };
    }
    case CONVERT_JOB_ERROR: {
      return {
        ...state,
        conversionJobs: {
          ...state.conversionJobs,
          [action.fileId]: {
            ...state.conversionJobs[action.fileId],
            jobDone: true,
            jobError: true,
          },
        },
      };
    }

    case UPLOAD_JOB_CREATED: {
      return {
        ...state,
        uploadJobs: {
          ...state.uploadJobs,
          [action.fileId]: {
            ...state.uploadJobs[action.fileId],
            jobId: action.jobId,
          },
        },
      };
    }
    case UPLOAD_JOB_CREATE_STARTED: {
      return {
        ...state,
        uploadJobs: {
          ...state.uploadJobs,
          [action.fileId]: {
            jobStarted: true,
            jobStatus: 'Queued',
            jobDone: false,
            jobError: false,
          },
        },
      };
    }
    case UPLOAD_JOB_STATUS_UPDATED: {
      return {
        ...state,
        uploadJobs: {
          ...state.uploadJobs,
          [action.fileId]: {
            ...state.uploadJobs[action.fileId],
            jobStatus: action.status,
          },
        },
      };
    }
    case UPLOAD_JOB_DONE: {
      return {
        ...state,
        uploadJobs: {
          ...state.uploadJobs,
          [action.fileId]: {
            ...state.uploadJobs[action.fileId],
            jobDone: true,
            svgUrl: action.svgUrl,
          },
        },
      };
    }
    case UPLOAD_JOB_ERROR: {
      return {
        ...state,
        uploadJobs: {
          ...state.uploadJobs,
          [action.fileId]: {
            ...state.uploadJobs[action.fileId],
            jobDone: true,
            jobError: true,
          },
        },
      };
    }

    case ANNOTATION_JOB_STARTED: {
      return {
        ...state,
        annotationJobs: {
          ...state.annotationJobs,
          [action.fileId]: {
            jobDone: false,
            jobError: false,
          },
        },
      };
    }
    case ANNOTATION_JOB_DONE: {
      return {
        ...state,
        annotationJobs: {
          ...state.annotationJobs,
          [action.fileId]: {
            ...state.annotationJobs[action.fileId],
            jobDone: true,
          },
        },
      };
    }
    case ANNOTATION_JOB_ERROR: {
      return {
        ...state,
        annotationJobs: {
          ...state.annotationJobs,
          [action.fileId]: {
            ...state.annotationJobs[action.fileId],
            jobDone: true,
            jobError: true,
          },
        },
      };
    }
    case PNID_OPTIONS: {
      return {
        ...state,
        options: {
          ...state.options,
          partialMatch: action.partialMatch,
          grayscale: action.grayscale,
        },
      };
    }
    default: {
      return state;
    }
  }
}

export const makeNumPnidParsingJobSelector = () =>
  createSelector(
    (state: RootState) => state.pnidParsing.parsingJobs,
    (_: any, fileIds: number[]) => fileIds,
    (parsingJobs, fileIds) => {
      const jobIds = new Set(Object.keys(parsingJobs));
      return fileIds.filter(fileId => jobIds.has(`${fileId}`)).length;
    }
  );

export const makeNumPnidConvertJobSelector = () =>
  createSelector(
    (state: RootState) => state.pnidParsing.conversionJobs,
    (_: any, fileIds: number[]) => fileIds,
    (conversionJobs, fileIds) => {
      const jobIds = new Set(Object.keys(conversionJobs));
      return fileIds.filter(fileId => jobIds.has(`${fileId}`)).length;
    }
  );

export const selectParsingJobForFileId = createSelector(
  (state: RootState) => state.pnidParsing.parsingJobs,
  jobMap => (fileId: number) => {
    return (jobMap[fileId] || {}).jobId;
  }
);

export const stuffForUnitTests = {
  initialPnIDState,
  downloadFile,
  UPLOAD_JOB_CREATE_STARTED,
  UPLOAD_JOB_CREATED,
  UPLOAD_JOB_STATUS_UPDATED,
  UPLOAD_JOB_DONE,
  UPLOAD_JOB_ERROR,
};
