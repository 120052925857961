import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';
import { Colors, Icon, Button } from '@cognite/cogs.js';
import styled from 'styled-components';
import queryString from 'query-string';
import { trackUsage } from 'utils/Metrics';
import {
  getStagedChanges,
  Resource,
} from 'modules/contextualization/predictions';
import { dataKitItemsSelector } from 'modules/selection';
import EntityMatchingPieChart from 'components/EntityMatchingPieChart';
import MatchingFieldsModal from './MatchingFieldsModal';

const HeaderRow = styled(Row)`
  background: #fff;
  .summary {
    padding: 12px;
    font-weight: 800;
    .header {
      margin-bottom: 8px;
    }
    div {
      margin-bottom: 2px;
      display: flex;
      align-items: center;
      span {
        margin-left: 2px;
      }
    }
  }
`;

const CustomTabHeader = styled.div<{ selected: boolean }>`
  cursor: pointer;
  height: 100%;
  border-right: 1px solid
    ${props => (props.selected ? Colors['greyscale-grey3'].hex() : '#fff')};
  border-left: 1px solid
    ${props => (props.selected ? Colors['greyscale-grey3'].hex() : '#fff')};
  transition: 0.3s all;
  p {
    font-weight: 800;
    color: ${props => (props.selected ? Colors.primary.hex() : '#000')};
  }
  .details {
    padding: 12px;
    display: flex;
    flex-direction: column;
  }
  .indicator {
    border-top: 8px solid
      ${props => (props.selected ? Colors.primary.hex() : '#fff')};
    transition: 0.3s all;
  }

  &&:hover {
    border-right: 2px solid ${Colors['greyscale-grey3'].hex()};
    border-left: 2px solid ${Colors['greyscale-grey3'].hex()};
    .indicator {
      border-top: 8px solid ${Colors['midblue-4'].hex()};
    }
  }
`;

export default function EntityMatcherHeader() {
  const { resourceDataKitId, assetsDataKitId } = useParams<{
    assetsDataKitId: string;
    resourceDataKitId: string;
  }>();
  const history = useHistory();
  const { location } = history;
  const { search } = location;

  const { tab = 'manual' } = queryString.parse(search);

  useEffect(() => {
    trackUsage('Contextualization.ResourceMatching');
  }, []);

  const [showModal, setShowModal] = useState(false);
  const stagedChanges = useSelector(getStagedChanges)(
    resourceDataKitId,
    assetsDataKitId
  );
  const { items: resources } = useSelector(dataKitItemsSelector)(
    resourceDataKitId,
    true,
    true
  ) as {
    done: boolean;
    items: Resource[];
  };
  const { items: assets } = useSelector(dataKitItemsSelector)(
    assetsDataKitId,
    true,
    true
  );

  const numResourcesWithAssetId = resources.filter(ts => ts.assetId != null)
    .length;

  const numResourcesWithoutAssetId =
    resources.length - stagedChanges.length - numResourcesWithAssetId;

  useEffect(() => {
    trackUsage('Contextualization.ResourceMatching.TabChange', {
      tab,
    });
  }, [tab]);

  const changeTab = (newTab: string) => {
    const existingParams = queryString.parse(location.search);
    history.push({
      search: queryString.stringify({ ...existingParams, tab: newTab }),
    });
  };
  let mainTabKey = tab as string;
  if (mainTabKey === 'manual' || mainTabKey === 'rules') {
    mainTabKey = 'editing';
  }

  return (
    <>
      <h1>Entity Matching</h1>
      <Row gutter={24} style={{ marginTop: '12px', marginBottom: '12px' }}>
        <Col span={18}>
          <HeaderRow type="flex" align="stretch">
            <Col span={6}>
              <div className="summary">
                <p className="header">SELECTED DATA</p>
                <div>
                  <Icon type="Timeseries" />
                  <span>Resources: {resources.length}</span>
                </div>
                <div>
                  <Icon type="ThreeD" />
                  <span>Assets: {assets.length}</span>
                </div>
              </div>
            </Col>
            <Col span={9}>
              <CustomTabHeader
                onClick={() => changeTab('manual')}
                selected={mainTabKey === 'editing'}
              >
                <div className="indicator" />
                <div className="details">
                  <p>UNMATCHED RESOURCES</p>
                  <h1>{numResourcesWithoutAssetId}</h1>
                  <Button
                    icon="Filter"
                    style={{ alignSelf: 'flex-end' }}
                    onClick={() => setShowModal(!showModal)}
                  >
                    Config
                  </Button>
                  <MatchingFieldsModal
                    visible={showModal}
                    changeVisibility={setShowModal}
                  />
                </div>
              </CustomTabHeader>
            </Col>
            <Col span={9}>
              <CustomTabHeader
                onClick={() => changeTab('staged')}
                selected={mainTabKey === 'staged'}
              >
                <div className="indicator" />
                <div className="details">
                  <p>STAGED MATCHES</p>
                  <h1>{stagedChanges.length}</h1>
                </div>
              </CustomTabHeader>
            </Col>
          </HeaderRow>
        </Col>
        <Col span={6}>
          <EntityMatchingPieChart
            resourceDataKitId={resourceDataKitId}
            assetDataKitId={assetsDataKitId}
          />
        </Col>
      </Row>
    </>
  );
}
