/* eslint react/jsx-props-no-spreading: "off" */
import React from 'react';
import QueueAnim from 'rc-queue-anim';

const TableContext = React.createContext(false);

export const animationTag = ($props: any) => {
  return (
    <TableContext.Consumer>
      {() => {
        return (
          <QueueAnim
            component="tbody"
            duration={250}
            appear={false}
            animConfig={{ translateX: [0, 200], translateY: [0, -30] }}
            {...$props}
          />
        );
      }}
    </TableContext.Consumer>
  );
};
