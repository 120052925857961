import React, { useState } from 'react';
import styled from 'styled-components';
import { Select, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useMetadataSelectedResources } from 'hooks/CustomHooks';

type TFilterDropdown = {
  title: string;
  type: string | undefined;
  dataKitId: string;
  defaultProperties: string[];
  onPropertiesChange: Function;
};

const StyledDropdown = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  p {
    font-size: 0.8em;
    text-transform: uppercase;
  }
  > * {
    &:not(:last-child) {
      margin: 0 0 10px 0;
    }
  }
`;

export function FilterDropdown({
  title,
  type,
  defaultProperties,
  dataKitId,
  onPropertiesChange,
}: TFilterDropdown) {
  const [options, setOptions] = useState(defaultProperties);
  const resourceMetadata = useMetadataSelectedResources(type, dataKitId);

  const defaultChecked = true;

  const onOptionCheck = (checked: boolean, option: string) => {
    if (checked && !options.includes(option)) {
      setOptions([...options, option]);
    }
    onPropertiesChange(option, checked);
  };

  const visibleOptions = () => {
    return options.map(option => (
      <Checkbox
        name={option}
        key={option}
        onChange={(event: CheckboxChangeEvent) =>
          onOptionCheck(event.target.checked, option)
        }
        defaultChecked={defaultChecked}
        disabled={option === 'name'}
      >
        {option}
      </Checkbox>
    ));
  };

  const addOptionsToSelect = () => {
    return resourceMetadata.map((md: string) => (
      <Select.Option key={md} value={md}>
        {md}
      </Select.Option>
    ));
  };

  const addOption = (option: string) => {
    if (options.includes(option)) return;
    setOptions([...options, option]);
    onPropertiesChange(option, 'true');
  };

  return (
    <StyledDropdown>
      {title && <p>{title}</p>}
      {visibleOptions()}
      <Select
        showSearch
        placeholder="Type metadata field name here..."
        allowClear
        onSelect={addOption}
      >
        {addOptionsToSelect()}
      </Select>
    </StyledDropdown>
  );
}
