import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import SearchPage from 'containers/SearchPage';
import { RootState } from 'reducers/index';
import { BreadcrumbRouteMap } from 'components/Breadcrumbs/Breadcrumbs';
import { itemSelector } from 'modules/files';
import PnIDParsing from './PnIDParsing';
import PnIDParsingFilePreview from './PnIDParsingFilePreview';
import PnIDOptions from './PnIDOptions';

const PDF_FILTER = {
  files: {
    filter: {
      mimeType: 'application/pdf',
    },
  },
};

function PnIDParsingRoutes() {
  const match = useRouteMatch<{ tenant: string; fileId?: string }>();

  return (
    <>
      <Switch>
        <Route
          exact
          path={`${match.path}/`}
          render={() => (
            <SearchPage
              type="files"
              defaultFilters={PDF_FILTER}
              noDataText="No P&IDs found"
            />
          )}
        />
        <Route
          exact
          path={`${match.path}/:filesDataKitId`}
          render={() => <SearchPage type="assets" />}
        />
        <Route
          exact
          path={`${match.path}/:filesDataKitId/:assetsDataKitId`}
          component={PnIDOptions}
        />
        <Route
          exact
          path={`${match.path}/:filesDataKitId/:assetsDataKitId/:optionsId`}
          component={PnIDParsing}
        />
        <Route
          exact
          path={`${match.path}/:filesDataKitId/:assetsDataKitId/:optionsId/pnid/:fileId`}
          component={PnIDParsingFilePreview}
        />
      </Switch>
    </>
  );
}

function breadcrumbs(mountedAt: string) {
  return (state: RootState): BreadcrumbRouteMap => {
    const fileMap = itemSelector(state);
    return {
      [`${mountedAt}`]: ['P&ID Contextualization', 'File Selection'],
      [`${mountedAt}/:filesDataKitId`]: ['Reference Asset Selection'],
      [`${mountedAt}/:filesDataKitId/:assetsDataKitId`]: ['P&ID Options'],
      [`${mountedAt}/:filesDataKitId/:assetsDataKitId/:optionsId`]: [
        'Model Results',
      ],
      [`${mountedAt}/:filesDataKitId/:assetsDataKitId/:optionsId/pnid/:fileId`]: ({
        fileId,
      }) => {
        const file = fileMap(Number(fileId));
        if (file) {
          return [file.name];
        }
        return ['Loading'];
      },
    };
  };
}

PnIDParsingRoutes.breadcrumbs = breadcrumbs;

export default PnIDParsingRoutes;
