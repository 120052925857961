import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { FullScreenPnIDViewer } from './FullScreenPnIDViewer';

export default function PnIDViewerRoutes() {
  const match = useRouteMatch();

  return (
    <>
      <Switch>
        <Route
          path={`${match.path}/:fileId?`}
          component={FullScreenPnIDViewer}
        />
      </Switch>
    </>
  );
}
