import styled from 'styled-components';

export const ColorDot = styled.div<{ color: string }>`
  height: 8px;
  width: 8px;
  background: ${props => props.color};
  border-radius: 20px;
  border: 4px solid ${props => props.color}55;
  box-sizing: content-box;
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  display: inline-block;
`;
