import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Select } from 'antd';
import {
  ErrorBoundary,
  Header,
  CoolH1,
  WithLabel,
  Field,
  SelectWrapper,
} from 'components/Common';
import { CogniteType } from 'modules/types';
import AssetSelect from 'components/AssetSelect';

import { AssetTree, AssetMeta } from '@cognite/gearbox';

const Hierarchy = styled.div`
  margin-left: -38px;

  .ant-tree-title,
  .ant-tree-switcher {
    cursor: pointer;
    margin: 2px;
  }
`;
export const NewInstanceForm = ({
  templates,
  template,
  instanceCallback,
}: {
  templates: CogniteType[];
  template?: CogniteType;
  instanceCallback?: (
    callback: () => { assetId: number; type: CogniteType } | undefined
  ) => void;
}) => {
  const [assetId, setAssetId] = useState<number | undefined>(undefined);
  const [templateId, setTemplateId] = useState<number | undefined>(
    template ? template.id : undefined
  );

  useEffect(() => {
    if (instanceCallback) {
      instanceCallback(() => {
        if (assetId && templateId) {
          const type = templates.find(el => el.id === templateId);
          if (type) {
            return { assetId, type };
          }
        }
        return undefined;
      });
    }
  }, [template, instanceCallback, assetId, templateId, templates]);

  useEffect(() => {
    if (template) {
      setTemplateId(template.id);
    }
  }, [template]);

  return (
    <Container>
      <Row>
        <Col span={24}>
          <Header title={<CoolH1>New instance</CoolH1>} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={10}>
          <WithLabel title="Template *">
            <SelectWrapper>
              <Select
                style={{ width: '100%' }}
                placeholder="Template"
                value={templateId}
                allowClear
                onChange={(val: any) => {
                  if (!val) {
                    setTemplateId(undefined);
                  }
                }}
                onSelect={(id: number) => setTemplateId(Number(id))}
              >
                {templates.map(el => (
                  <Select.Option key={el.id} value={el.id}>
                    {el.externalId} ({el.name})
                  </Select.Option>
                ))}
              </Select>
            </SelectWrapper>
          </WithLabel>
        </Col>
        <Col span={8}>
          <WithLabel title="Asset *">
            <SelectWrapper>
              <AssetSelect
                style={{ width: '100%' }}
                selectedAssetId={assetId}
                onAssetSelected={asset =>
                  setAssetId(asset ? asset.id : undefined)
                }
              />
            </SelectWrapper>
          </WithLabel>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={10}>
          <Field title="Asset hierarchy">
            <Hierarchy>
              <AssetTree
                showLoading
                defaultExpandedKeys={(assetId && [assetId]) || []}
                onSelect={({ node }) => setAssetId(node?.id)}
                styles={{
                  list: {
                    fontSize: 16,
                    fontWeight: 'bold',
                    lineHeight: 2,
                    listStyleType: 'none',
                  },
                }}
              />
            </Hierarchy>
          </Field>
        </Col>
        <Col span={14}>
          <Field title="Selected asset">
            {(assetId && (
              <ErrorBoundary>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                  }}
                >
                  <AssetMeta
                    assetId={assetId}
                    hidePanels={['timeseries', 'documents', 'events']}
                  />
                </div>
              </ErrorBoundary>
            )) || <em>No asset selected</em>}
          </Field>
        </Col>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  padding: 30px;
  background-color: white;
  width: 100%;
`;
