import React from 'react';
import { IAnnotation, IRectShapeData } from '@cognite/react-picture-annotation';
import styled from 'styled-components';
import { itemSelector as assetSelector } from 'modules/assets';
import { useSelector } from 'react-redux';
import { selectAnnotations } from 'modules/annotations';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { itemSelector as fileSelector } from 'modules/files';
import { PDFViewer } from 'components/Common';
import { selectAnnotationColor } from './AnnotatedPnIDOverview';

const Wrapper = styled.div`
  flex: 1;
  min-height: 200px;
  height: 100%;
  width: 100%;
  position: relative;
`;

type Props = {
  fileId?: number;
  filePreviewUrl?: string;
  children?: React.ReactNode;
};

export const AnnotatedPnIDPreviewImage = ({
  fileId,
  filePreviewUrl,
}: Props) => {
  const { search } = useLocation();

  const {
    assetId: selectedAssetId,
    fileId: selectedFileId,
  } = queryString.parse(search, {
    parseNumbers: true,
  });

  const assetsMap = useSelector(assetSelector);
  const filesMap = useSelector(fileSelector);
  const pnidAnnotations = useSelector(selectAnnotations)(fileId);

  const annotations = pnidAnnotations.map(el => {
    const currentAsset = assetsMap(
      el.linkedAssetId || el.linkedAssetExternalId
    );
    const currentFile = filesMap(el.linkedFileId || el.linkedFileExternalId);
    return {
      id: `${el.id}`,
      comment: el.label || 'No Label',
      mark: {
        type: 'RECT',
        x: el.boundingBox!.x,
        y: el.boundingBox!.y,
        width: el.boundingBox!.width,
        height: el.boundingBox!.height,
        strokeWidth: 2,
        strokeColor: selectAnnotationColor(
          el,
          (!!currentAsset && currentAsset.id === selectedAssetId) ||
            (!!currentFile && currentFile.id === selectedFileId)
        ),
      },
    } as IAnnotation<IRectShapeData>;
  });

  return (
    <Wrapper>
      <PDFViewer
        image={filePreviewUrl}
        annotations={annotations}
        drawLabel={false}
      />
    </Wrapper>
  );
};
