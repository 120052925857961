import React, { useEffect, useState } from 'react';
import { message, Modal, Spin } from 'antd';
import { IAnnotation, IRectShapeData } from '@cognite/react-picture-annotation';
import styled from 'styled-components';
import { PnIDAnnotation, PendingPnIDAnnotation } from 'utils/PnIDApi';
import {
  retrieve as retrieveAssets,
  retrieveExternal as retrieveExternalAssets,
  itemSelector as assetSelector,
} from 'modules/assets';
import { useSelector, useDispatch } from 'react-redux';
import {
  create as createAnnotations,
  remove as removeAnnotations,
  selectAnnotations,
} from 'modules/annotations';
import { canEditEvents } from 'utils/PermissionUtils';
import { trackUsage } from 'utils/Metrics';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { Button } from '@cognite/cogs.js';
import {
  itemSelector as fileSelector,
  retrieve as retrieveFiles,
} from 'modules/files';
import { onResourceSelected } from 'modules/app';
import { FilesMetadata, Asset } from '@cognite/sdk';
import { RenderResourceActionsFunction } from 'containers/HoverPreview';
import { PDFViewer } from 'components/Common';
import { AnnotatedPnIDItemEditor } from './AnnotatedPnIDItemEditor';

import {
  selectAnnotationColor,
  AnnotatedPnIDOverview,
} from './AnnotatedPnIDOverview';

const OverviewWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 24px;
  z-index: 1000;
  padding-top: 24px;
  padding-bottom: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: none;

  && > * {
    margin-bottom: 24px;
  }
`;

const CenteredPlaceholder = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  text-align: center;
`;

const Buttons = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 1000;

  && > * {
    margin-right: 6px;
  }
  && > *:nth-last-child(1) {
    margin-right: 0px;
  }
`;

const Wrapper = styled.div`
  flex: 1;
  min-height: 200px;
  height: 100%;
  width: 100%;
  position: relative;
`;

type Props = {
  fileId?: number;
  filePreviewUrl?: string;
  children?: React.ReactNode;
  onFileClicked?: (file: FilesMetadata) => void;
  onAssetClicked?: (asset: Asset) => void;
  renderResourceActions?: RenderResourceActionsFunction;
};

export interface ProposedPnIDAnnotation extends PendingPnIDAnnotation {
  id: string;
}

export const AnnotatedPnIDPreview = ({
  fileId,
  filePreviewUrl,
  children,
  onFileClicked,
  onAssetClicked,
  renderResourceActions,
}: Props) => {
  const { search } = useLocation();

  const {
    assetId: selectedAssetId,
    fileId: selectedFileId,
  } = queryString.parse(search, {
    parseNumbers: true,
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const assetsMap = useSelector(assetSelector);
  const filesMap = useSelector(fileSelector);
  const file = filesMap(fileId);
  const pnidAnnotations = useSelector(selectAnnotations)(fileId);
  const [pendingPnidAnnotations, setPendingPnidAnnotations] = useState(
    [] as ProposedPnIDAnnotation[]
  );

  const [editable, setEditable] = useState(false);
  const [selectedAnnotation, setSelectedAnnotation] = useState<
    ProposedPnIDAnnotation | PnIDAnnotation | undefined
  >(undefined);

  const annotations = pnidAnnotations
    .map(el => {
      const currentAsset = assetsMap(
        el.linkedAssetId || el.linkedAssetExternalId
      );
      const currentFile = filesMap(el.linkedFileId || el.linkedFileExternalId);
      return {
        id: `${el.id}`,
        comment: el.label || 'No Label',
        mark: {
          type: 'RECT',
          x: el.boundingBox!.x,
          y: el.boundingBox!.y,
          width: el.boundingBox!.width,
          height: el.boundingBox!.height,
          strokeWidth: 2,
          strokeColor: selectAnnotationColor(
            el,
            (!!currentAsset && currentAsset.id === selectedAssetId) ||
              (!!currentFile && currentFile.id === selectedFileId)
          ),
        },
      } as IAnnotation<IRectShapeData>;
    })
    .concat(
      pendingPnidAnnotations.map(
        el =>
          ({
            id: el.id,
            comment: el.label || 'Pending Annotation',
            mark: {
              type: 'RECT',
              x: el.boundingBox!.x,
              y: el.boundingBox!.y,
              width: el.boundingBox!.width,
              height: el.boundingBox!.height,
              strokeColor: 'yellow',
            },
          } as IAnnotation<IRectShapeData>)
      )
    );

  useEffect(() => {
    const assetIds = pnidAnnotations.reduce(
      (prev: Set<number>, el: PnIDAnnotation) => {
        if (el.linkedAssetId) {
          prev.add(el.linkedAssetId);
        }
        return prev;
      },
      new Set<number>()
    );
    const assetExternalIds = pnidAnnotations.reduce(
      (prev: Set<string>, el: PnIDAnnotation) => {
        if (el.linkedAssetExternalId) {
          prev.add(el.linkedAssetExternalId);
        }
        return prev;
      },
      new Set<string>()
    );
    dispatch(retrieveAssets([...[...assetIds].map(id => ({ id }))]));
    dispatch(
      retrieveExternalAssets([
        ...[...assetExternalIds].map(id => ({ externalId: id })),
      ])
    );
  }, [dispatch, pnidAnnotations]);

  const onSaveDetection = async (
    pendingAnnotation: ProposedPnIDAnnotation | PnIDAnnotation
  ) => {
    if (!canEditEvents(true)) {
      return;
    }

    if (pendingPnidAnnotations.find(el => el.id === pendingAnnotation.id)) {
      trackUsage('Contextualization.PnidViewer.CreateAnnotation', {
        annotation: pendingAnnotation,
      });
      const pendingObj = { ...pendingAnnotation };
      delete pendingObj.id;
      dispatch(createAnnotations(file!, [pendingObj]));
      setPendingPnidAnnotations(
        pendingPnidAnnotations.filter(el => el.id !== pendingAnnotation.id)
      );
    } else {
      message.info('Coming Soon');
    }

    // load missing asset information
    if (
      pendingAnnotation.linkedAssetExternalId ||
      pendingAnnotation.linkedAssetId
    ) {
      const action = pendingAnnotation.linkedAssetId
        ? retrieveAssets([{ id: pendingAnnotation.linkedAssetId! }])
        : retrieveExternalAssets([
            { externalId: pendingAnnotation.linkedAssetExternalId! },
          ]);
      dispatch(action);
    }
  };

  const onDeleteAnnotation = async (annotation: IAnnotation) => {
    if (!canEditEvents(true)) {
      return;
    }

    if (pendingPnidAnnotations.find(el => el.id === annotation.id)) {
      setPendingPnidAnnotations(
        pendingPnidAnnotations.filter(el => el.id !== annotation.id)
      );
    } else {
      trackUsage('Contextualization.PnidViewer.DeleteAnnotation', {
        annotation,
      });
      const pnidIndex = pnidAnnotations.findIndex(
        el => `${el.id}` === annotation.id
      );
      if (pnidIndex > -1) {
        dispatch(removeAnnotations(file!, [pnidAnnotations[pnidIndex]]));
      }
    }
  };

  const onUpdateAnnotation = async (
    annotation: IAnnotation<IRectShapeData>
  ) => {
    if (!canEditEvents(true)) {
      return;
    }

    if (pendingPnidAnnotations.find(el => el.id === annotation.id)) {
      setPendingPnidAnnotations(
        pendingPnidAnnotations.reduce((prev: ProposedPnIDAnnotation[], el) => {
          if (el.id !== annotation.id) {
            prev.push(el);
          } else {
            prev.push({
              ...el,
              boundingBox: {
                x: annotation.mark.x,
                y: annotation.mark.y,
                width: annotation.mark.width,
                height: annotation.mark.height,
              },
            });
          }
          return prev;
        }, [] as ProposedPnIDAnnotation[])
      );
    } else {
      // message.info('Coming Soon');
    }
  };

  const onCreateAnnotation = async (
    annotation: IAnnotation<IRectShapeData>
  ) => {
    if (!canEditEvents(true) || !fileId) {
      return;
    }
    trackUsage('Contextualization.PnidViewer.LocalCreateAnnotation', {
      annotation,
    });
    setPendingPnidAnnotations(
      pendingPnidAnnotations
        .filter(el => el.label.length > 0)
        .concat([
          {
            id: annotation.id,
            type: 'User Defined',
            ...(file!.externalId
              ? { fileExternalId: file!.externalId }
              : { fileId: file!.id }),
            version: 3,
            source: 'user',
            label: '',
            boundingBox: {
              x: annotation.mark.x,
              y: annotation.mark.y,
              width: annotation.mark.width,
              height: annotation.mark.height,
            },
          },
        ])
    );
  };

  useEffect(() => {
    (async () => {
      if (selectedAnnotation) {
        const {
          linkedAssetId,
          linkedAssetExternalId,
          linkedFileId,
          linkedFileExternalId,
        } = selectedAnnotation;
        if (linkedAssetId || linkedAssetExternalId) {
          await dispatch(
            retrieveAssets([
              linkedAssetExternalId
                ? { externalId: linkedAssetExternalId }
                : { id: linkedAssetId! },
            ])
          );
          const asset = assetsMap(linkedAssetId || linkedAssetExternalId);
          if (asset) {
            await dispatch(
              onResourceSelected(
                { assetId: asset!.id },
                history,
                undefined,
                true
              )
            );
          }
        }
        if (linkedFileId || linkedFileExternalId) {
          await dispatch(
            retrieveFiles([
              linkedFileExternalId
                ? { externalId: linkedFileExternalId }
                : { id: linkedFileId! },
            ])
          );
          const selectedFile = filesMap(linkedFileId || linkedFileExternalId);
          if (selectedFile) {
            await dispatch(
              onResourceSelected(
                { fileId: selectedFile!.id },
                history,
                undefined,
                true
              )
            );
          }
        }
      }
    })();
  }, [dispatch, selectedAnnotation, assetsMap, filesMap, history]);

  if (!filePreviewUrl) {
    return (
      <Wrapper>
        <OverviewWrapper className="overview">{children}</OverviewWrapper>
        <CenteredPlaceholder>
          <h1>No P&ID Selected</h1>
          <p>Please search for a P&ID to start viewing.</p>
        </CenteredPlaceholder>
      </Wrapper>
    );
  }
  if (!filePreviewUrl) {
    return (
      <Wrapper>
        <OverviewWrapper className="overview">
          {children}
          {fileId && (
            <AnnotatedPnIDOverview fileId={fileId} annotations={annotations} />
          )}
        </OverviewWrapper>
        <CenteredPlaceholder>
          <Spin size="large" />
        </CenteredPlaceholder>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Buttons>
        <Button
          type="primary"
          icon={editable ? 'Check' : 'Edit'}
          onClick={() => {
            if (!canEditEvents(true)) {
              return;
            }
            if (editable) {
              if (pendingPnidAnnotations.length > 0) {
                Modal.confirm({
                  title: 'Are you sure?',
                  content: (
                    <span>
                      Do you want to stop editing? You have pending changes,
                      which will be <strong>deleted</strong> if you leave the
                      editing mode now. Of course, any changes you have already
                      written to CDF have been saved.
                    </span>
                  ),
                  onOk: () => {
                    setEditable(!editable);
                    setPendingPnidAnnotations([]);
                  },
                  onCancel: () => {},
                });
                return;
              }
            }
            setEditable(!editable);
          }}
        >
          {editable ? 'Finish Editing' : 'Edit Linkages'}
        </Button>
      </Buttons>
      <OverviewWrapper className="overview">
        {children}
        {fileId && (
          <AnnotatedPnIDOverview fileId={fileId} annotations={annotations} />
        )}
      </OverviewWrapper>
      <PDFViewer
        image={filePreviewUrl}
        annotations={annotations}
        drawLabel={false}
        editCallbacks={{
          onDelete: () => {},
          onCreate: onCreateAnnotation,
          onUpdate: onUpdateAnnotation,
        }}
        editable={editable}
        onSelect={annotation => {
          if (annotation) {
            const pnidAnnotation =
              pnidAnnotations.find(el => `${el.id}` === annotation.id) ||
              pendingPnidAnnotations.find(el => el.id === annotation.id);
            if (pnidAnnotation) {
              setSelectedAnnotation(pnidAnnotation);
            }
          } else {
            setSelectedAnnotation(undefined);
            dispatch(onResourceSelected({}, history, undefined, true));
          }
        }}
        renderItemPreview={(
          _: boolean,
          annotation: IAnnotation,
          onLabelChange: (value: string) => void,
          onDelete: () => void
        ) => {
          const pnidAnnotation =
            pnidAnnotations.find(el => `${el.id}` === annotation.id) ||
            pendingPnidAnnotations.find(el => el.id === annotation.id);
          if (pnidAnnotation) {
            return (
              <AnnotatedPnIDItemEditor
                onFileClicked={onFileClicked}
                onAssetClicked={onAssetClicked}
                editable={editable}
                annotation={pnidAnnotation}
                onUpdateDetection={async newAnnotation => {
                  onLabelChange(newAnnotation.label || 'No Label');
                  await onSaveDetection(newAnnotation);
                }}
                renderResourceActions={renderResourceActions}
                onDeleteDetection={() => {
                  Modal.confirm({
                    title: 'Are you sure?',
                    content: 'Are you sure you want to delete this linkage?',
                    onOk: () => {
                      onDelete();
                      onDeleteAnnotation(annotation);
                    },
                  });
                }}
              />
            );
          }
          return <></>;
        }}
      />
    </Wrapper>
  );
};
