import { CogniteInternalId, IdEither } from '@cognite/sdk';
import { RootState } from 'reducers';
import buildItems from 'modules/sdk-builder/items';
import { runUntilCompleted } from 'helpers/Helpers';
import { ApiResult } from 'modules/sdk-builder/types';
import resourceBuilder from './sdk-builder';
import buildList from './sdk-builder/list';

export const CogniteTypePropertyTypes = [
  'string',
  'boolean',
  'long',
  'double',
  'assetRef',
  'eventRef',
  'fileRef',
  'timeseriesRef',
  'sequenceRef',
] as const;

export const CogniteTypePropertyTypesMap: {
  [key in CogniteTypePropertyType]: string;
} = {
  string: 'Text',
  boolean: 'Boolean',
  long: 'Long',
  double: 'Double',
  assetRef: 'Asset',
  eventRef: 'Event',
  fileRef: 'File',
  timeseriesRef: 'Time series',
  sequenceRef: 'Sequence',
};

export type CogniteTypePropertyType = typeof CogniteTypePropertyTypes[number];

export type CogniteTypeProperty = {
  propertyId: string;
  name?: string;
  description?: string;
  type: CogniteTypePropertyType;
};

export type CogniteTypePopulatedProperty = CogniteTypeProperty & {
  value?: any;
};

export type CogniteTypeParent = {
  version: number;
  id: number;
  externalId?: string;
};

export type CogniteType = {
  externalId: string;
  name?: string;
  description?: string;
  properties?: CogniteTypeProperty[];
  parentType?: CogniteTypeParent;
  id: number;
  version: number;
  createdTime: Date;
  lastUpdatedTime: Date;
};

export type CreateCogniteTypeDTO = {
  externalId: string;
  name?: string;
  description?: string;
  properties?: CogniteTypeProperty[];
  parentType?: { version: number } & IdEither;
};

export type UpdateCogniteTypeDTO = {
  id: CogniteInternalId;
  version: number;
  set: {
    externalId: string;
    name?: string;
    description?: string;
    properties?: CogniteTypeProperty[];
    parentType?: { version: number } & IdEither;
  };
  createNewVersion?: boolean;
};

export type TypeFilterRequest = {
  filter?: {
    name?: string;
    externalIdPrefix?: string;
    typeSubtree?: {
      id: number;
      version: number;
    };
  };
  cursor?: string;
  limit?: number;
};

const NAME = 'types';

const {
  itemReducer,
  retrieve,
  update,
  create,
  remove,
  retrieveExternal,
  createItemSelector,
  createRetrieveSelector,
} = buildItems<CogniteType, UpdateCogniteTypeDTO, CreateCogniteTypeDTO>(
  NAME,
  sdk => async (q: IdEither[]) => {
    const result = await sdk.post<{ items: CogniteType[] }>(
      `/api/playground/projects/${sdk.project}/types/byids`,
      {
        data: {
          items: q,
        },
      }
    );
    return result.data.items;
  },
  (sdk, dispatch, getState) => async (q: CreateCogniteTypeDTO[]) => {
    const {
      data: { items },
    } = await sdk.post<{ items: CogniteType[] }>(
      `/api/playground/projects/${sdk.project}/types/`,
      {
        data: {
          items: q,
        },
      }
    );
    let refreshed = true;
    await new Promise((resolve, reject) =>
      runUntilCompleted(
        async () =>
          dispatch(
            list({}, true, undefined, undefined, (i: ApiResult) => {
              const shouldRefresh = refreshed;
              refreshed = false;
              return (!i.fetching && !i.done) || shouldRefresh;
            })
          ),
        () => {
          const types = listSelector(getState())({}, true);
          if (!types.items) {
            return false;
          }
          if (
            items.every(el =>
              types.items.some(type => el.externalId === type.externalId)
            )
          ) {
            return true;
          }
          return false;
        },
        () => resolve(),
        undefined,
        () => {
          reject();
        }
      )
    );
    return items;
  },
  sdk => async (q: UpdateCogniteTypeDTO[]) => {
    const result = await sdk.post<{ items: CogniteType[] }>(
      `/api/playground/projects/${sdk.project}/types/update`,
      {
        data: {
          items: q,
        },
      }
    );
    return result.data.items;
  },
  sdk => async (q: IdEither[]) => {
    const result = await sdk.post<{ items: CogniteType[] }>(
      `/api/playground/projects/${sdk.project}/types/delete`,
      {
        data: {
          items: q,
        },
      }
    );
    return result.data.items;
  }
);

const {
  listAction: list,
  listParallelAction: listParallel,
  listReducer,
  createListSelector,
} = buildList<TypeFilterRequest, CogniteType>(
  NAME,
  t => t,
  sdk => async (q: TypeFilterRequest) => {
    const result = await sdk.post<{
      items: CogniteType[];
      nextCursor?: string | undefined;
    }>(`/api/playground/projects/${sdk.project}/types/list`, {
      data: q,
    });
    return result.data;
  }
);

const { reducer, countAction: count, createCountSelector } = resourceBuilder<
  CogniteType,
  UpdateCogniteTypeDTO,
  TypeFilterRequest,
  TypeFilterRequest
>(NAME, t => t, itemReducer, listReducer);

const listSelector = createListSelector(
  // @ts-ignore
  (state: RootState) => state[NAME].items.items,
  (state: RootState) => state[NAME].list
);

const countSelector = createCountSelector(
  (state: RootState) => state[NAME].count
);

const retrieveSelector = createRetrieveSelector(
  (state: RootState) => state[NAME].items.items
);

const itemSelector = createItemSelector(
  (state: RootState) => state[NAME].items.items,
  (state: RootState) => state[NAME].items.getByExternalId
);

export {
  reducer,
  create,
  remove,
  retrieve,
  update,
  retrieveExternal,
  count,
  list,
  listParallel,
  itemSelector,
  listSelector,
  countSelector,
  retrieveSelector,
};
export default reducer;
