import React from 'react';
import { Row } from 'antd';
import { CoolH1, Header, Card } from 'components/Common';

interface SectionCardProps {
  title: React.ReactNode;
  extra?: React.ReactNode | React.ReactNode[];
  children?: React.ReactNode;
}
export const SectionCard = (props: SectionCardProps) => {
  const { title, extra, children } = props;
  return (
    <Card style={{ width: '100%', padding: '30px' }}>
      <Header title={<CoolH1>{title}</CoolH1>} extra={extra} />
      <Row style={{ marginTop: 15 }}>{children}</Row>
    </Card>
  );
};
