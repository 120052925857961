import React from 'react';
import { Row, Col } from 'antd';
import { Button, Colors } from '@cognite/cogs.js';
import moment from 'moment';
import { Field, SectionCard, Popover } from 'components/Common';
import { Instance } from 'modules/instances';
import { Asset } from '@cognite/sdk';
import { CogniteType } from 'modules/types';

// TODO, this should not be imported in here since this is a redux component!
import { AssetHoverPreview } from 'containers/HoverPreview';

interface TemplateMetadataProps {
  instance: Instance;
  type?: CogniteType;
  rootAsset?: Asset;
  onDelete?: () => void;
}
export const InstanceMetadata = ({
  instance,
  type,
  rootAsset,
  onDelete,
}: TemplateMetadataProps) => {
  const { name, description, createdTime, lastUpdatedTime } = instance;
  return (
    <SectionCard
      title={
        <Popover key="name" content={<AssetHoverPreview asset={instance} />}>
          <span style={{ color: Colors.midblue.hex(), cursor: 'pointer' }}>
            {name}
          </span>
        </Popover>
      }
      extra={[
        <Button icon="ThreeD" variant="outline" key="datakit" disabled>
          Create data kit
        </Button>,
        <Button icon="DataTable" variant="outline" key="template" disabled>
          Go to template
        </Button>,
        <Button icon="Delete" variant="outline" key="delete" onClick={onDelete}>
          Delete
        </Button>,
        <Button icon="Duplicate" variant="outline" key="duplicate" disabled>
          Duplicate
        </Button>,
        <Button icon="Edit" variant="outline" key="edit" disabled>
          Edit
        </Button>,
      ]}
    >
      <Row gutter={24}>
        <Col span={8}>
          <Field title="Description">
            <div style={{ minHeight: 120 }}>{description}</div>
          </Field>
        </Col>
        <Col span={8}>
          <Field title="Template">{type ? type.name : 'Loading...'}</Field>
          <Field title="Root Asset">
            {rootAsset ? rootAsset.name : 'Loading...'}
          </Field>
        </Col>
        <Col span={8}>
          <Field title="Created on">
            {moment(createdTime).format('MM/DD/YYYY HH:MM')}
          </Field>
          <Field title="Updated on">
            {moment(lastUpdatedTime).format('MM/DD/YYYY HH:MM')}
          </Field>
        </Col>
      </Row>
    </SectionCard>
  );
};
