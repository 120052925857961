import React, { useState } from 'react';
import { message } from 'antd';
import { Modal, NewTemplateForm } from 'components/Common';
import {
  CreateCogniteTypeDTO,
  CogniteType,
  create,
  update,
  itemSelector,
} from 'modules/types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

export const NewTemplateModal = ({
  visible,
  onClose,
  template,
}: {
  template?: CogniteType;
  visible: boolean;
  onClose: () => void;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { tenant } = useParams();
  const [loading, setLoading] = useState(false);
  const getType = useSelector(itemSelector);
  let callback: () => CreateCogniteTypeDTO;
  if (visible) {
    return (
      <Modal
        visible={visible}
        okText={template ? 'Save' : 'Create'}
        onCancel={() => onClose()}
        loading={loading}
        onDone={async () => {
          const newTemplate = callback();
          setLoading(true);
          if (
            newTemplate.externalId.length === 0 ||
            (newTemplate.properties &&
              newTemplate.properties.some(el => el.propertyId.length === 0))
          ) {
            message.error('Incomplete template, all IDs must be filled in!');
          } else {
            try {
              // if editing
              if (template) {
                // if editing
                await dispatch(
                  update([
                    {
                      id: template.id,
                      version: template.version,
                      set: {
                        externalId: newTemplate.externalId,
                        name: newTemplate.name,
                        description: newTemplate.description,
                        properties: newTemplate.properties,
                      },
                    },
                  ])
                );
                message.success('Successfully created new Template!');
              } else {
                // if creating
                await dispatch(create([newTemplate]));
                message.success('Successfully created new Template!');
                const item = getType(newTemplate.externalId);
                if (item) {
                  history.push(`/${tenant}/templates/${item.id}`);
                }
              }
              onClose();
              setLoading(false);
            } catch (e) {
              if (template) {
                // if editing
                message.error('Unable to edit template.');
              } else {
                // if creating
                message.error('Unable to create template.');
              }
              setLoading(false);
            }
          }
        }}
      >
        <NewTemplateForm
          initialTemplate={template}
          templateCallback={cb => {
            callback = cb;
          }}
        />
      </Modal>
    );
  }
  return null;
};
