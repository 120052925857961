import React, { ReactElement } from 'react';
import styled from 'styled-components';

import Link from 'components/Link';
import { Section as SectionType } from 'components/Navigation';
import { SectionColors } from 'components/Navigation/types';
import theme from 'styles/theme';

interface SectionProps {
  section: SectionType;
  onClose(destination: string): void;
  children: ReactElement | ReactElement[];
}

interface ContainerProps {
  background: string;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  flex-grow: 1;
  background-color: ${props => props.background};
  padding: 36px 0;
`;

const SectionName = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  position: relative;
  color: ${theme.textColor};
  margin: 36px;
  margin-top: 0;
  &::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    background-color: ${props => props.color};
    width: 48px;
    height: 4px;
    transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`;

const SectionNameLink = styled(SectionName)`
  &:hover::after {
    width: 72px;
  }
`;

interface SectionContentProps {
  colors: SectionColors;
}

const SectionContent = styled.div<SectionContentProps>`
  .icon {
    background-color: ${props => props.colors.secondary};
    color: ${props => props.colors.icon || theme.textColor};
  }
`;

const Section = (props: SectionProps) => {
  const { section, onClose, children } = props;
  const { title, colors, linkTo, externalLinkTo, sameWindow = false } = section;

  let sectionName;

  if (linkTo) {
    sectionName = (
      <Link to={linkTo} onClick={() => onClose(title)}>
        <SectionNameLink color={colors.primary}>{title}</SectionNameLink>
      </Link>
    );
  } else if (externalLinkTo) {
    sectionName = (
      <a
        href={externalLinkTo}
        target={sameWindow ? '_self' : '_blank'}
        rel="noopener noreferrer"
        onClick={() => onClose(title)}
      >
        <SectionNameLink color={colors.primary}>{title}</SectionNameLink>
      </a>
    );
  } else {
    sectionName = <SectionName color={colors.primary}>{title}</SectionName>;
  }

  return (
    <Container background={colors.background || 'transparent'}>
      {sectionName}
      <SectionContent colors={colors}>{children}</SectionContent>
    </Container>
  );
};

export default Section;
