import styled from 'styled-components';
import { Colors } from '@cognite/cogs.js';

export const CoolH1 = styled.h1`
  position: relative;
  font-weight: 800;
  font-size: 24px;
  &::after {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 0;
    height: 4px;
    width: 40px;
    background-color: ${Colors.primary.hex()};
  }
`;
