import { Colors } from '@cognite/cogs.js';
import { GetTimeSeriesMetadataDTO } from '@cognite/sdk';

export interface QualityDimension {
  id: string;
  label: string;
  color: string;
  thresholds?: { green: number; yellow: number };
}
export const qualityDimensions: { [key: string]: QualityDimension } = {
  dq_flatline_7d: {
    id: 'dq_flatline_7d',
    label: 'Flatlining',
    color: Colors.lightblue.hex(),
  },
  dq_gap_24h: { id: 'dq_gap_24h', label: 'Gaps', color: Colors.midblue.hex() },
  dq_phantom: {
    id: 'dq_phantom',
    label: 'Phantom',
    color: Colors.purple.hex(),
  },
  dq_overshoot_max_1h: {
    id: 'dq_overshoot_max_1h',
    label: 'Max value',
    color: Colors.pink.hex(),
  },
  dq_overshoot_min_1h: {
    id: 'dq_overshoot_min_1h',
    label: 'Min value',
    color: Colors.midorange.hex(),
  },
  dq_oscillation_1d: {
    id: 'dq_oscillation_1d',
    label: 'Oscillation',
    color: Colors.yellow.hex(),
    thresholds: { green: 4, yellow: 8 },
  },
};

export const getQualityDimension = (
  dimension: string | undefined
): QualityDimension | undefined =>
  dimension ? qualityDimensions[dimension] : undefined;

export const getStatusColor = (
  value: any,
  thresholds?: { green: number; yellow: number }
): string => {
  const parsedValue = Number(value);
  if (Number.isNaN(parsedValue)) return '#d4d4d4';

  const { green, yellow } = thresholds || { green: 0, yellow: 0 };

  if (parsedValue <= green) return Colors.success.hex();
  if (parsedValue <= yellow) return Colors.warning.hex();
  return Colors.danger.hex();
};

export const getWorstColor = (timeSeries: GetTimeSeriesMetadataDTO) =>
  Object.values(qualityDimensions).reduce((worstColor, dimension) => {
    const newColor = getStatusColor(
      timeSeries.metadata && timeSeries.metadata[dimension.id],
      dimension.thresholds
    );
    if ([worstColor, newColor].includes(Colors.danger.hex()))
      return Colors.danger.hex();
    if ([worstColor, newColor].includes(Colors.warning.hex()))
      return Colors.warning.hex();
    if ([worstColor, newColor].includes(Colors.success.hex()))
      return Colors.success.hex();
    return newColor;
  }, '#d4d4d4');
