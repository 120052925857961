import React, { useState, useEffect } from 'react';
import {
  useParams,
  useLocation,
  useRouteMatch,
  useHistory,
  Redirect,
} from 'react-router-dom';
import styled from 'styled-components';
import { Modal } from 'antd';
import { Button } from '@cognite/cogs.js';
import ConfigViewExample from 'assets/config-view-example.png';
import {
  useBuildModelGetPredictions,
  useLoadFeedbackModel,
  useModelSelector,
  usePredictSelector,
  useRuleSelector,
} from 'hooks/CustomHooks';
import MatchingFieldsModal, { defaultProperties } from './MatchingFieldsModal';

const StyledModalContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  img {
    width: 100%;
    margin: 10px;
  }
`;
const SmallText = styled.p`
  font-size: 0.9em;
`;

export function MatchingFieldsInfoModal() {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch<{ tenant: string }>('/:tenant');
  const [showModal, setShowModal] = useState(false);
  const [ready, setReady] = useState(false);
  const { resourceDataKitId, assetsDataKitId } = useParams<{
    assetsDataKitId: string;
    resourceDataKitId: string;
  }>();
  const { modelId, modelDone } = useModelSelector(assetsDataKitId);
  const { predictId, predictDone } = usePredictSelector(resourceDataKitId);
  const { ruleId, ruleDone } = useRuleSelector(resourceDataKitId);

  const modelType = 'simple';
  const properties = defaultProperties;

  const loadFeedbackModel = useLoadFeedbackModel(
    assetsDataKitId,
    resourceDataKitId,
    modelType,
    properties
  );
  const buildModelGetPredictions = useBuildModelGetPredictions(modelType);

  useEffect(() => {
    if (ready) {
      buildModelGetPredictions(ready, properties);
    }
  }, [ready, properties, buildModelGetPredictions]);

  if (ready && modelDone && predictDone && ruleDone) {
    return (
      <Redirect to={`${location.pathname}/${modelId}/${predictId}/${ruleId}`} />
    );
  }

  const handleReady = () => {
    setReady(!ready);
  };
  const handleCancel = () => {
    history.push(`/${match?.params.tenant}/entity_matching`);
  };

  return (
    <>
      {ready && loadFeedbackModel}

      <Modal
        visible={!ready}
        onCancel={handleCancel}
        title="Optional: select other properties as basis for matching"
        footer={[
          <Button key="button-ready" type="primary" onClick={handleReady}>
            Continue
          </Button>,
        ]}
      >
        <StyledModalContent>
          <SmallText>
            By default, the entity matching algorithm will search for matching
            text substrings within the name and description fields of each of
            the entities. Click &quot;Contextualization settings&quot; below if
            you want to match based on other fields such as timeseries property
            fields or metadata fields.
          </SmallText>
          <Button
            icon="Settings"
            onClick={() => setShowModal(!showModal)}
            type="primary"
            style={{ margin: '10px 0 20px', alignSelf: 'center' }}
          >
            Contextualization settings
          </Button>
          <SmallText>
            This can be changed at any point later by clicking the config
            button.
          </SmallText>
          <img
            alt="example"
            src={ConfigViewExample}
            style={{ margin: '20px 0 10px', alignSelf: 'center' }}
          />
        </StyledModalContent>
        <MatchingFieldsModal
          redirect
          visible={showModal}
          changeVisibility={setShowModal}
        />
      </Modal>
    </>
  );
}
