import React from 'react';
import { Modal } from 'antd';
import { TimeseriesChartMeta } from '@cognite/gearbox';

export default function TimeseriesModal(props: {
  timeseriesId: number;
  onClose?: () => void;
}) {
  return (
    <Modal
      footer={null}
      width="80%"
      visible
      onOk={props.onClose}
      onCancel={props.onClose}
    >
      <TimeseriesChartMeta timeseriesId={props.timeseriesId} />
    </Modal>
  );
}
