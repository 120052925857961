import { Colors } from '@cognite/cogs.js';
import styled from 'styled-components';

export const CELL_STYLE: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  padding: '16px',
  textAlign: 'left',
  justifyContent: 'start',
  borderBottom: `2px solid ${Colors['greyscale-grey2'].hex()}`,
  borderRight: `2px solid ${Colors['greyscale-grey2'].hex()}`,
};

export const STYLE_BOTTOM_LEFT_GRID: React.CSSProperties = {
  borderRight: `2px solid ${Colors['greyscale-grey2'].hex()}`,
  backgroundColor: `${Colors['greyscale-grey1'].hex()}`,
};
export const STYLE_TOP_LEFT_GRID: React.CSSProperties = {
  borderBottom: `2px solid ${Colors['greyscale-grey2'].hex()}`,
  borderRight: `2px solid ${Colors.white.hex()}`,
  backgroundColor: `${Colors['greyscale-grey1'].hex()}`,
  fontWeight: 800,
};
export const STYLE_TOP_RIGHT_GRID: React.CSSProperties = {
  borderBottom: `2px solid ${Colors['greyscale-grey2'].hex()}`,
  backgroundColor: `${Colors['greyscale-grey1'].hex()}`,
  fontWeight: 800,
};

export const PropertyCell = styled.div`
  padding: 16px;
  transition: 0.3s all;
  width: 100%;
  height: 100%;
  background: ${Colors.white.hex()};
  z-index: 1;
  cursor: pointer;

  && * {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &&:hover {
    box-shadow: 0px 0px 8px ${Colors['greyscale-grey5'].hex()};
    z-index: 10;
  }
  &&.ant-popover-open {
    transform: scale(1.1);
    box-shadow: 0px 0px 8px ${Colors['greyscale-grey5'].hex()};
    z-index: 10;
  }

  &&:after {
    content: ' ';
    display: block;
    clear: both;
  }
`;
