import React from 'react';
import { useSelector } from 'react-redux';
import { getStagedChanges } from 'modules/contextualization/predictions';
import PieChart from 'react-minimal-pie-chart';
import { Col, Row } from 'antd';
import { GetTimeSeriesMetadataDTO } from '@cognite/sdk';
import { dataKitItemsSelector } from 'modules/selection';
import { Result } from 'modules/sdk-builder/types';

export default function EntityMatchingPieChart({
  resourceDataKitId,
  assetDataKitId,
}: {
  resourceDataKitId: string;
  assetDataKitId: string;
}) {
  const stagedChanges = useSelector(getStagedChanges)(
    resourceDataKitId,
    assetDataKitId
  );
  const { items: resources } = useSelector(dataKitItemsSelector)(
    resourceDataKitId,
    true,
    true
  ) as Result<GetTimeSeriesMetadataDTO>;

  const numTimeseriesWithAssetId = resources.filter(ts => ts.assetId != null)
    .length;

  const numTimeseriesStaged = stagedChanges.length;

  const numTimeseriesWithoutAssetId =
    resources.length - numTimeseriesStaged - numTimeseriesWithAssetId;

  return (
    <Row>
      <Col span={8}>
        <div style={{ color: '#50642E' }}>
          Mapped {numTimeseriesWithAssetId}
        </div>
        <div style={{ color: '#246E92' }}>Staged {numTimeseriesStaged}</div>
        <div style={{ color: '#E74D30' }}>
          Unmapped {numTimeseriesWithoutAssetId}
        </div>
      </Col>
      <Col span={16}>
        <PieChart
          style={{
            height: '100px',
            width: '100px',
            margin: '0 auto',
          }}
          data={[
            {
              value: numTimeseriesWithAssetId,
              color: '#50642E',
            },
            {
              value: numTimeseriesStaged,
              color: '#246E92',
            },
            {
              value: numTimeseriesWithoutAssetId,
              color: '#E74D30',
            },
          ]}
        />
      </Col>
    </Row>
  );
}
