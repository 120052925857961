import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { Typography, Tooltip } from 'antd';
import { Button } from '@cognite/cogs.js';
import StickyBottomRow from 'components/StickyBottomRow';
import { trackUsage } from 'utils/Metrics';
import { dataKitItemMapSelector } from 'modules/selection';
import { Result } from 'modules/sdk-builder/types';
import {
  dismissChanges,
  writeStagedChanges,
  getStagedChanges,
  getPredictionStateSelector,
  Resource,
} from 'modules/contextualization/predictions';

import ConfirmationModal from './ConfirmationModal';

const { Text } = Typography;

export default function BottomRow(props: {
  changeTab: (tab: string) => void;
  activeTab: string;
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { activeTab, changeTab } = props;
  const { assetsDataKitId, resourceDataKitId, changed } = useParams<{
    assetsDataKitId: string;
    resourceDataKitId: string;
    changed: string; // TODO
  }>();

  const match = useRouteMatch();
  const stagedChanges = useSelector(getStagedChanges)(
    resourceDataKitId,
    assetsDataKitId
  );

  const { writingChanges } = useSelector(getPredictionStateSelector)(
    resourceDataKitId
  );
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { items: resources } = useSelector(dataKitItemMapSelector)(
    resourceDataKitId,
    true,
    true
  ) as Result<Resource>;
  const overwritingExistingAssetIds =
    stagedChanges.filter(change => {
      const resource = resources[change.resourceId];
      return resource?.assetId && change.assetId !== resource?.assetId;
    }).length > 0;

  const writeChanges = () => {
    trackUsage('Contextualization.ResourceMatching.StagedChanges.Write', {
      stagedChangesCount: stagedChanges.length,
      dataSetId: resourceDataKitId,
    });
    dispatch(writeStagedChanges(resourceDataKitId));

    if (!changed) {
      history.replace(`${match.url}/changed`);
    }
  };

  const onWriteClick = () => {
    if (overwritingExistingAssetIds) {
      setShowConfirmation(true);
    } else {
      writeChanges();
    }
  };

  const onDownloadClick = () => {
    const anchor = document.createElement('a');
    const file = new Blob([JSON.stringify(stagedChanges)], {
      type: 'text/json',
    });
    anchor.href = window.URL.createObjectURL(file);
    anchor.download = `staged_${resourceDataKitId}.json`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const numResourcesWithoutAssetId = Object.values(resources).filter(
    ts => !ts.assetId
  ).length;

  return (
    <StickyBottomRow
      justify={activeTab === 'editing' ? 'end' : 'space-between'}
    >
      <ConfirmationModal
        visible={showConfirmation}
        confirmed={() => writeChanges()}
        onClose={() => setShowConfirmation(false)}
        resourceDataKitId={resourceDataKitId}
        assetsDSId={assetsDataKitId}
      />
      {activeTab === 'editing' ? (
        <div>
          <Text style={{ marginRight: '20px' }} strong>
            {stagedChanges.length} Staged changes
          </Text>
          <Button
            style={{ marginLeft: '20px' }}
            disabled={stagedChanges.length === 0}
            type="primary"
            onClick={() => changeTab('staged')}
          >
            Next
          </Button>
        </div>
      ) : (
        <>
          <div>
            <Button
              style={{ marginRight: '20px' }}
              onClick={() => changeTab('manual')}
            >
              Back
            </Button>
            <Text strong>{numResourcesWithoutAssetId} Unmatched resources</Text>
          </div>
          <div>
            <Button
              disabled={writingChanges || stagedChanges.length === 0}
              onClick={() => {
                dispatch(dismissChanges(resourceDataKitId, stagedChanges));
              }}
            >
              Un-stage all changes
            </Button>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Text style={{ marginRight: '20px' }} strong>
              {stagedChanges.length} Staged changes
            </Text>
            <Tooltip
              placement="top"
              title="Download the staged changes as a JSON file"
            >
              <Button
                icon="Download"
                onClick={onDownloadClick}
                disabled={writingChanges || stagedChanges.length === 0}
                type="secondary"
              />
            </Tooltip>
            <Button
              onClick={onWriteClick}
              disabled={writingChanges || stagedChanges.length === 0}
              type="primary"
              style={{ marginLeft: '8px' }}
            >
              Write to CDF
            </Button>
          </div>
        </>
      )}
    </StickyBottomRow>
  );
}
