import React from 'react';
import styled from 'styled-components';

import Icon from 'antd/lib/icon';
import Tooltip from 'antd/lib/tooltip';

import Link from 'components/Link';
import { Item as ItemType } from 'components/Navigation';
import theme from 'styles/theme';

interface ItemProps {
  item: ItemType;
  onClose(destination: string): void;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0 12px;
  padding: 12px 24px;
  color: ${theme.textColor};
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

const LinkContainer = styled(Container)`
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const DisabledContainer = styled(Container)`
  opacity: 0.75;
`;

const ItemIcon = styled.div`
  font-size: 20px;
  background-color: ${props => props.color};
  flex: 0 0 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
`;

const Info = styled.div`
  margin-left: 24px;
  flex: 1 1 0;
`;

const Title = styled.div`
  font-weight: bold;
`;

const Subtitle = styled.div`
  p:last-of-type {
    margin-bottom: 0;
  }
`;

const Item = (props: ItemProps) => {
  const { item, onClose } = props;
  const {
    icon,
    title,
    subtitle = '',
    linkTo = '',
    externalLinkTo = '',
    sameWindow = false,
    disabled = false,
    tooltip = '',
    onClick,
  } = item;

  const ContainerType = disabled ? DisabledContainer : LinkContainer;

  let itemContent = (
    <ContainerType onClick={onClick}>
      <ItemIcon className="icon">
        <Icon type={icon} />
      </ItemIcon>
      <Info>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Info>
    </ContainerType>
  );

  if (disabled) {
    itemContent = (
      <Tooltip title={tooltip} placement="right">
        {itemContent}
      </Tooltip>
    );
  }

  let itemLink = itemContent;

  if (externalLinkTo) {
    itemLink = (
      <a
        href={externalLinkTo}
        target={sameWindow ? '_self' : '_blank'}
        rel="noopener noreferrer"
        onClick={() => onClose(title)}
      >
        {itemContent}
      </a>
    );
  } else if (linkTo) {
    itemLink = (
      <Link to={linkTo} onClick={() => onClose(title)}>
        {itemContent}
      </Link>
    );
  }

  return itemLink;
};

export default Item;
