import React, { useState } from 'react';
import { CogniteTypePopulatedProperty } from 'modules/types';
import { Button, Icon, Colors } from '@cognite/cogs.js';
import { GetTimeSeriesMetadataDTO } from '@cognite/sdk';
import styled from 'styled-components';
import { TimeseriesHoverPreview } from 'containers/HoverPreview';
import { Instance } from 'modules/instances';
import { useDispatch } from 'react-redux';
import { onResourceSelected } from 'modules/app';
import {
  SmallTitle,
  InfoCell,
  InfoGrid,
  IconWrapper,
  ColorDot,
} from 'components/Common';
import { qualityDimensions, getStatusColor } from 'utils/DataQuality';
import { useHistory } from 'react-router-dom';

const PropertyHeader = styled(InfoGrid)`
  button {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  h6 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  h5 {
    font-size: 16px;
    font-weight: 600;
  }
`;

const DataQualitySection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12px;

  && > div {
    display: flex;
    align-items: center;
    border-left: 2px solid ${Colors['greyscale-grey3'].hex()};
    padding-left: 8px;
  }
  && > div:nth-child(3n) {
    border-right: 2px solid ${Colors['greyscale-grey3'].hex()};
  }
  && > div span {
    margin-left: 8px;
  }
`;

export const InstanceTimeseriesPopover = ({
  instance,
  property,
  timeseries,
  onPropertyClicked,
  onClearClicked,
  onSuggestionClicked,
}: {
  instance: Instance;
  property: CogniteTypePopulatedProperty;
  timeseries: GetTimeSeriesMetadataDTO;
  onPropertyClicked?: () => void;
  onClearClicked?: () => void;
  onSuggestionClicked?: () => void;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const showActions =
    onPropertyClicked || onClearClicked || onSuggestionClicked;
  return (
    <div
      style={{
        width: '400px',
        maxHeight: '80vh',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <PropertyHeader noBorders>
        <InfoCell>
          <Button
            type="secondary"
            icon="PanelRight"
            shape="round"
            onClick={() =>
              dispatch(
                onResourceSelected(
                  {
                    timeseriesId: timeseries.id,
                    showSidebar: true,
                    showQualityEvents: true,
                  },
                  history
                )
              )
            }
          />
          <h6>Property</h6>
          <h5>{property.name || property.propertyId}</h5>
          <h6>Instance</h6>
          <h5>{instance.name}</h5>
        </InfoCell>
      </PropertyHeader>
      {collapsed ? (
        <InfoGrid noBorders>
          <div style={{ position: 'absolute', top: '16px', right: '16px' }}>
            <Icon
              key="collapse"
              type={collapsed ? 'LargeDown' : 'LargeUp'}
              onClick={() => {
                setCollapsed(!collapsed);
              }}
              style={{ cursor: 'pointer', padding: '16px' }}
            />
          </div>
          <InfoCell>
            <SmallTitle>
              <IconWrapper>
                <Icon type="Timeseries" />
              </IconWrapper>
              {timeseries.name}
            </SmallTitle>
          </InfoCell>
        </InfoGrid>
      ) : (
        <TimeseriesHoverPreview
          timeseries={timeseries}
          disableSidebarToggle
          extras={[
            <Icon
              key="collapse"
              type={collapsed ? 'LargeDown' : 'LargeUp'}
              onClick={() => {
                setCollapsed(!collapsed);
              }}
              style={{ cursor: 'pointer', padding: '16px' }}
            />,
          ]}
        />
      )}
      {showActions && (
        <InfoCell>
          <SmallTitle style={{ marginTop: 0 }}>Actions</SmallTitle>
          {onPropertyClicked && (
            <Button
              type="primary"
              onClick={onPropertyClicked}
              key="find-timeseries"
              icon="Timeseries"
              style={{ width: '100%', marginBottom: 10 }}
            >
              Replace Time Series
            </Button>
          )}
          {onClearClicked && (
            <Button
              type="danger"
              onClick={onClearClicked}
              key="clear-timeseries"
              icon="Close"
              style={{ width: '100%', marginBottom: 10 }}
            >
              Clear Time Series
            </Button>
          )}
          {onSuggestionClicked && (
            <Button
              type="primary"
              onClick={onSuggestionClicked}
              key="review-timeseries"
              icon="Feedback"
              style={{ width: '100%', backgroundColor: Colors.purple.hex() }}
            >
              Review Suggestion
            </Button>
          )}
        </InfoCell>
      )}
      <InfoCell noBorders>
        <SmallTitle style={{ marginTop: 0 }}>Data Quality</SmallTitle>
        <DataQualitySection>
          {Object.values(qualityDimensions).map(dimension => (
            <div key={dimension.id}>
              <ColorDot
                color={getStatusColor(
                  timeseries.metadata && timeseries.metadata[dimension.id],
                  dimension.thresholds
                )}
              />
              <span>{dimension.label}</span>
            </div>
          ))}
        </DataQualitySection>
      </InfoCell>
    </div>
  );
};
