import React, { useState } from 'react';
import { Button, Dropdown, Menu, AllIconTypes } from '@cognite/cogs.js';

type Option<K> = { value: K; name: string };

type Props<T> = {
  options: Option<T>[];
  onSelect?: (value: T) => void;
  icon?: AllIconTypes;
  placeholder?: string;
  disabled?: boolean;
};

export function BaseDropdown<T = string | number>(
  props: Props<T>
): JSX.Element {
  const {
    icon = 'Down',
    options,
    onSelect,
    disabled,
    placeholder = 'Select',
  } = props;
  const [selected, setSelected] = useState<Option<T>>();
  const handleSelect = (selectedOption: Option<T>) => {
    const { value } = selectedOption;
    setSelected(selectedOption);
    if (onSelect) onSelect(value);
  };

  const List = () => (
    <Menu>
      {options.map(opt => (
        <Menu.Item key={String(opt.value)} onClick={() => handleSelect(opt)}>
          {opt.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown content={<List />} disabled={disabled}>
      <Button
        style={{ width: '100%' }}
        icon={icon}
        iconPlacement="right"
        disabled={disabled}
      >
        {selected ? selected.name : placeholder}
      </Button>
    </Dropdown>
  );
}
