import { CogniteClient, FilesMetadata, Asset } from '@cognite/sdk';
import { PnidResponseEntity } from 'modules/pnidParsing';
import { stripWhitespace } from 'helpers/Helpers';
import {
  removeExtension,
  isSimilarBoundingBox,
  PendingPnIDAnnotation,
  PnIDAnnotation,
} from './PnIDApi';

export class FilesApi {
  sdk: CogniteClient;

  constructor(sdk: CogniteClient) {
    this.sdk = sdk;
  }

  public getPdfUrl = async (fileId: number) => {
    const link = `/api/v1/projects/${this.sdk.project}/files/downloadlink`;
    const response = await this.sdk.post(link, {
      data: {
        items: [{ id: fileId }],
      },
    });
    if (response.status !== 200 || !response.data.items[0].downloadUrl) {
      throw new Error('Unable to load PDF.');
    }
    return response.data.items[0].downloadUrl;
  };

  public createPendingAnnotationsFromJob = async (
    file: FilesMetadata,
    entities: PnidResponseEntity[],
    refAssets: Asset[],
    refFiles: FilesMetadata[],
    jobId: string,
    existingEntities: PnIDAnnotation[]
  ): Promise<PendingPnIDAnnotation[]> => {
    const assetsMap = refAssets.reduce((prev, asset) => {
      const key = stripWhitespace(asset.name);
      if (!prev[key]) {
        prev[key] = { id: [], externalId: [] };
      }
      prev[key].id.push(asset.id);
      if (asset.externalId) {
        prev[key].externalId.push(asset.externalId);
      }
      return prev;
    }, {} as { [key: string]: { id: number[]; externalId: string[] } });

    const filesMap = refFiles.reduce((prev, item) => {
      const key = stripWhitespace(removeExtension(item.name));
      if (!prev[key]) {
        prev[key] = { id: [], externalId: [] };
      }
      prev[key].id.push(item.id);
      if (item.externalId) {
        prev[key].externalId.push(item.externalId);
      }
      return prev;
    }, {} as { [key: string]: { id: number[]; externalId: string[] } });

    const boundingBoxes = existingEntities
      .filter(el => !el.isDeleted)
      .map(el => ({
        xMin: el.boundingBox.x,
        xMax: el.boundingBox.x + el.boundingBox.width,
        yMin: el.boundingBox.y,
        yMax: el.boundingBox.y + el.boundingBox.height,
      }));

    const deletedEntities = existingEntities.filter(el => el.isDeleted);

    const flattenedEntities: Array<any> = entities
      .map((el: any) =>
        Object.values(el.matches).map((value: any) => {
          value.page = el.page;
          return value;
        })
      )
      .flat(1);

    const filteredEntities = flattenedEntities.filter(el => {
      return !boundingBoxes.some(
        box =>
          isSimilarBoundingBox(box, el.boundingBox, 1, true) ||
          isSimilarBoundingBox(box, el.boundingBox, 0.2, false)
      );
    });

    const annotations = filteredEntities.map((el: any) => {
      let assetId: number | undefined;
      let assetExternalId: string | undefined;
      let fileId: number | undefined;
      let fileExternalId: string | undefined;
      const strippedEntityText = stripWhitespace(el.text);

      if (
        assetsMap[strippedEntityText] &&
        assetsMap[strippedEntityText].id.length === 1
      ) {
        if (assetsMap[strippedEntityText].externalId.length === 1) {
          [assetExternalId] = assetsMap[strippedEntityText].externalId;
        } else {
          [assetId] = assetsMap[strippedEntityText].id;
        }
      }
      if (
        filesMap[strippedEntityText] &&
        filesMap[strippedEntityText].id.length === 1
      ) {
        if (filesMap[strippedEntityText].externalId.length === 1) {
          [fileExternalId] = filesMap[strippedEntityText].externalId;
        } else {
          [fileId] = filesMap[strippedEntityText].id;
        }
      }

      if (
        deletedEntities.some(deletedEntity => {
          if (
            deletedEntity.linkedFileExternalId === fileExternalId ||
            deletedEntity.linkedFileId === fileId ||
            deletedEntity.linkedAssetId === assetId ||
            deletedEntity.linkedAssetExternalId === assetExternalId
          ) {
            return isSimilarBoundingBox(
              {
                xMin: deletedEntity.boundingBox.x,
                xMax:
                  deletedEntity.boundingBox.x + deletedEntity.boundingBox.width,
                yMin: deletedEntity.boundingBox.y,
                yMax:
                  deletedEntity.boundingBox.y +
                  deletedEntity.boundingBox.height,
              },
              el.boundingBox,
              0.3
            );
          }
          return false;
        })
      ) {
        return undefined;
      }

      return {
        type: 'Model Generated',
        boundingBox: {
          x: el.boundingBox.xMin,
          y: el.boundingBox.yMin,
          width: el.boundingBox.xMax - el.boundingBox.xMin,
          height: el.boundingBox.yMax - el.boundingBox.yMin,
        },
        ...(!file.externalId ? { fileId: file.id } : {}),
        ...(file.externalId ? { fileExternalId: file.externalId } : {}),
        linkedAssetId: assetId,
        linkedAssetExternalId: assetExternalId,
        linkedFileId: fileId,
        linkedFileExternalId: fileExternalId,
        label: el.text,
        source: `${jobId}`,
        version: 3,
        page: el.page ? el.page : 1,
      } as PendingPnIDAnnotation;
    });

    return annotations.filter(el => !!el) as PendingPnIDAnnotation[];
  };
}
