import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Radio, message } from 'antd';
import {
  searchSelector as searchFileSelector,
  search,
  retrieve,
  itemSelector,
} from 'modules/files';
import { FilesMetadata, FilesSearchFilter } from '@cognite/sdk';
import styled from 'styled-components';
import { WithLabel, Input, SmallTitle, FileList } from 'components/Common';
import { PNID_METADATA_IDENTIFIER } from 'utils/PnIDApi';
import { useDebounce } from 'hooks/Hooks';
import { FileHoverPreview } from 'containers/HoverPreview';
import { onResourceSelected } from 'modules/app';
import { sdk } from 'utils/SDK';
import { PatternManualSwitch } from 'containers/Contextualization/EntityMatching/AssetResourceMatching';
import { callUntilCompleted } from 'helpers';
import { Button } from '@cognite/cogs.js';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;

const getFilters = (query: string = ''): FilesSearchFilter => {
  return {
    limit: 100,
    search: query.length > 0 ? { name: query } : undefined,
    filter: {
      metadata: {
        [PNID_METADATA_IDENTIFIER]: 'true',
      },
    },
  };
};

type FileContentSearchResult = {
  highlights: string[];
  id: number;
};

const doRetrieveSearchByContentFunc = async () => {
  try {
    const {
      data: {
        items: [func],
      },
    } = await sdk.post(
      `/api/playground/projects/${sdk.project}/functions/byids`,
      {
        data: { items: [{ externalId: 'pnidgrep' }] },
      }
    );
    return func ? func.id : undefined;
  } catch {
    return undefined;
  }
};
const doSearchByFileContent = async (funcId: number, query: string) => {
  if (query.length > 0) {
    try {
      const {
        data: { id },
      } = await sdk.post(
        `/api/playground/projects/${sdk.project}/functions/${funcId}/call`,
        {
          data: {
            data: {
              search: query,
            },
          },
        }
      );
      return await new Promise<FileContentSearchResult[]>(resolve => {
        return callUntilCompleted(
          () =>
            sdk.get(
              `/api/playground/projects/${sdk.project}/functions/${funcId}/calls/${id}`
            ),
          data => data.status === 'Completed' || data.status === 'Failed',
          async data => {
            if (data.status === 'Completed') {
              try {
                const {
                  data: { response },
                } = await sdk.get(
                  `/api/playground/projects/${sdk.project}/functions/${funcId}/calls/${id}/response`
                );
                return resolve(response as FileContentSearchResult[]);
              } catch {
                return resolve([] as FileContentSearchResult[]);
              }
            }
            return resolve([] as FileContentSearchResult[]);
          },
          undefined,
          undefined,
          1000
        );
      });
    } catch {
      message.error(
        'Unable to search by file content, do you have the correct access permissions?'
      );
    }
  }
  return [] as FileContentSearchResult[];
};

export const PnIDSearchExplorer = (props: {
  onFileClicked?: (file: FilesMetadata) => void;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchFunctionId, setSearchFunctionId] = useState<number | undefined>(
    undefined
  );
  const [query, setQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [searchByContent, setSearchByContent] = useState<boolean>(false);
  const [contentResult, setContentResult] = useState<FileContentSearchResult[]>(
    []
  );
  const debouncedQuery = useDebounce<string>(query, 500);
  const [selectedFile, setSelectedFile] = useState<FilesMetadata | undefined>();

  const getFile = useSelector(itemSelector);

  const {
    onFileClicked = item =>
      dispatch(
        onResourceSelected({ fileId: item.id, showSidebar: true }, history)
      ),
  } = props;

  const filesSearch = getFilters(query);

  const {
    items: searchResult,
    fetching,
  }: { items: FilesMetadata[]; fetching: boolean } = useSelector(
    searchFileSelector
  )(filesSearch);

  const filesList =
    searchByContent && debouncedQuery.length > 0
      ? contentResult
          .filter(el => !!getFile(el.id))
          .map(
            el =>
              ({
                ...getFile(el.id),
                id: el.id,
                highlight: el.highlights[0],
              } as FilesMetadata & { highlight?: string })
          )
      : searchResult;

  useEffect(() => {
    (async () => {
      const funcId = await doRetrieveSearchByContentFunc();
      setSearchFunctionId(funcId);
    })();
  }, []);

  useEffect(() => {
    const filter = getFilters(debouncedQuery);
    if (searchFunctionId && searchByContent && debouncedQuery.length > 0) {
      (async () => {
        setLoading(true);
        const items = await doSearchByFileContent(
          searchFunctionId,
          debouncedQuery
        );
        setContentResult(items);
        setLoading(false);
        dispatch(retrieve(items.map(el => ({ id: el.id }))));
      })();
    } else {
      dispatch(search(filter));
    }
  }, [dispatch, debouncedQuery, searchFunctionId, searchByContent]);

  return (
    <Wrapper>
      <Row gutter={24}>
        <Col span={14}>
          <WithLabel title="Search">
            <Input
              value={query}
              onChange={setQuery}
              placeholder="Search for P&IDs..."
            />
          </WithLabel>
        </Col>
        <Col span={10}>
          <PatternManualSwitch
            value={searchByContent ? 'content' : 'name'}
            buttonStyle="solid"
            disabled={!searchFunctionId}
            onChange={ev => setSearchByContent(ev.target.value === 'content')}
          >
            <Radio.Button value="name">
              <span>Search by Name</span>
            </Radio.Button>
            <Radio.Button value="content">
              <span>Search by Content</span>
            </Radio.Button>
          </PatternManualSwitch>
        </Col>
      </Row>
      <Row
        style={{
          display: 'flex',
          overflowY: 'auto',
        }}
      >
        <Col
          span={selectedFile ? 6 : 24}
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
          }}
        >
          <SmallTitle>P&IDs</SmallTitle>
          <FileList
            files={filesList}
            loading={fetching || loading}
            query={query}
            onFileClick={item => setSelectedFile(item)}
            description="highlight"
          />
        </Col>
        {selectedFile && (
          <Col
            span={18}
            style={{
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <FileHoverPreview
              file={selectedFile}
              disableSidebarToggle
              actions={[
                <Button key="view" onClick={() => onFileClicked(selectedFile)}>
                  View File
                </Button>,
              ]}
            />
          </Col>
        )}
      </Row>
    </Wrapper>
  );
};
