import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Card } from 'antd';
import { Button, Checkbox } from '@cognite/cogs.js';
import { v4 as uuid } from 'uuid';
import { setOptions } from 'modules/pnidParsing';
import StickyBottomRow from 'components/StickyBottomRow';

export default function PnIDOptions() {
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const [fuzziness, setFuzziness] = useState(false);
  const [grayscale, setGrayscale] = useState(false);

  const summarizePnID = () => {
    const optionsId = uuid();
    dispatch(setOptions(fuzziness, grayscale));
    history.push(`${match.url}/${optionsId}`);
  };

  return (
    <>
      <h1>P&ID options</h1>
      <Card>
        <Card.Meta
          title={
            <Checkbox
              name="checkbox-partial"
              onChange={(checked: boolean) => setFuzziness(checked)}
            >
              Allow partial matches
            </Checkbox>
          }
          description="Add increased fuzziness without a loss in precision when matching entity names in P&ID.
          Leaving this option unchecked causes only exact matches to be matched (it has no influence on precision)."
        />
      </Card>
      <Card>
        <Card.Meta
          title={
            <Checkbox
              name="checkbox-grayscale"
              onChange={(checked: boolean) => setGrayscale(checked)}
            >
              Grayscale P&ID
            </Checkbox>
          }
          description="Checking this option will return an SVG file in black and white with significantly lower file size."
        />
      </Card>

      <StickyBottomRow>
        <Button size="large" type="secondary" onClick={() => history.goBack()}>
          Back
        </Button>
        <div>
          <Button size="large" type="primary" onClick={summarizePnID}>
            Next
          </Button>
        </div>
      </StickyBottomRow>
    </>
  );
}
