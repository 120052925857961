import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { itemSelector, retrieve as retrieveAsset } from 'modules/assets';
import { Button, Icon } from '@cognite/cogs.js';
import { List, Tabs } from 'antd';

import {
  AssetBreadcrumb,
  AssetDetailsPanel,
  AssetTree,
  TimeseriesPreview,
} from '@cognite/gearbox';
import {
  linkedFilesSelectorByAssetId,
  listFilesLinkedToAsset,
} from 'modules/annotations';
import { retrieve as retrieveFiles } from 'modules/files';
import { list as listTimeseries, listSelector } from 'modules/timeseries';
import { TimeseriesFilterQuery } from '@cognite/sdk';
import { onResourceSelected } from 'modules/app';
import { useHistory } from 'react-router-dom';
import { Wrapper } from './Common';

const createTimeseriesFilter = (assetId: number): TimeseriesFilterQuery => ({
  filter: { assetSubtreeIds: [{ id: assetId }] },
});

export const AssetMetadataPreview = ({
  assetId,
  extraActions,
}: {
  assetId: number;
  extraActions?: React.ReactNode[];
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const asset = useSelector(itemSelector)(assetId);
  const { files, fileIds } = useSelector(linkedFilesSelectorByAssetId)(assetId);
  const { items: timeseries } = useSelector(listSelector)(
    createTimeseriesFilter(assetId),
    false
  );

  useEffect(() => {
    (async () => {
      await dispatch(retrieveAsset([{ id: assetId }]));
      await dispatch(listFilesLinkedToAsset(assetId));
      await dispatch(listTimeseries(createTimeseriesFilter(assetId)));
    })();
  }, [dispatch, assetId]);

  useEffect(() => {
    if (fileIds) {
      dispatch(retrieveFiles(fileIds.map(id => ({ id }))));
    }
  }, [dispatch, fileIds]);

  return (
    <Wrapper>
      <Button className="back-button" onClick={() => history.goBack()}>
        Back
      </Button>
      <AssetBreadcrumb
        assetId={assetId}
        onBreadcrumbClick={newAsset =>
          dispatch(onResourceSelected({ assetId: newAsset.id }, history))
        }
      />
      <h1>
        <Icon type="ThreeD" /> {asset ? asset.name : 'Loading...'}
      </h1>
      {extraActions}
      <Tabs>
        <Tabs.TabPane
          key="timeseries"
          tab={<span>Linked Timeseries ({timeseries.length})</span>}
        >
          <List
            renderItem={ts => (
              <List.Item
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (ts) {
                    dispatch(
                      onResourceSelected(
                        {
                          timeseriesId: ts.id,
                          showSidebar: true,
                        },
                        history
                      )
                    );
                  }
                }}
              >
                <TimeseriesPreview timeseriesId={ts.id} />
              </List.Item>
            )}
            pagination={{ position: 'bottom' }}
            dataSource={timeseries}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="files"
          tab={<span>Linked Files ({files.length})</span>}
        >
          <List
            renderItem={file => (
              <List.Item
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (file) {
                    dispatch(
                      onResourceSelected(
                        { fileId: file.id, showSidebar: true },
                        history
                      )
                    );
                  }
                }}
              >
                <List.Item.Meta
                  title={file.name}
                  description={file.externalId}
                />
              </List.Item>
            )}
            pagination={{ position: 'bottom' }}
            dataSource={files}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="children" tab="Children">
          <AssetTree
            assetIds={[assetId]}
            defaultExpandedKeys={[assetId]}
            onSelect={newAsset => {
              if (newAsset.node) {
                dispatch(
                  onResourceSelected(
                    {
                      assetId: newAsset.node.id,
                      showSidebar: true,
                    },
                    history
                  )
                );
              }
            }}
          />
        </Tabs.TabPane>
      </Tabs>
      <AssetDetailsPanel assetId={assetId} />
    </Wrapper>
  );
};
