import React from 'react';
import { List, Row, Col } from 'antd';
import { FilesMetadata } from '@cognite/sdk/dist/src/types/types';
import styled from 'styled-components';
import { Icon, Colors } from '@cognite/cogs.js';
import Highlighter from 'react-highlight-words';
import { ListItem } from 'components/Common';

const IconWrapper = styled.span`
  background: #f5f5f5;
  padding: 5px;
  padding-bottom: 1px;
  border-radius: 4px;
  vertical-align: -0.225em;
`;

const ListWrapper = styled.div`
  display: flex;
  flex: 1;
  height: auto;
  width: 100%;
  overflow-y: auto;

  .ant-list {
    width: 100%;
    overflow-x: hidden;
  }

  .ant-row-flex {
    width: 100%;
  }
`;

const ListItemWrapper = styled.div`
  &:hover {
    background: ${Colors['midblue-8'].hex()};
  }
  cursor: pointer;
  transition: all 0.2s;
`;

const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
`;

interface FileListProps {
  files: FilesMetadata[];
  onFileClick?: (file: FilesMetadata) => void;
  query?: string;
  description?: string;
  loading?: boolean;
  renderItem?: (
    file: FilesMetadata,
    content: React.ReactNode
  ) => React.ReactNode;
}

export const FileList = (props: FileListProps) => {
  const {
    onFileClick = () => {},
    loading,
    files,
    description,
    query = '',
    renderItem = (_, content) => content,
  } = props;

  return (
    <ListWrapper>
      <List
        loading={loading}
        dataSource={files}
        renderItem={item =>
          renderItem(
            item,
            <ListItemWrapper>
              <ListItem
                onClick={() => onFileClick(item)}
                style={{ paddingLeft: 4, paddingRight: 4 }}
              >
                <Row gutter={12} type="flex" justify="start">
                  <Col>
                    <IconWrapper>
                      <Icon type="Document" />
                    </IconWrapper>
                  </Col>
                  <Col style={{ flex: 1, overflow: 'hidden' }}>
                    <Title>
                      {item.name && (
                        <Highlighter
                          searchWords={query.split(' ')}
                          textToHighlight={item.name}
                          autoEscape
                        />
                      )}
                    </Title>
                    {description && (item as any)[description] && (
                      <p>{(item as any)[description]}</p>
                    )}
                  </Col>
                </Row>
              </ListItem>
            </ListItemWrapper>
          )
        }
      />
    </ListWrapper>
  );
};
