import { combineReducers } from 'redux';
import retrieveFunctionsReducer from './retrieve';
import createFunctionReducer from './create';
import callFunctionReducer from './call';
import deleteFunctionReducer from './delete';
import functionCallsReducer from './functionCalls';
import schedulesReducer from './schedules';
import responseReducer from './response';

export default combineReducers({
  items: retrieveFunctionsReducer,
  create: createFunctionReducer,
  call: callFunctionReducer,
  delete: deleteFunctionReducer,
  allCalls: functionCallsReducer,
  schedules: schedulesReducer,
  response: responseReducer,
});
