import styled from 'styled-components';
import { Colors } from '@cognite/cogs.js';

export const VerticalDivider = styled.div`
  width: 2px;
  background: ${Colors['greyscale-grey3'].hex()};
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  align-self: stretch;
`;
