import React, { useEffect } from 'react';
import { Empty, Popover, notification } from 'antd';
import { Loader, XOMHeader, Card } from 'components/Common';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import {
  listAndCountInstancesForTypeId,
  listAllTypesForInstance,
  selectInstance,
  addPropertyFromType,
  selectInstanceStatus,
} from 'modules/instances';
import {
  itemSelector as typeSelector,
  CogniteTypeProperty,
} from 'modules/types';
import { Button, Colors } from '@cognite/cogs.js';
import PnIDViewer from 'containers/Exploration/PnIDViewer/PnIDViewer';
import queryString from 'query-string';
import { itemSelector } from 'modules/timeseries';
import { ResourceSidebar } from 'containers/ResourceSidebar';
import { RootState } from 'reducers';
import ScalingUseCaseRoutes from 'containers/ScalingUseCase/ScalingUseCaseRoutes';
import { AssetHoverPreview } from 'containers/HoverPreview';
import styled from 'styled-components';
import { EditProperty } from './EditProperty';

export const Wrapper = styled.div`
  overflow: auto;
  flex: 1;
  padding-left: 10px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

export const Properties = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { tenant, templateId, instanceId, propertyId, fileId } = useParams<{
    tenant: string;
    templateId: string;
    instanceId: string;
    propertyId: string;
    fileId?: string;
  }>();
  const { cdfEnv } = useSelector((state: RootState) => state.app);
  const { search } = useLocation();

  const { pnid }: { pnid?: number } = queryString.parse(search, {
    parseNumbers: true,
  });

  const templateIdNumber = Number.isNaN(Number(templateId))
    ? -1
    : Number(templateId);

  const instanceIdNumber = Number.isNaN(Number(instanceId))
    ? -1
    : Number(instanceId);

  useEffect(() => {
    if (templateIdNumber > -1) {
      dispatch(listAndCountInstancesForTypeId(templateIdNumber, true));
    }
  }, [dispatch, templateIdNumber]);

  useEffect(() => {
    if (instanceIdNumber > -1) {
      dispatch(listAllTypesForInstance(instanceIdNumber));
    }
  }, [dispatch, instanceIdNumber]);

  const type = useSelector(typeSelector)(templateIdNumber)!;
  const instance = useSelector(selectInstance)(instanceIdNumber);
  const instanceStatus = useSelector(selectInstanceStatus)(instanceIdNumber);
  const getTimeseries = useSelector(itemSelector);

  const typeWithProperties = instance?.types?.find(
    el => el.type.id === type.id && el.type.version === type.version
  );

  const properties: ({ value: any } & CogniteTypeProperty)[] =
    typeWithProperties?.properties || [];

  const property = properties.find(p => p.propertyId === propertyId);

  const renderExtraButtons = ({ timeseriesId }: { timeseriesId?: number }) => {
    if (timeseriesId) {
      const onTimeseriesClicked = () => {
        if (timeseriesId) {
          const timeseries = getTimeseries(timeseriesId);
          if (timeseries) {
            if (instance && property) {
              dispatch(
                addPropertyFromType([
                  {
                    instance,
                    type,
                    property: {
                      ...property,
                      value: { id: timeseries.id },
                    },
                  },
                ])
              );
              notification.success({
                placement: 'bottomLeft',
                message: 'Success',
                description: (
                  <div>
                    {property.name || property.propertyId} of {instance.name}{' '}
                    has been updated to {timeseries.name}
                    <Button
                      type="primary"
                      onClick={() =>
                        history.push(`/${tenant}/templates/${templateId}`)
                      }
                    >
                      Go back
                    </Button>
                  </div>
                ),
              });
            }
          }
        }
      };
      const openInsights = () => {
        if (timeseriesId) {
          window.open(
            `https://insight.${
              cdfEnv ? `${cdfEnv}.` : ''
            }cogniteapp.com/${tenant}/timeseries/${timeseriesId}`
          );
        }
      };
      return [
        <Button type="primary" onClick={onTimeseriesClicked} key="add">
          Add To Instance
        </Button>,
        <Button
          variant="outline"
          type="secondary"
          onClick={openInsights}
          key="view"
          icon="ExternalLink"
        >
          Open in ADI
        </Button>,
      ];
    }
    return [];
  };

  const renderDetails = () => {
    if (instanceIdNumber === -1) {
      return <Empty>Invalid Instance Selected.</Empty>;
    }
    if (!instance) {
      return <Loader />;
    }
    if (properties.length === 0) {
      return <Empty>No Properties Defined.</Empty>;
    }
    if (instanceStatus.error) {
      return <Empty>Something went wrong</Empty>;
    }
    if (fileId) {
      return (
        <div
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <div style={{ marginBottom: '8px' }}>
            <Button
              icon="ArrowLeft"
              type="secondary"
              onClick={() =>
                history.push(
                  `/${tenant}/templates/${templateId}/instances/${instanceId}/properties/${propertyId}`
                )
              }
            >
              BACK
            </Button>
          </div>
          <div style={{ flex: 1 }}>
            <PnIDViewer
              fileId={pnid}
              onFileClicked={newFile => {
                const currentParams: {
                  pnid?: number;
                } = queryString.parse(window.location.search, {
                  parseNumbers: true,
                });
                history.push({
                  pathname: `/${tenant}/templates/${templateId}/instances/${instanceId}/properties/${propertyId}/file`,
                  search: queryString.stringify({
                    ...currentParams,
                    pnid: newFile.id,
                    assetId: undefined,
                    fileId: newFile.id,
                    timeseriesId: undefined,
                  }),
                });
              }}
              renderResourceActions={renderExtraButtons}
            />
          </div>
        </div>
      );
    }
    if (instance && type && property) {
      return (
        <EditProperty
          onViewFile={() => {}}
          renderExtraButtons={renderExtraButtons}
          instance={instance}
          property={property}
        />
      );
    }
    return <Empty>Oops</Empty>;
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <XOMHeader
        onBackClicked={() => history.push(`/${tenant}/templates/${templateId}`)}
        breadcrumbs={ScalingUseCaseRoutes.breadcrumbs}
        path={`/${tenant}/templates/${templateId}/instances/${instanceId}/properties/${propertyId}`}
        title={
          instance && property && type ? (
            <>
              <Popover
                key="name"
                content={<AssetHoverPreview asset={instance} />}
              >
                <h4 style={{ display: 'inline-block' }}>
                  Add data to property {property?.name} for instance{' '}
                  <span
                    style={{ color: Colors.midblue.hex(), cursor: 'pointer' }}
                  >
                    {instance?.name}
                  </span>
                </h4>
              </Popover>
              <small style={{ marginLeft: '8px', textTransform: 'uppercase' }}>
                Template: {type?.name}
              </small>
            </>
          ) : (
            'Loading...'
          )
        }
      />
      <div
        style={{
          flex: 1,
          position: 'relative',
          margin: '24px',

          overflow: 'auto',
        }}
      >
        <Card
          style={{
            height: '100%',
            padding: '12px',
          }}
        >
          <Wrapper>{renderDetails()}</Wrapper>
        </Card>
        <ResourceSidebar extraButtons={renderExtraButtons} />
      </div>
    </div>
  );
};
