import React from 'react';
import { Popover, CELL_STYLE, PropertyCell, ColorDot } from 'components/Common';
import { useSelector, useDispatch } from 'react-redux';
import { CogniteType } from 'modules/types';
import {
  getPropertyFromInstance,
  removePropertyFromType,
  Instance,
} from 'modules/instances';
import { useParams, useHistory } from 'react-router-dom';
import { Icon, Colors, Button } from '@cognite/cogs.js';
import { itemSelector } from 'modules/timeseries';
import { GridCellProps } from 'react-virtualized';
import styled from 'styled-components';
import { AssetHoverPreview } from 'containers/HoverPreview';
import { InstanceTimeseriesPopover } from 'containers/ScalingUseCase/Instance/InstanceTimeseriesPopover';
import { getWorstColor } from 'utils/DataQuality';
import { selectSuggestionForPropertyAndInstance } from 'modules/templateCompletion';
import { selectInstance } from '../../../modules/instances';

const Subtitle = styled.div`
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  white-space: nowrap;
`;

const PendingAddCell = styled.div`
  cursor: pointer;
  padding: 16px;
  transition: 0.3s all;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.white.hex()};
  height: 100%;
  z-index: 1;
  cursor: pointer;

  &&:hover > div {
    opacity: 1;
  }
  && > div {
    transition: 0.3s all;
    opacity: 0;
    display: flex;
    align-items: center;
    width: 100%;
  }
  &&:hover {
    box-shadow: 0px 0px 8px ${Colors['greyscale-grey5'].hex()};
    z-index: 10;
  }
`;

export const InstancePropertyCell = ({
  cellProps: { columnIndex, key, rowIndex, style },
  type,
  onVisibleChange = () => {},
  instances,
}: {
  type: CogniteType;
  cellProps: GridCellProps;
  instances: Instance[];
  onVisibleChange?: (
    visible: boolean,
    propertyId: string,
    assetId: number,
    timeseriesId: number
  ) => void;
}) => {
  const { tenant } = useParams<{
    tenant: string;
  }>();
  const dispatch = useDispatch();
  const history = useHistory();

  const getTimeseries = useSelector(itemSelector);
  const getInstance = useSelector(selectInstance);

  const instance = getInstance(
    columnIndex !== 0 ? instances[columnIndex - 1].id : undefined
  );

  const getPropertySuggestion = useSelector(
    selectSuggestionForPropertyAndInstance
  );

  if (columnIndex === 0 && rowIndex === 0) {
    return (
      <div
        key={key}
        style={{
          ...CELL_STYLE,
          ...style,
          borderBottom: `2px solid ${Colors.white.hex()}`,
        }}
      >
        <strong>Properties</strong>
      </div>
    );
  }
  const property = (type.properties || [])[rowIndex - 1];
  if (columnIndex === 0) {
    return (
      <div
        key={key}
        style={{
          ...CELL_STYLE,
          ...style,
          borderBottom: `2px solid ${Colors.white.hex()}`,
        }}
      >
        <strong>{property.name || property.propertyId}</strong>
      </div>
    );
  }

  if (rowIndex === 0) {
    return (
      <Popover
        mouseEnterDelay={0.3}
        style={{
          cursor: 'pointer',
          ...CELL_STYLE,
          ...style,
          borderRight: `2px solid ${Colors.white.hex()}`,
        }}
        content={<AssetHoverPreview asset={instance!} />}
      >
        {instance!.name}
      </Popover>
    );
  }
  const ts = getTimeseries(
    getPropertyFromInstance(type, instance!, property.propertyId)?.id
  );
  const suggestion = getPropertySuggestion(
    type.id,
    property.propertyId,
    instance!.id
  );
  const onPropertyClicked = () => {
    history.push(
      `/${tenant}/templates/${type.id}/instances/${instance!.id}/properties/${
        property.propertyId
      }`
    );
  };
  const onClearClicked = () => {
    dispatch(removePropertyFromType(instance!, type, property.propertyId));
  };
  const onSuggestionClicked = () => {
    history.push(
      `/${tenant}/templates/${type.id}/suggestions/${property.propertyId}`
    );
  };
  if (!ts && suggestion) {
    return (
      <PendingAddCell key={key} style={{ ...CELL_STYLE, ...style }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'row',
            flexDirection: 'column',
          }}
        >
          <Button
            type="primary"
            onClick={e => {
              e.stopPropagation();
              onPropertyClicked();
            }}
            key="find-timeseries"
            icon="Timeseries"
            style={{ width: '100%' }}
          >
            Find Timeseries
          </Button>
          <Button
            type="primary"
            onClick={e => {
              e.stopPropagation();
              onSuggestionClicked();
            }}
            key="review-suggestion"
            icon="Feedback"
            style={{
              width: '100%',
              backgroundColor: Colors.purple.hex(),
              marginTop: '12px',
            }}
          >
            Review Suggestion
          </Button>
        </div>
      </PendingAddCell>
    );
  }
  if (!ts) {
    return (
      <PendingAddCell
        onClick={onPropertyClicked}
        key={key}
        style={{ ...CELL_STYLE, ...style }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'row',
            flexDirection: 'column',
          }}
        >
          <Button
            type="primary"
            onClick={e => {
              e.stopPropagation();
              onPropertyClicked();
            }}
            key="find-timeseries"
            icon="Timeseries"
            style={{ width: '100%' }}
          >
            Find Timeseries
          </Button>
        </div>
      </PendingAddCell>
    );
  }
  return (
    <Popover
      key={`${key}-${ts.id}-${property.propertyId}-${instance!.id}`}
      style={{ ...CELL_STYLE, padding: 0, ...style }}
      mouseEnterDelay={0.3}
      trigger="click"
      onVisibleChange={visible =>
        onVisibleChange(visible, property.propertyId, instance!.id, ts.id)
      }
      content={
        <InstanceTimeseriesPopover
          onPropertyClicked={onPropertyClicked}
          onClearClicked={onClearClicked}
          onSuggestionClicked={suggestion ? onSuggestionClicked : undefined}
          instance={instance!}
          property={property}
          timeseries={ts}
        />
      }
    >
      <PropertyCell style={{ display: 'flex' }}>
        <Icon type="Timeseries" style={{ marginRight: '4px' }} />
        <div style={{ flex: 1 }}>
          <p
            style={{
              color: Colors.midblue.hex(),
              fontWeight: 600,
              marginBottom: '4px',
            }}
          >
            {ts.name || 'Loading...'}
          </p>
          <Subtitle>{ts.description}</Subtitle>
          <ColorDot color={getWorstColor(ts)} style={{ marginTop: '4px' }} />
          {suggestion && (
            <Icon
              type="Feedback"
              style={{
                padding: '4px',
                borderRadius: '50%',
                background: Colors['purple-4'].hex(),
                color: Colors['purple-2'].hex(),
                display: 'inline-flex',
                height: '16px',
                width: '16px',
                marginLeft: '4px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            />
          )}
        </div>
      </PropertyCell>
    </Popover>
  );
};
