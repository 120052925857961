import React from 'react';
import { Modal as AntdModal } from 'antd';
import styled from 'styled-components';
import { Button } from '@cognite/cogs.js';

type FieldProps = {
  visible: boolean;
  children: React.ReactNode;
  okText?: string;
  cancelText?: string;
  onCancel: () => void;
  onDone: () => void;
  width?: string | number;
  loading?: boolean;
};
export const Modal = (props: FieldProps) => {
  const {
    visible,
    children,
    onCancel,
    onDone,
    loading,
    width = '80%',
    okText = 'Create',
    cancelText = 'Cancel',
  } = props;

  return (
    <AntdModal
      onCancel={onCancel}
      width={width}
      bodyStyle={{ padding: 0, maxHeight: '80vh', overflow: 'auto' }}
      footer={
        <Footer>
          <Button onClick={onCancel} variant="outline">
            {cancelText}
          </Button>
          <Button onClick={onDone} type="primary" loading={loading}>
            {okText}
          </Button>
        </Footer>
      }
      visible={visible}
    >
      {children}
    </AntdModal>
  );
};

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;
