import React, { useState, useEffect, useRef } from 'react';
import {
  ReactPictureAnnotation,
  IAnnotation,
} from '@cognite/react-picture-annotation';
import { Button, Colors } from '@cognite/cogs.js';
import styled from 'styled-components';
import { Pagination } from 'antd';
import { Loader } from 'components/Common';

const DocumentPagination = styled(Pagination)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 16px;
  && {
    background: #fff;
    border-radius: 50px;
    padding: 12px 24px;
    box-shadow: 0px 0px 8px ${Colors['greyscale-grey3'].hex()};
  }
`;

const Buttons = styled.div`
  display: inline-flex;
  position: absolute;
  z-index: 1000;
  right: 24px;
  bottom: 24px;
  && #controls {
    display: inline-flex;
  }
  && #controls > * {
    border-radius: 0px;
  }
  && #controls > *:nth-child(1) {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  && #controls > *:nth-last-child(1) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

type Props = {
  pdf?: string;
  image?: string;
  drawLabel?: boolean;
  editable?: boolean;
  hoverable?: boolean;
  annotations?: IAnnotation[];
  onSelect?: (annotation?: IAnnotation) => void;
  editCallbacks?: {
    onUpdate: (annotation: IAnnotation) => void;
    onCreate: (annotation: IAnnotation) => void;
    onDelete: (annotation: IAnnotation) => void;
  };
  renderItemPreview?: (
    editable: boolean,
    annotation: IAnnotation,
    onLabelValueUpdate: (value: string) => void,
    onDelete: () => void
  ) => React.ReactElement;
};

export const PDFViewer = ({
  pdf,
  image,
  annotations,
  drawLabel = true,
  hoverable = true,
  onSelect,
  editCallbacks,
  renderItemPreview,
  editable,
}: Props) => {
  const [realAnnotations, setRealAnnotations] = useState<IAnnotation[]>(
    annotations || ([] as IAnnotation[])
  );

  const annotatorRef = useRef<ReactPictureAnnotation>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(!!(pdf || !image));

  useEffect(() => {
    if (annotations) {
      setRealAnnotations(annotations);
    }
  }, [annotations]);

  useEffect(() => {
    if (pdf) {
      setLoading(true);
    }
  }, [pdf]);
  useEffect(() => {
    if (!image) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [image]);

  useEffect(() => {
    if (wrapperRef.current) {
      // change width from the state object
      setHeight(wrapperRef.current!.clientHeight);
      setWidth(wrapperRef.current!.clientWidth);
    }
  }, [wrapperRef]);

  useEffect(() => {
    const resizeListener = () => {
      if (wrapperRef.current) {
        // change width from the state object
        setHeight(wrapperRef.current!.clientHeight);
        setWidth(wrapperRef.current!.clientWidth);
      }
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const onAnnotationSelect = (id: string | null) => {
    if (!onSelect) {
      return;
    }
    if (id === null) {
      onSelect(undefined);
    }
    const annotation = realAnnotations.find(el => el.id === id);
    if (annotation) {
      onSelect(annotation);
    }
  };

  return (
    <div
      ref={wrapperRef}
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      {loading && (
        <div style={{ position: 'absolute', height: '100%', width: '100%' }}>
          <Loader />
        </div>
      )}
      <ReactPictureAnnotation
        ref={annotatorRef}
        drawLabel={drawLabel}
        hoverable={hoverable}
        annotationData={realAnnotations}
        onChange={setRealAnnotations}
        onSelect={onAnnotationSelect}
        onAnnotationCreate={editCallbacks && editCallbacks.onCreate}
        onAnnotationDelete={editCallbacks && editCallbacks.onDelete}
        onAnnotationUpdate={editCallbacks && editCallbacks.onUpdate}
        pdf={pdf}
        image={image}
        editable={editable}
        width={width}
        height={height}
        page={page}
        renderItemPreview={renderItemPreview}
        onPDFLoaded={({ pages }) => {
          setLoading(false);
          setTotalPages(pages);
        }}
      />
      {totalPages > 1 && (
        <DocumentPagination
          total={totalPages}
          current={page + 1}
          pageSize={1}
          size="small"
          showQuickJumper
          onChange={newPageNum => setPage(newPageNum - 1)}
        />
      )}
      <Buttons>
        <div id="controls">
          <Button
            onClick={() => {
              if (annotatorRef.current) {
                annotatorRef.current.zoomIn();
              }
            }}
            icon="ZoomIn"
          />
          <Button
            icon="Refresh"
            onClick={() => {
              if (annotatorRef.current) {
                annotatorRef.current.reset();
              }
            }}
          />
          <Button
            icon="ZoomOut"
            onClick={() => {
              if (annotatorRef.current) {
                annotatorRef.current.zoomOut();
              }
            }}
          />
        </div>
      </Buttons>
    </div>
  );
};
