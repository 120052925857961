import React from 'react';
import { CogniteType } from 'modules/types';
import { countFilledInProperties, Instance } from 'modules/instances';
import { CountLabel } from 'components/Common';
import { Colors } from '@cognite/cogs.js';

export const PropertyCountLabel = ({
  instance,
  type,
}: {
  instance: Instance;
  type: CogniteType;
}) => {
  const { count, total, invalid } = countFilledInProperties(instance, type);

  const chooseColor = () => {
    if (count === total) {
      return Colors.white.hex();
    }
    return Colors.black.hex();
  };
  const chooseBackgroundColor = () => {
    if (count === total) {
      return Colors.success.hex();
    }
    return Colors.warning.hex();
  };
  if (invalid) {
    return (
      <CountLabel
        value="Invalid"
        backgroundColor={Colors['greyscale-grey6'].hex()}
      />
    );
  }

  return (
    <CountLabel
      value={`${count} / ${total}`}
      backgroundColor={chooseBackgroundColor()}
      color={chooseColor()}
    />
  );
};
