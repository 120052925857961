import React from 'react';
import {
  Popover,
  MetadataFilterForm,
  MetadataFilterFormProps,
} from 'components/Common';
import { Button } from '@cognite/cogs.js';

export const MetadataFilterDropdown = ({
  metadata,
  filters = {},
  setFilters,
}: MetadataFilterFormProps) => {
  const numKeys = Object.keys(filters).length;
  return (
    <Popover
      trigger="click"
      placement="bottom"
      maxWidth="none"
      content={
        <MetadataFilterForm
          metadata={metadata}
          filters={filters}
          setFilters={setFilters}
        />
      }
    >
      <Button type={numKeys > 0 ? 'primary' : 'secondary'} icon="Settings">
        Filters {numKeys > 0 ? `(${numKeys})` : ''}
      </Button>
    </Popover>
  );
};
