import React from 'react';
import { Progress, Typography } from 'antd';
import { dataKitCountSelector } from 'modules/selection';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { generateItemCountSelector } from 'modules/sdk-builder/list';

const { Text } = Typography;

type Props = {
  id: string;
};
export default function DataKitLoadingProgress(props: Props) {
  const { id } = props;
  const { type, query } = useSelector(
    (state: RootState) => state.selection.items[id]
  );

  const itemCount = useSelector(generateItemCountSelector(type, query, true));
  const { count } = useSelector(dataKitCountSelector)(id);

  const percent: number = count ? Math.floor((itemCount / count) * 100) : 0;

  return (
    <>
      <Text strong>Loading {type}</Text>
      <br />
      <Text type="secondary">{itemCount}</Text>
      <Progress
        percent={percent}
        status={percent === 100 ? 'success' : 'active'}
      />
    </>
  );
}
