import React, { MouseEvent } from 'react';
import styled from 'styled-components';

import { Icons } from '@cognite/cogs.js';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Tooltip from 'antd/lib/tooltip';

import { Section, Item } from 'components/Navigation';
import MenuSection from 'components/Navigation/Menu/Section';
import MenuItem from 'components/Navigation/Menu/Item';
import theme from 'styles/theme';

interface MenuProps {
  visible: boolean;
  onClose(destination: string): void;
  sections: Section[];
  height: number;
}

interface MenuMaskProps {
  height: number;
  visible: boolean;
}

const MenuMask = styled.div<MenuMaskProps>`
  position: fixed;
  top: ${props => props.height}px;
  z-index: 10;
  width: 100%;
  height: calc(100vh - ${props => props.height}px);
  background-color: rgba(0, 0, 0, 0.2);
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: visibility 0s linear ${props => (props.visible ? '0s' : '0.3s')},
    opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

interface StyledMenuProps {
  height: number;
  visible: boolean;
}

const StyledMenu = styled.div<StyledMenuProps>`
  position: fixed;
  top: ${props => props.height}px;
  width: 100%;
  max-height: calc(100vh - ${props => props.height}px);
  opacity: ${props => (props.visible ? 1 : 0)};
  background-color: #ffffff;
  box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transform: ${props => (props.visible ? 'scaleY(1)' : 'scaleY(0.8)')};
  transform-origin: 0% 0%;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    visibility 0s linear ${props => (props.visible ? '0s' : '0.3s')};
`;

interface ContentProps {
  height: number;
}

const Content = styled.div<ContentProps>`
  padding: 12px 48px 56px 48px;
  max-height: calc(100vh - ${props => props.height}px);
  overflow-y: auto;
`;

const CloseButton = styled.div`
  position: absolute;
  width: 48px;
  height: 48px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 12px;
  right: 48px;
  cursor: pointer;
  border-radius: 4px;
  background-color: ${theme.navigationButton};
  &:hover {
    background-color: ${theme.navigationButtonHover};
  }
`;

const Menu = (props: MenuProps) => {
  const { visible, onClose, sections, height } = props;

  const renderItem = (item: Item) => {
    return <MenuItem item={item} onClose={onClose} key={item.title} />;
  };

  const renderSection = (section: Section) => {
    return (
      <MenuSection section={section} onClose={onClose} key={section.title}>
        {section.items.map((item: Item) => renderItem(item))}
      </MenuSection>
    );
  };

  const onCloseButtonClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onClose('close-button');
  };

  return (
    <MenuMask onClick={() => onClose('mask')} visible={visible} height={height}>
      <StyledMenu
        height={height}
        visible={visible}
        onClick={e => e.stopPropagation()}
      >
        <Content height={height}>
          <Row type="flex">
            {sections.map((section: Section) => (
              <Col xs={24} sm={12} md={12} lg={6} key={section.title}>
                {renderSection(section)}
              </Col>
            ))}
          </Row>
          <Tooltip title="Close" placement="bottom">
            <CloseButton onClick={onCloseButtonClick}>
              <Icons.Close />
            </CloseButton>
          </Tooltip>
        </Content>
      </StyledMenu>
    </MenuMask>
  );
};

export default Menu;
