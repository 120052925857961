import { ThunkDispatch } from 'redux-thunk';
import { Action, combineReducers } from 'redux';
import { sdk } from 'utils/SDK';
import { callUntilCompleted } from 'helpers/Helpers';
import { itemSelector } from 'modules/types';
import { RootState } from 'reducers';
import { createSelector } from 'reselect';

export type TemplateCompletitionItem = {
  column: string;
  items: {
    assetName: string;
    assetId: number;
    currentId?: number;
    currentName?: string;
    foundId?: number;
    foundIds?: number[];
    foundName?: string;
    matchType?: string;
  }[];
  patterns: { currentMatches: number; pattern: string }[];
};

const SET_COMPLETION_STARTED = 'templateCompletion/SET_COMPLETION_STARTED';
const SET_COMPLETION_RESULT = 'templateCompletion/SET_COMPLETION_RESULT';

interface SetCompletionStartedAction
  extends Action<typeof SET_COMPLETION_STARTED> {
  id: number;
}

interface SetCompletionResultAction
  extends Action<typeof SET_COMPLETION_RESULT> {
  id: number;
  results: TemplateCompletitionItem[];
}

export const loadCompletionModel = (
  typeId: number,
  forceRefresh = false
) => async (
  dispatch: ThunkDispatch<
    any,
    any,
    SetCompletionResultAction | SetCompletionStartedAction
  >,
  getState: () => RootState
) => {
  try {
    const type = itemSelector(getState())(typeId);
    const completionStatus = selectSuggestion(getState())(typeId);
    if (type && (!completionStatus || forceRefresh)) {
      dispatch({ type: SET_COMPLETION_STARTED, id: type.id });
      const {
        data: { jobId: id },
      } = await sdk.post(
        `/api/playground/projects/${sdk.project}/context/schemas/complete`,
        {
          data: {
            externalId: type.externalId,
          },
        }
      );
      const items = await new Promise<TemplateCompletitionItem[]>(resolve => {
        return callUntilCompleted(
          () =>
            sdk.get(
              `/api/playground/projects/${sdk.project}/context/schemas/${id}`
            ),
          data => data.status === 'Completed' || data.status === 'Failed',
          async data => {
            try {
              if (data.status === 'Completed') {
                return resolve(
                  data.properties
                    ? Object.keys(data.properties)
                        .filter(key => !!data.properties[key])
                        .reduce((prev, key) => {
                          prev.push({ column: key, ...data.properties[key] });
                          return prev;
                        }, [] as TemplateCompletitionItem[])
                    : ([] as TemplateCompletitionItem[])
                );
              }
            } catch {
              return resolve([] as TemplateCompletitionItem[]);
            }
            return resolve([] as TemplateCompletitionItem[]);
          },
          undefined,
          undefined
        );
      });

      dispatch({ type: SET_COMPLETION_RESULT, id: type.id, results: items });
    }
  } catch {
    // noop
  }
};

type CompletionResultsState = {
  [key: number]: { fetching: boolean; items: TemplateCompletitionItem[] };
};

const completionReducer = (
  state: CompletionResultsState = {},
  action: SetCompletionResultAction | SetCompletionStartedAction
): CompletionResultsState => {
  switch (action.type) {
    case SET_COMPLETION_STARTED: {
      return { ...state, [action.id]: { fetching: true, items: [] } };
    }
    case SET_COMPLETION_RESULT: {
      return {
        ...state,
        [action.id]: { fetching: false, items: action.results },
      };
    }
    default:
      return state;
  }
};

export const reducer = combineReducers({
  results: completionReducer,
});

export default reducer;

export const selectSuggestion = createSelector(
  (state: RootState) => state.templateCompletion.results,
  suggestionMap => (id: number) =>
    suggestionMap[id] && {
      ...suggestionMap[id],
      items: suggestionMap[id].items.map(el => ({
        ...el,
        items: el.items.filter(
          match =>
            (match.currentId !== match.foundId && match.foundId) ||
            (match.foundIds || []).length > 0
        ),
      })),
    }
);
export const selectSuggestionFetching = createSelector(
  (state: RootState) => state.templateCompletion.results,
  suggestionMap => (id: number) =>
    suggestionMap[id] ? suggestionMap[id].fetching : false
);

export const selectSuggestionCount = createSelector(
  selectSuggestion,
  getSuggestion => (id: number) => {
    const suggestion = getSuggestion(id);
    return (suggestion ? suggestion.items : []).reduce(
      (prev, el) => prev + el.items.length,
      0
    );
  }
);

export const selectSuggestionForPropertyAndInstance = createSelector(
  selectSuggestion,
  getSelection => (id: number, propertyId: string, instanceId: number) => {
    const suggestion = getSelection(id);
    if (suggestion) {
      const propertySuggestion = (suggestion.items || []).find(
        el => el.column === propertyId
      );
      if (propertySuggestion) {
        const instanceSuggestion = propertySuggestion.items.find(
          el => el.assetId === instanceId
        );
        return instanceSuggestion;
      }
    }
    return undefined;
  }
);
