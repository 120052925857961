import React, { useState } from 'react';
import { Drawer, List } from 'antd';
import {
  CoolH1,
  Header,
  ListItem,
  InstanceListItem,
  CountLabel,
  Input,
} from 'components/Common';
import { Button, Colors } from '@cognite/cogs.js';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { selectInstancesUnderTypeByRootAsset } from 'modules/instances';
import { itemSelector } from 'modules/types';

export const ViewInstancesSidebar = ({
  typeId,
  visible,
  onClose,
  onAddClicked,
}: {
  typeId: number;
  visible: boolean;
  onAddClicked: () => void;
  onClose: () => void;
}) => {
  const history = useHistory();
  const { tenant } = useParams<{ tenant: string }>();
  const [query, setQuery] = useState<string>('');

  const instances = useSelector(selectInstancesUnderTypeByRootAsset)(
    typeId,
    true
  );
  const type = useSelector(itemSelector)(typeId);

  return (
    <Drawer
      title="Instances of Well template (completion)"
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      width={1080}
    >
      <Header
        title={<CoolH1>Instances</CoolH1>}
        extra={[
          <Button key="add" type="primary" onClick={onAddClicked}>
            Create Instance
          </Button>,
        ]}
        style={{ marginBottom: '16px' }}
      />
      <Input
        placeholder="Search for an instance... "
        value={query}
        onChange={setQuery}
      />
      {Object.keys(instances)
        .map(el => Number(el))
        .map(assetId => {
          const { asset, items: preFilterItems } = instances[assetId];

          const items = preFilterItems.filter(
            el =>
              query.length === 0 ||
              el.name.toLowerCase().includes(query.toLowerCase())
          );

          return (
            <div key={assetId}>
              <ListItem
                style={{
                  backgroundColor: Colors['greyscale-grey2'].hex(),
                  marginTop: '24px',
                  marginBottom: '16px',
                  color: Colors.midblue.hex(),
                  cursor: 'default',
                }}
                title={asset ? asset.name : 'Loading...'}
              >
                <CountLabel value={items.length} />
              </ListItem>
              <List
                dataSource={items}
                rowKey="id"
                renderItem={item => (
                  <InstanceListItem
                    type={type!}
                    instance={item}
                    style={{
                      backgroundColor: Colors['greyscale-grey2'].hex(),
                      marginBottom: '2px',
                    }}
                    onClick={() => {
                      history.push(
                        `/${tenant}/templates/${typeId}/instances/${item.id}`
                      );
                    }}
                  />
                )}
              />
            </div>
          );
        })}
    </Drawer>
  );
};
