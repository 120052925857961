import producer from 'immer';
import { createSelector } from 'reselect';
import { chunk } from 'lodash';
import { Action, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { TimeSeriesUpdateById, CogniteInternalId } from '@cognite/sdk';

import {
  callUntilCompleted,
  mergeItems,
  boundedParallelRequests,
} from 'helpers/Helpers';
import { RootState } from 'reducers';
import { dataKitItemsSelector } from 'modules/selection';
import { update as updateTimeseries } from 'modules/timeseries';
import { update as updateSequence } from 'modules/sequences';
import { update as updateFiles } from 'modules/files';
import { update as updateEvents } from 'modules/events';

import { ResourceType } from 'modules/sdk-builder/types';
import {
  ModelState,
  ModelStatus,
  modelDefaultState,
  apiRootPath,
} from './models';

function getUpdateFn(type: ResourceType) {
  switch (type) {
    case 'timeseries':
      return updateTimeseries;
    case 'sequences':
      return updateSequence;
    case 'files':
      return updateFiles;
    case 'events':
      return updateEvents;
    default:
      throw new Error('Unsupported type');
  }
}

function supportsMultipleAssetIds(type: ResourceType) {
  return type === 'files' || type === 'events';
}

const PREDICT_CREATE_STARTED = 'contextualization/PREDICT_CREATE_STARTED';
const PREDICT_CREATED = 'contextualization/PREDICT_CREATED';
const PREDICT_STATUS_UPDATED = 'contextualization/PREDICT_STATUS_UPDATED';
const PREDICT_DONE = 'contextualization/PREDICT_DONE';
const PREDICT_ERROR = 'contextualization/PREDICT_ERROR';
const PREDICT_RESET = 'contextualization/PREDICT_RESET';

const WRITE_STAGED_CHANGES = 'contextualization/WRITE_STAGED_CHANGES';
const WRITE_STAGED_CHANGES_DONE = 'contextualization/WRITE_STAGED_CHANGES_DONE';
const WRITE_STAGED_CHANGES_ERROR =
  'contextualization/WRITE_STAGED_CHANGES_ERROR';

const STAGE_CHANGES = 'contextualization/STAGE_CHANGES';
const CLEAR_STAGED_CHANGES = 'contextualization/CLEAR_STAGED_CHANGES';
const DISMISS_STAGED_CHANGES = 'contextualization/DISMISS_STAGED_CHANGES';

interface WriteStagedChanges extends Action<typeof WRITE_STAGED_CHANGES> {
  dataKitId: string;
}
interface WriteStagedChangesDone
  extends Action<typeof WRITE_STAGED_CHANGES_DONE> {
  dataKitId: string;
}
interface WriteStagedChangesError
  extends Action<typeof WRITE_STAGED_CHANGES_ERROR> {
  dataKitId: string;
}
type WriteActions =
  | WriteStagedChanges
  | WriteStagedChangesDone
  | WriteStagedChangesError;

interface CreatePredictionStartedAction
  extends Action<typeof PREDICT_CREATE_STARTED> {
  dataKitId: string;
}
interface PredictionCreatedAction extends Action<typeof PREDICT_CREATED> {
  dataKitId: string;
  jobId: number;
}
interface PredictionStatusUpdatedAction
  extends Action<typeof PREDICT_STATUS_UPDATED> {
  dataKitId: string;
  jobId: number;
  status: ModelStatus;
}
interface PredictionDoneAction extends Action<typeof PREDICT_DONE> {
  dataKitId: string;
  predictions: Prediction[];
}
interface PredictionErrorAction extends Action<typeof PREDICT_ERROR> {
  dataKitId: string;
}
interface PredictResetAction extends Action<typeof PREDICT_RESET> {
  dataKitId: string;
}

type PredictionActions =
  | CreatePredictionStartedAction
  | PredictionCreatedAction
  | PredictionStatusUpdatedAction
  | PredictionDoneAction
  | PredictionErrorAction
  | PredictResetAction;

interface StageChanges extends Action<typeof STAGE_CHANGES> {
  changes: Change[];
  dataKitId: string;
}

interface ClearStagedChanges extends Action<typeof CLEAR_STAGED_CHANGES> {
  dataKitId: string;
}
interface DismissStagedChanges extends Action<typeof DISMISS_STAGED_CHANGES> {
  changes: Change[];
  dataKitId: string;
}

const createPredictionPath = (project: string, modelId: number) =>
  `${apiRootPath(project)}/${modelId}/predictml`;
const getPredictionStatusPath = (
  project: string,
  modelId: number,
  jobId: number
) => `${apiRootPath(project)}/${modelId}/predictml/${jobId}`;

export function predict(modelId: number, dataKitId: string) {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState: () => RootState
  ) => {
    const {
      app: { sdk },
    } = getState();
    dispatch({ type: PREDICT_CREATE_STARTED, dataKitId });

    return new Promise((resolve, reject) => {
      sdk
        .post(createPredictionPath(sdk.project, modelId), {
          data: {},
        })
        .then(response => {
          const {
            status: httpStatus,
            data: { jobId, status: queueStatus },
          } = response;

          dispatch({
            type: PREDICT_CREATED,
            jobId,
            dataKitId,
          });
          dispatch({
            type: PREDICT_STATUS_UPDATED,
            jobId,
            status: queueStatus,
            dataKitId,
          });

          if (httpStatus === 200) {
            callUntilCompleted(
              () =>
                sdk.get(getPredictionStatusPath(sdk.project, modelId, jobId)),
              data => data.status === 'Completed' || data.status === 'Failed',
              data => {
                dispatch({
                  type: PREDICT_DONE,
                  predictions: data.items.map((prediction: any) => {
                    // TODO this should be adjusted since predictml output no longer correlates to the rules input
                    return {
                      ...prediction,
                      score: prediction.score || 0,
                      predictedId: prediction.matches[0].matchTo.id, // TODO is that right?
                    };
                  }),
                  dataKitId,
                });
                resolve(jobId);
              },
              data =>
                dispatch({
                  type: PREDICT_STATUS_UPDATED,
                  status: data.status,
                  dataKitId,
                }),
              () => {
                dispatch({
                  type: PREDICT_STATUS_UPDATED,
                  status: 'Failed',
                  dataKitId,
                });
                dispatch({ type: PREDICT_ERROR, dataKitId });
                reject();
              }
            );
          } else {
            dispatch({ type: PREDICT_ERROR, dataKitId });
            reject();
          }
        })
        .catch(() => {
          dispatch({ type: PREDICT_ERROR, dataKitId });
          reject();
        });
    });
  };
}

export function ensurePrediction(
  modelId: string,
  predictId: string,
  dataKitId: string,
  trainingData: ItemWithNameAndId[],
  predictData: ItemWithNameAndId[]
) {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState: () => RootState
  ) => {
    const {
      app: { sdk },
      contextualization: { predictions },
    } = getState();

    if (predictions[dataKitId] && predictions[dataKitId].done) {
      return;
    }

    dispatch({ type: PREDICT_CREATE_STARTED, dataKitId });
    try {
      const { data } = await sdk.get(
        getPredictionStatusPath(
          sdk.project,
          parseInt(modelId, 10),
          parseInt(predictId, 10)
        )
      );

      const assetsByName = mergeItems(trainingData, {}, 'name');

      dispatch({
        type: PREDICT_DONE,
        predictions: data.items.map((p: ApiPrediction, index: number) => {
          // prediction.predicted can be null
          return {
            ...p,
            score: p.score || 0,
            inputId: predictData[index].id,
            predictedId: assetsByName[p.predictedId || ''],
          };
        }),
        dataKitId,
      });

      dispatch({
        type: PREDICT_STATUS_UPDATED,
        modelId,
        status: data.status,
        dataKitId,
      });
      if (data.status === 'Failed') {
        dispatch({
          type: PREDICT_ERROR,
          dataKitId,
        });
      }
    } catch (e) {
      dispatch({
        type: PREDICT_ERROR,
        dataKitId,
      });
      throw e;
    }
  };
}

export function stageChange(change: Change, dataKitId: string) {
  return {
    type: STAGE_CHANGES,
    changes: [change],
    dataKitId,
  };
}

export function stageChanges(changes: Change[], dataKitId: string) {
  return {
    type: STAGE_CHANGES,
    changes,
    dataKitId,
  };
}

export function writeStagedChanges(dataKitId: string) {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState: () => RootState
  ) => {
    const state = getState();
    const { type } = state.selection.items[dataKitId];

    const multipleAssetIDs = supportsMultipleAssetIds(type);

    const changes: TimeSeriesUpdateById[] = state.contextualization.predictions[
      dataKitId
    ].stagedChanges.map((change: Change) => ({
      id: change.resourceId,
      update: multipleAssetIDs
        ? { assetIds: { set: [change.assetId] } }
        : { assetId: { set: change.assetId } },
    }));

    const update = getUpdateFn(type);

    const batchSize = 100;
    const updateBatches = chunk(changes, batchSize);
    dispatch({ type: WRITE_STAGED_CHANGES, dataKitId });
    try {
      await boundedParallelRequests(
        batch => dispatch(update(batch)),
        updateBatches,
        10
      );
      dispatch({ type: WRITE_STAGED_CHANGES_DONE, dataKitId });
    } catch (e) {
      dispatch({ type: WRITE_STAGED_CHANGES_ERROR, dataKitId });
    }
  };
}

export function dismissChanges(dataKitId: string, changes: ChangeInfo[]) {
  return {
    type: DISMISS_STAGED_CHANGES,
    changes,
    dataKitId,
  };
}

export function resetPredictions(dataKitId: string) {
  return {
    type: PREDICT_RESET,
    dataKitId,
  };
}

export interface Resource {
  id: CogniteInternalId;
  name?: string;
  description?: string;
  assetId?: CogniteInternalId;
}
export interface ItemWithNameAndId {
  id: number;
  name: string;
}
export interface ApiPrediction {
  matchFrom: any; // TODO less generic
  matches: Array<any>;
  predictedId: number;
  score: number;
}
export interface Prediction extends ApiPrediction {}

// This will have to be more general then timeseries => assets at some point
export interface Change {
  resourceId: number;
  assetId: number;
  rule: string;
}

// All of these could be empty strings
export interface ChangeInfo extends Change {
  assetName: string;
  assetDescription: string;
  resourceName: string;
  resourceDescription: string;
  rule: string;
}

export interface PredictionState extends ModelState {
  predictions: Prediction[];

  stagedChanges: Change[];
  writingChanges: boolean;
  writeError: boolean;
}

export const predictionDefaultState: PredictionState = Object.freeze({
  ...modelDefaultState,
  predictions: [],
  stagedChanges: [],
  writingChanges: false,
  writeError: false,
});

export interface Rule {
  inputPattern: string;
  predictPattern: string;
  avgScore: number;
  matches: Prediction[];
}

interface PredictStore {
  [dataKitId: string]: PredictionState;
}

type Actions =
  | PredictionActions
  | StageChanges
  | ClearStagedChanges
  | DismissStagedChanges
  | WriteActions;

export default function reducer(
  state: PredictStore = {},
  action: Actions
): PredictStore {
  return producer(state, draft => {
    const id = action.dataKitId || -1;
    switch (action.type) {
      case PREDICT_CREATE_STARTED: {
        draft[id] = { ...predictionDefaultState };
        draft[id].started = true;
        break;
      }

      case PREDICT_CREATED: {
        draft[id].id = action.jobId;
        break;
      }

      case PREDICT_STATUS_UPDATED: {
        draft[id].status = action.status;
        break;
      }

      case PREDICT_DONE: {
        draft[id].done = true;
        draft[id].error = false;
        draft[id].predictions = action.predictions;
        break;
      }

      case PREDICT_ERROR: {
        draft[id].error = true;
        draft[id].status = 'Failed';
        break;
      }

      case PREDICT_RESET: {
        draft[id] = predictionDefaultState;
        break;
      }

      case WRITE_STAGED_CHANGES: {
        if (!draft[id]) {
          draft[id] = { ...predictionDefaultState };
        }
        draft[id].writingChanges = true;
        break;
      }

      case WRITE_STAGED_CHANGES_DONE: {
        draft[id].stagedChanges = [];
        draft[id].writingChanges = false;
        draft[id].writeError = false;
        break;
      }

      case WRITE_STAGED_CHANGES_ERROR: {
        draft[id].writingChanges = false;
        draft[id].writeError = true;
        break;
      }

      case STAGE_CHANGES: {
        if (!draft[id].stagedChanges) {
          draft[id].stagedChanges = [];
        }
        const changes = draft[id].stagedChanges;

        action.changes.forEach(change => {
          const preExistingChange = changes.findIndex(
            c => c.resourceId === change.resourceId
          );
          if (preExistingChange === -1) {
            changes.push(change);
          } else {
            changes[preExistingChange] = change;
          }

          draft[id].stagedChanges = changes;
        });
        break;
      }

      case DISMISS_STAGED_CHANGES: {
        action.changes.forEach(change => {
          draft[id].stagedChanges = draft[id].stagedChanges.filter(
            c =>
              !(
                c.resourceId === change.resourceId &&
                c.assetId === change.assetId
              )
          );
        });
        break;
      }

      case CLEAR_STAGED_CHANGES: {
        draft[id].stagedChanges = [];
        break;
      }
    }
  });
}

export const getPredictionStateSelector = createSelector(
  (state: RootState) => state.contextualization.predictions,
  predictions => (id: string) => predictions[id] || predictionDefaultState
);

export const getStagedChanges = createSelector(
  dataKitItemsSelector,
  getPredictionStateSelector,
  (getDataKitResurce, getPrediction) => (
    resourceDKId: string,
    assetDKId: string
  ) => {
    const { stagedChanges } = getPrediction(resourceDKId);
    let { items: assets } = getDataKitResurce(assetDKId, true);
    let { items: resources } = getDataKitResurce(resourceDKId, true);
    // @ts-ignore
    assets = assets.reduce((accl, i) => {
      accl[i.id] = i;
      return accl;
    }, {});
    // @ts-ignore
    resources = resources.reduce((accl, i) => {
      accl[i.id] = i;
      return accl;
    }, {});
    return stagedChanges.map(c => {
      const asset = assets[c.assetId];
      const resource = resources[c.resourceId];
      return {
        ...c,
        assetName: asset?.name,
        assetDescription: asset?.description,
        resourceName: resource?.name,
        resourceDescription: resource?.description,
      };
    }) as ChangeInfo[];
  }
);

export const getStagedChangesByIdSelector = createSelector(
  getStagedChanges,
  getChanges => (resourceId: string, assetId: string) => {
    const stagedChanges = getChanges(resourceId, assetId);
    return mergeItems(stagedChanges, {}, 'resourceId');
  }
);

export const getPredictionsSelector = createSelector(
  dataKitItemsSelector,
  getPredictionStateSelector,
  getStagedChangesByIdSelector,
  (dataKitSelector, getState, getStagedChangesById) => (
    resourceId: string,
    assetId: string
  ) => {
    const { items: resources } = dataKitSelector(resourceId);
    // @ts-ignore
    resources.reduce((accl, i) => {
      accl[i.id] = i;
      return accl;
    }, {});
    const changeById = getStagedChangesById(resourceId, assetId);
    const { predictions } = getState(resourceId);

    return predictions
      .filter(p => !changeById[p.matchFrom?.id])
      .map(p => ({ ...p, resource: resources[p.matchFrom?.id] }))
      .sort((a, b) => b.score - a.score);
  }
);
