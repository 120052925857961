import React, { useState, useEffect, useRef } from 'react';
import { message } from 'antd';
import styled from 'styled-components';
import { PnIDAnnotation } from 'utils/PnIDApi';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Icon, Colors, Dropdown, Menu } from '@cognite/cogs.js';
import {
  retrieve as assetRetrieve,
  retrieveExternal as assetExternalRetrieve,
  itemSelector as assetItemSelector,
} from 'modules/assets';
import {
  itemSelector as fileItemSelector,
  retrieve as fileRetrieve,
  retrieveExternal as fileExternalRetrieve,
} from 'modules/files';
import { listFilesLinkedToAsset } from 'modules/annotations';
import {
  AssetHoverPreview,
  FileHoverPreview,
  RenderResourceActionsFunction,
} from 'containers/HoverPreview';
import { onResourceSelected } from 'modules/app';
import { FilesMetadata, Asset } from '@cognite/sdk';
import { SmallTitle } from 'components/Common';
import { useHistory } from 'react-router-dom';
import { ProposedPnIDAnnotation } from './AnnotatedPnIDPreview';
import { selectAnnotationColor } from './AnnotatedPnIDOverview';
import { AnnotatedPnIDItemEditorSelect } from './AnnotatedPnIDItemEditorSelect';

const WrappedPopover = styled.div<{ minHeight?: number }>`
  position: relative;
  width: 340px;
  max-height: inherit;
  min-height: ${props => props.minHeight}px;
  overflow: auto;
  background: #fff;
  box-shadow: 0px 0px 4px #cdcdcd;
  overflow: auto;
  && > div {
    min-height: 300px;
  }

  .button-row {
    margin-top: 16px;
  }
`;

const Indicator = styled.div`
  padding-right: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;

  && > div {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 4px;
  }
`;

type AnnotatedPnIDItemEditorProps = {
  annotation: ProposedPnIDAnnotation | PnIDAnnotation;
  editable: boolean;
  onUpdateDetection: (
    annotation: ProposedPnIDAnnotation | PnIDAnnotation
  ) => void;
  onDeleteDetection: () => void;
  onFileClicked?: (file: FilesMetadata) => void;
  onAssetClicked?: (asset: Asset) => void;
  renderResourceActions?: RenderResourceActionsFunction;
};

export const AnnotatedPnIDItemEditor = ({
  annotation,
  editable,
  onUpdateDetection,
  onDeleteDetection,
  onFileClicked,
  onAssetClicked,
  renderResourceActions = () => [],
}: AnnotatedPnIDItemEditorProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const wrapper = useRef<HTMLDivElement>(null);

  const getAsset = useSelector(assetItemSelector);
  const getFile = useSelector(fileItemSelector);

  const [label, setLabel] = useState(annotation ? annotation.label : undefined);
  const [assetId, setAssetId] = useState(
    annotation ? annotation.linkedAssetId : undefined
  );
  const [fileId, setFileId] = useState(
    annotation ? annotation.linkedFileId : undefined
  );
  const [minHeight, setMinHeight] = useState(0);
  const currentAsset = assetId ? getAsset(assetId) : undefined;
  const currentFile = fileId ? getFile(fileId) : undefined;
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(editable);

  useEffect(() => {
    if (wrapper && wrapper.current) {
      const newMin = Math.floor(wrapper.current.clientHeight);
      if (newMin > minHeight) {
        setMinHeight(newMin);
      }
    }
  }, [wrapper, minHeight]);

  useEffect(() => setIsEditing(editable), [editable]);

  useEffect(() => {
    setLabel(annotation.label);
  }, [annotation]);

  useEffect(() => {
    const asset = getAsset(
      annotation.linkedAssetExternalId || annotation.linkedAssetId
    );
    if (asset) {
      setAssetId(asset.id);
    } else {
      setAssetId(undefined);
    }
    const file = getFile(
      annotation.linkedFileExternalId || annotation.linkedFileId
    );
    if (file) {
      setFileId(file.id);
    } else {
      setFileId(undefined);
    }
  }, [annotation, getAsset, getFile]);

  useEffect(() => {
    if (annotation.linkedAssetId || annotation.linkedAssetExternalId) {
      if (annotation.linkedAssetExternalId) {
        dispatch(
          assetExternalRetrieve([
            { externalId: annotation.linkedAssetExternalId! },
          ])
        );
      } else {
        dispatch(assetRetrieve([{ id: annotation.linkedAssetId! }]));
      }
    }
    if (annotation.linkedFileId || annotation.linkedFileExternalId) {
      if (annotation.linkedFileExternalId) {
        dispatch(
          fileExternalRetrieve([
            { externalId: annotation.linkedFileExternalId! },
          ])
        );
      } else {
        dispatch(fileRetrieve([{ id: annotation.linkedFileId! }]));
      }
    }
  }, [annotation, dispatch]);

  useEffect(() => {
    if (currentAsset !== undefined) {
      dispatch(listFilesLinkedToAsset(currentAsset.id));
    }
  }, [currentAsset, dispatch]);

  const isNewDetection = Number.isNaN(Number(annotation.id));

  const actions = (
    <Menu>
      <Menu.Item
        key="edit"
        onClick={() => setIsEditing(true)}
        appendIcon="Edit"
      >
        Edit
      </Menu.Item>
      <Menu.Item
        key="delete"
        onClick={() => onDeleteDetection()}
        appendIcon="Delete"
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  let content = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '200px',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <p>
          <strong>{annotation.label}</strong>
        </p>
        {(assetId || fileId) && (
          <Icon
            type="Loading"
            style={{ width: '24px', color: Colors['greyscale-grey6'].hex() }}
          />
        )}
      </div>
    </div>
  );
  if (isEditing) {
    content = (
      <div style={{ padding: '12px' }}>
        <SmallTitle>Choose which asset or file to link to</SmallTitle>
        <Indicator>
          <div
            style={{
              backgroundColor: selectAnnotationColor(annotation),
            }}
          />
          {annotation.type}
        </Indicator>
        <AnnotatedPnIDItemEditorSelect
          fileId={fileId}
          assetId={assetId}
          label={label}
          setFileId={setFileId}
          setAssetId={setAssetId}
          setLabel={setLabel}
        />
        <div className="button-row" style={{ display: 'flex' }}>
          <Button onClick={() => setIsEditing(false)}>Cancel</Button>
          <div style={{ flex: 1 }} />
          <Button
            type="primary"
            icon={isNewDetection ? 'Plus' : 'Edit'}
            loading={loading}
            onClick={async () => {
              setLoading(true);
              if (label) {
                const asset = getAsset(assetId);
                const file = getFile(fileId);
                const assetExtraLinkage = asset
                  ? {
                      ...(asset.externalId
                        ? { linkedAssetExternalId: asset.externalId }
                        : { linkedAssetId: asset.id }),
                    }
                  : {};
                const fileExtraLinkage = file
                  ? {
                      ...(file.externalId
                        ? { linkedFileExternalId: file.externalId }
                        : { linkedFileId: file.id }),
                    }
                  : {};
                await onUpdateDetection({
                  ...annotation,
                  label,
                  ...assetExtraLinkage,
                  ...fileExtraLinkage,
                });
              } else {
                message.error('A label must be provided');
              }
              setLoading(false);
            }}
          >
            {isNewDetection ? 'Add Tag Link' : 'Edit Tag Link'}
          </Button>
          <Button icon="Delete" onClick={() => onDeleteDetection()} />
        </div>
      </div>
    );
  } else {
    const extraActions = renderResourceActions({
      assetId: currentAsset && currentAsset.id,
      fileId: currentFile && currentFile.id,
    });
    if (currentAsset) {
      content = (
        <div>
          <AssetHoverPreview
            asset={currentAsset}
            disableSidebarToggle
            extras={[
              <Dropdown content={actions} key="extra">
                <Button icon="VerticalEllipsis" />
              </Dropdown>,
            ]}
            renderResourceActions={renderResourceActions}
            actions={[
              <Button
                key="open"
                onClick={() => {
                  if (onAssetClicked) {
                    onAssetClicked(currentAsset!);
                  } else {
                    dispatch(
                      onResourceSelected(
                        {
                          showSidebar: true,
                          assetId: currentAsset.id,
                        },
                        history
                      )
                    );
                  }
                }}
                icon="PanelRight"
              >
                More Details
              </Button>,
              ...extraActions,
            ]}
          />
        </div>
      );
    }
    if (currentFile) {
      content = (
        <div>
          <FileHoverPreview
            file={currentFile}
            disableSidebarToggle
            extras={[
              <Dropdown content={actions} key="extra">
                <Button icon="VerticalEllipsis" />
              </Dropdown>,
            ]}
            actions={[
              <Button
                key="open"
                onClick={() => {
                  if (onFileClicked) {
                    onFileClicked(currentFile!);
                  } else {
                    dispatch(
                      onResourceSelected(
                        {
                          showSidebar: true,
                          fileId: currentFile.id,
                        },
                        history
                      )
                    );
                  }
                }}
                icon="Select"
              >
                Open File
              </Button>,
              ...extraActions,
            ]}
          />
        </div>
      );
    }
  }
  return (
    <WrappedPopover minHeight={minHeight} ref={wrapper}>
      {content}
    </WrappedPopover>
  );
};
