import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd';
import {
  searchSelector as searchTimeseriesSelector,
  retrieve,
  itemSelector,
  search,
} from 'modules/timeseries';
import AssetSelect from 'components/AssetSelect';
import {
  SelectWrapper,
  WithLabel,
  TimeseriesList,
  Input,
  SmallTitle,
  TimeseriesDetailsAbstract,
} from 'components/Common';
import { TimeSeriesSearchDTO, Asset } from '@cognite/sdk/dist/src/types/types';
import styled from 'styled-components';
import { useDebounce } from 'hooks/Hooks';
import { onResourceSelected } from 'modules/app';
import { GetTimeSeriesMetadataDTO, CogniteEvent } from '@cognite/sdk';
import { dqEventsSelector, fetchDQEventsForAssetId } from 'modules/dataQuality';
import { qualityDimensions } from 'utils/DataQuality';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;

interface TimeSeriesSearchProps {
  selectedTimeSeriesId: number | undefined;
  onTimeseriesClicked?: (timeseries: GetTimeSeriesMetadataDTO) => void;
  actions?: React.ReactNode[];
}

const getFilters = (
  query: string = '',
  assetId?: number
): TimeSeriesSearchDTO => {
  const timeseriesSearch: TimeSeriesSearchDTO = {
    limit: 100,
    search: query.length > 0 ? { query } : {},
  };
  if (assetId) {
    const assetTimeseriesSearch: TimeSeriesSearchDTO = {
      ...timeseriesSearch,
      filter: {
        assetIds: [assetId],
      },
    };
    return assetTimeseriesSearch;
  }
  return timeseriesSearch;
};

export const TimeseriesSearchExplorer = (props: TimeSeriesSearchProps) => {
  const [asset, setAsset] = useState<Asset | undefined>();
  const [query, setQuery] = useState<string>('');
  const debouncedQuery = useDebounce<string>(query, 500);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    selectedTimeSeriesId,
    onTimeseriesClicked = item =>
      dispatch(
        onResourceSelected(
          {
            timeseriesId: item.id,
            showSidebar: true,
            showQualityEvents: true,
          },
          history
        )
      ),
    actions,
  } = props;

  const timeseriesSearch = getFilters(query, asset?.id);

  const { items: timeSeriesList, fetching } = useSelector(
    searchTimeseriesSelector
  )(timeseriesSearch);

  const selectedTimeseries = useSelector(itemSelector)(selectedTimeSeriesId);

  if (
    selectedTimeseries &&
    !timeSeriesList.some(el => el.id === selectedTimeSeriesId)
  ) {
    timeSeriesList.unshift(selectedTimeseries);
  }

  const qualityEventsSelector = useSelector(dqEventsSelector);
  const { items: events, fetching: fetchingEvents } = selectedTimeseries
    ? qualityEventsSelector(selectedTimeseries)
    : { items: [], fetching: false };

  useEffect(() => {
    const filter = getFilters(debouncedQuery, asset?.id);
    dispatch(search(filter));
  }, [dispatch, debouncedQuery, asset]);

  useEffect(() => {
    if (selectedTimeSeriesId && !selectedTimeseries) {
      dispatch(retrieve([{ id: selectedTimeSeriesId }]));
    }
  }, [dispatch, selectedTimeseries, selectedTimeSeriesId]);

  const assetId = selectedTimeseries?.assetId;

  useEffect(() => {
    if (assetId && !fetchingEvents && !events.length) {
      dispatch(fetchDQEventsForAssetId(assetId));
    }
  }, [dispatch, assetId, fetchingEvents, events.length]);

  return (
    <Wrapper>
      <Row gutter={24}>
        <Col span={14}>
          <WithLabel title="Search">
            <Input
              value={query}
              onChange={setQuery}
              placeholder="Search for time series..."
            />
          </WithLabel>
        </Col>
        <Col span={10}>
          <WithLabel title="Filter by asset">
            <SelectWrapper>
              <AssetSelect
                selectedAssetId={asset?.id}
                onAssetSelected={setAsset}
              />
            </SelectWrapper>
          </WithLabel>
        </Col>
      </Row>
      <Row
        style={{
          display: 'flex',
          overflowY: 'auto',
        }}
      >
        <Col
          span={selectedTimeseries ? 6 : 24}
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
          }}
        >
          <SmallTitle>Time series</SmallTitle>
          <TimeseriesList
            selectedTimeSeriesId={selectedTimeSeriesId}
            timeseries={timeSeriesList}
            loading={fetching}
            query={query}
            onTimeSeriesClick={onTimeseriesClicked}
          />
        </Col>
        {selectedTimeseries && (
          <Col
            span={18}
            style={{
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <TimeseriesDetailsAbstract
              timeSeries={selectedTimeseries}
              actions={actions}
              events={events.filter(
                (e: CogniteEvent) => e.subtype && !!qualityDimensions[e.subtype]
              )}
            />
          </Col>
        )}
      </Row>
    </Wrapper>
  );
};
