import React, { useEffect, useState } from 'react';
import { Tabs, Result, Row, Col, Radio, Spin, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { RootState } from 'reducers/';
import { ensureDownloadedData, reset } from 'modules/contextualization/';
import { Prediction } from 'modules/contextualization/predictions';
import { trackUsage } from 'utils/Metrics';
import styled from 'styled-components';
import { Colors, Icon } from '@cognite/cogs.js';
import queryString from 'query-string';
import ManualMatching from './ManualMatching';
import RulesMatching from './RulesMatching';
import ResourceLoadingFeedbackModal from './ResourceLoadingFeedbackModal';
import StagedChanges from './StagedChanges';
import EntityMatcherHeader from './EntityMatcherHeader';
import BottomRow from './BottomRow';

const { Option } = Select;

export const PatternManualSwitch = styled(Radio.Group)`
  display: inline-flex;
  background: ${Colors['greyscale-grey3'].hex()};
  border-radius: 8px;
  padding: 4px;
  float: right;

  && label.ant-radio-button-wrapper {
    display: flex;
    align-items: center;
    flex: 1;
    width: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: ${Colors['greyscale-grey3'].hex()};
    font-weight: 800;
    border: none !important;
    border-radius: 8px;
  }
  && label.ant-radio-button-wrapper > span {
    display: flex;
    align-items: center;
  }
  && label.ant-radio-button-wrapper > span > span {
    margin-left: 6px;
  }
  && label.ant-radio-button-wrapper:hover {
    color: ${Colors['midblue-4'].hex()};
  }

  && label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    background: #fff;
    color: ${Colors['greyscale-grey8'].hex()};
    box-shadow: none;
  }
`;

type ItemFilterKey = 'all' | 'unmatched' | 'matched' | 'diff';

const itemFilters = {
  all: {
    name: 'All resources',
    filter: () => true,
  },
  unmatched: {
    name: 'Unmatched resources',
    filter: (p: Prediction) => !p.matchFrom?.id,
  },
  matched: {
    name: 'Matched resources',
    filter: (p: Prediction) => !!p.matchFrom?.id,
  },
  diff: {
    name: 'Matched resources with different recommendation',
    filter: (p: Prediction) =>
      !!p.matchFrom?.id && p.predictedId !== p.matchFrom?.id,
  },
};

export default function AssetResourceMatching() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    resourceDataKitId,
    assetsDataKitId,
    modelId,
    predictId,
    ruleId,
    changed,
  } = useParams<{
    assetsDataKitId: string;
    resourceDataKitId: string;
    modelId: string;
    predictId: string;
    ruleId: string;
    changed: string;
  }>();
  const { search } = history.location;

  const { tab = 'manual' } = queryString.parse(search);

  const match = useRouteMatch<{ tenant: string; path: string }>(
    '/:tenant/:path'
  );
  useEffect(() => {
    if (changed) {
      history.replace(
        `/${match?.params.tenant}/${match?.params.path}/${resourceDataKitId}/${assetsDataKitId}`
      );
    } else {
      dispatch(
        ensureDownloadedData(
          assetsDataKitId,
          resourceDataKitId,
          modelId,
          predictId,
          ruleId
        )
      );
    }
    // Changing 'changed' (can also be thought of 'dirty') should not
    // trigger this effect. Hence the eslint disable.
    // eslint-disable-next-line
  }, [assetsDataKitId, resourceDataKitId, modelId, predictId, ruleId]);

  useEffect(() => {
    trackUsage('Contextualization.ResourceMatching');
  }, []);

  const predictDone = useSelector(
    (state: RootState) =>
      (state.contextualization.predictions[resourceDataKitId] || {}).done
  );

  const rulesDone = useSelector(
    (state: RootState) =>
      (state.contextualization.rules[resourceDataKitId] || {}).done
  );

  useEffect(() => {
    trackUsage('Contextualization.ResourceMatching.TabChange', { tab });
  }, [tab]);

  const retry = () => {
    dispatch(reset(assetsDataKitId, resourceDataKitId));
    dispatch(
      ensureDownloadedData(
        assetsDataKitId,
        resourceDataKitId,
        modelId,
        predictId,
        ruleId
      )
    );
  };

  useEffect(() => {
    return () => {
      dispatch(reset(assetsDataKitId, resourceDataKitId));
    };
  }, [dispatch, assetsDataKitId, resourceDataKitId]);

  const changeTab = (newTab: string) => {
    const existingParams = queryString.parse(window.location.search);
    history.push({
      search: queryString.stringify({ ...existingParams, tab: newTab }),
    });
  };
  let mainTabKey = tab as string;
  if (mainTabKey === 'manual' || mainTabKey === 'rules') {
    mainTabKey = 'editing';
  }

  const [mode, setMode] = useState<ItemFilterKey>('all');

  if (!predictDone || !rulesDone) {
    return (
      <>
        <ResourceLoadingFeedbackModal
          assetDataKitId={assetsDataKitId}
          resourceDataKitId={resourceDataKitId}
          retry={retry}
        />
        <Result icon={<Spin size="large" />} subTitle="Loading results..." />
      </>
    );
  }

  return (
    <>
      <EntityMatcherHeader />
      <Tabs
        renderTabBar={() => <></>}
        activeKey={mainTabKey}
        style={{ marginBottom: '50px' }}
      >
        <Tabs.TabPane key="editing">
          <Row
            type="flex"
            justify="space-between"
            gutter={24}
            style={{ marginTop: '12px', marginBottom: '12px' }}
          >
            <Col>
              <Select
                value={mode}
                defaultValue="all"
                onChange={(value: ItemFilterKey) => setMode(value)}
              >
                <Option value="all">{itemFilters.all.name}</Option>
                <Option value="matched">{itemFilters.matched.name}</Option>
                <Option value="unmatched">{itemFilters.unmatched.name}</Option>
                <Option value="diff">{itemFilters.diff.name}</Option>
              </Select>
            </Col>
            <Col>
              <PatternManualSwitch
                value={tab as string}
                buttonStyle="solid"
                onChange={ev => changeTab(ev.target.value)}
              >
                <Radio.Button value="manual">
                  <Icon type="BulletList" />
                  <span>Match Individual</span>
                </Radio.Button>
                <Radio.Button value="rules">
                  <Icon type="DataTable" />
                  <span>Grouped by pattern</span>
                </Radio.Button>
              </PatternManualSwitch>
            </Col>
          </Row>
          <Tabs renderTabBar={() => <></>} activeKey={tab as string}>
            <Tabs.TabPane key="manual">
              <ManualMatching
                resourceDataKitId={resourceDataKitId}
                assetDKId={assetsDataKitId}
                itemFilter={itemFilters[mode].filter}
              />
            </Tabs.TabPane>
            <Tabs.TabPane key="rules">
              <RulesMatching
                resourceDataKitId={resourceDataKitId}
                assetDKId={assetsDataKitId}
                itemFilter={itemFilters[mode].filter}
              />
            </Tabs.TabPane>
          </Tabs>
        </Tabs.TabPane>
        <Tabs.TabPane key="staged">
          <StagedChanges
            resourceDataKitId={resourceDataKitId}
            assetDataKitId={assetsDataKitId}
          />
        </Tabs.TabPane>
      </Tabs>
      <BottomRow activeTab={mainTabKey} changeTab={changeTab} />
    </>
  );
}
