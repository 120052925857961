import React from 'react';
import { Input, Col } from 'antd';
import { Filter } from 'modules/search';
import DataSetSelect from 'components/DataSetSelect';
import { Icon, Colors } from '@cognite/cogs.js';

type Props = {
  updateFilter: (filter: Filter) => void;
  filter: Filter;
};
export default function TimeseriesSearchBar({ filter, updateFilter }: Props) {
  const dataSetIds = (filter.filter?.dataSetIds || []).map((el: any) => el.id);
  return (
    <>
      <Col span={12}>
        <p>Data Set</p>
        <DataSetSelect
          multiple
          style={{ width: '100%' }}
          selectedDataSetIds={dataSetIds}
          type="timeseries"
          onDataSetSelected={(ids: number[]) => {
            const newDataSetIds =
              ids.length === 0 ? undefined : ids.map(id => ({ id }));
            updateFilter({
              ...filter,
              filter: {
                ...filter.filter,
                dataSetIds: newDataSetIds,
              },
            });
          }}
        />
      </Col>
      <Col span={12}>
        <p>Search</p>
        <Input
          prefix={
            <Icon
              type="Search"
              style={{ height: '16px', color: Colors['greyscale-grey6'].hex() }}
            />
          }
          placeholder="Filter by name/description"
          value={filter.search ? filter.search.name : ''}
          onChange={ev =>
            updateFilter({
              ...filter,
              search:
                ev.target.value.length > 0
                  ? {
                      ...filter.search,
                      name: ev.target.value,
                    }
                  : undefined,
            })
          }
        />
      </Col>
    </>
  );
}
