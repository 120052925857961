import React from 'react';
import styled from 'styled-components';
import { Icon } from '@cognite/cogs.js';
import { Row } from 'antd';
import moment from 'moment';
import {
  InfoGrid,
  InfoCell,
  ListItem,
  CountLabel,
  SmallTitle,
} from 'components/Common';

import { FilesMetadata, Asset } from '@cognite/sdk';

interface FileDetailsProps {
  file: FilesMetadata;
  imgPreview?: React.ReactNode;
  actions?: React.ReactNode[];
  children?: React.ReactNode;
  extras?: React.ReactNode;
  assets?: Asset[];
  files?: FilesMetadata[];
}

const IconWrapper = styled.span`
  background: #f5f5f5;
  padding: 5px;
  padding-bottom: 1px;
  border-radius: 4px;
  margin-right: 8px;
  vertical-align: -0.225em;
`;

const NoDataText = styled.span`
  font-style: italic;
`;

const DetailsItem = (props: { name: string; value: any }) => (
  <>
    <InfoCell
      half
      containerStyles={{ borderRight: 'none', borderLeft: 'none' }}
    >
      {props.name}:
    </InfoCell>
    <InfoCell half containerStyles={{ borderRight: 'none' }}>
      {props.value || <NoDataText>Not set</NoDataText>}
    </InfoCell>
  </>
);

export const FileDetailsAbstract = ({
  file,
  actions,
  children,
  imgPreview,
  assets,
  extras,
  files,
}: FileDetailsProps) => {
  return (
    <InfoGrid className="file-info-grid" noBorders>
      {extras && (
        <div style={{ position: 'absolute', top: '16px', right: '16px' }}>
          {extras}
        </div>
      )}
      {file.name && (
        <InfoCell
          noBorders
          containerStyles={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <SmallTitle style={{ display: 'flex', alignItems: 'center' }}>
            <IconWrapper>
              <Icon type="Document" />
            </IconWrapper>
            <span
              style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {file.name}
            </span>
          </SmallTitle>
        </InfoCell>
      )}

      {actions && (
        <InfoCell noBorders>
          <Row type="flex" gutter={12} justify="start" className="button-row">
            {actions}
          </Row>
        </InfoCell>
      )}

      {imgPreview && <InfoCell noBorders>{imgPreview}</InfoCell>}

      <InfoCell noBorders>
        <p>CONTENT</p>
        {assets && (
          <ListItem
            style={{
              padding: 0,
              width: '100%',
              border: 'none',
              marginBottom: '8px',
            }}
            title={
              <div style={{ display: 'flex' }}>
                <Icon type="ThreeD" style={{ marginRight: '4px' }} />
                <span>Detected Asset Tags</span>
              </div>
            }
            bordered={false}
          >
            <CountLabel value={assets.length} />
          </ListItem>
        )}

        {files && (
          <ListItem
            style={{
              padding: 0,
              width: '100%',
              border: 'none',
              marginBottom: '8px',
            }}
            title={
              <div style={{ display: 'flex' }}>
                <Icon type="ThreeD" style={{ marginRight: '4px' }} />
                <span>Detected File Tags</span>
              </div>
            }
            bordered={false}
          >
            <CountLabel value={files.length} />
          </ListItem>
        )}
        {!assets && !files && <p>No Tags Detected</p>}
      </InfoCell>

      <InfoGrid noBorders>
        <DetailsItem name="Source" value={file.source} />
        <DetailsItem name="MimeType" value={file.mimeType} />
        <DetailsItem
          name="Uploaded at"
          value={
            file.uploadedTime
              ? moment(file.uploadedTime).format('MM/DD/YYYY HH:MM')
              : 'undefined'
          }
        />
        <DetailsItem
          name="Created at"
          value={moment(file.createdTime).format('MM/DD/YYYY HH:MM')}
        />
        <DetailsItem
          name="Updated at"
          value={moment(file.lastUpdatedTime).format('MM/DD/YYYY HH:MM')}
        />
      </InfoGrid>
      {children}
    </InfoGrid>
  );
};
