import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  Asset,
  AssetSearchFilter,
  AssetListScope,
  AssetFilterProps,
  InternalId,
} from '@cognite/sdk';
import { RootState } from 'reducers';
import buildCount from 'modules/sdk-builder/count';
import buildSearch from 'modules/sdk-builder/search';
import buildList from 'modules/sdk-builder/list';
import { Result } from 'modules/sdk-builder/types';
import { createSelector } from 'reselect';
import resourceBuilder from './sdk-builder';
import * as Assets from './playgroundAssets';
import buildItems from './sdk-builder/items';

export interface PlaygroundAsset extends Asset {
  types?: {
    type: {
      id: number;
      externalId?: string;
      version: number;
    };
    properties?: { [key: string]: any };
  }[];
}

export interface PlaygroundAssetListDTO extends AssetListScope {
  filter: AssetFilterProps & {
    types?: {
      type: { id: number; version: number };
      properties?: any;
    }[];
  };
}

interface PlaygroundAssetSearchDTO extends AssetSearchFilter {
  filter: AssetFilterProps & {
    types: {
      type: { id: number; version: number };
      properties?: any;
    }[];
  };
}

export interface PlaygroundAssetUpdateDTO extends InternalId {
  update: {
    types: {
      put?: {
        type: {
          id: number;
          version: number;
        };
        properties?: any;
      }[];
      remove?: {
        id: number;
        version: number;
      }[];
    };
  };
}

const NAME = 'playgroundAssets';
type Resource = PlaygroundAsset;
type Change = PlaygroundAssetUpdateDTO;
type ListScope = PlaygroundAssetListDTO;
type SearchFilter = PlaygroundAssetSearchDTO;

const {
  retrieve,
  update,
  retrieveExternal,
  itemReducer,
  createItemSelector,
} = buildItems<PlaygroundAsset, PlaygroundAssetUpdateDTO>(
  'playgroundAssets',
  sdk => async q => {
    const response = await sdk.post<{ items: PlaygroundAsset[] }>(
      `/api/playground/projects/${sdk.project}/assets/byIds`,
      {
        data: { items: q },
      }
    );
    return response.data.items;
  },
  undefined,
  sdk => async q => {
    const response = await sdk.post<{ items: PlaygroundAsset[] }>(
      `/api/playground/projects/${sdk.project}/assets/update`,
      {
        data: { items: q },
      }
    );
    return response.data.items;
  },
  sdk => async q => {
    sdk.post<{ items: PlaygroundAsset[] }>(
      `/api/playground/projects/${sdk.project}/assets/delete`,
      {
        data: { items: q },
      }
    );
  }
);

const {
  listAction: list,
  listParallelAction: listParallel,
  listReducer,
  createListSelector,
} = buildList<PlaygroundAssetListDTO, PlaygroundAsset>(
  'playgroundAssets',
  t => t,
  sdk => async q => {
    const result = await sdk.post<{ items: PlaygroundAsset[] }>(
      `/api/playground/projects/${sdk.project}/assets/list`,
      {
        data: q,
      }
    );
    return result.data;
  }
);

const {
  searchAction: search,
  searchReducer,
  createSearchSelector,
} = buildSearch<PlaygroundAsset, PlaygroundAssetSearchDTO>(
  'playgroundAssets',
  t => t,
  sdk => async q => {
    const result = await sdk.post<{ items: PlaygroundAsset[] }>(
      `/api/playground/projects/${sdk.project}/assets/search`,
      {
        data: q,
      }
    );
    return result.data;
  }
);

const { countAction: count, countReducer, createCountSelector } = buildCount<
  PlaygroundAssetListDTO
>('playgroundAssets', sdk => async q => {
  const response = await sdk.post<{ count: number }>(
    `/api/playground/projects/${sdk.project}/assets/count`,
    {
      data: q,
    }
  );
  return { ...response, data: { items: [{ count: response.data.count }] } };
});

export const searchOrListAndCount = (assetFilter: PlaygroundAssetSearchDTO) => (
  dispatch: ThunkDispatch<any, any, AnyAction>
) => {
  const countFilter = { filter: assetFilter.filter };
  if (assetFilter.search) {
    dispatch(Assets.search(assetFilter));
  } else {
    dispatch(Assets.list(assetFilter as PlaygroundAssetListDTO));
  }
  dispatch(Assets.count(countFilter));
};

const { reducer, createRetrieveSelector } = resourceBuilder<
  Resource,
  Change,
  ListScope,
  SearchFilter
>(NAME, t => t, itemReducer, listReducer, searchReducer, countReducer);

const searchSelector: (
  _: RootState
) => (q: SearchFilter) => Result<Resource> = createSearchSelector(
  // @ts-ignore
  (state: RootState): Map<number, Resource> => state[NAME].items.items,
  (state: RootState) => state[NAME].search
);

const listSelector = createListSelector(
  // @ts-ignore
  (state: RootState) => state[NAME].items.items,
  (state: RootState) => state[NAME].list
);

const countSelector = createCountSelector(
  (state: RootState) => state[NAME].count
);

const retrieveSelector = createRetrieveSelector(
  (state: RootState) => state[NAME].items.items
);

const itemSelector = createItemSelector(
  (state: RootState) => state[NAME].items.items,
  (state: RootState) => state[NAME].items.getByExternalId
);

const selectMetadata = createSelector(
  itemSelector,
  getPlaygroundAsset => (ids: (string | number)[]) => {
    const items = ids
      .map(id => getPlaygroundAsset(id))
      .filter(el => !!el) as PlaygroundAsset[];
    const metadataMap: { [key: string]: Set<any> } = {};
    items.forEach(asset => {
      const { metadata = {} } = asset;
      return Object.keys(metadata).forEach(key => {
        if (!metadataMap[key]) {
          metadataMap[key] = new Set();
        }
        metadataMap[key]!.add(metadata[key]);
      });
    });
    return Object.keys(metadataMap).reduce(
      (acc, key) => ({
        ...acc,
        [key]: Array.from(metadataMap[key] || []),
      }),
      {} as { [key: string]: any[] }
    );
  }
);

export {
  reducer,
  retrieve,
  update,
  retrieveExternal,
  search,
  count,
  list,
  listParallel,
  searchSelector,
  itemSelector,
  listSelector,
  countSelector,
  retrieveSelector,
  selectMetadata,
};
export default reducer;
