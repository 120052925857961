import React, { useEffect } from 'react';
import {
  CoolH1,
  ListItem,
  ListItemProps,
  Field,
  CountLabel,
  Header,
} from 'components/Common';
import { CogniteTypeProperty } from 'modules/types';
import { Colors, Button } from '@cognite/cogs.js';
import styled, { CSSProperties } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { itemSelector, retrieve } from 'modules/timeseries';

const StyledField = styled.span<{ selected?: boolean }>`
  width: 100%;
  .field-text-box {
    background: ${({ selected }) => (selected ? '#edf0ff' : '')};
    border: ${({ selected }) => (selected ? '2px solid #4255bb' : '')};
  }
`;
export const PropertyListItem = ({
  property,
  onClick,
  onDeleteClicked,
  selected,
}: {
  property: CogniteTypeProperty & { value?: any };
  onDeleteClicked?: () => void;
} & ListItemProps) => {
  const style: CSSProperties = {
    background: '#ffffff',
    color: '#000000',
    boxShadow: selected ? '0px 8px 16px rgba(0, 0, 0, 0.08)' : 'none',
    margin: 15,
    transition: '0.3s all',
  };

  const dispatch = useDispatch();

  const timeseriesSelector = useSelector(itemSelector);

  useEffect(() => {
    if (property.type === 'timeseriesRef' && property.value) {
      const timeseries = timeseriesSelector(property.value.id);
      if (!timeseries && property.value.id) {
        dispatch(retrieve([{ id: property.value.id }]));
      }
    }
  }, [dispatch, timeseriesSelector, property]);
  const value = () => {
    if (property.type === 'timeseriesRef' && property.value) {
      const timeseries = timeseriesSelector(property.value.id);
      return (
        <div style={{ display: 'flex' }}>
          <span style={{ flex: 1 }}>
            {timeseries ? timeseries.name : 'Loading...'}
          </span>
          <Button type="danger" onClick={onDeleteClicked} icon="Close" />
        </div>
      );
    }
    return '';
  };
  return (
    <ListItem
      style={{ ...style, flexDirection: 'column' }}
      onClick={onClick}
      selected={selected}
    >
      <Header
        title={<CoolH1>{property.name}</CoolH1>}
        extra={
          <CountLabel
            value={property.value?.id ? '1' : '0'}
            backgroundColor={
              property.value?.id
                ? Colors.primary.hex()
                : Colors['greyscale-grey6'].hex()
            }
          />
        }
      />
      <Field title="Description">{property.description}</Field>
      <StyledField selected={selected}>
        <Field title="Value">{value()}</Field>
      </StyledField>
      <Field title="Property id">{property.propertyId}</Field>
      <Field title="Type">{property.type}</Field>
    </ListItem>
  );
};
