import React from 'react';
import { List } from 'antd';
import Highlighter from 'react-highlight-words';

export default function ItemWithDescription(props: {
  onClick: () => void;
  name: string;
  highlight?: string;
  description: string;
}) {
  return (
    <List.Item style={{ margin: 0, padding: 0 }}>
      <List.Item.Meta
        style={{ margin: 0, padding: 0 }}
        title={
          <a
            href="#modal"
            onClick={e => {
              e.preventDefault();
              props.onClick();
            }}
          >
            {props.highlight ? (
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[props.highlight]}
                autoEscape
                textToHighlight={props.name}
              />
            ) : (
              props.name
            )}
          </a>
        }
        description={props.description}
      />
    </List.Item>
  );
}
