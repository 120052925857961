import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';

const ExtraRow = styled(Row)`
  > * {
    margin-left: 8px;
  }
`;

interface SectionCardProps {
  title: string | React.ReactNode;
  extra?: React.ReactNode | React.ReactNode[];
  style?: React.CSSProperties;
}
export const Header = (props: SectionCardProps) => {
  const { title, extra, style } = props;
  return (
    <Row
      type="flex"
      justify="space-between"
      style={{ width: '100%', ...style }}
    >
      <Col style={{ display: 'inline-flex', flex: 1 }}>{title}</Col>
      <Col style={{ display: 'inline-flex' }}>
        <ExtraRow type="flex" justify="end" align="middle">
          {extra}
        </ExtraRow>
      </Col>
    </Row>
  );
};
