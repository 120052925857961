import { createSelector } from 'reselect';
import {
  GetTimeSeriesMetadataDTO,
  EventSearchRequest,
  CogniteEvent,
  Asset,
} from '@cognite/sdk';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { Result } from 'modules/sdk-builder/types';
import { searchSelector as searchEventSelector, search } from './events';

const getFilter = (assetIds: number[]): EventSearchRequest => ({
  filter: { type: 'Data Quality', assetIds },
  limit: 1000,
});
export const fetchDQEventsForAssetId = (assetId: number) => (
  dispatch: ThunkDispatch<any, any, AnyAction>
) => {
  dispatch(search(getFilter([assetId])));
};
export const fetchDQEventsForAssets = (assets: Asset[]) => (
  dispatch: ThunkDispatch<any, any, AnyAction>
) => {
  dispatch(search(getFilter(assets.map(el => el.id))));
};
export const dqEventsSelector = createSelector(
  searchEventSelector,
  searchSelector => (
    timeseries: GetTimeSeriesMetadataDTO
  ): Result<CogniteEvent> => {
    if (timeseries.assetId) {
      const eventResult = searchSelector(getFilter([timeseries.assetId]));
      return eventResult;
    }
    return {
      fetching: false,
      error: false,
      ids: [],
      items: [],
      progress: 0,
      done: false,
    } as Result<CogniteEvent>;
  }
);
export const dqEventsForAssetsSelector = createSelector(
  searchEventSelector,
  searchSelector => (assets: Asset[]): Result<CogniteEvent> => {
    const eventResult = searchSelector(getFilter(assets.map(el => el.id)));
    return eventResult;
  }
);
