import React, { useEffect, useState } from 'react';
import { Empty } from 'antd';
import {
  Loader,
  Card,
  Header,
  VerticalDivider,
  PropertySummaryIndicator,
  XOMHeader,
  ColorDot,
  SmallTitle,
} from 'components/Common';
import { useDispatch, useSelector } from 'react-redux';
import {
  list as listTypes,
  listSelector,
  CogniteType,
  CogniteTypePopulatedProperty,
} from 'modules/types';
import { Button, Colors, Icon } from '@cognite/cogs.js';
import {
  selectSuggestionCount,
  selectSuggestionFetching,
  loadCompletionModel,
} from 'modules/templateCompletion';
import {
  selectInstancesPropertyCount,
  listAndCountInstancesForTypeId,
  selectInstancesUnderType,
  Instance,
  getPropertyFromInstance,
  selectInstancesCountUnderType,
} from 'modules/instances';
import styled from 'styled-components';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import { formatLongNumber } from 'helpers';
import { getStatusColor, qualityDimensions } from 'utils/DataQuality';
import { retrieve, itemSelector } from 'modules/timeseries';
import { GetTimeSeriesMetadataDTO } from '@cognite/sdk';
import { Container } from '../Common';
import { NewTemplateModal } from './NewTemplateModal';
import ScalingUseCaseRoutes from '../ScalingUseCaseRoutes';
import { HeaderButtons } from '../Common/Common';

const TemplatesWrapper = styled.div`
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(480px, 1fr));
  grid-gap: 72px;
  padding: 64px;
  padding-left: 124px;
  padding-right: 124px;
`;

const TemplateCard = styled(Card)`
  border: 1px solid ${Colors['greyscale-grey5'].hex()};
  cursor: pointer;
  transition: 0.3s all;

  &&:hover {
    box-shadow: 0px 0px 12px ${Colors['greyscale-grey5'].hex()};
  }

  h1,
  h2 {
    font-weight: 800;
  }
  h1 {
    margin: 0 auto;
    text-align: center;
    font-size: 64px;
    margin-bottom: 32px;
  }

  .details {
    display: flex;
    margin-bottom: 32px;
    .item {
      flex: 1;
    }
  }

  .indicators {
    display: flex;
  }
`;

export const Templates = () => {
  const { tenant } = useParams<{
    tenant: string;
  }>();
  const dispatch = useDispatch();
  const history = useHistory();

  const [newTemplateFormOpen, setNewTemplateFormOpen] = useState(false);

  const { fetching, items: types } = useSelector(listSelector)({}, true);
  const getInstancesCount = useSelector(selectInstancesCountUnderType);
  const getSuggestionCount = useSelector(selectSuggestionCount);
  const getPropertyCount = useSelector(selectInstancesPropertyCount);
  const getSuggestionCountFetching = useSelector(selectSuggestionFetching);
  const getTimeseries = useSelector(itemSelector);
  const getInstancesUnderType = useSelector(selectInstancesUnderType);

  useEffect(() => {
    dispatch(listTypes({}, true));
  }, [dispatch]);

  useEffect(() => {
    types.forEach(el => {
      dispatch(listAndCountInstancesForTypeId(el.id, true));
      dispatch(loadCompletionModel(el.id));
    });
  }, [dispatch, types]);

  const instancesMap: { [key: string]: Instance[] } = types.reduce(
    (map, type) => {
      const { items: instances } = getInstancesUnderType(type.id, true);
      return {
        ...map,
        [type.id]: instances,
      };
    },
    {}
  );

  const timeSeriesIdsMap: { [key: string]: Set<number> } = {};
  Object.keys(instancesMap).forEach(typeId => {
    timeSeriesIdsMap[typeId] = new Set<number>();
    const instances = instancesMap[typeId];
    if (!instancesMap) return;
    instances.forEach(instance => {
      const type = types.find(t => t.id === Number(typeId));
      if (type) {
        (type?.properties || []).forEach(
          (prop: CogniteTypePopulatedProperty) => {
            const property = getPropertyFromInstance(
              type,
              instance,
              prop.propertyId
            );
            if (property && property.id) {
              timeSeriesIdsMap[type.id].add(property.id);
            }
          }
        );
      }
    });
  });

  const timeSeriesMap: { [key: string]: GetTimeSeriesMetadataDTO[] } = {};
  Object.keys(timeSeriesIdsMap).forEach(typeId => {
    timeSeriesMap[typeId] = Array.from(timeSeriesIdsMap[typeId])
      .map(el => getTimeseries(el))
      .filter(el => !!el) as GetTimeSeriesMetadataDTO[];
  });

  useEffect(() => {
    Object.keys(timeSeriesIdsMap).forEach(typeId => {
      if (
        timeSeriesIdsMap[typeId].size > 0 &&
        timeSeriesMap[typeId].length !== timeSeriesIdsMap[typeId].size
      ) {
        dispatch(
          retrieve(Array.from(timeSeriesIdsMap[typeId]).map(id => ({ id })))
        );
      }
    });
  }, [dispatch, timeSeriesIdsMap, timeSeriesMap, types]);

  const qualityStatsMap: { [key: string]: string[] } = {};

  Object.keys(timeSeriesMap).forEach(typeId => {
    qualityStatsMap[typeId] = timeSeriesMap[typeId].reduce(
      (stats: string[], ts) => {
        return [
          ...stats,
          ...Object.values(qualityDimensions).map(dimension =>
            getStatusColor(
              ts.metadata && ts.metadata[dimension.id],
              dimension.thresholds
            )
          ),
        ];
      },
      []
    );
  });

  const renderTemplateCard = (type: CogniteType) => {
    const suggestionCount = getSuggestionCount(type.id);
    const suggestionCountFetching = getSuggestionCountFetching(type.id);
    const { total, completed } = getPropertyCount(type.id);
    const { count } = getInstancesCount(type.id);

    return (
      <TemplateCard
        key={type.id}
        onClick={() => history.push(`/${tenant}/templates/${type.id}`)}
      >
        <div className="content">
          <Header
            title={<h2>{type.name}</h2>}
            extra={
              <Button
                shape="round"
                icon="VerticalEllipsis"
                type="link"
                style={{ color: Colors.black.hex() }}
              />
            }
          />
          <div className="details">
            <div className="item">
              <span>CREATED BY</span>
              <h4>N/A</h4>
            </div>
            <VerticalDivider />
            <div className="item">
              <span>CREATED</span>
              <h4>{moment(type.createdTime).format('MM/DD/YYYY HH:MM')}</h4>
            </div>
          </div>
          <h1>
            {count ? formatLongNumber(count) : 0}
            <small style={{ marginLeft: '6px', fontSize: '24px' }}>
              Instances
            </small>
          </h1>
          <div className="indicators">
            <VerticalDivider style={{ marginLeft: 0 }} />
            <PropertySummaryIndicator
              text="# MAPPED PROPERTIES"
              count={completed}
              style={{ flex: 1 }}
            />
            <VerticalDivider />
            <PropertySummaryIndicator
              text="TOTAL # PROPERTIES"
              count={total}
              style={{ flex: 1 }}
            />
            <VerticalDivider />
            <PropertySummaryIndicator
              text="SUGGESTIONS"
              count={
                suggestionCountFetching ? (
                  <Icon type="Loading" />
                ) : (
                  suggestionCount
                )
              }
              disabled={suggestionCount === 0}
              onClick={ev => {
                ev.stopPropagation();
                history.push(`/${tenant}/templates/${type.id}/suggestions`);
              }}
              style={{ flex: 1 }}
            />
            <VerticalDivider style={{ marginRight: 0 }} />
          </div>
          <span>DATA QUALITY MONITORING</span>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SmallTitle>
              <ColorDot color={Colors.danger.hex()} />{' '}
              {
                qualityStatsMap[type.id]?.filter(
                  status => status === Colors.danger.hex()
                ).length
              }{' '}
              severe
            </SmallTitle>
            <SmallTitle>
              <ColorDot color={Colors.warning.hex()} />{' '}
              {
                qualityStatsMap[type.id]?.filter(
                  status => status === Colors.warning.hex()
                ).length
              }{' '}
              moderate
            </SmallTitle>
            <SmallTitle>
              <ColorDot color={Colors.success.hex()} />{' '}
              {
                qualityStatsMap[type.id]?.filter(
                  status => status === Colors.success.hex()
                ).length
              }{' '}
              normal
            </SmallTitle>
            <SmallTitle>
              <ColorDot color="#d4d4d4" />{' '}
              {
                qualityStatsMap[type.id]?.filter(status => status === '#d4d4d4')
                  .length
              }{' '}
              no DQM
            </SmallTitle>
          </div>
        </div>
      </TemplateCard>
    );
  };

  const renderDetails = () => {
    if (fetching) {
      return <Loader />;
    }
    if (types.length === 0) {
      return <Empty>No Templates Defined.</Empty>;
    }
    return (
      <TemplatesWrapper>
        {types.map(el => renderTemplateCard(el))}
      </TemplatesWrapper>
    );
  };

  return (
    <Container style={{ flexDirection: 'column' }}>
      <XOMHeader
        onBackClicked={() => history.push(`/${tenant}`)}
        breadcrumbs={ScalingUseCaseRoutes.breadcrumbs}
        path={`/${tenant}/templates`}
        title="Templates"
      >
        <HeaderButtons>
          <Button
            icon="Plus"
            onClick={() => setNewTemplateFormOpen(true)}
            variant="outline"
          >
            Create New
          </Button>
        </HeaderButtons>
      </XOMHeader>
      {renderDetails()}
      <NewTemplateModal
        visible={newTemplateFormOpen}
        onClose={() => setNewTemplateFormOpen(false)}
      />
    </Container>
  );
};
