import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Tabs, Empty } from 'antd';
import { GetTimeSeriesMetadataDTO as TimeSeries } from '@cognite/sdk';
import { Instance } from 'modules/instances';
import { CogniteTypeProperty } from 'modules/types';
import { Colors, Button } from '@cognite/cogs.js';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import {
  TimeseriesSearchExplorer,
  PnIDSearchExplorer,
} from 'containers/Exploration/ResourceExplorer';
import { onResourceSelected } from 'modules/app';

const ContentWrapper = styled.div`
  min-height: 600px;
  background: #fff;
  padding: 16px;
  display: flex;
  flex: 1;

  .rp-image {
    background: ${Colors['greyscale-grey1'].hex()};
  }

  && .pnid-sidebar {
    padding: 0px;
    margin-right: 8px;
  }
  && .search-content {
    padding-left: 0px;
    margin-right: 8px;
  }

  .pnid-content {
    background: ${Colors['greyscale-grey1'].hex()};
  }
`;

const TabWrapper = styled.div`
  .ant-tabs-ink-bar {
    background-color: ${Colors.primary.hex()};
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    font-weight: bold;
    color: ${Colors.primary.hex()};
  }
  .ant-tabs-bar {
    font-weight: bold;
    margin-bottom: 0px;
  }
  .ant-tabs-content {
    height: 0;
  }
`;

type PopulatedProperty = CogniteTypeProperty & { value: any };
interface EditPropertyProps {
  instance: Instance;
  property: PopulatedProperty;
  renderExtraButtons: ({
    timeseriesId,
  }: {
    timeseriesId: number;
  }) => React.ReactNode[];
  onViewFile?: () => void;
}
export const EditProperty = ({
  property,
  onViewFile,
  renderExtraButtons,
}: EditPropertyProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { search, pathname } = useLocation();

  const { tab = 'timeseries' }: { tab?: string } = queryString.parse(search, {
    parseNumbers: true,
  });

  const [selectedTimeSeries, setSelectedTimeSeries] = useState<
    TimeSeries | undefined
  >();

  const selectedTimeSeriesId = selectedTimeSeries?.id || property?.value?.id;

  const extras = renderExtraButtons({ timeseriesId: selectedTimeSeriesId });

  const renderContent = () => {
    switch (tab) {
      case 'timeseries':
        return (
          <TimeseriesSearchExplorer
            selectedTimeSeriesId={selectedTimeSeriesId}
            onTimeseriesClicked={setSelectedTimeSeries}
            actions={[
              <Button
                size="large"
                icon="Expand"
                onClick={() =>
                  selectedTimeSeriesId &&
                  dispatch(
                    onResourceSelected(
                      {
                        timeseriesId: selectedTimeSeriesId,
                        showSidebar: true,
                        showQualityEvents: true,
                      },
                      history
                    )
                  )
                }
              >
                View
              </Button>,
              ...extras,
            ]}
          />
        );
      case 'pnids':
        return (
          <PnIDSearchExplorer
            onFileClicked={file => {
              history.push({
                pathname: `${pathname}/file`,
                search: queryString.stringify({
                  ...queryString.parse(search),
                  pnid: file.id,
                }),
              });
              if (onViewFile) {
                onViewFile();
              }
            }}
          />
        );
      default:
        return <Empty>Coming soon...</Empty>;
    }
  };

  return (
    <>
      <TabWrapper>
        <Tabs
          animated={false}
          activeKey={tab || 'timeseries'}
          onChange={key => {
            history.push({
              search: queryString.stringify({
                ...queryString.parse(search),
                tab: key,
              }),
            });
          }}
        >
          <Tabs.TabPane tab="Time series" key="timeseries" />
          <Tabs.TabPane tab="P&IDs" key="pnids" />
          <Tabs.TabPane tab="3D models" key="threedmodels" disabled />
        </Tabs>
      </TabWrapper>
      <ContentWrapper>{renderContent()}</ContentWrapper>
    </>
  );
};
