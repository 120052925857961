import React, { useState } from 'react';
import { Modal, NewInstanceForm } from 'components/Common';
import { useSelector, useDispatch } from 'react-redux';
import { CogniteType } from 'modules/types';
import { message } from 'antd';
import { addType } from 'modules/instances';
import { RootState } from 'reducers';

export const NewInstanceModal = ({
  visible,
  template,
  onClose,
}: {
  visible: boolean;
  template?: CogniteType;
  onClose: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const typesMap = useSelector((state: RootState) => state.types.items.items);
  const dispatch = useDispatch();
  let callback: () => { assetId: number; type: CogniteType } | undefined;
  if (visible) {
    return (
      <Modal
        visible={visible}
        width={1200}
        onCancel={onClose}
        loading={loading}
        onDone={async () => {
          setLoading(true);
          const newInstance = callback();
          if (!newInstance) {
            message.error(
              'Incomplete instance - template and asset must be filled in!'
            );
            setLoading(false);
          } else {
            try {
              // if creating
              await dispatch(addType(newInstance.assetId, newInstance.type));
              message.success('Successfully created new instance!');
              onClose();
              setLoading(false);
            } catch (e) {
              // if creating
              message.error('Unable to create instance.');
              setLoading(false);
            }
          }
        }}
      >
        <NewInstanceForm
          templates={Array.from(typesMap.values())}
          template={template}
          instanceCallback={cb => {
            callback = cb;
          }}
        />
      </Modal>
    );
  }
  return null;
};
