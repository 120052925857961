import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { itemSelector, retrieve as retrieveFiles } from 'modules/files';
import { loadPreviewUrl, urlPreviewSelector } from 'modules/filePreview';
import { Collapse, List } from 'antd';
import { Button } from '@cognite/cogs.js';
import { retrieve as retrieveAssets } from 'modules/assets';
import {
  listByFileId,
  linkedAssetsSelector,
  linkedFilesSelectorByFileId,
} from 'modules/annotations';
import { onResourceSelected } from 'modules/app';
import { PNID_PARSING_JOB_ID_METADATA_FIELD } from 'utils/PnIDApi';
import { AnnotatedPnIDPreview } from 'components/PnIDPreview';
import { useHistory } from 'react-router-dom';
import { Wrapper } from './Common';

export const FileMetadataPreview = ({
  fileId,
  extraActions,
}: {
  fileId: number;
  extraActions?: React.ReactNode[];
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const file = useSelector(itemSelector)(fileId);
  const { assetIds, assets } = useSelector(linkedAssetsSelector)(fileId);
  const { fileIds, files } = useSelector(linkedFilesSelectorByFileId)(fileId);

  const filePreviewUrlResult = useSelector(urlPreviewSelector(fileId));

  const filePreviewUrl = filePreviewUrlResult
    ? filePreviewUrlResult.pngUrl
    : undefined;

  useEffect(() => {
    (async () => {
      await dispatch(retrieveFiles([{ id: fileId }]));
      await dispatch(listByFileId(fileId));
    })();
  }, [dispatch, fileId]);

  useEffect(() => {
    if (file) {
      if (
        file.metadata &&
        file.metadata[PNID_PARSING_JOB_ID_METADATA_FIELD] &&
        !filePreviewUrl
      ) {
        dispatch(loadPreviewUrl(file.id));
      }
    }
  }, [dispatch, file, filePreviewUrl]);

  useEffect(() => {
    dispatch(
      retrieveAssets(
        assetIds.map(id =>
          typeof id === 'string' ? { externalId: id } : { id }
        )
      )
    );
  }, [dispatch, assetIds]);
  useEffect(() => {
    dispatch(
      retrieveFiles(
        fileIds.map(id =>
          typeof id === 'string' ? { externalId: id } : { id }
        )
      )
    );
  }, [dispatch, fileIds]);

  return (
    <Wrapper>
      <Button className="back-button" onClick={() => history.goBack()}>
        Back
      </Button>
      <h1>{file ? file.name : 'Loading...'}</h1>
      {extraActions}
      {filePreviewUrl && (
        <div style={{ height: 800 }}>
          <AnnotatedPnIDPreview
            fileId={fileId}
            filePreviewUrl={filePreviewUrl}
          />
        </div>
      )}
      <Collapse>
        <Collapse.Panel
          key="assets"
          header={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              Assets Tags Detected ({assets.length})
            </span>
          }
        >
          <List
            renderItem={asset => (
              <List.Item
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (asset) {
                    dispatch(
                      onResourceSelected({ assetId: asset.id }, history)
                    );
                  }
                }}
              >
                <List.Item.Meta
                  title={asset.name}
                  description={asset.description}
                />
              </List.Item>
            )}
            pagination={{ position: 'bottom' }}
            dataSource={assets}
          />
        </Collapse.Panel>
        <Collapse.Panel
          key="files"
          header={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              Files Tags Detected ({files.length})
            </span>
          }
        >
          <List
            renderItem={linkedFile => (
              <List.Item
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (linkedFile) {
                    dispatch(
                      onResourceSelected({ fileId: linkedFile.id }, history)
                    );
                  }
                }}
              >
                <List.Item.Meta
                  title={linkedFile.name}
                  description={linkedFile.externalId}
                />
              </List.Item>
            )}
            pagination={{ position: 'bottom' }}
            dataSource={files}
          />
        </Collapse.Panel>
        <Collapse.Panel key="metdata" header="Metadata">
          <pre>{JSON.stringify(file, null, 2)}</pre>
        </Collapse.Panel>
      </Collapse>
    </Wrapper>
  );
};
