import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import styled from 'styled-components';
import { Button } from '@cognite/cogs.js';
import { itemSelector as fileItemSelector, retrieve } from 'modules/files';
import { selectAnnotations } from 'modules/annotations';
import { PDFViewer } from 'components/Common';
import { getPnIdAnnotationCategories } from 'components/PnIDPreview/AnnotatedPnIDOverview';
import { FilesApi } from 'utils/FilesApi';
import ResourceSidebar from './ResourceSidebar';

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - 180px);
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  padding: 20px 24px;
  box-shadow: 0px 0px 6px #cdcdcd;
  border-radius: 12px;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  margin-top: 40px;
  background: #fff;
  button {
    margin-right: 26px;
  }
  h1 {
    margin-bottom: 0px;
    flex: 1;
  }
`;

const HeaderText = styled.h1`
  font-weight: 800;
  font-size: 1.1em;
  text-transform: uppercase;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-top: 20px;
  box-sizing: border-box;
`;

export default function DocumentContextualizeFilePreview() {
  const { fileId, tenant, filesDataKitId, assetsDataKitId } = useParams<{
    fileId: string;
    tenant: string;
    filesDataKitId: string;
    assetsDataKitId: string;
  }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const [pdfUrl, setPdfUrl] = useState('');
  const [fileLoaded, setFileLoaded] = useState(false);
  const fileIdNumber = parseInt(fileId, 10);

  const file = useSelector(fileItemSelector)(fileIdNumber);
  const state = useSelector((rootState: RootState) => rootState);
  const annotationsMap = useSelector(selectAnnotations);
  const annotations = annotationsMap(fileIdNumber);
  const annotationDetails = getPnIdAnnotationCategories(annotations);

  const getState = () => state;
  const { sdk } = getState().app;
  const filesApi = new FilesApi(sdk);

  const {
    Asset: { count: assetCount },
  } = annotationDetails;

  useEffect(() => {
    dispatch(retrieve([{ id: fileIdNumber }]));
  }, [dispatch, fileIdNumber]);
  useEffect(() => {
    const fetchPdf = async () => {
      if (file) {
        const url = await filesApi.getPdfUrl(file.id);
        setPdfUrl(url);
        setFileLoaded(true);
      }
    };
    if (!fileLoaded) {
      fetchPdf();
    }
  }, [file, filesApi, fileLoaded]);

  return (
    <Wrapper>
      <Header>
        <HeaderText>{file ? file.name : 'Loading...'}</HeaderText>
        <Button
          shape="round"
          icon="ArrowLeft"
          onClick={() =>
            history.push(
              `/${tenant}/document_contextualize/${filesDataKitId}/${assetsDataKitId}`
            )
          }
        >
          Back
        </Button>
      </Header>
      <ContentWrapper>
        <ResourceSidebar
          annotationDetails={annotationDetails}
          assetCount={assetCount}
        />
        <PDFViewer pdf={pdfUrl} />
      </ContentWrapper>
    </Wrapper>
  );
}
