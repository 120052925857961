import React from 'react';
import { Route, Switch } from 'react-router';

type RouteDef = {
  exact?: boolean;
  path: string;
  component: any;
};

export default function SwitchWithBreadcrumbs(props: { routes: RouteDef[] }) {
  return (
    <>
      <Switch>
        {props.routes.map(route => (
          <Route
            key={route.path}
            exact={!!route.exact}
            path={route.path}
            component={route.component}
          />
        ))}
      </Switch>
    </>
  );
}
