import { Colors } from '@cognite/cogs.js';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;
`;

export const Sider = styled.div`
  flex: 1;
  max-width: 440px;
  height: auto;
  border-right: 1px solid ${Colors['greyscale-grey3'].hex()};
  padding-right: 10px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Main = styled.div`
  overflow: auto;
  flex: 4;
  padding-left: 10px;
  width: 0;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
  && > * {
    margin-right: 24px;
  }
`;
