import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Divider, Table, Select } from 'antd';
import { Button, Colors } from '@cognite/cogs.js';
import {
  Header,
  CoolH1,
  WithLabel,
  Input,
  TextArea,
  SelectWrapper,
} from 'components/Common';
import {
  CreateCogniteTypeDTO,
  CogniteTypePropertyTypes,
  CogniteTypePropertyTypesMap,
} from 'modules/types';
import update from 'lodash/update';

export const NewTemplateForm = ({
  initialTemplate,
  templateCallback,
}: {
  initialTemplate?: CreateCogniteTypeDTO;
  templateCallback?: (callback: () => CreateCogniteTypeDTO) => void;
}) => {
  const [template, setTemplate] = useState(
    initialTemplate
      ? {
          ...JSON.parse(JSON.stringify(initialTemplate)),
          properties: initialTemplate.properties
            ? JSON.parse(JSON.stringify(initialTemplate.properties))
            : [],
        }
      : ({
          externalId: '',
          properties: [],
        } as CreateCogniteTypeDTO)
  );

  const updateValue = (path: string, value: any) => {
    const newTemplate = { ...template };
    update(template, path, () => value);
    setTemplate(newTemplate);
  };

  useEffect(() => {
    if (templateCallback) {
      templateCallback(() => ({
        ...template,
        properties:
          template.properties!.length !== 0 ? template.properties : undefined,
      }));
    }
  }, [template, templateCallback]);

  return (
    <Container>
      <Row>
        <Col span={24}>
          <Header
            title={
              <CoolH1>
                {initialTemplate ? 'Edit template' : 'New template'}
              </CoolH1>
            }
            extra={[
              <Button variant="outline" icon="DataTable" disabled>
                Import from CSV
              </Button>,
              <Button variant="outline" icon="Copy" disabled>
                Copy from existing
              </Button>,
            ]}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <WithLabel title="Template ID *">
            <Input
              placeholder="Create a unique ID"
              value={template.externalId}
              disabled={!!initialTemplate}
              onChange={externalId => setTemplate({ ...template, externalId })}
            />
          </WithLabel>
        </Col>
        <Col span={8}>
          <WithLabel title="Template name">
            <Input
              value={template.name}
              onChange={name => setTemplate({ ...template, name })}
            />
          </WithLabel>
        </Col>
        <Col span={8}>
          <WithLabel title="Owner">
            <Input disabled />
          </WithLabel>
        </Col>
        <Col span={24}>
          <WithLabel title="Description">
            <TextArea
              value={template.description}
              onChange={description =>
                setTemplate({ ...template, description })
              }
            />
          </WithLabel>
        </Col>
      </Row>
      <Divider />
      <CoolH1>Properties</CoolH1>
      <Table
        columns={[
          {
            title: 'ID *',
            dataIndex: 'propertyId',
            render: (id, _, index) => {
              return (
                <Input
                  value={id}
                  onChange={val => {
                    updateValue(`properties[${index}].propertyId`, val);
                  }}
                />
              );
            },
          },
          {
            title: 'Name',
            dataIndex: 'name',
            render: (name, _, index) => {
              return (
                <Input
                  value={name}
                  onChange={val => {
                    updateValue(`properties[${index}].name`, val);
                  }}
                />
              );
            },
          },
          {
            title: 'Description',
            dataIndex: 'description',
            render: (description, _, index) => {
              return (
                <Input
                  value={description}
                  onChange={val => {
                    updateValue(`properties[${index}].description`, val);
                  }}
                />
              );
            },
          },
          {
            title: 'Type',
            dataIndex: 'type',
            width: 160,
            render: (type, _, index) => {
              return (
                <SelectWrapper>
                  <Select
                    style={{
                      width: '100%',
                    }}
                    value={type}
                    onChange={(val: string) => {
                      updateValue(`properties[${index}].type`, val);
                    }}
                  >
                    {CogniteTypePropertyTypes.map(key => (
                      <Select.Option value={key}>
                        {CogniteTypePropertyTypesMap[key]}
                      </Select.Option>
                    ))}
                  </Select>
                </SelectWrapper>
              );
            },
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, _2, index) => (
              <Button
                variant="outline"
                icon="Delete"
                onClick={() => {
                  setTemplate({
                    ...template,
                    properties: template
                      .properties!.slice(0, index)
                      .concat(template.properties!.slice(index + 1)),
                  });
                }}
                style={{
                  color: Colors.danger.hex(),
                  borderColor: Colors.danger.hex(),
                }}
              />
            ),
          },
        ]}
        locale={{ emptyText: <span>No Properties Defined</span> }}
        dataSource={template.properties}
        pagination={false}
        footer={() => (
          <Button
            icon="Plus"
            onClick={() => {
              const updatedTemplate = { ...template };
              updatedTemplate.properties!.push({
                propertyId: '',
                type: 'timeseriesRef',
              });
              setTemplate(updatedTemplate);
            }}
          >
            Add Property
          </Button>
        )}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 30px;
  background-color: white;
  width: 100%;
`;
export default NewTemplateForm;
