import React from 'react';
import styled from 'styled-components';
import { Colors } from '@cognite/cogs.js';

export const Wrapper = styled.div<{ clickable: boolean; disabled: boolean }>`
  text-align: center;
  background: ${props => {
    if (props.disabled) {
      return Colors['greyscale-grey2'].hex();
    }
    if (props.clickable) {
      return Colors['purple-4'].hex();
    }
    return '#fff';
  }};
  color: ${props => {
    if (props.disabled) {
      return Colors['greyscale-grey6'].hex();
    }
    if (props.clickable) {
      return '#fff';
    }
    return 'inherit';
  }};
  cursor: ${props => {
    if (props.disabled) {
      return 'pointer';
    }
    if (props.clickable) {
      return 'pointer';
    }
    return 'default';
  }};
  border-radius: 4px;
  padding: 4px;
  padding-left: 16px;
  padding-right: 16px;
  p {
    margin-bottom: 0px;
    font-size: 12px;
  }
  .count {
    font-size: 18px;
    font-weight: 800;
  }
`;

export const PropertySummaryIndicator = ({
  disabled = false,
  text,
  count,
  onClick,
  style,
}: {
  disabled?: boolean;
  text: React.ReactNode;
  count: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  style?: React.CSSProperties;
}) => {
  return (
    <Wrapper
      onClick={onClick}
      style={style}
      clickable={!!onClick}
      disabled={disabled}
    >
      <p>{text}</p>
      {typeof count === 'string' || typeof count === 'number' ? (
        <p className="count">{count}</p>
      ) : (
        count
      )}
    </Wrapper>
  );
};
