import React from 'react';

interface ErrorBoundaryProps {
  children: React.ReactNode | React.ReactNode[];
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = { hasError: false };

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Something went wrong.</h1>
        </div>
      );
    }
    return this.props.children;
  }
}

export { ErrorBoundary };
