import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { uniq } from 'lodash';
import {
  buildModelGetPredictions,
  reset,
  ModelType,
} from 'modules/contextualization/';
import {
  dataKitItemsSelector,
  SelectionFilter,
  update as updateSelection,
} from 'modules/selection';
import { RootState } from 'reducers';
import { createSelector } from 'reselect';
import { trackUsage } from 'utils/Metrics';
import { Resource } from 'modules/contextualization/predictions';
import ResourceLoadingFeedbackModal from '../containers/Contextualization/EntityMatching/ResourceLoadingFeedbackModal';

// SELECTORS
export function useModelSelector(assetsDataKitId: string) {
  const { id: modelId, done: modelDone } = useSelector(
    (s: RootState) => s.contextualization.models[assetsDataKitId] || {}
  );
  return { modelId, modelDone };
}
export function usePredictSelector(resourceDataKitId: string) {
  const { id: predictId, done: predictDone } = useSelector(
    (s: RootState) => s.contextualization.predictions[resourceDataKitId] || {}
  );
  return { predictId, predictDone };
}
export function useRuleSelector(resourceDataKitId: string) {
  const { id: ruleId, done: ruleDone } = useSelector(
    (s: RootState) => s.contextualization.rules[resourceDataKitId] || {}
  );
  return { ruleId, ruleDone };
}

// CUSTOM HOOKS
export function useBuildModelGetPredictions(
  modelType: ModelType = 'simple',
  filter?: SelectionFilter
) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [ready, setReady] = useState(false);
  const [properties, setProperties] = useState();
  const { resourceDataKitId, assetsDataKitId } = useParams<{
    resourceDataKitId: string;
    assetsDataKitId: string;
  }>();

  useEffect(() => {
    if (ready) {
      trackUsage('Contextualization.CreateEMModels');
      if (filter) {
        dispatch(
          updateSelection({
            id: resourceDataKitId,
            filter,
          })
        );
      }
      dispatch(
        buildModelGetPredictions(
          assetsDataKitId,
          resourceDataKitId,
          modelType,
          properties
        )
      );
    }
  }, [
    dispatch,
    ready,
    properties,
    filter,
    assetsDataKitId,
    resourceDataKitId,
    location.key,
    modelType,
  ]);

  return (isReady: boolean, prop?: any) => {
    setReady(isReady);
    if (prop) {
      setProperties(prop);
    }
  };
}

export function useIsReady(resourceDataKitId: string) {
  const { filter } = useSelector(
    (state: RootState) => state.selection.items[resourceDataKitId]
  );
  const { items: resources, done: resourcesFetched } = useSelector(
    dataKitItemsSelector
  )(resourceDataKitId, true, true) as {
    done: boolean;
    items: Resource[];
  };

  return (ready: boolean) => {
    if (
      ready &&
      filter === 'missingAssetId' &&
      resourcesFetched &&
      resources.filter(t => !t.assetId).length === 0
    ) {
      return true;
    }
    return false;
  };
}

export function useLoadFeedbackModel(
  assetsDataKitId: string,
  resourceDataKitId: string,
  modelType: ModelType,
  properties: any
) {
  const dispatch = useDispatch();
  const retry = () => {
    dispatch(reset(assetsDataKitId, resourceDataKitId));
    dispatch(
      buildModelGetPredictions(
        assetsDataKitId,
        resourceDataKitId,
        modelType,
        properties
      )
    );
  };

  return (
    <ResourceLoadingFeedbackModal
      assetDataKitId={assetsDataKitId}
      resourceDataKitId={resourceDataKitId}
      retry={retry}
    />
  );
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current as T;
}

const metadataSelector = (resourceType: string) =>
  createSelector(
    // @ts-ignore:next-line
    (state: RootState) => state[resourceType].items.items,
    items => {
      return () =>
        Object.values(items.toJS())
          .filter((item: any) => item.metadata)
          .map((item: any) => ({
            id: item.id,
            metadata: Object.keys(item.metadata),
          }));
    }
  );

export const useMetadataSelectedResources = (
  resourceType: any,
  dataKitId: string
) => {
  let selectedItemsIds: string[] = [];
  let filteredResources: any[] = [];
  const dataSelector: any = useSelector(metadataSelector(resourceType));
  const selectedData: any = useSelector(
    (state: RootState) => state.selection.items[dataKitId]
  );
  if (Array.isArray(selectedData.query)) {
    selectedItemsIds = selectedData.query.map((item: any) => item.id);
    filteredResources = dataSelector().filter(
      (resource: any) =>
        resource.metadata && selectedItemsIds.includes(resource.id)
    );
  } else {
    // when dataset is selected as a search filter, query is an object, not array
    filteredResources = dataSelector();
  }
  filteredResources = uniq(
    filteredResources
      .map((item: any) => item.metadata)
      .reduce((original: any[], reduced: any[]) => original.concat(reduced), [])
  );
  return filteredResources;
};
