import producer from 'immer';
import { createSelector } from 'reselect';
import { Action, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { callUntilCompleted } from 'helpers/Helpers';
import { RootState } from 'reducers';

import { dataKitItemMapSelector } from 'modules/selection';
import {
  ModelStatus,
  ModelState,
  apiRootPath,
  modelDefaultState,
} from './models';
import { Prediction, getStagedChangesByIdSelector } from './predictions';

const RULES_CREATE_STARTED = 'contextualization/RULES_CREATE_STARTED';
const RULES_CREATED = 'contextualization/RULES_CREATED';
const RULES_STATUS_UPDATED = 'contextualization/RULES_STATUS_UPDATED';
const RULES_DONE = 'contextualization/RULES_DONE';
const RULES_ERROR = 'contextualization/RULES_ERROR';
const RULES_RESET = 'contextualization/RULES_RESET';

interface RulesStartedAction extends Action<typeof RULES_CREATE_STARTED> {
  dataKitId: string;
}
interface RulesCreatedAction extends Action<typeof RULES_CREATED> {
  dataKitId: string;
  jobId: number;
}
interface RulesStatusUpdatedAction extends Action<typeof RULES_STATUS_UPDATED> {
  dataKitId: string;
  status: ModelStatus;
}
interface RulesDoneAction extends Action<typeof RULES_DONE> {
  dataKitId: string;
  rules: Rule[];
}
interface RulesErrorAction extends Action<typeof RULES_ERROR> {
  dataKitId: string;
}
interface RulesResetAction extends Action<typeof RULES_RESET> {
  dataKitId: string;
}

type RulesActions =
  | RulesStartedAction
  | RulesCreatedAction
  | RulesStatusUpdatedAction
  | RulesDoneAction
  | RulesErrorAction
  | RulesResetAction;

const createRulesPath = (project: string) => `${apiRootPath(project)}/rules`;
const getRulesPath = (project: string, jobId: number) =>
  `${apiRootPath(project)}/rules/${jobId}`;

export function getRules(dataKitId: string) {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState: () => RootState
  ) => {
    const {
      app: { sdk },
      contextualization,
    } = getState();
    if ((contextualization.rules[dataKitId] || {}).done) {
      return Promise.resolve(contextualization.predictions[dataKitId].id);
    }
    if ((contextualization.rules[dataKitId] || {}).started) {
      return Promise.reject();
    }
    const { predictions } = contextualization.predictions[dataKitId];
    if (predictions.length === 0) {
      return Promise.reject();
    }
    const validPredictions: Prediction[] = predictions.filter(
      prediction => !!prediction.predictedId
    );
    const input = validPredictions.map((prediction: any) => ({
      input: prediction.matchFrom.name,
      predicted: prediction.matches[0].matchTo.name,
      score: prediction.matches[0].score,
    }));

    dispatch({ type: RULES_CREATE_STARTED, dataKitId });
    return new Promise((resolve, reject) => {
      sdk
        .post(createRulesPath(sdk.project), {
          data: {
            items: input,
          },
        })
        .then(response => {
          const {
            status: httpStatus,
            data: { jobId, status: queueStatus },
          } = response;

          dispatch({
            type: RULES_CREATED,
            jobId,
            dataKitId,
          });

          dispatch({
            type: RULES_STATUS_UPDATED,
            jobId,
            dataKitId,
            status: queueStatus,
          });

          if (httpStatus === 200) {
            callUntilCompleted(
              () => sdk.get(getRulesPath(sdk.project, jobId)),
              data => data.status === 'Completed' || data.status === 'Failed',
              data => {
                const rules = data.items.map((rule: ApiRule) => ({
                  ...rule,
                  matches: rule.matchIndex.map(
                    index => validPredictions[index]
                  ),
                  matchIndex: undefined,
                }));
                dispatch({
                  type: RULES_DONE,
                  jobId,
                  dataKitId,
                  rules,
                });
                resolve(jobId);
              },
              data =>
                dispatch({
                  type: RULES_STATUS_UPDATED,
                  jobId,
                  dataKitId,
                  status: data.status,
                }),
              () => {
                dispatch({
                  type: RULES_STATUS_UPDATED,
                  jobId,
                  dataKitId,
                  status: 'Failed',
                });
                dispatch({ type: RULES_ERROR, dataKitId });
                reject();
              }
            );
          } else {
            dispatch({ type: RULES_ERROR, dataKitId });
            reject();
          }
        })
        .catch(() => {
          dispatch({ type: RULES_ERROR, dataKitId });
          reject();
        });
    });
  };
}

export function downloadRules(jobId: string, dataKitId: string) {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState: () => RootState
  ) => {
    const {
      app: { sdk },
      contextualization: { predictions, rules },
    } = getState();

    if (rules[jobId] && rules[jobId].done) {
      return;
    }

    const { predictions: prediction } = predictions[dataKitId];
    if (!prediction || prediction.length === 0) {
      throw new Error('Predictions missing');
    }

    const validPredictions: Prediction[] = prediction.filter(
      p => !!p.predictedId
    );

    dispatch({ type: RULES_CREATE_STARTED, dataKitId });
    dispatch({
      type: RULES_CREATED,
      jobId,
      dataKitId,
    });

    try {
      const { data } = await sdk.get(
        getRulesPath(sdk.project, parseInt(jobId, 10))
      );
      const newRules = (data.items || []).map((rule: ApiRule) => ({
        ...rule,
        matches: rule.matchIndex.map(index => validPredictions[index]),
        matchIndex: undefined,
      }));
      dispatch({
        type: RULES_DONE,
        jobId,
        dataKitId,
        rules: newRules,
      });
      dispatch({
        type: RULES_STATUS_UPDATED,
        jobId,
        dataKitId,
        status: data.status,
      });
    } catch (e) {
      dispatch({ type: RULES_ERROR, dataKitId });
      throw e;
    }
  };
}

export function resetRules(dataKitId: string) {
  return {
    type: RULES_RESET,
    dataKitId,
  };
}

interface RuleState extends ModelState {
  rules: Rule[];
}

export const ruleDefaultState: RuleState = Object.freeze({
  ...modelDefaultState,
  rules: [],
});

interface ApiRule {
  inputPattern: string;
  predictPattern: string;
  avgScore: number;
  matchIndex: number[];
}
export interface Rule {
  inputPattern: string;
  predictPattern: string;
  avgScore: number;
  matches: Prediction[];
}

interface RuleStore {
  [dataKitId: string]: RuleState;
}

export default function reducer(
  state: RuleStore = {},
  action: RulesActions
): RuleStore {
  return producer(state, draft => {
    const id = action.dataKitId || -1;
    switch (action.type) {
      case RULES_CREATE_STARTED: {
        draft[id] = { ...ruleDefaultState };
        draft[id].started = true;
        break;
      }

      case RULES_CREATED: {
        draft[id].id = action.jobId;
        break;
      }

      case RULES_STATUS_UPDATED: {
        draft[id].status = action.status;
        break;
      }
      case RULES_DONE: {
        draft[id].done = true;
        draft[id].rules = action.rules;
        break;
      }
      case RULES_RESET: {
        draft[id] = ruleDefaultState;
        break;
      }
      case RULES_ERROR: {
        draft[id].error = true;
        draft[id].started = false;
        draft[id].status = 'Failed';
        break;
      }
    }
  });
}

export const getRulesSelector = createSelector(
  (state: RootState) => state.contextualization.rules,
  rules => (id: string) => rules[id] || ruleDefaultState
);

export const getRulesWithApplicableMatches = createSelector(
  dataKitItemMapSelector,
  getStagedChangesByIdSelector,
  getRulesSelector,
  (
    dataKitMapSelector,
    getChanges: ReturnType<typeof getStagedChangesByIdSelector>,
    getRulesFn: ReturnType<typeof getRulesSelector>
  ) => (resourceDKId: string, assetDKId: string) => {
    const { items: resources } = dataKitMapSelector(resourceDKId, true);
    const stagedChanges = getChanges(resourceDKId, assetDKId);
    const { rules } = getRulesFn(resourceDKId);

    return rules.map(rule => ({
      ...rule,
      matches: rule.matches
        .filter(r => !stagedChanges[r.matches[0]?.matchTo.id])
        .map(p => ({ ...p, resource: resources[p.matchFrom.id] }))
        .sort((a, b) => b.score - a.score),
    }));
  }
);
