import { Limit } from '@cognite/sdk';

export type ResourceType =
  | 'playgroundAssets'
  | 'assets'
  | 'files'
  | 'timeseries'
  | 'functions'
  | 'events'
  | 'sequences'
  | 'types';

export type CountableResourceType =
  | 'assets'
  | 'playgroundAssets'
  | 'files'
  | 'timeseries'
  | 'sequences'
  | 'events';

export const isCountable = (
  type: ResourceType
): type is CountableResourceType => {
  switch (type) {
    case 'assets':
    case 'files':
    case 'timeseries':
    case 'events':
      return true;
    default:
      return false;
  }
};

export interface ApiCall {
  fetching: boolean;
  error: boolean;
  done: boolean;
}
export interface ApiResult extends ApiCall {
  ids: number[];
}

export interface Result<T> extends ApiResult {
  items: T[];
  progress: number;
  nextCursor?: string;
}

export interface Query extends Limit {
  filter?: any;
}
