import React, { useState, useEffect } from 'react';
import { Row, Col, Empty, message } from 'antd';
import {
  Card,
  SmallTitle,
  XOMHeader,
  Loader,
  SuggestionsTable,
} from 'components/Common';
import { useSelector, useDispatch } from 'react-redux';
import {
  retrieve as retrieveTypes,
  itemSelector as typeSelector,
  CogniteTypeProperty,
  CogniteTypePopulatedProperty,
  CogniteType,
} from 'modules/types';
import {
  selectInstancesUnderType,
  listAllRootAssetsIfNeeded,
  addPropertyFromType,
  listAndCountInstancesForTypeId,
  Instance,
} from 'modules/instances';
import { useParams, useHistory } from 'react-router-dom';
import { retrieve, itemSelector } from 'modules/timeseries';
import { GetTimeSeriesMetadataDTO } from '@cognite/sdk';
import styled from 'styled-components';
import {
  selectSuggestion,
  loadCompletionModel,
} from 'modules/templateCompletion';
import { Button, Icon } from '@cognite/cogs.js';
import ScalingUseCaseRoutes from 'containers/ScalingUseCase/ScalingUseCaseRoutes';
import { HeaderButtons } from 'containers/ScalingUseCase/Common';
import { AssetHoverPreview } from 'containers/HoverPreview';
import { ResourceSidebar } from 'containers/ResourceSidebar';
import { InstanceTimeseriesPopover } from '../Instance/InstanceTimeseriesPopover';

const SuggestionSection = styled(Card)`
  margin: 40px 130px;
  .content {
    display: flex;
  }
  .content > * {
    flex: 1;
    align-items: stretch;
    position: relative;
  }

  .content .left {
    position: relative;
  }

  .content .right {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const GreenBox = styled.div`
  border-radius: 4px;
  background-color: #2acf5844;
  padding: 8px;
  display: flex;
  margin-bottom: 16px;
`;

export const Suggestions = () => {
  const { tenant, templateId } = useParams<{
    tenant: string;
    templateId: string;
    propertyId?: string;
  }>();

  const [confirmedSuggestions, setConfirmedSuggestions] = useState<{
    [key: string]:
      | {
          assetId: number;
          propertyId: string;
          tsId: number | null | undefined;
        }
      | undefined;
  }>({});

  const typeId = Number.isNaN(Number(templateId)) ? -1 : Number(templateId);
  const dispatch = useDispatch();
  const history = useHistory();
  const type = useSelector(typeSelector)(typeId)!;
  const getTimeseries = useSelector(itemSelector);

  useEffect(() => {
    if (typeId) {
      dispatch(retrieveTypes([{ id: typeId }]));
    }
  }, [dispatch, typeId]);

  useEffect(() => {
    if (type) {
      dispatch(listAndCountInstancesForTypeId(type.id, true));
    }
  }, [dispatch, type]);

  useEffect(() => {
    if (type) {
      dispatch(loadCompletionModel(type.id));
    }
  }, [dispatch, type]);
  const instances = useSelector(selectInstancesUnderType)(typeId, true);
  const suggestions = useSelector(selectSuggestion)(typeId);
  useEffect(() => {
    if (instances && instances.items) {
      dispatch(listAllRootAssetsIfNeeded(instances.items));
    }
  }, [dispatch, instances]);

  const timeSeriesIds = new Set<number>();

  if (suggestions) {
    suggestions.items.forEach(column =>
      column.items.forEach(match => {
        if (match.foundId) {
          timeSeriesIds.add(match.foundId);
        }
        if (match.foundIds) {
          timeSeriesIds.add(match.foundIds[0]);
        }
        if (match.currentId) {
          timeSeriesIds.add(match.currentId);
        }
      })
    );
  }

  const timeSeries = Array.from(timeSeriesIds)
    .map(el => getTimeseries(el))
    .filter(el => !!el) as GetTimeSeriesMetadataDTO[];

  useEffect(() => {
    if (timeSeriesIds.size > 0 && timeSeries.length !== timeSeriesIds.size) {
      dispatch(
        retrieve(
          Array.from(timeSeriesIds)
            .filter(id => !!id)
            .map(id => ({ id }))
        )
      );
    }
  }, [dispatch, timeSeriesIds, timeSeries]);

  const addSuggestionsToType = async () => {
    const updates = Object.values(confirmedSuggestions)
      .map(confirmedSuggestion => {
        if (!confirmedSuggestion) {
          return undefined;
        }
        const instance = instances.items.find(
          i => i.id === confirmedSuggestion.assetId
        );
        const typeWithProperties = instance?.types?.find(
          el => el.type.id === type.id && el.type.version === type.version
        );

        const properties: ({ value: any } & CogniteTypeProperty)[] =
          typeWithProperties?.properties || [];
        const property = properties.find(
          p => p.propertyId === confirmedSuggestion.propertyId
        );

        if (instance && property && type) {
          return {
            instance,
            type,
            property: {
              ...property,
              value: { id: confirmedSuggestion.tsId },
            },
          };
        }
        message.error('Failed to store suggestions');
        return undefined;
      })
      .filter(el => !!el) as {
      instance: Instance;
      type: CogniteType;
      property: CogniteTypePopulatedProperty;
    }[];
    await dispatch(addPropertyFromType(updates));
    message.success(`${updates.length} suggestions successfully applied!`);
    dispatch(loadCompletionModel(typeId, true));
    history.push(`/${tenant}/templates/${templateId}`);
  };

  if (typeId === -1 || !type) {
    return <Empty description="Invalid Template Selected" />;
  }

  return (
    <>
      <XOMHeader
        onBackClicked={() => history.push(`/${tenant}/templates/${templateId}`)}
        breadcrumbs={ScalingUseCaseRoutes.breadcrumbs}
        path={`/${tenant}/templates`}
        title={`Suggestions: ${
          type ? type.name || type.externalId : 'Loading...'
        }`}
      >
        <HeaderButtons>
          <Button onClick={() => setConfirmedSuggestions({})}>Reset</Button>
          <Button
            onClick={() => {
              setConfirmedSuggestions(
                (suggestions ? suggestions.items : []).reduce(
                  (prev, el) => ({
                    ...prev,
                    ...el.items.reduce(
                      (innerPrev, item) => ({
                        ...innerPrev,
                        [`${item.assetId}-${el.column}`]: {
                          assetId: item.assetId,
                          propertyId: el.column,
                          tsId: item.foundId || (item.foundIds || [])[0],
                        },
                      }),
                      {}
                    ),
                  }),
                  {}
                )
              );
            }}
          >
            Select All
          </Button>
          <Button type="primary" onClick={addSuggestionsToType}>
            COMMIT
          </Button>
        </HeaderButtons>
      </XOMHeader>
      {suggestions && suggestions.fetching && (
        <Loader>
          <p style={{ marginLeft: '12px', marginBottom: 0 }}>
            Calculating suggestions...
          </p>
        </Loader>
      )}
      <div style={{ flex: 1, overflowY: 'auto' }}>
        {(suggestions ? suggestions.items : []).map(el => {
          const suggestionsForColumns = Object.values(confirmedSuggestions)
            .filter(it => it && it.propertyId === el.column)
            .reduce(
              (prev, u) => ({ ...prev, [u!.assetId]: u!.tsId }),
              {} as { [key: number]: number | undefined | null }
            );

          const amountConfirmed = Object.values(confirmedSuggestions).filter(
            u => !!u && u.propertyId === el.column
          ).length;
          return (
            <SuggestionSection key={el.column}>
              <Row>
                <Col span={14}>
                  <SuggestionsTable
                    item={el}
                    onSelect={(assetId, timeseriesIds) => {
                      setConfirmedSuggestions({
                        ...confirmedSuggestions,
                        [`${assetId}-${el.column}`]: {
                          assetId,
                          propertyId: el.column,
                          tsId: timeseriesIds,
                        },
                      });
                    }}
                    onSkip={assetId => {
                      setConfirmedSuggestions({
                        ...confirmedSuggestions,
                        [`${assetId}-${el.column}`]: undefined,
                      });
                    }}
                    onAcceptAll={() => {
                      setConfirmedSuggestions({
                        ...confirmedSuggestions,
                        ...el.items.reduce(
                          (prev, item) => ({
                            ...prev,
                            [`${item.assetId}-${el.column}`]: {
                              assetId: item.assetId,
                              propertyId: el.column,
                              tsId: item.foundId || (item.foundIds || [])[0],
                            },
                          }),
                          {}
                        ),
                      });
                    }}
                    onSkipAll={() => {
                      setConfirmedSuggestions({
                        ...confirmedSuggestions,
                        ...el.items.reduce(
                          (prev, item) => ({
                            ...prev,
                            [`${item.assetId}-${el.column}`]: undefined,
                          }),
                          {}
                        ),
                      });
                    }}
                    confirmedSuggestions={suggestionsForColumns}
                    renderInstancePopover={assetId => {
                      const instance = instances.items.find(
                        i => i.id === assetId
                      );
                      if (instance) {
                        return <AssetHoverPreview asset={instance} />;
                      }
                      return null;
                    }}
                    renderTimeseriesHover={(assetId, propertyId, tsId) => {
                      const instance = instances.items.find(
                        i => i.id === assetId
                      );
                      const typeWithProperties = (instance
                        ? instance.types || []
                        : []
                      ).find(
                        item =>
                          item.type.id === type.id &&
                          item.type.version === type.version
                      );
                      const property:
                        | CogniteTypePopulatedProperty
                        | undefined = (
                        typeWithProperties?.properties || []
                      ).find(p => p.propertyId === propertyId);
                      const ts = getTimeseries(tsId);
                      if (instance && property && ts) {
                        return (
                          <InstanceTimeseriesPopover
                            instance={instance}
                            property={property}
                            timeseries={ts}
                          />
                        );
                      }
                      return null;
                    }}
                  />
                </Col>
                <Col
                  span={10}
                  style={{
                    padding: 24,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <SmallTitle style={{ marginTop: 0, marginBottom: 0 }}>
                    We found {el.items.length} potential candidates for{' '}
                    <strong>{el.column}</strong>.
                  </SmallTitle>
                  {amountConfirmed > 0 && (
                    <GreenBox>
                      <Icon
                        type="Check"
                        style={{ marginRight: 8, marginTop: 3 }}
                      />
                      <SmallTitle style={{ marginTop: 0, marginBottom: 0 }}>
                        {amountConfirmed} suggestions confirmed
                      </SmallTitle>
                    </GreenBox>
                  )}
                  <p>Template: {type.name}</p>
                  <p>Property: {el.column}</p>
                  {el.patterns.map(item => (
                    <p key={item.pattern}>
                      {/** @ts-ignore */}
                      {item.pattern}: {item.currentMatches}
                    </p>
                  ))}
                </Col>
              </Row>
            </SuggestionSection>
          );
        })}
      </div>
      <ResourceSidebar />
    </>
  );
};
