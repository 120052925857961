import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import {
  Templates,
  ViewTemplate,
  Suggestions,
} from 'containers/ScalingUseCase/Templates';
import { itemSelector } from 'modules/types';
import { BreadcrumbRouteMap } from 'components/Breadcrumbs/Breadcrumbs';
import styled from 'styled-components';
import { RootState } from 'reducers';
import { selectInstance } from 'modules/instances';
import { Instances } from './Instance/Instances';
import { Properties } from './Property/Properties';

function ScalingUseCaseRoutes() {
  const match = useRouteMatch();

  return (
    <Wrapper>
      <Switch>
        <Route exact path={`${match.path}/`} component={Templates} />
        <Route
          exact
          path={`${match.path}/:templateId/suggestions/:propertyId?`}
          component={Suggestions}
        />
        <Route
          exact
          path={`${match.path}/:templateId`}
          component={ViewTemplate}
        />
        <Route
          exact
          path={`${match.path}/:templateId/instances/:instanceId`}
          component={Instances}
        />
        <Route
          path={`${match.path}/:templateId/instances/:instanceId/properties/:propertyId/:fileId?`}
          component={Properties}
        />
      </Switch>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: -30px -30px;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const breadcrumbs = (mountedAt: string) => (
  state: RootState
): BreadcrumbRouteMap => {
  const typesMap = itemSelector(state);
  const getInstance = selectInstance(state);
  return {
    [`${mountedAt}`]: ['Data Templates'],
    [`${mountedAt}/:templateId`]: ({ templateId }) => [
      typesMap(Number(templateId))
        ? `Template: ${typesMap(Number(templateId))!.name}`
        : 'Loading...',
    ],
    [`${mountedAt}/:templateId/instances`]: ['Instances'],
    [`${mountedAt}/:templateId/instances/:instanceId`]: ({ instanceId }) => [
      getInstance(Number(instanceId))
        ? `Instance: ${getInstance(Number(instanceId))!.name}`
        : 'Loading...',
    ],
    [`${mountedAt}/:templateId/instances/:instanceId/:propertyId`]: () => [
      'Property',
    ],
    [`${mountedAt}/:templateId/instances/:instanceId/:propertyId/edit`]: () => [
      'Edit Property',
    ],
    [`${mountedAt}/:templateId/instances/:instanceId/:propertyId/edit/search`]: () => [
      'Search',
    ],
    [`${mountedAt}/:templateId/suggestions`]: () => ['Suggestions'],
  };
};
ScalingUseCaseRoutes.breadcrumbs = breadcrumbs;

export default ScalingUseCaseRoutes;
