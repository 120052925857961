import { Action, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IdInfo } from '@cognite/sdk';
import { RootState } from 'reducers';
import { fetchUserGroups } from './app';
import { clearLocalStorage, loadInitialStorage } from './selection';

const LOCAL_STORAGE_TENANT = 'TENANT';

const STATUS = 'login/STATUS';
const STATUS_DONE = 'login/STATUS_DONE';
const STATUS_ERROR = 'login/STATUS_ERROR';
const AUTH = 'login/AUTH';
const AUTH_DONE = 'login/AUTH_DONE';
const AUTH_ERROR = 'login/AUTH_ERROR';

interface AuthAction extends Action<typeof AUTH> {}

interface AuthDoneAction extends Action<typeof AUTH_DONE> {
  status: boolean;
}

interface AuthErrorAction extends Action<typeof AUTH_ERROR> {}

interface StatusAction extends Action<typeof STATUS> {}

interface StatusDoneAction extends Action<typeof STATUS_DONE> {
  status: IdInfo;
}

interface StatusErrorAction extends Action<typeof STATUS_ERROR> {}

export function authenticate(tenant: string) {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState: () => RootState
  ) => {
    const { authPending, isAuthenticated } = getState().login;
    if (authPending || isAuthenticated) {
      return;
    }
    dispatch({
      type: AUTH,
    });

    const { sdk } = getState().app;

    try {
      // Decide how to deal with tenant and load storage accordingly
      if (localStorage.getItem(LOCAL_STORAGE_TENANT) !== tenant) {
        await dispatch(clearLocalStorage());
      } else {
        await dispatch(loadInitialStorage());
      }
      localStorage.setItem(LOCAL_STORAGE_TENANT, tenant);

      await sdk.loginWithOAuth({ project: tenant });
      const authStatus = await sdk.authenticate();
      if (authStatus) {
        dispatch({
          type: AUTH_DONE,
        });
        dispatch(fetchUserGroups());
        dispatch(status());
      } else {
        dispatch({
          type: AUTH_ERROR,
        });
      }
    } catch (e) {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  };
}

export function status() {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState: () => RootState
  ) => {
    dispatch({
      type: STATUS,
    });
    const { sdk } = getState().app;
    try {
      const response = await sdk.login.status();
      dispatch({
        type: STATUS_DONE,
        status: response,
      });
    } catch (e) {
      dispatch({
        type: STATUS_ERROR,
      });
    }
  };
}

type Actions =
  | AuthAction
  | AuthDoneAction
  | AuthErrorAction
  | StatusAction
  | StatusDoneAction
  | StatusErrorAction;

const initialState = {
  fetching: false,
  done: false,
  authPending: false,
  isAuthenticated: false,
};

export interface LoginStore {
  fetching: boolean;
  done?: boolean;
  authPending: boolean;
  isAuthenticated: boolean;
  error?: boolean; // TODO
  status?: IdInfo;
}

export default function reducer(
  state: LoginStore = initialState,
  action: Actions
) {
  switch (action.type) {
    case AUTH: {
      return {
        ...state,
        authPending: true,
      };
    }
    case AUTH_DONE: {
      return {
        ...state,
        authPending: false,
        isAuthenticated: true,
      };
    }
    case AUTH_ERROR: {
      return {
        ...state,
        authPending: false,
        isAuthenticated: false,
      };
    }
    case STATUS: {
      return {
        ...state,
        fetching: true,
      };
    }
    case STATUS_DONE: {
      return {
        ...state,
        fetching: false,
        error: false,
        done: true,
        status: action.status,
      };
    }
    case STATUS_ERROR: {
      return {
        ...state,
        fetching: false,
        error: true,
      };
    }
    default: {
      return state;
    }
  }
}

// selectors
export function isCogniteUser(state: RootState): boolean {
  const {
    login: { done, status: loginStatus },
  } = state;

  if (done && loginStatus && typeof loginStatus.user === 'string') {
    const userEmail = loginStatus.user;
    return (
      userEmail.search(/cognite.com$/) !== -1 ||
      userEmail.search(/cognitedata.com$/) !== -1
    );
  }
  return false;
}

export function selectUser(state: RootState): string {
  const {
    login: { done, status: loginStatus },
  } = state;

  if (done && loginStatus && typeof loginStatus.user === 'string') {
    return loginStatus.user;
  }
  return '';
}
