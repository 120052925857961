import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, Redirect } from 'react-router-dom';
import { RootState } from 'reducers';
import styled from 'styled-components';
import { Icon, Button } from '@cognite/cogs.js';
import { Popover, Modal, List, Card, Radio, Tooltip } from 'antd';
import { SelectionFilter, update as updateSelection } from 'modules/selection';
import { ModelType } from 'modules/contextualization/';
import {
  useIsReady,
  useBuildModelGetPredictions,
  useLoadFeedbackModel,
  useModelSelector,
  usePredictSelector,
  useRuleSelector,
} from 'hooks/CustomHooks';
import { FilterDropdown } from './FilterDropdown';
import AllMatchedResult from './AllMatchedResult';

const { Meta } = Card;

type TModalProps = {
  visible: boolean;
  changeVisibility: Function;
  redirect?: boolean;
};
type TDirection = 'from' | 'to';

const StyledItem = styled(List.Item)`
  background-color: #f8f8f8;
  padding: 10px;
  .ant-list-item {
    color: red;
  }
`;
const StyledRadio = styled(Radio)`
  display: block;
  height: 30px;
  line-height: 30px;
`;
const StyledFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const SmallText = styled.p`
  font-size: 0.9em;
`;

export const defaultProperties = {
  from: ['name', 'description'],
  to: ['name', 'description'],
};

export default function MatchingFieldsModal({
  visible,
  changeVisibility,
  redirect,
}: TModalProps) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [ready, setReady] = useState(false);
  const [allMatched, setAllMatched] = useState(false);
  const [modelType, setModelType] = useState<ModelType>('simple');
  const [filterResourcesByMatch, setFilterResourcesByMatch] = useState('none');
  const [properties, setProperties] = useState(defaultProperties);
  const { resourceDataKitId, assetsDataKitId } = useParams<{
    assetsDataKitId: string;
    resourceDataKitId: string;
  }>();
  const { modelId, modelDone } = useModelSelector(assetsDataKitId);
  const { predictId, predictDone } = usePredictSelector(resourceDataKitId);
  const { ruleId, ruleDone } = useRuleSelector(resourceDataKitId);

  const typeFrom = useSelector(
    (state: RootState) => state.selection.items[resourceDataKitId].type
  );
  const typeTo = useSelector(
    (state: RootState) => state.selection.items[assetsDataKitId].type
  );

  const loadFeedbackModel = useLoadFeedbackModel(
    assetsDataKitId,
    resourceDataKitId,
    modelType,
    properties
  );
  const buildModelGetPredictions = useBuildModelGetPredictions(modelType);
  const isReady = useIsReady(resourceDataKitId);

  useEffect(() => {
    if (ready) {
      buildModelGetPredictions(ready, properties);
    }
  }, [ready, properties, buildModelGetPredictions]);

  useEffect(() => {
    if (ready) {
      setAllMatched(isReady(ready));
    }
  }, [ready, isReady]);

  useEffect(() => {
    if (ready && allMatched) {
      Modal.info({
        content: <AllMatchedResult />,
      });
    }
  }, [ready, allMatched]);

  if (redirect && !allMatched && modelDone && predictDone && ruleDone) {
    return (
      <Redirect to={`${location.pathname}/${modelId}/${predictId}/${ruleId}`} />
    );
  }

  const handleContinue = () => {
    dispatch(
      updateSelection({
        id: resourceDataKitId,
        filter: filterResourcesByMatch as SelectionFilter,
      })
    );
    changeVisibility(false);
    setReady(!ready);
  };

  const handleNewProperties = (
    option: string,
    checked: boolean,
    direction: TDirection
  ) => {
    if (checked && !properties[direction].includes(option)) {
      const newProperties = {
        ...properties,
        [direction]: properties[direction].concat([option]),
      };
      setProperties(newProperties);
    }
    if (!checked) {
      const newProperties = {
        ...properties,
        [direction]: properties[direction].filter(
          property => property !== option
        ),
      };
      setProperties(newProperties);
    }
  };

  const mapProperties = (direction: TDirection) => {
    if (properties[direction]) {
      return properties[direction].map((property: string) => (
        <StyledItem key={`${property}-${direction}`}>{property}</StyledItem>
      ));
    }
    return <div />;
  };

  return (
    <>
      {ready && !allMatched && loadFeedbackModel}

      <Modal
        visible={visible}
        onCancel={() => changeVisibility(false)}
        title="Select properties to be used for matching entities"
        bodyStyle={{
          maxHeight: '70vh',
          overflowY: 'scroll',
        }}
        width="70vw"
        footer={[
          <Button type="primary" onClick={handleContinue} key="continuebutton">
            Continue
          </Button>,
        ]}
      >
        <SmallText>
          By default, we&apos;ll search for matches between name and description
          field. Using the plus button, add fields that you want to be matched.
          They will show in the list below.
        </SmallText>
        <StyledFlexRow
          style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}
        >
          <List
            style={{ width: '40%' }}
            header={
              <StyledFlexRow style={{ justifyContent: 'space-between' }}>
                <h3>{typeFrom.toUpperCase()} properties</h3>
                <Popover
                  content={
                    <FilterDropdown
                      type={typeFrom}
                      title="Add property"
                      dataKitId={resourceDataKitId}
                      defaultProperties={defaultProperties.from}
                      onPropertiesChange={(option: string, checked: boolean) =>
                        handleNewProperties(option, checked, 'from')
                      }
                    />
                  }
                  placement="bottomRight"
                  trigger="click"
                >
                  <Button icon="LargePlus" type="secondary" />
                </Popover>
              </StyledFlexRow>
            }
          >
            {mapProperties('from')}
          </List>
          <List
            style={{ width: '40%' }}
            header={
              <StyledFlexRow style={{ justifyContent: 'space-between' }}>
                <h3>{typeTo.toUpperCase()} properties</h3>
                <Popover
                  content={
                    <FilterDropdown
                      type={typeTo}
                      title="Add property"
                      dataKitId={assetsDataKitId}
                      defaultProperties={defaultProperties.to}
                      onPropertiesChange={(option: string, checked: boolean) =>
                        handleNewProperties(option, checked, 'to')
                      }
                    />
                  }
                  placement="bottomRight"
                  trigger="click"
                >
                  <Button icon="LargePlus" type="secondary" />
                </Popover>
              </StyledFlexRow>
            }
          >
            {mapProperties('to')}
          </List>
        </StyledFlexRow>
        <div>
          <Card bordered={false}>
            <Meta
              title={
                <StyledFlexRow>
                  Model type
                  <Tooltip
                    title="The entity matcher supports two different model types. 
                      The simple model looks at each part of the entity name and calculates 
                      a similarity score based on that. This is the fastest option. 
                      The bigram option looks at sequences of terms on the name to give 
                      a higher score when pairs of terms occurs next to each other. 
                      This is a slower option."
                  >
                    <Icon type="Info" style={{ marginLeft: '10px' }} />
                  </Tooltip>
                </StyledFlexRow>
              }
              description={
                <StyledRadio.Group
                  style={{ display: 'flex', flexDirection: 'column' }}
                  onChange={e => setModelType(e.target.value)}
                  value={modelType}
                >
                  <StyledRadio value="simple">Simple</StyledRadio>
                  <StyledRadio value="bigram">Bigram</StyledRadio>
                </StyledRadio.Group>
              }
            />
          </Card>
          <Card bordered={false}>
            <Meta
              title={
                <StyledFlexRow style={{ justifyContent: 'space-between' }}>
                  <StyledFlexRow>
                    Scope
                    <Tooltip
                      title="Select whether to match resource that currently do 
                        not have an assosiated asset id or all resources."
                    >
                      <Icon type="Info" style={{ marginLeft: '10px' }} />
                    </Tooltip>
                  </StyledFlexRow>
                </StyledFlexRow>
              }
              description={
                <StyledRadio.Group
                  style={{ display: 'flex', flexDirection: 'column' }}
                  value={filterResourcesByMatch}
                  onChange={e => setFilterResourcesByMatch(e.target.value)}
                >
                  <StyledRadio value="missingAssetId">
                    Unmatched only
                  </StyledRadio>
                  <StyledRadio value="none">All resources</StyledRadio>
                </StyledRadio.Group>
              }
            />
          </Card>
        </div>
        <SmallText>
          <span style={{ fontWeight: 'bold' }}>Protip:</span> This can be
          changed at any point later by clicking the config button.
        </SmallText>
      </Modal>
    </>
  );
}
