import React, { useEffect } from 'react';
import { Table, Empty } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { selectInstance, removePropertyFromType } from 'modules/instances';
import {
  itemSelector as typeSelector,
  CogniteTypePopulatedProperty,
} from 'modules/types';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from '@cognite/cogs.js';
import { itemSelector, retrieve } from 'modules/timeseries';
import { Popover } from 'components/Common';
import { TimeseriesHoverPreview } from 'containers/HoverPreview/TimeseriesHoverPreview';

export const PropertiesPane = ({
  instanceId,
  typeId,
  renderExtraButtons,
  onVisibleChange = () => {},
}: {
  instanceId: number;
  typeId: number;
  renderExtraButtons: (propertyId: string) => React.ReactNode[];
  onVisibleChange?: (
    visible: boolean,
    propertyId: string,
    assetId: number,
    timeseriesId: number
  ) => void;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { tenant } = useParams<{
    tenant: string;
  }>();
  const instance = useSelector(selectInstance)(instanceId);
  const type = useSelector(typeSelector)(typeId)!;
  const timeseriesSelector = useSelector(itemSelector);

  const typeWithProperties = (instance || { types: [] }).types!.find(
    el => el.type.id === type.id && el.type.version === type.version
  );

  const properties: CogniteTypePopulatedProperty[] = typeWithProperties
    ? typeWithProperties.properties || []
    : [];

  useEffect(() => {
    const timeseriesIds = new Set<number>();
    properties.forEach(el => {
      if (
        el.type === 'timeseriesRef' &&
        el.value &&
        !timeseriesSelector(el.value.id)
      ) {
        timeseriesIds.add(el.value.id);
      }
    });
    if (timeseriesIds.size > 0) {
      dispatch(retrieve([...timeseriesIds].map(id => ({ id }))));
    }
  }, [dispatch, properties, timeseriesSelector]);

  if (!instance || !instance.types || !type) {
    return <Empty>Loading...</Empty>;
  }

  if (!typeWithProperties) {
    return <Empty>No Data Found</Empty>;
  }

  return (
    <>
      <Table
        columns={[
          { title: 'Attribute name', dataIndex: 'name' },
          { title: 'Description', dataIndex: 'description' },
          {
            title: 'Linked data',
            render: (value, record) => {
              switch (record.type) {
                case 'timeseriesRef': {
                  const timeseries = record.value
                    ? timeseriesSelector(record.value.id)
                    : undefined;
                  return (
                    <div>
                      {record.value &&
                        (timeseries ? (
                          <Popover
                            trigger="click"
                            key={timeseries.id}
                            onVisibleChange={visible =>
                              onVisibleChange(
                                visible,
                                record.propertyId,
                                instance.id,
                                timeseries.id
                              )
                            }
                            content={
                              <TimeseriesHoverPreview
                                timeseries={timeseries}
                                actions={renderExtraButtons(record.propertyId)}
                              />
                            }
                          >
                            <Button type="link">{timeseries.name}</Button>
                          </Popover>
                        ) : (
                          <p>Loading...</p>
                        ))}
                      <div style={{ display: 'flex' }}>
                        <Button
                          type="primary"
                          size="small"
                          icon={record.value ? 'Edit' : 'Plus'}
                          onClick={() =>
                            history.push(
                              `/${tenant}/templates/${typeId}/instances/${instanceId}/properties/${record.propertyId}`
                            )
                          }
                        >
                          {record.value ? 'Edit data' : 'Add data'}
                        </Button>
                        {record.value && (
                          <Button
                            style={{ marginLeft: '4px' }}
                            type="danger"
                            size="small"
                            icon="Close"
                            onClick={() =>
                              dispatch(
                                removePropertyFromType(
                                  instance,
                                  type,
                                  record.propertyId
                                )
                              )
                            }
                          >
                            Clear
                          </Button>
                        )}
                      </div>
                    </div>
                  );
                }
                default: {
                  return JSON.stringify(value);
                }
              }
            },
            dataIndex: 'value',
          },
          { title: 'Workflow' },
          { title: 'Unit' },
          { title: 'Criticality' },
        ]}
        rowKey="propertyId"
        dataSource={properties}
      />
    </>
  );
};
