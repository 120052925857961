import React from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import App from 'containers/App';
import Login from 'containers/Login';

const history = createBrowserHistory();
function Routes() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/:tenant" component={App} />
      </Switch>
    </Router>
  );
}

export default Routes;
