import { Button } from '@cognite/cogs.js';
import { Col, Row } from 'antd';
import { CountLabel, InstanceCard, SectionCard } from 'components/Common';

import React from 'react';
import styled from 'styled-components';

type Props = {
  onViewAll: () => void;
  onCreate: () => void;
  instances: {
    name: string;
    total: number;
    key: string | number;
    onClick?: () => void;
  }[];
};
export const InstancesCard = (props: Props) => {
  const { onCreate, onViewAll, instances } = props;
  return (
    <SectionCard
      title="Instances"
      extra={[
        <Button
          variant="outline"
          icon="Table"
          style={{ width: 120 }}
          onClick={onViewAll}
          key="view"
        >
          View all
        </Button>,
        <Button
          style={{ width: 130, padding: '10px 0' }}
          type="primary"
          onClick={onCreate}
          key="create"
        >
          Create Instance
        </Button>,
      ]}
    >
      <>
        <CustomRow style={{ marginBottom: 15 }}>
          <p style={{ margin: 0 }}>
            This template has{' '}
            <CountLabel
              value={instances
                .map(i => i.total)
                .reduce((acc, cur) => acc + cur, 0)}
            />{' '}
            instances on <CountLabel value={instances.length} /> assets
          </p>
        </CustomRow>
        <Row gutter={16} type="flex" style={{ marginBottom: -16 }}>
          {instances.map(({ total, name, key, onClick }) => (
            <Col span={8} key={key} style={{ marginBottom: 16 }}>
              <InstanceCard name={name} value={total} onClick={onClick} />
            </Col>
          ))}
        </Row>
      </>
    </SectionCard>
  );
};

const CustomRow = styled.div<{ justify?: string; gutter?: string }>`
  display: flex;
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: center;
`;
