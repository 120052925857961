import React from 'react';
import styled from 'styled-components';
import { CountLabel } from 'components/Common';

type Props = {
  value: number;
  name: string;
  onClick?: React.MouseEventHandler<any>;
};
export const InstanceCard = (props: Props) => {
  const { value, name, onClick } = props;
  return (
    <Container onClick={onClick}>
      <CountLabel value={value} />
      <p>{name}</p>
    </Container>
  );
};

const Container = styled.div`
  padding: 10px 20%;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  border-radius: 4px;
  cursor: pointer;

  & p {
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 0;
    text-align: center;
  }
`;
