import produce from 'immer';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { v4 as uuid } from 'uuid';
import { createSelector } from 'reselect';
import { RootState } from '../reducers/index';
import { ResourceType } from './sdk-builder/types';
import {
  countSelector as countTimeseriesSelector,
  list as listTimeseries,
  count as countTimeseries,
  listParallel as listTimeseriesParallel,
  listSelector as listTimeseriesSelector,
  retrieve as retrieveTimeseries,
  retrieveSelector as retrieveTimeseriesSelector,
  searchSelector as searchTimeseriesSelector,
} from './timeseries';

import {
  countSelector as countFileSelector,
  list as listFiles,
  count as countFiles,
  listSelector as listFileSelector,
  retrieve as retrieveFiles,
  retrieveSelector as retrieveFileSelector,
  searchSelector as searchFileSelector,
} from './files';
import {
  countSelector as countAssetSelector,
  list as listAssets,
  count as countAssets,
  listParallel as listAssetsParallel,
  listSelector as listAssetSelector,
  retrieve as retrieveAssets,
  retrieveSelector as retrieveAssetSelector,
  searchSelector as searchAssetSelector,
} from './assets';
import {
  countSelector as countSequencesSelector,
  list as listSequences,
  count as countSequences,
  listSelector as listSequencesSelector,
  retrieve as retrieveSequences,
  retrieveSelector as retrieveSequencesSelector,
  searchSelector as searchSequencesSelector,
} from './sequences';
import {
  countSelector as countEventsSelector,
  list as listEvents,
  count as countEvents,
  listSelector as listEventsSelector,
  retrieve as retrieveEvents,
  retrieveSelector as retrieveEventsSelector,
  searchSelector as searchEventsSelector,
} from './events';

// Constants
const LOCAL_STORAGE_IDS = 'SELECTION_IDS';

const CREATE_SELECTION = 'selection/CREATE_SELECTION';
const UPDATE_SELECTION = 'selection/UPDATE_SELECTION';
const SELECTION_ERROR = 'selection/SELECTION_ERROR';

export type SelectionFilter = 'none' | 'missingAssetId';

interface Item {
  assetId?: number;
}
const filters = {
  none: (i: Item) => i,
  missingAssetId: (i: Item) => (i.assetId ? undefined : i),
};

export type SelectionEndpointType = 'list' | 'retrieve';

export type ResourceSelection = {
  id: string;
  type: ResourceType;
  endpoint: SelectionEndpointType;
  query: any;
  filter?: SelectionFilter;
};

type ResourceSelectionUpdate = {
  id: string;
  filter?: SelectionFilter;
};

export type PendingResourceSelection = Omit<ResourceSelection, 'id'>;

interface CreateSelectionAction extends Action<typeof CREATE_SELECTION> {
  payload: ResourceSelection;
}

interface UpdateSelectionAction extends Action<typeof UPDATE_SELECTION> {
  payload: ResourceSelectionUpdate;
}

interface SelectionErrorAction extends Action<typeof SELECTION_ERROR> {}

type SelectionActions =
  | CreateSelectionAction
  | UpdateSelectionAction
  | SelectionErrorAction;

// Reducer
export interface SelectionStore {
  items: {
    [key: string]: ResourceSelection;
  };
  error: boolean;
}

const initialState: SelectionStore = {
  items: {},
  error: false,
};

export default function reducer(
  state = initialState,
  action: SelectionActions
): SelectionStore {
  return produce(state, draft => {
    switch (action.type) {
      case CREATE_SELECTION: {
        const { id, type, query, endpoint, filter = 'none' } = action.payload;
        draft.items[id] = {
          id,
          type,
          endpoint,
          query,
          filter,
        };
        break;
      }
      case UPDATE_SELECTION: {
        const { id } = action.payload;

        if (draft.items[id]) {
          if ('filter' in action.payload) {
            draft.items[id].filter = action.payload.filter;
          }
        }
        break;
      }
      case SELECTION_ERROR: {
        draft.error = true;
        break;
      }
    }
  });
}

// Functions
export const loadInitialStorage = () => {
  return async (dispatch: ThunkDispatch<any, void, CreateSelectionAction>) => {
    const keyString = localStorage.getItem(LOCAL_STORAGE_IDS);
    const keys = keyString ? keyString.split(',') : [];

    keys.forEach(key => {
      const item = JSON.parse(localStorage.getItem(key) || '{}');
      const { id, type, endpoint, query, version, filter } = item;
      if (version === 1 && id && type && endpoint && query) {
        dispatch({
          type: CREATE_SELECTION,
          payload: {
            id,
            type,
            endpoint,
            query,
            filter,
          },
        });
      }
    });
  };
};
export const clearLocalStorage = () => {
  return async () => {
    const keyString = localStorage.getItem(LOCAL_STORAGE_IDS);
    const keys = keyString ? keyString.split(',') : [];

    keys.forEach(key => {
      localStorage.removeItem(key);
    });

    localStorage.removeItem(LOCAL_STORAGE_IDS);
  };
};

export const create = (resource: PendingResourceSelection) => {
  return async (
    dispatch: ThunkDispatch<any, void, SelectionActions>
  ): Promise<string> => {
    const id = uuid();
    const selection = {
      id,
      type: resource.type,
      endpoint: resource.endpoint,
      query: resource.query,
      filter: resource.filter,
    };
    await dispatch({
      type: CREATE_SELECTION,
      payload: selection,
    });

    try {
      storeInLocalStorage(selection);
    } catch {
      await dispatch({
        type: SELECTION_ERROR,
      });
    }

    return id;
  };
};

export const update = (u: ResourceSelectionUpdate) => {
  return async (
    dispatch: ThunkDispatch<any, void, SelectionActions>,
    getState: () => RootState
  ) => {
    dispatch({
      type: UPDATE_SELECTION,
      payload: u,
    });
    const selection = getState().selection.items[u.id];
    try {
      storeInLocalStorage(selection);
    } catch {
      await dispatch({
        type: SELECTION_ERROR,
      });
    }
  };
};

function getCountAction(type: ResourceType) {
  switch (type) {
    case 'files':
      return countFiles;
    case 'assets':
      return countAssets;
    case 'timeseries':
      return countTimeseries;
    case 'sequences':
      return countSequences;
    case 'events':
      return countEvents;
    default:
      throw new Error(`type '${type}' not supported`);
  }
}

function getRetrieveAction(type: ResourceType) {
  switch (type) {
    case 'files':
      return retrieveFiles;
    case 'assets':
      return retrieveAssets;
    case 'timeseries':
      return retrieveTimeseries;
    case 'sequences':
      return retrieveSequences;
    case 'events':
      return retrieveEvents;
    default:
      throw new Error(`type '${type}' not supported`);
  }
}

export const loadResourceSelection = (selectionId: string, loadAll = true) => {
  return async (
    dispatch: ThunkDispatch<any, void, SelectionActions>,
    getState: () => RootState
  ) => {
    try {
      const selection = getState().selection.items[selectionId];
      if (selection) {
        const { type, endpoint, query } = selection;
        const retrieveAction = getRetrieveAction(type);
        const countAction = getCountAction(type);

        dispatch(countAction(query));

        switch (endpoint) {
          case 'retrieve':
            await dispatch(retrieveAction(query));
            break;
          case 'list': {
            switch (type) {
              case 'files':
                await dispatch(listFiles(selection.query, loadAll));
                break;
              case 'events':
                await dispatch(listEvents(selection.query, loadAll));
                break;
              case 'sequences':
                await dispatch(listSequences(selection.query, loadAll));
                break;
              case 'assets': {
                if (loadAll) {
                  await dispatch(listAssetsParallel(selection.query));
                  break;
                }
                await dispatch(listAssets(selection.query));
                break;
              }
              case 'timeseries': {
                if (loadAll) {
                  await dispatch(listTimeseriesParallel(selection.query));
                  break;
                }
                await dispatch(listTimeseries(selection.query));
                break;
              }
            }
          }
        }
      } else {
        dispatch({
          type: SELECTION_ERROR,
        });
      }
    } catch {
      dispatch({
        type: SELECTION_ERROR,
      });
    }
  };
};

const storeInLocalStorage = (selection: ResourceSelection) => {
  const keys = localStorage.getItem(LOCAL_STORAGE_IDS) || '';
  const newKeys = [...keys.split(','), selection.id];

  localStorage.setItem(LOCAL_STORAGE_IDS, newKeys.join(','));
  localStorage.setItem(
    selection.id,
    JSON.stringify({ ...selection, version: 1 })
  );
};

// Selectors

export const stuffForTesting = {
  LOCAL_STORAGE_IDS,
  CREATE_SELECTION,
  initialState,
};

const defaultSelection = {
  items: [],
  ids: [],
  progress: 1,
  fetching: false,
  error: false,
  done: false,
};

export const getCountsSelector = createSelector(
  countAssetSelector,
  countFileSelector,
  countTimeseriesSelector,
  countSequencesSelector,
  countEventsSelector,
  (
    assetCounter,
    fileCounter,
    timeseriesCounter,
    sequencesCounter,
    eventsCounter
  ) => (type: ResourceType) => {
    switch (type) {
      case 'files':
        return fileCounter;
      case 'assets':
        return assetCounter;
      case 'timeseries':
        return timeseriesCounter;
      case 'sequences':
        return sequencesCounter;
      case 'events':
        return eventsCounter;
      default:
        throw new Error(`type '${type}' not supported`);
    }
  }
);

export const getItemsSearchSelector = createSelector(
  searchFileSelector,
  searchAssetSelector,
  searchTimeseriesSelector,
  searchSequencesSelector,
  searchEventsSelector,
  (fileSearch, assetSearch, timeseriesSearch, sequenceSearch, eventSearch) => (
    type: ResourceType
  ) => {
    switch (type) {
      case 'assets':
        return assetSearch;
      case 'files':
        return fileSearch;
      case 'timeseries':
        return timeseriesSearch;
      case 'sequences':
        return sequenceSearch;
      case 'events':
        return eventSearch;
      default:
        throw new Error(`type '${type}' is not supported`);
    }
  }
);

export const getItemsSelector = createSelector(
  retrieveFileSelector,
  retrieveAssetSelector,
  retrieveTimeseriesSelector,
  retrieveSequencesSelector,
  retrieveEventsSelector,
  (getFiles, getAssets, getTimeseries, getSequences, getEvents) => (
    type: ResourceType
  ) => {
    switch (type) {
      case 'assets':
        return getAssets;
      case 'files':
        return getFiles;
      case 'timeseries':
        return getTimeseries;
      case 'sequences':
        return getSequences;
      case 'events':
        return getEvents;
      default:
        throw new Error(`type '${type}' is not supported`);
    }
  }
);

export const getItemListSelector = createSelector(
  listFileSelector,
  listAssetSelector,
  listTimeseriesSelector,
  listSequencesSelector,
  listEventsSelector,
  (fileList, assetList, timeseriesList, sequenceList, eventsList) => (
    type: ResourceType
  ) => {
    switch (type) {
      case 'assets':
        return assetList;
      case 'files':
        return fileList;
      case 'timeseries':
        return timeseriesList;
      case 'sequences':
        return sequenceList;
      case 'events':
        return eventsList;
      default:
        throw new Error(`type '${type}' is not supported`);
    }
  }
);

export const dataKitItemsSelector = createSelector(
  (state: RootState) => state.selection.items,
  getItemListSelector,
  getItemsSelector,
  (datakits, getListSelector, getRetrieveSelector) => (
    datakitId: string,
    loadAll: boolean = false,
    allowInvalidId: boolean = false
  ) => {
    if (!datakits[datakitId]) {
      if (!allowInvalidId) {
        throw new Error(`cannot find '${datakitId}'`);
      }
      return defaultSelection;
    }
    const { query, endpoint, type, filter } = datakits[datakitId];
    const listSelector = getListSelector(type);
    const retrieveSelector = getRetrieveSelector(type);

    const result = (() => {
      switch (endpoint) {
        case 'list':
          return listSelector(query, loadAll);
        case 'retrieve':
          return retrieveSelector(query);
        default:
          throw new Error(`Unsupported endpoint: ${endpoint}`);
      }
    })();

    const filterFn = filters[filter || 'missingAssetId'];
    return {
      ...result,
      // @ts-ignore
      items: result.items.filter(filterFn),
    };
  }
);

export const dataKitItemMapSelector = createSelector(
  dataKitItemsSelector,
  dataKitSelector => (
    datakitId: string,
    loadAll: boolean = false,
    allowInvalidId: boolean = false
  ) => {
    const resource = dataKitSelector(datakitId, loadAll, allowInvalidId);

    // @ts-ignore
    resource.items = resource.items.reduce((accl, i) => {
      accl[i.id] = i;
      return accl;
    }, {});
    return resource;
  }
);

export const dataKitCountSelector = createSelector(
  (state: RootState) => state.selection.items,
  getCountsSelector,
  (datakits, foo) => (datakitId: string) => {
    const { type, query } = datakits[datakitId];
    return foo(type)(query);
  }
);
