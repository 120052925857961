import React, { useEffect, useState } from 'react';
import { Empty, List } from 'antd';
import {
  CoolH1,
  Header,
  InstanceListItem,
  CountLabel,
  ListItem,
  Loader,
  Input,
} from 'components/Common';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Colors } from '@cognite/cogs.js';
import { useParams, useHistory } from 'react-router-dom';
import {
  selectInstancesUnderType,
  listAndCountInstancesForTypeId,
  listAllTypesForInstance,
  selectInstance,
  selectInstancesUnderTypeByRootAsset,
  selectInstanceStatus,
} from 'modules/instances';
import { itemSelector } from 'modules/types';
import { NewInstanceModal } from 'containers/ScalingUseCase/Instance';
import { EditInstance } from './EditInstance';
import { Container, Sider, Main } from '../Common';

export const Instances = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [createInstanceOpen, setCreateInstanceOpen] = useState(false);
  const [query, setQuery] = useState<string>('');
  const { tenant, templateId, instanceId } = useParams<{
    tenant: string;
    templateId: string;
    instanceId: string;
  }>();

  const templateIdNumber = Number.isNaN(Number(templateId))
    ? -1
    : Number(templateId);

  const instanceIdNumber = Number.isNaN(Number(instanceId))
    ? -1
    : Number(instanceId);

  const { items } = useSelector(selectInstancesUnderType)(
    templateIdNumber,
    true
  );

  const instances = useSelector(selectInstancesUnderTypeByRootAsset)(
    templateIdNumber,
    true
  );

  const type = useSelector(itemSelector)(templateIdNumber);

  useEffect(() => {
    if (templateIdNumber > -1) {
      dispatch(listAndCountInstancesForTypeId(templateIdNumber, true));
    }
  }, [dispatch, templateIdNumber]);

  useEffect(() => {
    if (instanceIdNumber > -1) {
      dispatch(listAllTypesForInstance(instanceIdNumber));
    }
  }, [dispatch, instanceIdNumber]);

  const instance = useSelector(selectInstance)(instanceIdNumber);
  const instanceStatus = useSelector(selectInstanceStatus)(instanceId);

  useEffect(() => {
    if (items.length > 0 && templateId === undefined) {
      history.replace(`/${tenant}/templates/${items[0].id}`);
    }
  }, [history, templateId, items, tenant]);

  const renderDetails = () => {
    if (instanceIdNumber === -1) {
      return <Empty>Invalid Instance Selected.</Empty>;
    }
    if (instanceStatus.inProgress || !instance) {
      return <Loader />;
    }
    if (items.length === 0) {
      return <Empty>No Instances Defined.</Empty>;
    }
    if (instanceStatus.error) {
      return <Empty>Something went wrong</Empty>;
    }
    return (
      <EditInstance instanceId={instanceIdNumber} typeId={templateIdNumber} />
    );
  };

  return (
    <>
      <Container>
        <Sider>
          <Header
            title={<CoolH1>Instances</CoolH1>}
            extra={[
              <Button
                key="add"
                icon="Plus"
                type="primary"
                onClick={() => setCreateInstanceOpen(true)}
              >
                Create new
              </Button>,
            ]}
            style={{ marginBottom: '40px' }}
          />
          <Input
            style={{ background: Colors['greyscale-grey3'].hex() }}
            placeholder="Search for an instance... "
            value={query}
            onChange={setQuery}
          />
          {Object.keys(instances)
            .map(el => Number(el))
            .map(assetId => {
              const { asset, items: preFilterItems } = instances[assetId];

              const assetItems = preFilterItems.filter(
                el =>
                  query.length === 0 ||
                  el.name.toLowerCase().includes(query.toLowerCase())
              );

              return (
                <div key={assetId}>
                  <ListItem
                    style={{
                      backgroundColor: Colors['greyscale-grey3'].hex(),
                      marginTop: '24px',
                      marginBottom: '16px',
                      cursor: 'default',
                    }}
                    title={asset ? asset.name : 'Loading...'}
                  >
                    <CountLabel value={assetItems.length} />
                  </ListItem>
                  <List
                    dataSource={assetItems}
                    rowKey="id"
                    renderItem={item => (
                      <InstanceListItem
                        type={type!}
                        instance={item}
                        style={{
                          backgroundColor:
                            item.id === instanceIdNumber
                              ? Colors['midblue-7'].hex()
                              : Colors.white.hex(),
                          marginBottom: '2px',
                        }}
                        selected={item.id === instanceIdNumber}
                        onClick={() => {
                          history.push(
                            `/${tenant}/templates/${templateId}/instances/${item.id}`
                          );
                        }}
                      />
                    )}
                  />
                </div>
              );
            })}
        </Sider>
        <Main>{renderDetails()}</Main>
      </Container>
      <NewInstanceModal
        template={type}
        visible={createInstanceOpen}
        onClose={() => setCreateInstanceOpen(false)}
      />
    </>
  );
};
