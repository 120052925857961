import React from 'react';
import Breadcrumbs, { BreadcrumbRouteMapFn } from 'components/Breadcrumbs';
import { Button, Colors } from '@cognite/cogs.js';
import styled from 'styled-components';
import { BreadcrumbRouteMap } from 'components/Breadcrumbs/Breadcrumbs';
import { RootState } from 'reducers';

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  background: #fff;
  width: 100%;
  z-index: 1;
  height: auto;
  box-shadow: 0px 4px 8px ${Colors['greyscale-grey3'].hex()};

  h4 {
    font-size: 20px;
    font-weight: 500;
    margin-top: 16px;
    margin-bottom: 4px;
  }

  .breadcrumbs-wrapper a {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
  }
`;

export const XOMHeader = ({
  onBackClicked,
  breadcrumbs,
  children,
  title,
  path,
}: {
  title: React.ReactNode;
  path: string;
  breadcrumbs: (mountedAt: string) => (state: RootState) => BreadcrumbRouteMap;
  children?: React.ReactNode | React.ReactNode[];
  onBackClicked?: () => void;
}) => {
  const routesMap: BreadcrumbRouteMapFn[] = [
    breadcrumbs(path) as BreadcrumbRouteMapFn,
  ];
  return (
    <Wrapper>
      {onBackClicked && (
        <Button
          style={{
            marginRight: '12px',
            borderRadius: 0,
            background: Colors['greyscale-grey1'].hex(),
          }}
          icon="ArrowLeft"
          onClick={onBackClicked}
        >
          BACK
        </Button>
      )}
      <div style={{ flex: 1, marginLeft: onBackClicked ? '0px' : '12px' }}>
        {typeof title === 'string' ? <h4>{title}</h4> : title}
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs routesMap={routesMap} />
        </div>
      </div>
      {children}
    </Wrapper>
  );
};
