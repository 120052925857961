import { combineReducers } from 'redux';

import { InternalId, CogniteInternalId } from '@cognite/sdk';

import { ResourceType, Query } from './types';

import buildCount from './count';
import buildSearch from './search';
import buildList from './list';
import buildItems from './items';

export default function resourceBuilder<
  Resource extends InternalId,
  Change extends { id: CogniteInternalId },
  ListScope extends Query,
  SearchFilter extends Query
>(
  resourceType: ResourceType,
  itemProcessor: (r: Resource) => Resource = r => r,
  propsItemReducer?: any,
  propsListReducer?: any,
  propsSearchReducer?: any,
  propsCountReducer?: any
) {
  const {
    retrieve,
    retrieveExternal,
    update,
    itemReducer,
    createItemSelector,
    createRetrieveSelector,
  } = buildItems<Resource, Change>(resourceType);

  const {
    listAction,
    listParallelAction,
    listReducer,
    createListSelector,
  } = buildList<ListScope, Resource>(resourceType, itemProcessor);

  const { searchAction, searchReducer, createSearchSelector } = buildSearch<
    Resource,
    SearchFilter
  >(resourceType, itemProcessor);

  const { countAction, countReducer, createCountSelector } = buildCount<
    ListScope
  >(resourceType);

  type ITEM_REDUCER_TYPE = typeof itemReducer;
  type LIST_REDUCER_TYPE = typeof listReducer;
  type SEARCH_REDUCER_TYPE = typeof searchReducer;
  type COUNT_REDUCER_TYPE = typeof countReducer;

  const reducer = combineReducers({
    items: (propsItemReducer || itemReducer) as ITEM_REDUCER_TYPE,
    list: (propsListReducer || listReducer) as LIST_REDUCER_TYPE,
    search: (propsSearchReducer || searchReducer) as SEARCH_REDUCER_TYPE,
    count: (propsCountReducer || countReducer) as COUNT_REDUCER_TYPE,
  });

  return {
    reducer,
    retrieve,
    retrieveExternal,
    update,
    searchAction,
    countAction,
    listAction,
    listParallelAction,

    createSearchSelector,
    createListSelector,
    createCountSelector,
    createRetrieveSelector,
    createItemSelector,
  };
}
