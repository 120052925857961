import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { createSelector } from 'reselect';
import {
  itemSelector as typeSelector,
  list as listTypes,
  CogniteType,
  retrieve as retrieveTypes,
} from 'modules/types';
import { Asset } from '@cognite/sdk';
import { runUntilCompleted } from 'helpers/Helpers';
import {
  PlaygroundAssetListDTO,
  list,
  listSelector as listPlaygroundAssetSelector,
  countSelector as countPlaygroundAssetSelector,
  count,
  PlaygroundAsset,
  update,
} from './playgroundAssets';
import { RootState } from '../reducers/index';
import { retrieve as retrieveAssets, itemSelector } from './assets';
import { itemSelector as timeseriesSelector } from './timeseries';
import { CogniteTypeProperty, CogniteTypePopulatedProperty } from './types';
import { ApiResult } from './sdk-builder/types';

export interface Instance extends Asset {
  types?: {
    type: {
      id: number;
      externalId?: string;
      version: number;
    };
    properties?: (CogniteTypeProperty & { value: any })[];
  }[];
}

const queryAllAssetsUnderType = (
  id: number,
  version: number
): PlaygroundAssetListDTO => ({
  filter: {
    types: [{ type: { id, version } }],
  },
});

export const createTypesAssetQuery = (
  query: {
    type: { id: number; version: number };
    properties?: any;
  }[]
): PlaygroundAssetListDTO => ({
  filter: {
    types: query,
  },
});

export const listAllRootAssetsIfNeeded = (
  assets: (PlaygroundAsset | Asset)[]
) => async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  const assetIds = new Set<number>();
  assets.forEach(el => assetIds.add(el.rootId));
  if (assets && assetIds.size > 0) {
    dispatch(retrieveAssets([...assetIds].map(id => ({ id }))));
  }
};

export const listAndCountInstancesForTypeId = (
  id: number,
  all = false,
  forceRefresh = false
) => async (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  getState: () => RootState
) => {
  let type = typeSelector(getState())(id);
  if (!type) {
    await dispatch(listTypes({}));
    type = typeSelector(getState())(id);
    if (!type) {
      return;
    }
  }
  let refreshed = forceRefresh;
  await dispatch(
    list(
      queryAllAssetsUnderType(type.id, type.version),
      all,
      undefined,
      undefined,
      (i: ApiResult) => {
        const shouldRefresh = refreshed;
        refreshed = false;
        return (!i.fetching && !i.done) || shouldRefresh;
      }
    )
  );
  try {
    await dispatch(count(queryAllAssetsUnderType(type.id, type.version)));
  } catch {
    // noop
  }
};

export const listAllTypesForInstance = (id: number) => async (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  getState: () => RootState
) => {
  const instance = getState().playgroundAssets.items.items.get(id);
  if (!instance || !instance.types || instance.types.length === 0) {
    return;
  }
  await dispatch(
    retrieveTypes(
      instance.types.map(el => ({
        id: el.type.id,
        version: el.type.version,
      }))
    )
  );
};

export const addType = (assetId: number, type: CogniteType) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState: () => RootState
  ) => {
    await dispatch(
      update([
        {
          id: assetId,
          update: {
            types: {
              put: [
                {
                  type: { id: type.id, version: type.version },
                  properties: {},
                },
              ],
            },
          },
        },
      ])
    );
    await new Promise((resolve, reject) =>
      runUntilCompleted(
        async () =>
          dispatch(listAndCountInstancesForTypeId(type.id, true, true)),
        () => {
          const instances = selectInstancesUnderType(getState())(type.id, true);
          if (!instances.items) {
            return false;
          }
          if (instances.items.some(el => el.id === assetId)) {
            return true;
          }
          return false;
        },
        () => resolve(),
        undefined,
        () => {
          reject();
        }
      )
    );
  };
};

export const removeType = (assetId: number, type: CogniteType) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState: () => RootState
  ) => {
    await dispatch(
      update([
        {
          id: assetId,
          update: {
            types: {
              remove: [{ id: type.id, version: type.version }],
            },
          },
        },
      ])
    );
    await new Promise((resolve, reject) =>
      runUntilCompleted(
        async () =>
          dispatch(listAndCountInstancesForTypeId(type.id, true, true)),
        () => {
          const instances = selectInstancesUnderType(getState())(type.id, true);
          if (!instances.items) {
            return false;
          }
          if (instances.items.some(el => el.id === assetId)) {
            return false;
          }
          return true;
        },
        () => resolve(),
        undefined,
        () => {
          reject();
        }
      )
    );
  };
};

export const removePropertyFromType = (
  instance: Instance,
  type: CogniteType,
  propertyId: string
) => {
  return async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    const typeWithProperties = instance.types!.find(
      el => el.type.id === type.id && el.type.version === type.version
    );

    const properties: CogniteTypePopulatedProperty[] = typeWithProperties
      ? typeWithProperties.properties || []
      : [];
    dispatch(
      update([
        {
          id: instance.id,
          update: {
            types: {
              put: [
                {
                  type: {
                    id: type.id,
                    version: type.version,
                  },
                  properties: {
                    ...properties.reduce(
                      (prev, el: CogniteTypePopulatedProperty) => {
                        if (el.propertyId !== propertyId) {
                          return {
                            ...prev,
                            [el.propertyId]: el.value,
                          };
                        }
                        return prev;
                      },
                      {}
                    ),
                  },
                },
              ],
            },
          },
        },
      ])
    );
  };
};
export const addPropertyFromType = (
  items: {
    instance: Instance;
    type: CogniteType;
    property: CogniteTypePopulatedProperty;
  }[]
) => {
  return async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    const updates = items.reduce((acc, item) => {
      const { instance, type, property } = item;
      const typeWithProperties = instance.types!.find(
        el => el.type.id === type.id && el.type.version === type.version
      );
      const properties: CogniteTypePopulatedProperty[] = typeWithProperties
        ? typeWithProperties.properties || []
        : [];

      const existing = acc[instance.id];
      if (existing) {
        return {
          ...acc,
          [instance.id]: {
            update: {
              types: {
                put: [
                  {
                    type: {
                      id: type.id,
                      version: type.version,
                    },
                    properties: {
                      ...existing.update.types.put[0].properties,
                      [property.propertyId]: property.value,
                    },
                  },
                ],
              },
            },
          },
        };
      }
      return {
        ...acc,
        [instance.id]: {
          update: {
            types: {
              put: [
                {
                  type: {
                    id: type.id,
                    version: type.version,
                  },
                  properties: {
                    ...properties.reduce(
                      (prev, el: CogniteTypePopulatedProperty) => ({
                        ...prev,
                        [el.propertyId]: el.value,
                      }),
                      {}
                    ),
                    [property.propertyId]: property.value,
                  },
                },
              ],
            },
          },
        },
      };
    }, {} as { [key: number]: any });

    await dispatch(
      update(
        Object.keys(updates).map(key => ({
          ...updates[Number(key)],
          id: Number(key),
        }))
      )
    );
  };
};

export const selectInstancesUnderType = createSelector(
  typeSelector,
  listPlaygroundAssetSelector,
  (getType, instancesSelector) => (id: number, all = false) => {
    const type = getType(id);
    if (!type) {
      return {
        items: [] as Instance[],
        fetching: false,
        done: false,
        error: false,
      };
    }

    const instances = instancesSelector(
      queryAllAssetsUnderType(type.id, type.version),
      all
    );
    return {
      ...instances,
      items: instances.items
        .map(el => {
          return {
            ...el,
            types: (el.types || [])
              .map(instanceType => {
                if (
                  getType(instanceType.type.id) &&
                  getType(instanceType.type.id)!.version ===
                    instanceType.type.version
                ) {
                  const typeProps =
                    getType(instanceType.type.id)!.properties || [];
                  const instProps = instanceType.properties || [];
                  return {
                    ...instanceType,
                    properties: typeProps.map(prop => ({
                      ...prop,
                      value: instProps[prop.propertyId],
                    })),
                  };
                }
                return undefined;
              })
              .filter(it => !!it),
          } as Instance;
        })
        .sort((a: Instance, b: Instance) => a.name.localeCompare(b.name)),
    };
  }
);
export const selectInstancesUnderTypeByRootAsset = createSelector(
  selectInstancesUnderType,
  itemSelector,
  (selectInstanceUnderType, assetMap) => (id: number, all = false) => {
    const instances = selectInstanceUnderType(id, all);
    const instancesCategorizedByAssetId = (instances.items as Instance[]).reduce(
      (prev, el) => {
        if (!prev[el.rootId]) {
          prev[el.rootId] = {
            asset: assetMap(el.rootId),
            items: [],
          };
        }
        prev[el.rootId].items.push(el);
        return prev;
      },
      {} as {
        [key: number]: { asset: Asset | undefined; items: Instance[] };
      }
    );

    return instancesCategorizedByAssetId;
  }
);
export const selectInstance = createSelector(
  (state: RootState) => state.playgroundAssets.items.items,
  typeSelector,
  (instances, getType) => (id: number | undefined) => {
    if (!id) {
      return undefined;
    }
    const instanceItem = instances.get(id);
    if (!instanceItem) {
      return undefined;
    }
    return {
      ...instanceItem,
      types: (instanceItem.types || []).map(instanceType => {
        if (
          getType(instanceType.type.id) &&
          getType(instanceType.type.id)!.version === instanceType.type.version
        ) {
          const typeProps = getType(instanceType.type.id)!.properties || [];
          const instProps = instanceType.properties || [];
          return {
            ...instanceType,
            properties: typeProps.map(prop => ({
              ...prop,
              value: instProps[prop.propertyId],
            })) as ({ value: any } & CogniteTypeProperty)[],
          };
        }
        return instanceType;
      }),
    } as Instance;
  }
);

export const selectInstancesPropertyCount = createSelector(
  typeSelector,
  selectInstancesUnderType,
  (getType, instancesSelector) => (id: number) => {
    const type = getType(id);
    if (!type) {
      return {
        total: 0,
        completed: 0,
      };
    }

    const instances = instancesSelector(type.id, true);
    return {
      total: (type.properties || []).length * instances.items.length,
      completed: instances.items.reduce((prev, instance) => {
        const typeWithProperties = (instance || { types: [] }).types!.find(
          el => el.type.id === type.id && el.type.version === type.version
        );

        const properties: CogniteTypePopulatedProperty[] = typeWithProperties
          ? typeWithProperties.properties || []
          : [];

        return prev + properties.filter(el => !!el.value).length;
      }, 0),
    };
  }
);

export const selectInstanceStatus = createSelector(
  (state: RootState) => state.playgroundAssets.items.getById,
  (state: RootState) => state.playgroundAssets.items.getByExternalId,
  (byId, byExternalId) => (id: number | string | undefined) => {
    let status = {
      inProgress: false,
      done: false,
      error: false,
    };
    if (typeof id === 'number') {
      status = byId[id] || status;
    }
    if (typeof id === 'string') {
      status = byExternalId[id] || status;
    }
    return status;
  }
);

export const selectInstancesCountUnderType = createSelector(
  typeSelector,
  countPlaygroundAssetSelector,
  (getType, countSelector) => (id: number) => {
    const type = getType(id);
    if (!type) {
      return { fetching: false, done: false, error: false, count: 0 };
    }

    return countSelector(queryAllAssetsUnderType(type.id, type.version));
  }
);

export const selectTypeCSVData = createSelector(
  typeSelector,
  selectInstancesUnderType,
  timeseriesSelector,
  (getType, getInstancesForType, getTimeseries) => async (
    typeId: number
  ): Promise<
    {
      Parent: string;
      Name: string;
      ObjectType: string;
      AttributeConfigString: string;
    }[]
  > => {
    const type = getType(typeId);
    const { items } = getInstancesForType(typeId, true);
    const datas: {
      Parent: string;
      Name: string;
      ObjectType: string;
      AttributeConfigString: string;
    }[] = [];
    if (!type) {
      return datas;
    }

    items.forEach(instance => {
      const parentExternalIdOrEmpty = 'Canada East\\Wells';
      datas.push({
        Parent: parentExternalIdOrEmpty,
        Name: instance.name,
        ObjectType: 'Element',
        AttributeConfigString: '',
      });

      if (instance.types) {
        const typeProperty = instance.types.find(
          el => el.type.externalId === type.externalId
        );
        if (typeProperty) {
          datas.push({
            Parent: `${parentExternalIdOrEmpty}\\${instance.name}`,
            Name: typeProperty.type.externalId!,
            ObjectType: 'Element',
            AttributeConfigString: '',
          });
          datas.push({
            Parent: `${parentExternalIdOrEmpty}\\${instance.name}\\${typeProperty.type.externalId}`,
            Name: `${instance.name}_CAT`,
            ObjectType: 'Element',
            AttributeConfigString: '',
          });
          if (typeProperty.properties) {
            typeProperty.properties.forEach(property => {
              const timeseries = getTimeseries(
                property.value ? property.value.id : undefined
              );
              const parentString = `${parentExternalIdOrEmpty}\\${instance.name}\\${typeProperty.type.externalId}\\${instance.name}_CAT`;
              datas.push({
                Parent: parentString,
                Name: property.propertyId,
                ObjectType: 'Attribute',
                AttributeConfigString: timeseries
                  ? timeseries.externalId || `${timeseries.id}`
                  : '',
              });
            });
          }
        }
      }
    });
    return datas;
  }
);

export const countFilledInProperties = (
  instance: Instance,
  type: CogniteType
) => {
  const typeProperties = (instance.types || []).find(
    el => el.type.id === type.id && el.type.version === type.version
  );
  if (!typeProperties) {
    return {
      count: 0,
      total: 0,
      invalid: true,
    };
  }
  if (!type.properties || type.properties.length === 0) {
    return {
      count: 0,
      total: 0,
      invalid: false,
    };
  }
  const propertyKeys = new Set(type.properties.map(el => el.propertyId));
  (typeProperties.properties || []).forEach(item => {
    if (item.value) {
      propertyKeys.delete(item.propertyId);
    }
  });

  return {
    total: type.properties.length,
    count: type.properties.length - propertyKeys.size,
    invalid: false,
  };
};

export const getPropertyFromInstance = (
  type: CogniteType,
  instance: Instance,
  propId: string
) => {
  const typeWithProperties = (instance || { types: [] }).types!.find(
    el => el.type.id === type.id && el.type.version === type.version
  );

  const properties: CogniteTypePopulatedProperty[] = typeWithProperties
    ? typeWithProperties.properties || []
    : [];

  return properties.find(p => p.propertyId === propId)?.value;
};
