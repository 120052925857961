import React from 'react';
import { WithLabel, BaseDropdown } from 'components/Common';

type FieldProps = {
  title: string;
  options: { value: string | number; name: string }[];
  onSelect?: (value: string | number) => void;
};
export const DropdownWithLabel = (props: FieldProps) => {
  const { title, options, onSelect } = props;

  return (
    <WithLabel title={title}>
      <BaseDropdown options={options} onSelect={onSelect} />
    </WithLabel>
  );
};
