import React from 'react';
import { List, Row, Col } from 'antd';
import { GetTimeSeriesMetadataDTO as TimeSeries } from '@cognite/sdk/dist/src/types/types';
import styled from 'styled-components';
import { Icons, Colors } from '@cognite/cogs.js';
import Highlighter from 'react-highlight-words';
import { ListItem } from 'components/Common';

const IconWrapper = styled.span`
  background: #f5f5f5;
  padding: 5px;
  padding-bottom: 1px;
  border-radius: 4px;
  vertical-align: -0.225em;
`;

const ListWrapper = styled.div`
  display: flex;
  flex: 1;
  height: auto;
  width: 100%;
  overflow-y: auto;

  .ant-list {
    width: 100%;
    overflow-x: hidden;
  }

  .ant-row-flex {
    width: 100%;
  }
`;

const ListItemWrapper = styled.div`
  &:hover {
    background: ${Colors['midblue-8'].hex()};
  }
  cursor: pointer;
  transition: all 0.2s;
`;

const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
`;
const Subtitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface TimeseriesListProps {
  timeseries: TimeSeries[];
  selectedTimeSeriesId?: TimeSeries['id'];
  onTimeSeriesClick?: (timeSeries: TimeSeries) => void;
  renderItem?: (
    timeSeries: TimeSeries,
    content: React.ReactNode
  ) => React.ReactNode;
  query?: string;
  loading?: boolean;
}

export const TimeseriesList = (props: TimeseriesListProps) => {
  const {
    onTimeSeriesClick = () => {},
    selectedTimeSeriesId,
    timeseries,
    query = '',
    renderItem = (_, el) => el,
    loading,
  } = props;

  return (
    <ListWrapper>
      <List
        loading={loading}
        dataSource={timeseries}
        renderItem={item =>
          renderItem(
            item,
            <ListItemWrapper>
              <ListItem
                style={{ paddingLeft: 4, paddingRight: 4 }}
                onClick={() => onTimeSeriesClick(item)}
                selected={item.id === selectedTimeSeriesId}
              >
                <Row gutter={12} type="flex" justify="start">
                  <Col>
                    <IconWrapper>
                      <Icons.Timeseries />
                    </IconWrapper>
                  </Col>
                  <Col style={{ flex: 1, overflow: 'hidden' }}>
                    <Title>
                      {item.name && (
                        <Highlighter
                          searchWords={query.split(' ')}
                          textToHighlight={item.name}
                          autoEscape
                        />
                      )}
                    </Title>
                    <Subtitle>
                      {(item?.description && (
                        <Highlighter
                          searchWords={query.split(' ')}
                          textToHighlight={item?.description}
                          autoEscape
                        />
                      )) || <em>No description</em>}
                    </Subtitle>
                  </Col>
                </Row>
              </ListItem>
            </ListItemWrapper>
          )
        }
      />
    </ListWrapper>
  );
};
