import React from 'react';
import { Avatar, Progress, Col, Row, Modal, Typography, Alert } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router-dom';
import { Button } from '@cognite/cogs.js';

import { RootState } from 'reducers/';
import { ModelStatus } from 'modules/contextualization/models';
import Link from 'components/Link';
import DataKitLoadingProgress from './DataKitLoadingProgress';

const { Text } = Typography;

const CircleAvatar = styled(Avatar)`
  background-color: initial;
  color: #000000d9;
  border: 1px solid #000000d9;
`;

interface LoadResourcesProps {
  assetDataKitId: string;
  resourceDataKitId: string;
  retry?: () => void;
}
export default function LoadResources(props: LoadResourcesProps) {
  const { assetDataKitId, resourceDataKitId, retry = () => {} } = props;
  const match = useRouteMatch<{ tenant: string }>('/:tenant');

  const modelFitProgress = {
    '': 0,
    New: 0,
    Scheduled: 10,
    Queued: 20,
    Running: 40,
    Completed: 100,
    Failed: 100,
  };

  type ProgressProps =
    | 'active'
    | 'success'
    | 'exception'
    | 'normal'
    | undefined;

  const statusMapping = (status: ModelStatus): ProgressProps => {
    switch (status) {
      case 'New': {
        return 'active';
      }
      case 'Scheduled': {
        return 'active';
      }
      case 'Queued': {
        return 'active';
      }
      case 'Running': {
        return 'active';
      }
      case 'Completed': {
        return 'success';
      }
      case 'Failed': {
        return 'exception';
      }
      default: {
        return 'active';
      }
    }
  };

  const { status: modelStatus, error: modelError } = useSelector(
    (store: RootState) => store.contextualization.models[assetDataKitId]
  ) || {
    status: 'New',
    done: false,
  };

  const { status: predictStatus, error: predictError } = useSelector(
    (store: RootState) => store.contextualization.predictions[resourceDataKitId]
  ) || {
    status: 'New',
    done: false,
  };
  const {
    status: rulesStatus,
    done: rulesDone,
    error: rulesError,
  } = useSelector(
    (store: RootState) => store.contextualization.rules[resourceDataKitId]
  ) || {
    status: 'New',
    done: false,
  };

  const startOverButton = (
    <Link to={`/${match?.params.tenant}/entity_matching`}>
      <Button style={{ marginRight: '8px' }}>Start over</Button>
    </Link>
  );
  const tryAgainButton = <Button onClick={() => retry()}>Try again</Button>;

  const loading = !rulesDone;

  return (
    <Modal
      title="Hold on, we are getting your data"
      visible={loading}
      footer={null}
      closable={false}
    >
      <Row gutter={[0, 20]} align="middle" type="flex">
        <Col span={2}>
          <CircleAvatar>1</CircleAvatar>
        </Col>
        <Col span={22}>
          <DataKitLoadingProgress id={assetDataKitId} />
        </Col>
      </Row>
      <Row gutter={[0, 30]} align="middle" type="flex">
        <Col span={2}>
          <CircleAvatar>2</CircleAvatar>
        </Col>
        <Col span={22}>
          <DataKitLoadingProgress id={resourceDataKitId} />
        </Col>
      </Row>
      <Row gutter={[0, 40]} align="middle" type="flex">
        <Col span={2}>
          <CircleAvatar>3</CircleAvatar>
        </Col>
        <Col span={22}>
          <Text strong>Train entity matching model based on provided data</Text>
          <Progress
            percent={modelFitProgress[modelStatus] || 0}
            status={statusMapping(modelStatus)}
          />
        </Col>
      </Row>
      <Row gutter={[0, 40]} align="middle" type="flex">
        <Col span={2}>
          <CircleAvatar>4</CircleAvatar>
        </Col>
        <Col span={22}>
          <Text strong>Generating predictions</Text>
          <Progress
            percent={modelFitProgress[predictStatus] || 0}
            status={statusMapping(predictStatus)}
          />
        </Col>
      </Row>
      <Row gutter={[0, 40]} align="middle" type="flex">
        <Col span={2}>
          <CircleAvatar>5</CircleAvatar>
        </Col>
        <Col span={22}>
          <Text strong>Generating rules for predictions</Text>
          <Progress
            percent={modelFitProgress[rulesStatus] || 0}
            status={statusMapping(rulesStatus)}
          />
        </Col>
      </Row>
      {modelError || predictError || rulesError ? (
        <Row
          gutter={[0, 40]}
          align="middle"
          type="flex"
          style={{ textAlign: 'center' }}
        >
          <Col span={22}>
            <Alert
              message="There was an error!"
              type="error"
              description={
                <div>
                  {startOverButton}
                  {tryAgainButton}
                </div>
              }
            />
          </Col>
        </Row>
      ) : null}
    </Modal>
  );
}
