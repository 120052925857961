import React from 'react';
import { Input, Col } from 'antd';
import AssetSelect from 'components/AssetSelect';
import { InternalId } from '@cognite/sdk';
import { Filter } from 'modules/search';
import DataSetSelect from 'components/DataSetSelect';
import { Icon, Colors } from '@cognite/cogs.js';

type Props = {
  updateFilter: (filter: Filter) => void;
  filter: Filter;
};
export default function AssetSearchBar({ filter, updateFilter }: Props) {
  const selectedDataSetIds = (filter.filter?.dataSetIds || []).map(
    (e: any) => e?.id
  );

  return (
    <>
      <Col span={6}>
        <p>Data Set</p>
        <DataSetSelect
          multiple
          style={{ width: '100%' }}
          selectedDataSetIds={selectedDataSetIds}
          type="assets"
          onDataSetSelected={(ids: number[]) =>
            updateFilter({
              ...filter,
              filter: {
                ...filter.filter,
                dataSetIds: ids.map(id => ({ id })),
              },
            })
          }
        />
      </Col>
      <Col span={6}>
        <p>Search</p>
        <Input
          prefix={
            <Icon
              type="Search"
              style={{ height: '16px', color: Colors['greyscale-grey6'].hex() }}
            />
          }
          placeholder="Filter by name/description"
          value={filter.search ? filter.search.name : ''}
          onChange={ev =>
            updateFilter({
              ...filter,
              search:
                ev.target.value.length > 0
                  ? {
                      ...filter.search,
                      name: ev.target.value,
                    }
                  : undefined,
            })
          }
        />
      </Col>
      <Col span={6}>
        <p>Source</p>
        <Input
          id="source"
          placeholder="Filter by source"
          value={filter.filter ? filter.filter.source : undefined}
          onChange={ev =>
            updateFilter({
              ...filter,
              filter: {
                ...filter.filter,
                source:
                  ev.target.value.length > 0 ? ev.target.value : undefined,
              },
            })
          }
        />
      </Col>
      <Col span={6}>
        <p>Root Assets</p>
        <AssetSelect
          style={{ width: '100%' }}
          placeholder="Filter by root asset"
          rootOnly
          selectedAssetId={
            filter.filter &&
            filter.filter.rootIds &&
            filter.filter.rootIds.length > 0
              ? (filter.filter.rootIds[0] as InternalId).id
              : undefined
          }
          onAssetSelected={asset =>
            updateFilter({
              ...filter,
              filter: {
                ...filter.filter,
                rootIds: asset ? [{ id: asset.id }] : undefined,
              },
            })
          }
        />
      </Col>
    </>
  );
}
