import React from 'react';
import { TextBox, WithLabel } from 'components/Common';

interface FieldProps {
  title: string;
  children: React.ReactNode;
}
export const Field = ({ title, children }: FieldProps) => {
  return (
    <WithLabel title={title}>
      <TextBox>{children}</TextBox>
    </WithLabel>
  );
};
