import { createGlobalStyle } from 'styled-components';
import theme from './theme';

const GlobalStyle = createGlobalStyle<{ theme: typeof theme }>`
  html,
  body {
    height: 100%;
    width: 100%;
    font-family: "proxima-nova", sans-serif;
  }

  body {
    margin: 0;
    padding: 0;
  }

  .ant-layout-content {
    background-color: ${props => props.theme.backgroundColor};
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }

  .ant-input,
  .ant-tag {
    font-family: BlinkMacSystemFont,Arial,sans-serif;
  }

  .ant-form-explain-holder {
    display: none;
  }

  h1 {
    font-size: 28px;
  }

  a {
    color: ${props => props.theme.actionText};
    :hover {
      color: ${props => props.theme.actionText};
    }
  }
  .ant-table {
    overflow: auto;
    background: white;
  }

  .antd-table table {
    min-width: 1100px;
  }
  .ant-btn-primary-disabled, .ant-btn-danger[disabled], .ant-btn-primary.disabled, .ant-btn-primary[disabled], .ant-btn-primary-disabled:focus, .ant-btn-primary.disabled:focus, .ant-btn-primary[disabled]:focus, .ant-btn-primary-disabled:active, .ant-btn-primary.disabled:active, .ant-btn-primary[disabled]:active, .ant-btn-primary-disabled.active, .ant-btn-primary.disabled.active, .ant-btn-primary[disabled].active{
    color : white;
  }

  .popover__no-padding {
    .ant-popover-inner-content {
      padding: 0;
    }
  }

  .popover__no-arrow {
    .ant-popover-arrow {
      display: none;
    }
  }
`;

export default GlobalStyle;
