import { combineReducers } from 'redux';
import functions from 'modules/functions/';
import app from 'modules/app';
import assets from 'modules/assets';
import dataSets from 'modules/datasets';
import login from 'modules/login';
import timeseries from 'modules/timeseries';
import events from 'modules/events';
import files from 'modules/files';
import filePreview from 'modules/filePreview';
import contextualization from 'modules/contextualization/';
import pnidParsing from 'modules/pnidParsing';
import playgroundAssets from 'modules/playgroundAssets';
import types from 'modules/types';
import templateCompletion from 'modules/templateCompletion';
import documentContext from 'modules/documentContext';
import selection from 'modules/selection';
import annotations from 'modules/annotations';
import sequences from 'modules/sequences';

const createRootReducer = () =>
  combineReducers({
    annotations,
    app,
    assets,
    dataSets,
    functions,
    login,
    timeseries,
    events,
    files,
    types,
    contextualization,
    pnidParsing,
    playgroundAssets,
    selection,
    templateCompletion,
    filePreview,
    sequences,
    documentContext,
  });

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;

export default createRootReducer;
