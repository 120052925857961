import React, { useEffect, useRef } from 'react';
import {
  Loader,
  STYLE_BOTTOM_LEFT_GRID,
  STYLE_TOP_LEFT_GRID,
  STYLE_TOP_RIGHT_GRID,
} from 'components/Common';
import { useSelector, useDispatch } from 'react-redux';
import {
  itemSelector as typeSelector,
  CogniteTypePopulatedProperty,
} from 'modules/types';
import {
  listAndCountInstancesForTypeId,
  getPropertyFromInstance,
  Instance,
} from 'modules/instances';
import { retrieve, itemSelector } from 'modules/timeseries';
import { GetTimeSeriesMetadataDTO } from '@cognite/sdk';
import { MultiGrid, AutoSizer, GridCellRenderer } from 'react-virtualized';
import styled from 'styled-components';
import { loadCompletionModel } from 'modules/templateCompletion';
import { InstancePropertyCell } from 'containers/ScalingUseCase/Instance/InstancePropertyCell';

const Wrapper = styled.div`
  flex: 1;
  width: 100%;

  && *:focus {
    outline: none;
  }
`;

export const InstancesTable = ({
  typeId,
  onVisibleChange,
  instances,
}: {
  typeId: number;
  onVisibleChange?: (
    visible: boolean,
    propertyId: string,
    assetId: number,
    timeseriesId: number
  ) => void;
  instances: Instance[];
}) => {
  const dispatch = useDispatch();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const type = useSelector(typeSelector)(typeId)!;
  const getTimeseries = useSelector(itemSelector);

  useEffect(() => {
    if (typeId) {
      (async () => {
        await dispatch(listAndCountInstancesForTypeId(typeId, true));
        await dispatch(loadCompletionModel(typeId));
      })();
    }
  }, [dispatch, typeId]);

  const timeSeriesIds = new Set<number>();

  instances.forEach(instance => {
    (type?.properties || []).forEach((prop: CogniteTypePopulatedProperty) => {
      const property = getPropertyFromInstance(type, instance, prop.propertyId);
      if (property && property.id) {
        timeSeriesIds.add(property.id);
      }
    });
  });

  const timeSeries = Array.from(timeSeriesIds)
    .map(el => getTimeseries(el))
    .filter(el => !!el) as GetTimeSeriesMetadataDTO[];

  useEffect(() => {
    if (timeSeriesIds.size > 0 && timeSeries.length !== timeSeriesIds.size) {
      dispatch(retrieve(Array.from(timeSeriesIds).map(id => ({ id }))));
    }
  }, [dispatch, timeSeriesIds, timeSeries]);

  const renderPropertyCell: GridCellRenderer = props => {
    return (
      <InstancePropertyCell
        instances={instances}
        type={type}
        cellProps={props}
        key={props.key}
        onVisibleChange={onVisibleChange}
      />
    );
  };

  const instancesCount = instances.length;
  const propertiesCount = (type.properties || []).length;

  let content = <Loader />;

  content = (
    <AutoSizer>
      {({ width, height }) => (
        <MultiGrid
          enableFixedColumnScroll
          enableFixedRowScroll
          hideTopRightGridScrollbar
          hideBottomLeftGridScrollbar
          cellRenderer={renderPropertyCell}
          columnWidth={260}
          columnCount={instancesCount + 1}
          fixedColumnCount={1}
          fixedRowCount={1}
          height={wrapperRef.current ? wrapperRef.current.clientHeight : height}
          rowHeight={120}
          rowCount={propertiesCount + 1}
          width={width}
          styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
          styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
          styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
        />
      )}
    </AutoSizer>
  );

  return <Wrapper ref={wrapperRef}>{content}</Wrapper>;
};
