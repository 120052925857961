import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from 'reducers';
import { message } from 'antd';
import { PnIDApi } from 'utils/PnIDApi';
import { FilesApi } from 'utils/FilesApi';
import { itemSelector, retrieve } from './files';

import { ApiCall } from './sdk-builder/types';

const LOAD_PDF_URL = 'files/LOAD_PDF_URL';
const LOAD_PDF_URL_DONE = 'files/LOAD_PDF_URL_DONE';
const LOAD_PDF_URL_ERROR = 'files/LOAD_PDF_URL_ERROR';

interface LoadPDFUrlAction extends Action<typeof LOAD_PDF_URL> {
  id: any;
}

interface LoadPDFUrlDoneAction extends Action<typeof LOAD_PDF_URL_DONE> {
  id: any;
  pdfUrl: string;
}
interface LoadPDFUrlErrorAction extends Action<typeof LOAD_PDF_URL_ERROR> {
  id: any;
}

type LoadPDFActions =
  | LoadPDFUrlAction
  | LoadPDFUrlDoneAction
  | LoadPDFUrlErrorAction;

export function loadPDFUrl(fileId: number, isRetry = false) {
  return async (
    dispatch: ThunkDispatch<any, any, LoadPDFActions>,
    getState: () => RootState
  ) => {
    const { sdk } = getState().app;
    const file = itemSelector(getState())(fileId);

    if (!file) {
      if (isRetry) {
        message.error('Unable to load file.');
        return;
      }
      await dispatch(retrieve([{ id: fileId }], true));
      await dispatch(loadPDFUrl(fileId, true));
      return;
    }
    dispatch({
      type: LOAD_PDF_URL,
      id: fileId,
    });
    try {
      const filesApi = new FilesApi(sdk);
      const pdfUrl = await filesApi.getPdfUrl(file.id);

      dispatch({
        type: LOAD_PDF_URL_DONE,
        id: fileId,
        pdfUrl,
      });
    } catch (e) {
      message.error('Unable to load PDF. Please try again.');
      dispatch({
        type: LOAD_PDF_URL_ERROR,
        id: fileId,
      });
    }
  };
}

const LOAD_PREVIEW_URL = 'files/LOAD_PREVIEW_URL';
const LOAD_PREVIEW_URL_DONE = 'files/LOAD_PREVIEW_URL_DONE';
const LOAD_PREVIEW_URL_ERROR = 'files/LOAD_PREVIEW_URL_ERROR';

interface LoadPreviewUrlAction extends Action<typeof LOAD_PREVIEW_URL> {
  id: number;
}

interface LoadPreviewUrlDoneAction
  extends Action<typeof LOAD_PREVIEW_URL_DONE> {
  id: number;
  pngUrl: string;
}
interface LoadPreviewUrlErrorAction
  extends Action<typeof LOAD_PREVIEW_URL_ERROR> {
  id: number;
}

type LoadPreviewActions =
  | LoadPreviewUrlAction
  | LoadPreviewUrlDoneAction
  | LoadPreviewUrlErrorAction;

export function loadPreviewUrl(fileId: number, isRetry = false) {
  return async (
    dispatch: ThunkDispatch<any, any, LoadPreviewActions>,
    getState: () => RootState
  ) => {
    const { sdk } = getState().app;
    const file = itemSelector(getState())(fileId);
    if (!file) {
      if (isRetry) {
        message.error('Unable to load file.');
        return;
      }
      await dispatch(retrieve([{ id: fileId }], true));
      await dispatch(loadPreviewUrl(fileId, true));
      return;
    }
    dispatch({
      type: LOAD_PREVIEW_URL,
      id: fileId,
    });
    try {
      const pnidApi = new PnIDApi(sdk);
      const pngUrl = await pnidApi.getPngUrl(file);

      dispatch({
        type: LOAD_PREVIEW_URL_DONE,
        id: fileId,
        pngUrl,
      });
    } catch (e) {
      message.error(
        'Unable to preview P&ID. Please run the P&ID pipeline on this file.'
      );
      dispatch({
        type: LOAD_PREVIEW_URL_ERROR,
        id: fileId,
      });
    }
  };
}

type LoadActions = LoadPDFActions | LoadPreviewActions;

interface LoadPreviewResult extends ApiCall {
  pngUrl?: string;
  pdfUrl?: string;
}
interface PreviewStore {
  [key: number]: LoadPreviewResult;
}

const defaultState = {
  fetching: false,
  error: false,
  done: false,
};

const loadUrlDefaultState: LoadPreviewResult = {
  ...defaultState,
};

function previewReducer(
  state: PreviewStore = {},
  action: LoadActions
): PreviewStore {
  switch (action.type) {
    case LOAD_PREVIEW_URL: {
      return {
        ...state,
        [action.id]: { ...loadUrlDefaultState, fetching: true },
      };
    }

    case LOAD_PREVIEW_URL_DONE: {
      return {
        ...state,
        [action.id]: {
          ...(state[action.id] || loadUrlDefaultState),
          done: true,
          error: false,
          fetching: false,
          pngUrl: action.pngUrl,
        },
      };
    }

    case LOAD_PREVIEW_URL_ERROR: {
      return {
        ...state,
        [action.id]: {
          ...(state[action.id] || loadUrlDefaultState),
          done: true,
          error: true,
          fetching: false,
        },
      };
    }

    case LOAD_PDF_URL: {
      return {
        ...state,
        [action.id]: { ...loadUrlDefaultState, fetching: true },
      };
    }

    case LOAD_PDF_URL_DONE: {
      return {
        ...state,
        [action.id]: {
          ...(state[action.id] || loadUrlDefaultState),
          done: true,
          error: false,
          fetching: false,
          pdfUrl: action.pdfUrl,
        },
      };
    }

    case LOAD_PDF_URL_ERROR: {
      return {
        ...state,
        [action.id]: {
          ...(state[action.id] || loadUrlDefaultState),
          done: true,
          error: true,
          fetching: false,
        },
      };
    }

    default: {
      return state;
    }
  }
}

export const reducer = previewReducer;
export default reducer;

// Selectors
export const urlPreviewSelector = (id: number) => (
  state: RootState
): any | undefined => state.filePreview[id];

export function filesQuery(filesDataSetId: number) {
  return {
    limit: 1000,
    filter: {
      dataSetIds: [{ id: filesDataSetId }],
    },
  };
}
