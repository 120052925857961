import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 4px;
  align-items: normal;

  .content {
    padding: 32px;
  }

  .header {
    margin-bottom: 14px;
  }
`;
