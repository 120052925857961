import produce from 'immer';
import { Map } from 'immutable';
import { createSelector } from 'reselect';
import { Action, AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  InternalId,
  IdEither,
  ExternalId,
  CogniteClient,
  CogniteInternalId,
} from '@cognite/sdk';
import { RootState } from 'reducers';
import { ResourceType, Result } from './types';

export default function buildItems<
  T extends InternalId & { externalId?: string },
  U extends { id: CogniteInternalId },
  C = any
>(
  type: ResourceType,
  propRetrieveFn?: (
    sdk: CogniteClient,
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState: any
  ) => (q: IdEither[]) => Promise<T[]>,
  propCreateFn?: (
    sdk: CogniteClient,
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState: any
  ) => (q: C[]) => Promise<T[]>,
  propUpdateFn?: (
    sdk: CogniteClient,
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState: any
  ) => (q: U[]) => Promise<T[]>,
  propDeleteFn?: (
    sdk: CogniteClient,
    dispatch: ThunkDispatch<AnyAction, any, any>,
    getState: any
  ) => (q: IdEither[]) => void,
  deleteOldItems: boolean = false
) {
  const UPDATE_ITEMS = `${type}/UPDATE_ITEMS`;

  const RETRIEVE = `${type}/RETRIEVE`;
  const RETRIEVE_DONE = `${type}/RETRIEVE_DONE`;
  const RETRIEVE_ERROR = `${type}/RETRIEVE_ERROR`;

  const RETRIEVE_EXTERNAL = `${type}/RETRIEVE_EXTERNAL`;
  const RETRIEVE_EXTERNAL_DONE = `${type}/RETRIEVE_EXTERNAL_DONE`;
  const RETRIEVE_EXTERNAL_ERROR = `${type}/RETRIEVE_EXTERNAL_ERROR`;

  const UPDATE = `${type}/UPDATE`;
  const UPDATE_DONE = `${type}/UPDATE_DONE`;
  const UPDATE_ERROR = `${type}/UPDATE_ERROR`;

  const DELETE = `${type}/DELETE`;
  const DELETE_DONE = `${type}/DELETE_DONE`;
  const DELETE_ERROR = `${type}/DELETE_ERROR`;

  interface UpdateItemAction extends Action<typeof UPDATE_ITEMS> {
    result: T[];
  }

  interface RetrieveAction extends Action<typeof RETRIEVE> {
    ids: IdEither[];
  }

  interface RetrieveDoneAction extends Action<typeof RETRIEVE_DONE> {
    ids: InternalId[];
    items: T[];
  }

  interface RetrieveErrorAction extends Action<typeof RETRIEVE_ERROR> {
    ids: InternalId[];
  }

  interface RetrieveExternalAction extends Action<typeof RETRIEVE_EXTERNAL> {
    ids: ExternalId[];
  }

  interface RetrieveExternalDoneAction
    extends Action<typeof RETRIEVE_EXTERNAL_DONE> {
    ids: ExternalId[];
    items: T[];
  }

  interface RetrieveExternalErrorAction
    extends Action<typeof RETRIEVE_EXTERNAL_ERROR> {
    ids: ExternalId[];
  }

  interface UpdateAction extends Action<typeof UPDATE> {
    updates: U[];
  }

  interface UpdateDoneAction extends Action<typeof UPDATE_DONE> {
    updates: U[];
  }

  interface UpdateErrorAction extends Action<typeof UPDATE_ERROR> {
    updates: U[];
  }
  type UpdateActions = UpdateAction | UpdateDoneAction | UpdateErrorAction;

  interface DeleteAction extends Action<typeof DELETE> {
    deletes: T[];
  }

  interface DeleteDoneAction extends Action<typeof DELETE_DONE> {
    deletes: T[];
  }

  interface DeleteErrorAction extends Action<typeof DELETE_ERROR> {
    deletes: T[];
  }
  type DeleteActions = DeleteAction | DeleteDoneAction | DeleteErrorAction;

  type RetrieveActions =
    | RetrieveAction
    | RetrieveDoneAction
    | RetrieveErrorAction;

  type RetrieveExternalActions =
    | RetrieveExternalAction
    | RetrieveExternalDoneAction
    | RetrieveExternalErrorAction;

  type Actions =
    | UpdateItemAction
    | RetrieveAction
    | RetrieveExternalAction
    | DeleteActions;

  interface Request {
    inProgress: boolean;
    done: boolean;
    error: boolean;
    item?: number;
  }

  interface ItemStore {
    items: Map<number, T>;
    getById: { [key: number]: Request };
    getByExternalId: { [key: string]: Request };
  }

  function getIdsToFetch(
    ids: IdEither[],
    state: { [key: string]: Request },
    forceFetch: boolean
  ) {
    if (forceFetch) {
      return ids;
    }
    return ids.filter(id => {
      // @ts-ignore
      const i = id.id || id.externalId;
      return !state[i]?.done && !state[i]?.inProgress;
    });
  }

  function buildRetrieveAction(
    startAction: string,
    doneAction: string,
    errorAction: string,
    getRequests: (state: RootState) => { [key: string]: Request }
  ) {
    return (pendingIds: IdEither[], forceFetch = false) => {
      return async (
        dispatch: ThunkDispatch<any, any, Actions>,
        getState: () => RootState
      ) => {
        const state = getState();
        const requests = getRequests(state);
        pendingIds = getIdsToFetch(pendingIds, requests, forceFetch);

        const doRetrieve = async (
          ids: IdEither[],
          isRetry = false
        ): Promise<void> => {
          const { sdk } = state.app;
          const retrieveFn = propRetrieveFn
            ? propRetrieveFn(sdk, dispatch, getState)
            : ((sdk as any)[type].retrieve as (q: IdEither[]) => Promise<T[]>);
          if (ids.length === 0 && !forceFetch) {
            return;
          }

          dispatch({
            type: startAction,
            ids,
          });

          try {
            const result = await retrieveFn(ids);
            dispatch({
              type: UPDATE_ITEMS,
              result,
            });

            dispatch({
              type: doneAction,
              ids,
              items: result,
            });
          } catch (e) {
            if (!e.errors && typeof e.errors !== 'object') {
              dispatch({
                type: errorAction,
                ids,
              });
              return;
            }
            const failed: IdEither[] = e.errors.reduce(
              (prev: IdEither[], error: { missing?: IdEither[] }) =>
                prev.concat(error.missing || []),
              [] as IdEither[]
            );
            dispatch({
              type: errorAction,
              ids: failed,
            });
            if (!isRetry) {
              await doRetrieve(
                ids.filter(
                  el =>
                    !failed.some(item => {
                      return (
                        // @ts-ignore
                        (item.id && item.id === el.id) ||
                        // @ts-ignore
                        (item.externalId && item.externalId === el.externalId)
                      );
                    })
                ),
                true
              );
            }
          }
        };
        await doRetrieve(pendingIds, false);
      };
    };
  }

  const retrieve = buildRetrieveAction(
    RETRIEVE,
    RETRIEVE_DONE,
    RETRIEVE_ERROR,
    (state: RootState) => state[type].items.getById
  );
  const retrieveExternal = buildRetrieveAction(
    RETRIEVE_EXTERNAL,
    RETRIEVE_EXTERNAL_DONE,
    RETRIEVE_EXTERNAL_ERROR,
    (state: RootState) => state[type].items.getByExternalId
  );

  function itemReducer(
    state: Map<number, T> = Map(),
    action: UpdateItemAction | DeleteDoneAction
  ): Map<number, T> {
    switch (action.type) {
      case UPDATE_ITEMS: {
        const u: Iterable<[
          number,
          T
        ]> = (action as UpdateItemAction).result.map((i: T) => [i.id, i]);
        if (deleteOldItems) {
          return Map<number, T>(u);
        }
        return state.merge(u);
      }
      case DELETE_DONE: {
        return state.deleteAll(
          (action as DeleteDoneAction).deletes.map(el => el.id)
        );
      }
      default: {
        return state;
      }
    }
  }

  // TODO: merge with external reducer
  function buildRetrieveReducer(
    startAction: string,
    doneAction: string,
    errorAction: string
  ) {
    return (
      state: { [key: string]: Request } = {},
      action: RetrieveActions | RetrieveExternalActions
    ): { [key: string]: Request } => {
      return produce(state, draft => {
        switch (action.type) {
          case startAction: {
            action.ids.forEach(el => {
              // @ts-ignore
              const id = el.id || el.externalId;
              draft[id] = {
                ...draft[id],
                inProgress: true,
                done: false,
                error: false,
              };
            });
            break;
          }
          case doneAction: {
            action.ids.forEach((el, i) => {
              // @ts-ignore
              const id = el.id || el.externalId;
              draft[id] = {
                ...draft[id],
                item: (action as
                  | RetrieveDoneAction
                  | RetrieveExternalDoneAction).items[i].id,
                inProgress: false,
                done: true,
                error: false,
              };
            });
            break;
          }
          case errorAction: {
            action.ids.forEach(el => {
              // @ts-ignore
              const id = el.id || el.externalId;
              draft[id] = {
                ...draft[id],
                inProgress: false,
                done: true,
                error: true,
              };
            });
            break;
          }
        }
      });
    };
  }

  function update(updates: U[]) {
    return async (
      dispatch: ThunkDispatch<any, any, AnyAction>,
      getState: () => RootState
    ) => {
      dispatch({
        type: UPDATE,
        updates,
      });
      const { sdk } = getState().app;
      const updateFn = propUpdateFn
        ? propUpdateFn(sdk, dispatch, getState)
        : (sdk as any)[type].update;
      try {
        const result = await updateFn(updates);
        dispatch({
          type: UPDATE_ITEMS,
          result,
        });
        dispatch({
          type: UPDATE_DONE,
          updates,
        });
      } catch (e) {
        dispatch({
          type: UPDATE_ERROR,
          updates,
        });
      }
    };
  }

  function remove(deletes: T[]) {
    return async (
      dispatch: ThunkDispatch<any, any, AnyAction>,
      getState: () => RootState
    ) => {
      dispatch({
        type: DELETE,
        deletes,
      });

      const ids = deletes.map(el => ({ id: el.id }));
      const { sdk } = getState().app;
      const deleteFn = propDeleteFn
        ? propDeleteFn(sdk, dispatch, getState)
        : (sdk as any)[type].delete;
      try {
        await deleteFn(ids);
        dispatch({
          type: DELETE_DONE,
          deletes,
        });
      } catch (e) {
        dispatch({
          type: DELETE_ERROR,
          deletes,
        });
      }
    };
  }

  function create(updates: C[]) {
    return async (
      dispatch: ThunkDispatch<any, any, AnyAction>,
      getState: () => RootState
    ) => {
      const { sdk } = getState().app;
      const createFn = propCreateFn
        ? propCreateFn(sdk, dispatch, getState)
        : (sdk as any)[type].create;
      const result = await createFn(updates);
      dispatch({
        type: UPDATE_ITEMS,
        result,
      });
    };
  }

  function updateReducer(
    state: { [key: number]: T } = {},
    action: UpdateActions
  ) {
    return produce(state, draft => {
      switch (action.type) {
        case UPDATE: {
          action.updates.forEach(u => {
            draft[u.id] = {
              ...draft[u.id],
              inProgress: true,
            };
          });
          break;
        }
        case UPDATE_DONE: {
          action.updates.forEach(u => {
            draft[u.id] = {
              ...draft[u.id],
              inProgress: false,
              done: true,
            };
          });
          break;
        }
        case UPDATE_ERROR: {
          action.updates.forEach(u => {
            draft[u.id] = {
              ...draft[u.id],
              inProgress: false,
              error: true,
            };
          });
          break;
        }
      }
    });
  }
  function deleteReducer(
    state: { [key: number]: T } = {},
    action: DeleteActions
  ) {
    return produce(state, draft => {
      switch (action.type) {
        case DELETE: {
          action.deletes.forEach(u => {
            draft[u.id] = {
              ...draft[u.id],
              inProgress: true,
            };
          });
          break;
        }
        case DELETE_DONE: {
          action.deletes.forEach(u => {
            draft[u.id] = {
              ...draft[u.id],
              inProgress: false,
              done: true,
            };
          });
          break;
        }
        case DELETE_ERROR: {
          action.deletes.forEach(u => {
            draft[u.id] = {
              ...draft[u.id],
              inProgress: false,
              error: true,
            };
          });
          break;
        }
      }
    });
  }

  const reducer = combineReducers({
    items: itemReducer,
    delete: deleteReducer,
    update: updateReducer,
    getById: buildRetrieveReducer(RETRIEVE, RETRIEVE_DONE, RETRIEVE_ERROR),
    getByExternalId: buildRetrieveReducer(
      RETRIEVE_EXTERNAL,
      RETRIEVE_EXTERNAL_DONE,
      RETRIEVE_EXTERNAL_ERROR
    ),
  });

  function createItemSelector(
    itemsSelector: (_: RootState) => Map<number, T>,
    getByExternalId: (_: RootState) => { [key: string]: Request }
  ): (_: RootState) => (id: number | string | undefined) => T | undefined {
    return createSelector(
      itemsSelector,
      getByExternalId,
      (items, byExternalId) => (id: number | string | undefined) => {
        if (typeof id === 'number') {
          return items.get(id);
        }
        if (typeof id === 'string') {
          const request = byExternalId[id];
          const itemId = request?.item;
          return itemId ? items.get(itemId) : undefined;
        }
        return undefined;
      }
    );
  }

  function createExternalIdMapSelector(
    itemStoreSelector: (_: RootState) => ItemStore
  ) {
    return createSelector(itemStoreSelector, itemStore => {
      const { items, getByExternalId } = itemStore;
      return Object.keys(getByExternalId).reduce((accl, key) => {
        const itemId: number | undefined = getByExternalId[key]?.item;
        const item = itemId && items.get(itemId);
        if (item) {
          accl[key] = item;
        }
        return accl;
      }, {} as { [key: string]: T });
    });
  }

  function createRetrieveSelector(
    itemsSelector: (_: RootState) => Map<number, T>
  ): (_: RootState) => (ids: InternalId[]) => Result<T> {
    return createSelector(itemsSelector, allItems => (ids: InternalId[]) => {
      const items: T[] = ids
        .map(i => allItems.get(i.id))
        .filter(i => !!i) as T[];
      const doneCount = ids.filter(p => !!p).length;
      const progress = doneCount === 0 ? 0 : doneCount / ids.length;
      const fetching = progress !== 1;
      const done = progress === 1;
      return {
        progress,
        fetching,
        done,
        error: false,
        items,
      } as Result<T>;
    });
  }

  return {
    itemReducer: reducer,
    retrieve,
    update,
    create,
    remove,
    retrieveExternal,
    createExternalIdMapSelector,
    createItemSelector,
    createRetrieveSelector,
  };
}
