import React from 'react';
import { ListItem, ListItemProps } from 'components/Common';
import { CogniteType } from 'modules/types';
import { Instance } from 'modules/instances';
import { PropertyCountLabel } from '../PropertyCountLabel';

export const InstanceListItem = ({
  instance,
  type,
  style,
  onClick,
  selected,
}: {
  instance: Instance;
  type: CogniteType;
} & ListItemProps) => {
  return (
    <ListItem
      style={style}
      onClick={onClick}
      selected={selected}
      title={instance.name}
    >
      <PropertyCountLabel instance={instance} type={type} />
    </ListItem>
  );
};
